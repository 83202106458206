// @flow
import React, { type Node, useRef } from 'react'
import { View } from 'react-native'
import { Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'relay-hooks'
import useScreenSizes from 'src/utils/useScreenSizes'
import Label from 'src/components/common/atoms/Label'
import TextInputFormik from 'src/components/common/forms/TextInputFormik'
import Button from 'src/components/common/atoms/Button'
import s from './PaymentSpecialOfferSection.style'

export default function PaymentSpecialOfferSection ({
  basket
}: PropsTypes): Node {

  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const formRef: any = useRef()

  // apply voucher to order mutation
  const [applyVoucher, { loading }] = useMutation(
    graphql`
      mutation PaymentSpecialOfferSectionMutation($code:String!) {
        ApplyVoucherToBasket(code: $code) {
          id
          amount
          amountAfterReduction
          voucher {
            code
            percentage
            price
          }
        }
      }
    `,
    {
      onCompleted: (res) => {console.log('onCompleted', res)},
      onError: (errors) => {
        // Handle errors
        for (let error of errors) {
          switch (error.errorCode) {
            case '145001':
              formRef.current.setFieldError('code', t('BasketScreen.errors.invalidVoucher'))
              break
            case '145002':
              formRef.current.setFieldError('code', t('BasketScreen.errors.usedVoucher'))
              break
            case '145003':
              formRef.current.setFieldError('code', t('BasketScreen.errors.invalidVoucher'))
              break
            case '145004':
              formRef.current.setFieldError('code', t('BasketScreen.errors.expiredVoucher'))
              break
            case '145006':
              formRef.current.setFieldError('code', t('BasketScreen.errors.expiredVoucher'))
              break
            case '145007':
              formRef.current.setFieldError('code', t('BasketScreen.errors.notElligibleVoucher'))
              break
            case '145008':
              formRef.current.setFieldError('code', t('BasketScreen.errors.notElligibleVoucher'))
              break
            default :
              formRef.current.setFieldError('code', t('Yup.global.errorTryAgain'))
          }
        }
      }
    }
  )

  return (
    <View style={[s.specialOfferContainer, !isMobile && s.specialOfferContainerDesktop]}>
      <Formik
        innerRef={formRef}
        initialValues={{ code: '' }}
        onSubmit={(values) => applyVoucher({ variables: { ...values } })}
      >
        {(formikProps) => (
          <>
            {formikProps.errors.code && (
              <Label size={isMobile ? 9 : 11} type={'error'} style={s.specialOfferError}>{formikProps.errors.code}</Label>
            )}

            <View style={s.specialOfferContent}>
              <Label size={isMobile ? 9 : 16} style={s.specialOfferLabel}>{t('BasketScreen.specialOffer.question')}</Label>

              <View style={s.specialOfferForm}>
                <TextInputFormik
                  size={isMobile ? 'XS' : 'S'}
                  placeholder={t('BasketScreen.specialOffer.enter')}
                  {...formikProps}
                  name={'code'}
                  style={[s.specialOfferInput, !isMobile && s.specialOfferInputDesktop]}
                  onSubmitEditing={formikProps.handleSubmit}
                />
                <Button
                  size={isMobile ? 'XS' : 'S'}
                  onPress={formikProps.handleSubmit}
                  disabled={!(formikProps.isValid && formikProps.dirty)}
                  isLoading={loading}
                  style={[s.specialOfferButton, !isMobile && s.specialOfferButtonDestop]}
                >{t('BasketScreen.specialOffer.ok')}</Button>
              </View>
            </View>

            {basket?.voucher && (
              <Label size={isMobile ? 8 : 10}>{basket.voucher.code} -{basket.voucher.percentage}%</Label>
            )}
          </>
        )}
      </Formik>
    </View>
  )
}

type PropsTypes = {
  basket: any
}