import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

export default StyleSheet.create({
  container: {
    width: '100%'
  },
  containerDesktop: {
    width: '100%',
    paddingHorizontal: 20
  },
  progressBar: {
    height: 3,
    backgroundColor: Colors.red100
  }
})
