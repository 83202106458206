import config from 'react-native-config';

const parseBoolean = (input: string) => {
  return input === 'true';
};

/**
 * Export environment variable
 */
export const VERSION = '5.3.5';
export const ENVIRONMENT = config.ENVIRONMENT;
export const EQQI_API_PRIVATE_ENDPOINT = config.EQQI_API_PRIVATE_ENDPOINT;
export const EQQI_API_ADYEN_ENDPOINT = config.EQQI_API_ADYEN_ENDPOINT;
export const SENTRY_ENVIRONMENT = config.SENTRY_ENVIRONMENT;
export const SENTRY_DEBUG = parseBoolean(config.SENTRY_DEBUG);
export const SENTRY_DSN = config.SENTRY_DSN;
export const ADYEN_ENV = config.ADYEN_ENV;
export const ADYEN_CLIENT_KEY = config.ADYEN_CLIENT_KEY;
export const ADYEN_PUBLIC_KEY = config.ADYEN_PUBLIC_KEY;
export const ADYEN_ORIGIN_KEY = config.ADYEN_ORIGIN_KEY;
export const INTERCOM_APP_ID = config.INTERCOM_APP_ID;
