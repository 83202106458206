// @flow
import React, { useRef, useEffect, type Node } from 'react'
import { TouchableWithoutFeedback, Animated, Text, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Field } from 'formik'
import s, { sAsObject } from './SwitchInputFormik.style'

/**
 * SwitchInputFormik atom.
 */
export default function SwitchInputFormik ({
    styles,
    style,
    size = 'M',
    palette = 'default',
    isDisabled = false,
    values = {},
    setFieldValue,
    onChange = () => {},
    name = ''
}: PropsTypes) : Node {

  // Init state
  const { t } = useTranslation()
  const onPosition= sAsObject['circle' + size + 'On'].left
  const offPosition= sAsObject['circle' + size + 'Off'].left
  const slideAnim = useRef(new Animated.Value(values[name] ? onPosition : offPosition)).current
  const isEnabledText = values[name] ? t('SwitchInputFormik.on') : t('SwitchInputFormik.off')

  // Animation on switch
  useEffect(() => {
    Animated.timing(
      slideAnim,
      {
        toValue: values[name] ? onPosition : offPosition,
        duration: 300,
        useNativeDriver: false
      }
    ).start()
  }, [values[name]])

  // Render component
  return (
    <Field
      name={name}
      component={() => (
        <TouchableWithoutFeedback
          onPress={!isDisabled ? () => {
            setFieldValue && setFieldValue(name, !values[name]) && onChange(!values[name])
          }: undefined}
        >
          <View
            style={[
              s.container,
              isDisabled && s.containerDisabled,
              s[size],
              s[size + (values[name] ? 'On' : 'Off')],
              style,
              styles?.container
            ]}
          >
            {/* Circle */}
            <Animated.View
              style={[
                values[name] ? s[palette + 'On'] : s[palette + 'Off'],
                isDisabled && s.disabled,
                s['circle' + size],
                {
                  left: slideAnim,
                  zIndex: 100
                }
              ]}
            />
            {/* Text */}
            <Text
              style={[
                s.text,
                isDisabled && s.textDisabled,
                s['text' + size],
                s['text' + size + (values[name] ? 'On' : 'Off')]
              ]}
            >
              {isEnabledText}
            </Text>
          </View>
        </TouchableWithoutFeedback>
      )}
    />
  )
}

// PropsTypes
type PropsTypes = {
  name?: string,
  styles?: any,
  style?: any,
  values: any,
  setFieldValue?: function,
  onChange?: function,
  defaultValue?: boolean,
  size?: 'S' | 'M' | 'L' | 'XL',
  palette?: string,
  isDisabled?: boolean
}