// @flow
import React, { type Node, useState } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import Icon from 'src/components/common/atoms/Icon'
import Label from 'src/components/common/atoms/Label'
import useScreenSizes from 'src/utils/useScreenSizes'
import Button from 'src/components/common/atoms/Button'
import Loader from 'src/components/common/atoms/Loader'
import { Colors } from 'src/styles'
import { freeBasketModalContent as s } from 'src/components/common/moles/PayModal/PayModal.style'

export default function FreeBasketModalContent ({
  payableType,
  handlePay,
  onClose,
  setModalStep,
  setPaidBasket
}: PropsTypes): Node {

  // init values
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const handlePaymentSubmit = async () => {
    setLoading(true)
    await handlePay({ variables: { paymentMethod: 'METHOD_FREE' } })
      .then(() => {
        setLoading(false)
        setSuccess(true)
        setModalStep()
        setPaidBasket(true)
      })
      .catch(() => {
        setLoading(false)
        setError(true)
      })
  }

  return (
    <>
      <Label size={!isMobile ? 16 : 14}>{t('PayBasketModal.FreeBasketModalContent.title.' + payableType)}</Label>
      {
        loading ? (
            <View style={s.iconContainer}>
              <Loader size={20}/>
            </View>
          ) :
          success ? (
            <View style={s.iconContainer}>
              <Icon name={'check'} color={Colors.orange100}/>
            </View>
          ) : (
            error ? (
              <View style={[s.iconContainer, s.errorContainer]}>
                <Icon name={'close'} color={Colors.orange100} style={s.errorIcon}/>
                <Label type={'error'} style={s.textCenter}>{t('PayBasketModal.error')}</Label>
              </View>
            ) : (
              <View style={s.container}>
                <Button
                  palette={'primary'}
                  size={'M'}
                  fullWidth
                  onPress={handlePaymentSubmit}
                  style={s.button}>
                  {t('PayBasketModal.FreeBasketModalContent.title.' + payableType)}
                </Button>

                <Button onPress={onClose} size={'M'} fullWidth style={s.button}>
                  {t('PayBasketModal.backToWalletModal')}
                </Button>
              </View>
            )
          )
      }
    </>
  )
}

type PropsTypes = {
  handlePay: function,
  onClose: function
}