import React, { type Node, useEffect, useState } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'relay-hooks'
import SkeletonContent from 'react-native-skeleton-content-nonexpo'
import useScreenSizes from 'src/utils/useScreenSizes'
import Label from 'src/components/common/atoms/Label'
import Loader from 'src/components/common/atoms/Loader'
import Icon from 'src/components/common/atoms/Icon'
import Button from 'src/components/common/atoms/Button'
import ReceptionContent from './ReceptionContent'
import LockerContent from './LockerContent'
import ClosetContent from './ClosetContent'
import s, { modalSkeletonLayout } from './CoverDeliveryModal.style'
import { Colors } from 'src/styles'
import GlobalRefetchStore from 'src/store/GlobalRefetchStore'

/**
 * CoverRecoveryModal component
 */
export default function CoverDeliveryModal ({
  onClose,
  setOnCloseParam,
  setAllowClosing,
  orderId,
}: PropsTypes): Node {
  /*
   * API Query: get order
   */
  const { data } = useQuery(graphql`
    query CoverDeliveryModalQuery($id: Int!) {
      order(id: $id) {
        id
        rowId
        deliveryLocker {
          name
          lockerType
        }
      }
    }
  `, { id: orderId })

  /*
   * API Mutation: user retrieved order
   */
  const [userRetrievedOrderMutation] = useMutation(
    graphql`
      mutation CoverDeliveryModalMutation($orderId: Int!) {
        UserRetrievedOrder(orderId: $orderId) {
          id
          status
          statusName
          allowedCustomerActions
          primeCustomerAction
        }
      }
    `,
    {
      onCompleted: () => {
        setAllowClosing(true)
        setCurrentStep(steps.DELIVERY_SUCCESS)
        GlobalRefetchStore.update(s => {
          s.bestServicesKey = new Date()
        })
      },
      onError: () => setCurrentStep(steps.DELIVERY_READY)
    }
  )

  /*
   * Init vars
   */
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const steps = {
    DELIVERY_READY : 10,
    DELIVERY_PROCESSING: 20,
    DELIVERY_SUCCESS: 30
  }
  const [currentStep, setCurrentStep] = useState(steps.DELIVERY_READY)
  const types = {
    KERN: 'KERN_SLAVE_LOCKER',
    LOCAL: 'LOCAL',
    SPARTIME: 'SPARTIME'
  }
  const order = data?.order
  const type = order?.deliveryLocker.lockerType
  const lockerName = order?.deliveryLocker.name

  /*
   * Add "true" param to onClose when collect is successful
   * Triggers isDone param in handleCloseDeliverModal (ProfileOrdersScreen)
   */
  useEffect(() => {
    if (currentStep === steps.DELIVERY_SUCCESS) {
      setOnCloseParam(true)
    }
  }, [currentStep])


  /*
   * Next step function
   */
  const onRetrievedOrder = () => {
    setCurrentStep(steps.DELIVERY_PROCESSING)
    setAllowClosing(false)
    userRetrievedOrderMutation({ variables: { orderId: orderId } })
  }

  /*
   * Render component
   */
  return (
    <View>

      <SkeletonContent
        isLoading={!order}
        containerStyle={!order ? {width: '100%', alignItems: 'center'} : {}}
        layout={modalSkeletonLayout}
      >

        {/* Modal Title */ }
        <Label style={s.modalTitle} size={!isMobile ? 16 : 14}>{t('CoverDeliveryModal.title', {orderNumber: orderId})}</Label>

        {/* Dynamic content depending on type */}
        {currentStep === steps.DELIVERY_READY && type === types.LOCAL && <ReceptionContent
          onRetrievedOrder={onRetrievedOrder}
          onClose={onClose}
          />}

        {currentStep === steps.DELIVERY_READY && type === types.KERN && <LockerContent
          orderId={orderId}
          onRetrievedOrder={onRetrievedOrder}
          onClose={onClose}
          lockerName={lockerName}
          />}

        {currentStep === steps.DELIVERY_READY && type === types.SPARTIME && <ClosetContent
          orderId={orderId}
          onRetrievedOrder={onRetrievedOrder}
          onClose={onClose}
        />}

        {/* Processing step */}
        {currentStep === steps.DELIVERY_PROCESSING &&
          <View style={s.processingContainer}>
            <Loader color={Colors.orange100} size={isMobile ? 20 : 24} />
          </View>
        }

        {/* Success step */}
        {currentStep === steps.DELIVERY_SUCCESS &&
          <>
            <View style={s.processingContainer}>
              <Icon name={'check'} color={Colors.orange100} size={isMobile ? 16 : 20} />
            </View>
            <Button onPress={() => onClose(true)} palette={'primary'} size={isMobile ? 'S' : 'M'} style={s.successCloseButton}>{t('App.close')}</Button>
          </>
        }

      </SkeletonContent>

    </View>
  )
}

type PropsTypes = {
  orderId: number
}