/**
 * @generated SignedSource<<d83137a55d818bc3426b0c135bc8ffc0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pictureURL",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileMenuQuery",
    "selections": (v0/*: any*/),
    "type": "ClientAppQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProfileMenuQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "309284b0d3d3fed1130106800cc42d2d",
    "id": null,
    "metadata": {},
    "name": "ProfileMenuQuery",
    "operationKind": "query",
    "text": "query ProfileMenuQuery {\n  user {\n    id\n    firstName\n    lastName\n    pictureURL\n  }\n}\n"
  }
};
})();

node.hash = "8d435980ccd7c66fa32e644960975e8c";

module.exports = node;
