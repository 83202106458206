import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

// Styles
export default StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    marginHorizontal: 15,
    marginTop: 10
  },
  containerDesktop: {
    marginLeft: 40,
    marginRight: 30,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },

  desktopHeaderContainer: {
    height: 120,
    paddingLeft: 30,
    backgroundColor: Colors.orange100Less80,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 40
  },
  desktopBackgroundIcon: {
    position: 'absolute',
    color: Colors.white100Less50,
    right: 25,
    transform: [{ rotate: '-29deg' }]
  },
  desktopTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  desktopTitleIcon: {
    color: Colors.orange100,
    marginRight: 30
  },
})