import React, { type Node } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'relay-hooks'
import moment from 'moment'
import SkeletonContent from 'react-native-skeleton-content-nonexpo'
import Button from 'src/components/common/atoms/Button'
import Label from 'src/components/common/atoms/Label'
import OrderHeaderModalContent from './OrderHeaderModalContent'
import OrderProductsModalContent from './OrderProductsModalContent'
import OrderDeliveryModalContent from './OrderDeliveryModalContent'
import OrderPaymentModalContent from './OrderPaymentModalContent'
import OrderCancellationModalContent from './OrderCancellationModalContent'
import { actions } from '../constants/OrderActionsConstant'
import s, { skeletonLayout } from './OrderModal.style'
import useScreenSizes from 'src/utils/useScreenSizes'

/**
 * OrderModal content component
 */
export default function OrderModal ({
  onClose,
  orderId,
  handleAction
}: PropsTypes): Node {
  /*
   * API query
   */
  const { data } = useQuery(graphql`
    query OrderModalQuery ($id: Int!) {
      user {
        id
        lang
      }
      order (id: $id) {
        id
        rowId
        service {
          id
          name
          logoURL
        }
        orderProducts {
          id
          name
          quantity
          price
          unitPrice
        }
        status
        paymentStatus
        paymentDateTime
        amount
        deliveryPrice
        deliveryLocker {
          id
          name
        }
        deliveryDate
        collectDate
        createdAt
        primeCustomerAction
        allowedCustomerActions
        impossibleCancelReason
      }
    }
  `, {id: orderId}, { networkCacheConfig: { force: true }})
  const order = data?.order
  const lang = data?.user.lang

  /*
   * Init vars
   */
  moment.locale(lang)
  const { t } = useTranslation()
  const primeAction = order?.primeCustomerAction
  const allowedCustomerActions = order?.allowedCustomerActions
  const { isMobile } = useScreenSizes()

  /*
   * Check allowed action
   */
  const hasAllowedAction = (action) => {
    if (!allowedCustomerActions) return false
    return allowedCustomerActions.indexOf(action) >= 0
  }

  /*
   * Render component
   */
  return (
    <SkeletonContent
      isLoading={!order}
      containerStyle={{}}
      layout={skeletonLayout}
    >

      {/* OrderModal header */}
      <OrderHeaderModalContent
        id={order?.rowId}
        status={order?.status}
        createdAt={order?.createdAt}
        amount={order?.amount}
        primeAction={primeAction}
        handleAction={handleAction}
      />

      {/* OrderModal product list */}
      <OrderProductsModalContent
        serviceName={order?.service.name}
        serviceIcon={order?.service.logoURL}
        products={order?.orderProducts}
      />

      {/* OrderModal delivery informations */}
      <OrderDeliveryModalContent
        price={order?.deliveryPrice}
        lockerName={order?.deliveryLocker?.name}
        collectDate={order?.collectDate}
        deliveryDate={order?.deliveryDate}
        hasAllowedAction={hasAllowedAction}
        handleAction={handleAction}
      />

      {/* OrderModal payment actions */}
      <OrderPaymentModalContent
        allowedActions={order?.allowedCustomerActions}
        amount={order?.amount}
        paymentStatus={order?.paymentStatus}
        payedAt={order?.paymentDateTime}
        primeAction={primeAction}
        hasAllowedAction={hasAllowedAction}
        handleAction={handleAction}
      />

      {/* OrderModal cancel order action */}
      {(primeAction === actions.PAY || primeAction === actions.COLLECT) &&
        <OrderCancellationModalContent
          hasAllowedAction={hasAllowedAction}
          handleAction={handleAction}
          impossibleCancelReason={order?.impossibleCancelReason}
          collectDate={order?.collectDate}
        />
      }

      {/* OrderModal rate */}
      {hasAllowedAction(actions.RATE) &&
        <View style={s.block}>
          <View style={s.blockTitle}>
            <Label size={isMobile ? 12 : 14} type={'bold'}>{t('OrderModal.rateTitle')}</Label>
          </View>
          <View style={s.blockButtonsRow}>
            <Button size={isMobile ? 'S' : 'M'} style={s.btn} onPress={() => handleAction(actions.RATE)}>{t('ProfileOrderCard.rate')}</Button>
          </View>
        </View>
      }

      {/* OrderModal other actions */}
      <View style={s.lastBlock}>
        <View style={s.blockButtonsRow}>

          <Button size={isMobile ? 'S' : 'M'} style={s.btn} onPress={onClose}>{t('OrderModal.goBack')}</Button>

          {hasAllowedAction(actions.CONTACT) &&
            <Button
              size={isMobile ? 'S' : 'M'}
              style={s.btn}
              onPress={() => handleAction(actions.CONTACT)}
            >
              {t('OrderModal.askQuestion')}
            </Button>
          }

        </View>
      </View>

    </SkeletonContent>
  )
}

type PropsTypes = {
  orderId: number,
  handleAction: function
}