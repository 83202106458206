import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

// Styles
export default StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    height: 150,
    padding: 10,
    paddingTop: 4
  },
  header: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 6
  },
  imageBackground: {
    flex: 3,
    justifyContent: 'center',
    paddingRight: 20,
    paddingLeft: 30
  },
  image: {
    borderRadius: 6
  },
  imageText: {
    zIndex:1,
    textAlign: 'right'
  },
  skeletonTitle: {
    width: 80,
    height: 16,
    marginTop: 4
  },
  linearGradient: {
    position: 'absolute',
    right: 0,
    top: 0,
    bottom: 0,
    left: 0,
    borderRadius: 5
  }
})