import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    width: '100%',
    alignItems: 'center'
  },
  border: {
    borderBottomWidth: 1
  }
})
