import { StyleSheet } from 'react-native'

// Styles
export default StyleSheet.create({
  container: {
    height: '100%'
  },
  skeletonContainer: {
    paddingHorizontal: 10,
    marginTop: 20,
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  skeletonContainerDesktop: {
    paddingHorizontal: 10,
    flexWrap: 'wrap',
    flexDirection: 'row'
  },
  subContainer: {
    flex: 1
  },
  list: {
    width: '100%'
  },
  listContentContainer: {
    paddingHorizontal: 20,
    paddingBottom: 100,
    // alignItems: 'center',
  },
  listContentContainerDesktop: {
    paddingHorizontal: 40,
    // alignItems: 'flex-start',
  }
})

// Skeleton
export const productsSkeleton = [
  {width: 130, height: 180, margin: 10},
  {width: 130, height: 180, margin: 10},
  {width: 130, height: 180, margin: 10},
  {width: 130, height: 180, margin: 10},
  {width: 130, height: 180, margin: 10},
  {width: 130, height: 180, margin: 10}
]

export const productsSkeletonDesktop = [
  {width: '100%', height: 225, marginBottom: 20},
  {width: 280, height: 300, margin: 10},
  {width: 280, height: 300, margin: 10},
  {width: 280, height: 300, margin: 10},
  {width: 280, height: 300, margin: 10},
  {width: 280, height: 300, margin: 10},
  {width: 280, height: 300, margin: 10},
  {width: 280, height: 300, margin: 10},
  {width: 280, height: 300, margin: 10}
]