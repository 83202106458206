import AsyncStorage from '@react-native-async-storage/async-storage'

/**
 * LocalStorage util to interact with AsyncStorage cross-platform.
 */
export default {

  /**
   * Set value in AsyncStorage.
   */
  setItem: async (key, value) => {
    try {
      value = typeof value === 'object' ? JSON.stringify(value) : value
      await AsyncStorage.setItem(key, value)
    } catch (e) {
      console.error('There was an error while trying to set value for key "' + key + '" inside AsyncStorage')
    }
  },

  /**
   * Get value from AsyncStorage.
   */
  getItem: async (key, parse = false) => {
    try {
      let value = await AsyncStorage.getItem(key)
      return parse ? JSON.parse(value) : value
    } catch (e) {
      console.error('There was an error while trying to get value for key "' + key + '" from AsyncStorage')
    }
  },

  /**
   * Delete value from AsyncStorage.
   */
  removeItem: async (key) => {
    try {
      await AsyncStorage.removeItem(key)
    } catch (e) {
      console.error('There was an error while trying to remove key "' + key + '" from AsyncStorage')
    }
  }

}
