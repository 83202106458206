import React, { type Node } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import useScreenSizes from 'src/utils/useScreenSizes'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import s from './CoverCollectModal.style'

/**
 * DepositCancelModalContent component
 */
export default function DepositCancelModalContent ({
  style,
  styles,
  onClose,
  collectDate
}: PropsTypes): Node {
  /*
   * Init vars
   */
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()

  /*
   * Render component
   */
  return (
    <View style={[style, styles?.container]}>
      <Label size={isMobile ? 10 : 12}>{t('CoverDepositModal.DepositCancelModalContent.description')}</Label>
      <View style={s.buttonsContainer}>
        <Label types={['bold', 'smallMargin']} style={s.validationLimitDate} size={!isMobile ? 16 : 14}>
          {t('CoverDepositModal.DepositCancelModalContent.limitDate', { date: collectDate })}
        </Label>
        <Button onPress={onClose} palette={'primary'} size={'M'} style={s.validationButton}>
          {t('CoverDepositModal.DepositCancelModalContent.understoodBtn')}
        </Button>
      </View>
    </View>
  )
}

type PropsTypes = {
  style?: any,
  styles?: any,
  onClose: function,
  collectDate: string
}