import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

export default StyleSheet.create({
  paymentContainer: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    padding: 10,
    paddingBottom: 15,
    borderLeftColor: Colors.orange100,
    borderLeftWidth: 2,
    backgroundColor: 'white'
  },
  paymentContainerDesktop: {
    paddingTop: 20,
    paddingBottom: 0
  },
  paymentContainerTablet: {
    marginTop: 40
  },
  paymentHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: 15
  },
  paymentHeaderDesktop: {
    marginBottom: 30
  },
  paymentButton: {
    marginBottom: 10
  },
  paymentButtonDesktop: {
    marginBottom: 20,
  },
  toPay: {
    textAlign: 'right'
  }
})