/**
 * @generated SignedSource<<f0ec64c5e264f23d79aafac988d8c2c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Locker",
    "kind": "LinkedField",
    "name": "basketAvailableLockers",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rowId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectLockerModalQuery",
    "selections": (v0/*: any*/),
    "type": "ClientAppQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SelectLockerModalQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "6c9195f7b9bb8be44defad6743db0d98",
    "id": null,
    "metadata": {},
    "name": "SelectLockerModalQuery",
    "operationKind": "query",
    "text": "query SelectLockerModalQuery {\n  basketAvailableLockers {\n    id\n    rowId\n    name\n  }\n}\n"
  }
};
})();

node.hash = "e17f6f7deb9a9d0da24b1a052e3c1bf9";

module.exports = node;
