import React, { type Node } from 'react'
import useScreenSizes from 'src/utils/useScreenSizes'
import { ImageBackground } from 'react-native'
import Label from 'src/components/common/atoms/Label'
import Icon from 'src/components/common/atoms/Icon'
import s from './FamilyCard.style'
import { LinearGradient } from 'expo-linear-gradient'

/**
 * FamilyCard mole.
 */
const FamilyCard = ({
  style,
  styles,
  title,
  pictureURL,
  color,
  isFav = false
}: PropsTypes): Node => {

  const { isMobile } = useScreenSizes()

  // Render mole
  return (
    <ImageBackground
      style={[s.container, !isMobile && s.containerDesktop, style, styles?.container]}
      source={pictureURL ? { uri: pictureURL } : require('assets/img/screens/private/CatalogScreen/family_card.png')}
    >
      {isFav && <Icon name={'favorite-filled'} color={color} size={20} style={{zIndex: 1}}/>}
      <Label
        style={s.title}
        types={['bold', 'smallMargin']}
        color={'#fff'}
        size={!isMobile ? 28 : 14}
      >
        {title}
      </Label>

      <LinearGradient
        colors={['transparent', 'rgba(0,0,0,0.74)']}
        style={[s.linearGradient, !isMobile && s.linearGradientDesktop]}
      />
    </ImageBackground>
  )
}

// Memo
export default React.memo(FamilyCard, (prev, next) => {
  return prev.title === next.title &&
    prev.pictureURL === next.pictureURL &&
    prev.color === next.color &&
    prev.isFav === next.isFav
})

// PropsTypes
type PropsTypes = {
  style?: any,
  styles?: any,
  title: string,
  color: string,
  isFav?: boolean
}