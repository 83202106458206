// @flow
import React, { type Node, useState } from 'react'
import { Image, View, Platform, Dimensions } from 'react-native'
import { Trans, useTranslation } from 'react-i18next'
import { formatPrice } from 'src/utils/FormatDataUtils'
import useScreenSizes from 'src/utils/useScreenSizes'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import Loader from 'src/components/common/atoms/Loader'
import Icon from 'src/components/common/atoms/Icon'
import { Colors } from 'src/styles'
import { payWithRecurringCardModalContent as s } from './PayModal.style'

export default function PayWithRecurringCardModalContent ({
  amount,
  onClose,
  selectedCard,
  setPaidBasket,
  setModalStep,
  handlePay,
  modalSteps
}: PropsTypes): Node {

  // init values
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const handlePaymentSubmit = () => {
    setError(false)
    setLoading(true)
    handlePay({
      variables: {
        paymentMethod: 'METHOD_REGISTERED_CREDIT_CARD',
        browserInfo: {
          browser: Platform.OS === 'web' ? {
            acceptHeader: 'application/json',
            colorDepth: window.screen.colorDepth,
            javaEnabled: navigator.javaEnabled(),
            language: navigator.language,
            screenHeight: Dimensions.get('window').height,
            screenWidth: Dimensions.get('window').width,
            timeZoneOffset: new Date().getTimezoneOffset(),
            userAgent: navigator.userAgent
          } : {},
          isFromAndroid: Platform.OS === 'android',
          isFromIos: Platform.OS === 'ios'
        },
        recurringCreditCard: {
          contractId: selectedCard.token
        }
      }
    }).then(() => {
      setLoading(false)
      setSuccess(true)
      setModalStep()
      setPaidBasket(true)
    }).catch(() => {
      setLoading(false)
      setError(true)
    })
  }

  return (
    <>
      <Label size={!isMobile ? 16 : 14}>{t('PayBasketModal.PayWithNewCardModalContent.title')}</Label>

      {
        loading ? (
            <View style={s.iconContainer}>
              <Loader size={20}/>
            </View>
          ) :
          success ? (
            <View style={s.iconContainer}>
              <Icon name={'check'} color={Colors.orange100}/>
            </View>
          ) : (
            error ? (
              <View style={[s.iconContainer, s.errorContainer]}>
                <Icon name={'close'} color={Colors.orange100} style={{ marginBottom: 15 }}/>
                <Label type={'error'} style={s.textCenter}>{t('PayBasketModal.error')}</Label>
              </View>
            ) : (
              <>
                <Label size={isMobile ? 10 : 12} style={s.amountToPay}>
                  <Trans
                    i18nKey={'PayBasketModal.toPay'}
                    values={{ amount: formatPrice(amount) }}
                    components={{ b: <Label type={'bold'} size={isMobile ? 10 : 12}/> }}
                  />
                </Label>

                <Button palette={'primary'}
                        size={'M'}
                        fullWidth
                        onPress={() => handlePaymentSubmit()}
                        style={s.button}>
                  {t('PayBasketModal.PayWithRecurringCardModalContent.recurringCard', { cardNumber: selectedCard.cardNumber })}
                </Button>

                <Button onPress={onClose} size={'M'} fullWidth style={s.button}>
                  {t('PayBasketModal.changePaymentMethod')}
                </Button>

                <View style={s.cardsLogoContainer}>
                  <Image
                    source={require('assets/img/screens/private/profile/ProfileWalletScreen/FillWalletModal/NewCreditCardModalContent/logo_cb.png')}
                    style={s.cardsImage}
                  />
                </View>
                <View style={s.securePaymentContainer}>
                  <Label size={isMobile ? 8 : 10}>{t('PayBasketModal.securePaymentsByPartner')}</Label>
                  <Image
                    source={require('assets/img/screens/private/profile/ProfileWalletScreen/FillWalletModal/NewCreditCardModalContent/adyen_logo.png')}
                    style={s.adyenLogo}
                  />
                </View>
              </>
            )
          )
      }
    </>
  )
}

type PropsTypes = {
  amount: number,
  onClose: function,
  selectedCard: any,
  setPaidBasket: function,
  setModalStep: function,
  handlePay: function
}