/**
 * @generated SignedSource<<7fab269671018915a484b40542d4c19a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "BasketDeliveryDate",
    "kind": "LinkedField",
    "name": "basketAvailableDeliveryDates",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "price",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectDeliveryDateModalQuery",
    "selections": (v0/*: any*/),
    "type": "ClientAppQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SelectDeliveryDateModalQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "655100e5022572f9f4b350300f988f04",
    "id": null,
    "metadata": {},
    "name": "SelectDeliveryDateModalQuery",
    "operationKind": "query",
    "text": "query SelectDeliveryDateModalQuery {\n  basketAvailableDeliveryDates {\n    date\n    price\n  }\n}\n"
  }
};
})();

node.hash = "9480a9067c0db1b91f367cd1a70d86ab";

module.exports = node;
