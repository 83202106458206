import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

// Styles
export default StyleSheet.create({
  container: {
    flexDirection: 'column',
    height: '100%',
    marginTop: 10
  },
  containerDesktop: {
    marginTop: 0,
    marginLeft: 20,
  },
  skeletonContainer: {
    paddingHorizontal: 30,
    marginTop: 20
  },
  subContainer: {
    flex: 1
  },
  listContainer: {
    paddingHorizontal: 20,
    paddingBottom: 100
  },
  listContainerDesktop: {
    paddingLeft: 20,
    paddingRight: 40,
    paddingBottom: 100
  },
  list: {
    width: '100%',
    paddingBottom: 16
  },
  familyCard: {
    marginTop: 14,
    marginBottom: 14
  },
  familyCardDesktop: {
    marginVertical: 40
  },
  goodDealListContainer: {
    marginRight: 20
  }
})

// Skeleton
export const serviceItemsSkeleton = [
  {width: '100%', height: 50, marginBottom: 14},
  {width: '100%', height: 50, marginBottom: 14},
  {width: '100%', height: 50, marginBottom: 14},
  {width: '100%', height: 50, marginBottom: 14},
  {width: '100%', height: 50, marginBottom: 14},
  {width: '100%', height: 50, marginBottom: 14},
  {width: '100%', height: 50, marginBottom: 14},
  {width: '100%', height: 50, marginBottom: 14}
]