import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  iconContainer: {
    height: 180,
    alignSelf: 'center',
    justifyContent: 'center'
  },
  errorContainer: {
    textAlign: 'center'
  },
  errorIcon: {
    marginBottom: 15
  }
})