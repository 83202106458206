// @flow
import React, { type Node } from 'react'
import { View, Image, StyleSheet } from 'react-native'
import SkeletonContent from 'react-native-skeleton-content-nonexpo'
import { Sizes, Colors } from 'src/styles'

/**
 * Avatar atom.
 */
export default function Avatar ({
  source = null,
  isActive = true,
  size = Sizes.XL,
  skeleton = false, // {width: xx, height: xx} | true
  style
}: PropsTypes) : Node {

  // Dynamic style rules
  const dynamicStyles = {
    width: size,
    height: size,
    borderRadius: size,
    borderColor: isActive ? Colors.orange100 : Colors.darkGrey100
  }

  if (source === null && skeleton) {
    return (<SkeletonContent
      containerStyle={[style, {width: size, height: size, borderRadius: size, borderWidth: 0}]}
      isLoading={true}
      layout={[{width: size, height: size, borderRadius: size}]}
    />)
  }

  const src = typeof source === 'string' ? source : source.uri

  // Render component
  return (
    <View
      style={[
        s.container,
        dynamicStyles,
        style
      ]}
    >
      {source &&
        <Image
          source={{ uri: src }}
          resizeMode={'cover'}
          style={{ width: size, height: size }}
        />
      }
    </View>
  )
}

// PropsTypes
type PropsTypes = {
  style?: any,
  source?: any,
  isActive?: boolean,
  size?: number,
  skeleton?: any
}

// Styles
const s = StyleSheet.create({
  container: {
    backgroundColor: Colors.lightGrey100,
    overflow: 'hidden',
    borderWidth: 2
  }
})