import { StyleSheet, Platform } from 'react-native'
import { Colors } from 'src/styles'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'

// Styles
export default StyleSheet.create({
  mainContainer: {
    width: wp('100%'),
    maxWidth: 500,
    alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    justifyContent: 'space-between',
    paddingLeft: 15,
    paddingRight: 15,
  },
  mainContainerDesktop: {
    maxWidth: '100%',
    width: '50%'
  },
  mainContainerTablet: {
    maxWidth: '100%',
    width: '80%'
  },
  formContainer: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    marginTop: 30
  },
  formContainerDesktop: {
    marginTop: 60
  },
  updatePassword: {
    color: Colors.darkGrey100,
    textAlign: 'center',
    marginBottom: 10
  },
  updatePasswordDesktop: {
    marginBottom: 15
  },
  updatePasswordDetail: {
    color: Colors.darkGrey100,
    textAlign: 'center',
    marginBottom: 5
  },
  updatePasswordDetailDesktop: {
    marginBottom: 30,
  },
  textInput: {
    marginTop: 15,
  },
  error: {
    marginTop: 5,
    marginLeft: 30,
    marginBottom: -9,
    color: Colors.red100
  },
  errorDesktop: {
    marginTop: 10,
    marginLeft: 65,
    marginBottom: -10
  },
  generalError: {
    textAlign: 'center',
    marginTop: 20,
    marginBottom: -10,
    marginLeft: 0
  },
  textInputDesktop: {
    marginTop: 30,
  },
  submitButton: {
    marginTop: 30,
    width: '60%'
  },
  submitButtonDesktop: {
    marginTop: 60,
    width: '50%'
  },
  cancelButton: {
    marginTop: 20,
    width: '60%'
  },
  cancelButtonDesktop: {
    marginTop: 30,
    width: '50%'
  },
  mainContainerSuccess: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 30
  },
  mainContainerSuccessDesktop: {
    paddingLeft: 30,
    paddingRight: 30
  },
  successIcon: {
    color: Colors.orange100
  },
  successIconDesktop: {
    color: Colors.orange100,
    marginTop: 60
  },
  successTitle: {
    marginTop: 15,
    marginBottom: 30,
  },
  successTitleDesktop: {
    marginTop: 15,
    marginBottom: 30,
  },
  backToLoginButton: {
    marginTop: 30
  }
})