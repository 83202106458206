// @flow
import React, { type Node, useCallback, useMemo, useRef, useState } from 'react'
import { Platform, TouchableWithoutFeedback, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { useMutation } from 'relay-hooks'
import * as Yup from 'yup'
import { openImagePickerAsync } from 'src/utils/FileUtils'
import useScreenSizes from 'src/utils/useScreenSizes'
import Label from 'src/components/common/atoms/Label'
import Avatar from 'src/components/common/atoms/Avatar'
import Button from 'src/components/common/atoms/Button'
import TextInputFormik from 'src/components/common/forms/TextInputFormik'
import SelectInputFormik from 'src/components/common/forms/SelectInputFormik'
import Icon from 'src/components/common/atoms/Icon'
import { Colors, Palette, Sizes } from 'src/styles'
import s from './ProfilePersonalInfoScreenModal.style'
import NoPictureAvatar from 'src/components/common/atoms/NoPictureAvatar'
import useInputRefs from 'src/utils/useInputRefs'

/**
 * ProfilePersonalInfoScreenModal modal.
 */
export default function ProfilePersonalInfoScreenModal ({
  onClose,
  user,
  availableLanguages
}: PropsTypes): Node {

  // Init state
  const { t, i18n } = useTranslation()
  const formRef: any = useRef()
  const [newPic, setNewPic] = useState()
  const [success, setSuccess] = useState(false)

  // Responsive
  const { isMobile, isDesktop } = useScreenSizes()

  // Handle avatar update
  const handleAvatarUpdate = () => {
    openImagePickerAsync()
      .then(response => {
        // Non web platforms will not have a localUri in base64 so we have to check it
        if (response.base64) {
          setNewPic({ localUri: response.uri, base64: 'data:image/jpeg;base64,' + response.base64 })
        } else {
          setNewPic({ localUri: response.uri })
        }
      })
  }

  // API Call to update personal infos
  const [updateUserMutation, { loading }] = useMutation(
    graphql`
      mutation ProfilePersonalInfoScreenModalMutation($firstName: String!, $lastName: String!, $phoneNumber: String!, $lang: UserLang!) {
        UpdateUserInfos(firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber, lang: $lang) {
          id
          rowId
          email
          firstName
          lastName
          phoneNumber
          lang
        }
      }
    `
  )

  // API Call to update avatar
  const [updateUserPicMutation] = useMutation(
    graphql`
      mutation ProfilePersonalInfoScreenModalUpdateProfileImageMutation($encodedImage: String!) {
        UpdateProfileImage(encodedImage: $encodedImage) {
          id
          pictureURL
        }
      }
    `
  )

  const handleSubmit = useCallback(values => {
    const promises = [updateUserMutation({ variables: { ...values, lang: values.lang.toUpperCase() } })]
    newPic && promises.push(updateUserPicMutation({ variables: { encodedImage: newPic.base64 ? newPic.base64 : newPic.localUri } }))

    Promise.all(promises)
      .then(res => {
        res.forEach(data => {
          if (data.UpdateUserInfos) {
            i18n.changeLanguage(data.UpdateUserInfos.lang)
            setSuccess(true)
          }
        })

      })
      .catch(() => formRef.current.setFieldError('general', t('Yup.global.error')))
  }, [newPic])

  // Form validation schema
  const profileSchema = useMemo(() => Yup.object().shape({
    firstName: Yup.string().min(1, t('Yup.string.min', { length: 1 })),
    lastName: Yup.string().min(1, t('Yup.string.min', { length: 1 })),
    email: Yup.string().email(t('Yup.string.emailInvalid')).required(t('Yup.mixed.required')),
    phoneNumber: Yup.string().matches(/^(0)[1-9]( \d{2}){4}$/, t('ProfilePersonalInfoScreen.errors.wrongPhoneFormat')),
    lang: Yup.string().required(t('Yup.mixed.required'))
  }))

  const initialValues = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    email: user?.email,
    phoneNumber: user?.phoneNumber,
    lang: user?.lang
  }

  const [inputRefs, focusNextInputWithName] = useInputRefs(initialValues)


  // Render modal success state
  if (success) {
    return (
      <View>
        <Label style={s.modalTitle} size={!isDesktop ? 14 : 28}>
          {t('ProfilePersonalInfoScreen.labels.updateProfile')}
        </Label>
        <View style={s.successContainer}>
          <Icon name={'check'} size={24} style={s.successIcon}/>
          <Label style={s.successLabel}>{t('ProfilePersonalInfoScreen.success.text')}</Label>
          <Button
            style={s.successCloseButton}
            size={'M'}
            palette={'primary'}
            onPress={onClose}
          >
            {t('App.close')}
          </Button>
        </View>
      </View>
    )
  }

  // Render modal
  return (
    <View>

      {/* Title */}
      <Label style={[s.modalTitle, isDesktop && s.modalTitleDesktop]} size={!isDesktop ? 14 : 28}>
        {t('ProfilePersonalInfoScreen.labels.updateProfile')}
      </Label>

      {/* Edit personal infos form */}
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={profileSchema}
        onSubmit={values => handleSubmit(values)}
      >
        {(formikProps) => (
          <View>

            {/* Fields */}
            <View style={s.dataContainer}>

              {/* Avatar */}
              <TouchableWithoutFeedback onPress={handleAvatarUpdate}>
                <View style={{ height: !isDesktop ? 40 : 110 }}>
                  {newPic || user?.pictureURL ? (
                    <Avatar
                      source={newPic ? { uri: newPic.localUri } : user?.pictureURL && { uri: user?.pictureURL }}
                      style={isMobile && s.avatar}
                      size={isMobile ? 40 : 110}
                      isActive={true}
                    />
                  ) : (
                    <NoPictureAvatar
                      style={!isDesktop && s.avatar}
                      size={!isDesktop ? 40 : 110}
                      iconSize={!isDesktop ? 15 : 50}
                      labelSize={!isDesktop ? 15 : 40}
                      labelColor={Colors.darkGrey100}
                      initials={user?.firstName?.charAt(0).toUpperCase() + user?.lastName?.charAt(0).toUpperCase()}/>
                  )}
                  <Button
                    style={[s.updateAvatar, s.modalUpdateAvatar, isDesktop && s.modalUpdateAvatarDesktop]}
                    Icon={<Icon name="edit" style={Sizes.fontSize(!isDesktop ? 12 : 22)} color={Palette.ppDefaultText}/>}
                    onPress={handleAvatarUpdate}
                  />
                </View>
              </TouchableWithoutFeedback>

              {/* Personal informations */}
              <View style={!isDesktop ? s.textContainer : s.textContainerDesktop}>

                {/* Firstname */}
                <View style={[s.formRow, isDesktop && s.formRowDesktop]}>
                  <Label style={!isDesktop ? s.formLabel : s.formLabelDesktop} size={!isDesktop ? 10 : 22}>
                    {t('ProfilePersonalInfoScreen.labels.firstName')}
                  </Label>
                  <View style={[s.inputContainer, isDesktop && s.inputContainerDesktop]}>
                    <TextInputFormik
                      innerRef={inputRefs['firstName']}
                      size={!isDesktop ? 'S' : 'L'}
                      style={s.textInput}
                      name={'firstName'}
                      placeholder={t('ProfilePersonalInfoScreen.placeholders.firstName')}
                      hasError={formikProps.errors.firstName && formikProps.touched.firstName}
                      autoCompleteType={Platform.OS === 'web' ? 'given-name' : undefined}
                      textContentType={'givenName'}
                      onSubmitEditing={() => focusNextInputWithName('lastName')}
                      returnKeyType={'next'}
                      {...formikProps}
                    />
                    {formikProps.errors.firstName && formikProps.touched.firstName && (
                      <Label types={['alert', 'error']} size={!isDesktop ? 10 : 14}>{formikProps.errors.firstName}</Label>
                    )}
                  </View>
                </View>

                {/* Lastname */}
                <View style={[s.formRow, isDesktop && s.formRowDesktop]}>
                  <Label style={!isDesktop ? s.formLabel : s.formLabelDesktop} size={!isDesktop ? 10 : 22}>
                    {t('ProfilePersonalInfoScreen.labels.lastName')}
                  </Label>
                  <View style={[s.inputContainer, isDesktop && s.inputContainerDesktop]}>
                    <TextInputFormik
                      innerRef={inputRefs['lastName']}
                      size={!isDesktop ? 'S' : 'L'}
                      style={s.textInput}
                      name={'lastName'}
                      placeholder={t('ProfilePersonalInfoScreen.placeholders.lastName')}
                      hasError={formikProps.errors.lastName && formikProps.touched.lastName}
                      autoCompleteType={Platform.OS === 'web' ? 'family-name' : undefined}
                      textContentType={'familyName'}
                      onSubmitEditing={() => focusNextInputWithName('phoneNumber')}
                      returnKeyType={'next'}
                      {...formikProps}
                    />
                    {formikProps.errors.lastName && (
                      <Label types={['alert', 'error']} size={!isDesktop ? 10 : 14}>{formikProps.errors.lastName}</Label>
                    )}
                  </View>
                </View>

                {/* Email */}
                <View style={[s.formRow, isDesktop && s.formRowDesktop]}>
                  <Label style={!isDesktop ? s.formLabel : s.formLabelDesktop} size={!isDesktop ? 10 : 22}>
                    {t('ProfilePersonalInfoScreen.labels.email')}
                  </Label>
                  <View style={[s.inputContainer, isDesktop && s.inputContainerDesktop]}>
                    <TextInputFormik
                      size={!isDesktop ? 'S' : 'L'}
                      style={s.textInput}
                      name={'email'}
                      placeholder={t('ProfilePersonalInfoScreen.placeholders.email')}
                      hasError={formikProps.errors.email && formikProps.touched.email}
                      editable={false}
                      onSubmitEditing={formikProps.handleSubmit}
                      autoCorrect={false}
                      {...formikProps}
                    />
                    {formikProps.errors.email && formikProps.touched.email && (
                      <Label types={['alert', 'error']} size={!isDesktop ? 10 : 14}>{formikProps.errors.email}</Label>
                    )}
                  </View>
                </View>

                {/* Phone */}
                <View style={[s.formRow, isDesktop && s.formRowDesktop]}>
                  <Label style={!isDesktop ? s.formLabel : s.formLabelDesktop} size={!isDesktop ? 10 : 22}>
                    {t('ProfilePersonalInfoScreen.labels.phone')}
                  </Label>
                  <View style={[s.inputContainer, isDesktop && s.inputContainerDesktop]}>
                    <TextInputFormik
                      innerRef={inputRefs['phoneNumber']}
                      size={!isDesktop ? 'S' : 'L'}
                      style={s.textInput}
                      name={'phoneNumber'}
                      placeholder={t('ProfilePersonalInfoScreen.placeholders.phone')}
                      hasError={formikProps.errors.phoneNumber && formikProps.touched.phoneNumber}
                      mask={'00 00 00 00 00'}
                      autoCompleteType={'tel'}
                      textContentType={'telephoneNumber'}
                      onSubmitEditing={formikProps.handleSubmit}
                      {...formikProps}
                    />
                    {formikProps.errors.phoneNumber && formikProps.touched.phoneNumber && (
                      <Label types={['alert', 'error']}
                             size={!isDesktop ? 10 : 14}>{formikProps.errors.phoneNumber}</Label>
                    )}
                  </View>
                </View>

                {/* Language */}
                {/*<View style={[s.formRow, isDesktop && s.formRowDesktop]}>*/}
                {/*  <Label style={!isDesktop ? s.formLabel : s.formLabelDesktop} size={!isDesktop ? 10 : 22}>*/}
                {/*    {t('ProfilePersonalInfoScreen.labels.language')}*/}
                {/*  </Label>*/}
                {/*  <View style={[s.inputContainer, isDesktop && s.inputContainerDesktop]}>*/}
                {/*    <SelectInputFormik*/}
                {/*      size={!isDesktop ? 'S' : 'L'}*/}
                {/*      name={'lang'}*/}
                {/*      placeholder={t('ProfilePersonalInfoScreen.placeholders.language')}*/}
                {/*      items={availableLanguages}*/}
                {/*      fullWidth*/}
                {/*      hasError={formikProps.errors.lang && formikProps.touched.lang}*/}
                {/*      {...formikProps}*/}
                {/*    />*/}
                {/*    {formikProps.errors.lang && formikProps.touched.lang && (*/}
                {/*      <Label types={['alert', 'error']} size={!isDesktop ? 10 : 14}>{formikProps.errors.lang}</Label>*/}
                {/*    )}*/}
                {/*  </View>*/}

                {/*</View>*/}

              </View>
            </View>

            {/* Submit & Cancel buttons */}
            <Button
              size={!isDesktop ? 'M' : 'L'}
              style={[s.submitButton, !isMobile && s.submitButtonDesktop]}
              onPress={formikProps.handleSubmit}
              palette={'primary'}
              isLoading={loading}
              disabled={!newPic && !(formikProps.isValid && formikProps.dirty)}>
              {t('ProfilePersonalInfoScreen.labels.confirm')}
            </Button>
            <Button
              size={!isDesktop ? 'M' : 'L'}
              style={[s.cancelButton, !isMobile && s.cancelButtonDesktop]}
              onPress={onClose}>
              {t('ProfilePersonalInfoScreen.labels.cancel')}
            </Button>

            {/* General error */}
            {formikProps.errors.general && (
              <Label types={['alert', 'error']} size={!isDesktop ? 10 : 15} style={s.generalError}>
                {formikProps.errors.general}
              </Label>
            )}

          </View>
        )}
      </Formik>
    </View>
  )

}

// PropsTypes
type PropsTypes = {
  user: any,
  availableLanguages: any
}