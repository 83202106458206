// @flow
import React, { type Node } from 'react'
import { Platform, View, Pressable } from 'react-native'
import { BoxShadow } from 'react-native-shadow'
import s from './ViewShadow.style'

/**
 * ViewShadow atom.
 */
export default function ViewShadow ({
  style,
  styles,
  shadowOffset = { width: 0, height: 1 },
  shadowOpacity = 0.25,
  shadowRadius = 4,
  withColor, // {color: String, width: Number, height: Number, radius: Number}
  margin, // {top: Number, right: Number, bottom: Number, left: Number}
  hasPadding = false,
  onPress = false,
  children
}: PropsTypes): Node {


  // Handle custom padding and margin
  const containerPadding = hasPadding && {
    paddingTop: shadowRadius / 2 - shadowOffset.height,
    paddingRight: shadowRadius / 2 + shadowOffset.width,
    paddingBottom: shadowRadius / 2 + shadowOffset.height,
    paddingLeft: shadowRadius / 2 - shadowOffset.width
  }

  const containerMargin = {
    marginTop: margin ? margin.top : 0,
    marginRight: margin ? margin.right: 0,
    marginBottom: margin ? margin.bottom: 0,
    marginLeft: margin ? margin.left : 0
  }

  // Handle special shadow case on android
  if (Platform.OS === 'android' && withColor) {
    const shadowSettings = {
      width: withColor.width - shadowRadius,
      height: withColor.height - shadowRadius,
      color: withColor.color,
      border: shadowRadius,
      radius: withColor.radius,
      opacity: shadowOpacity,
      x: shadowOffset.width + (shadowRadius / 2),
      y: shadowOffset.height + (shadowRadius / 2) + 1,
      style: {width: withColor.width, height: withColor.height}
    }

    return (
      <View style={[s.container, containerPadding, containerMargin, style.hasOwnProperty('zIndex') && {zIndex: style.zIndex}]}>
        <BoxShadow setting={shadowSettings}>
          <Pressable onPress={onPress ? onPress : () => {}}>
            <View
              style={[style, {height: withColor.height }]}
            >
              {children && children}
            </View>
          </Pressable>
        </BoxShadow>
      </View>
    )
  }

  // Render content wrap in box allowing shadow
  return (
    <Pressable
      onPress={onPress ? onPress : () => {}}
      style={[
        { zIndex: 1 },
        !onPress && {...Platform.select({ web: { cursor: 'default' }})}
      ]}
    >
      <View style={[containerPadding, containerMargin, styles?.container]}>
        <View
          style={[
            s.container,
            {
              shadowColor: '#000',
              shadowOffset: shadowOffset,
              shadowOpacity: shadowOpacity,
              shadowRadius: shadowRadius,
              elevation: 5
            },
            withColor && {
              shadowColor: withColor.color,
              elevation: 0,
              width: withColor.width,
              height: withColor.height
            },
            onPress && {...Platform.select({ web: { cursor: 'pointer' }})},
            style
          ]}
        >
          {children && children}
        </View>
      </View>
    </Pressable>
  )
}

// PropsTypes
type PropsTypes = {
  style?: any,
  styles?: any,
  shadowColor?: string,
  shadowOffset?: any,
  shadowOpacity?: number,
  shadowRadius?: number,
  withColor?: any,
  margin?: any,
  onPress?: any,
  children?: any
}