/**
 * @generated SignedSource<<0c0a99ecc8178b051d0a89fc2a9c5fe8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserCashback",
        "kind": "LinkedField",
        "name": "cashbackWallet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activeAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentMonthExpirableAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "currentMonthExpirableAmountExpirationDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "inAcquisitionAmount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "inAcquisitionActivationDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UserCashbackDetails",
            "kind": "LinkedField",
            "name": "activeDetails",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "acquiredCashback",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "acquisitionDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "expirationDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileCashbackScreenQuery",
    "selections": (v0/*: any*/),
    "type": "ClientAppQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProfileCashbackScreenQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "129c66eaf1b0fa82c0baea3d2aadaf1f",
    "id": null,
    "metadata": {},
    "name": "ProfileCashbackScreenQuery",
    "operationKind": "query",
    "text": "query ProfileCashbackScreenQuery {\n  user {\n    id\n    cashbackWallet {\n      activeAmount\n      currentMonthExpirableAmount\n      currentMonthExpirableAmountExpirationDate\n      inAcquisitionAmount\n      inAcquisitionActivationDate\n      activeDetails {\n        acquiredCashback\n        acquisitionDate\n        expirationDate\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "f99d1209f244944f78c5e0c04a8ed39f";

module.exports = node;
