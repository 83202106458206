// @flow
import React, { type Node } from 'react'
import { View } from "react-native"
import { useTranslation } from "react-i18next"
import { Formik } from 'formik'
import * as Yup from 'yup'
import { formatPrice } from 'src/utils/FormatDataUtils'
import SelectInputFormik from 'src/components/common/forms/SelectInputFormik'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import s from './FillWalletModal.style'
import useScreenSizes from 'src/utils/useScreenSizes'

/**
 * ChooseAmountModalContent.
 */
export default function ChooseAmountModalContent({
  modalSteps,
  setAmount,
  setModalStep,
  closeAndReset,
  currentBalance
}: PropsTypes): Node {

  // Init state
  const { t } = useTranslation()
  const { isDesktop } = useScreenSizes()
  const maxWalletAmount = 500
  const defaultChoices = [
    { label: formatPrice('25'), value: '25' },
    { label: formatPrice('50'), value: '50' },
    { label: formatPrice('100'), value: '100' },
    { label: formatPrice('200'), value: '200' },
    { label: formatPrice('500'), value: '500' }
  ]

  // Filter list of choices because use can only load 200€ max in his wallet
  let atLeastOneChoice = false
  const availableChoices = defaultChoices.filter(choice => {
    if (parseFloat(currentBalance) + parseFloat(choice.value) <= maxWalletAmount) {
      atLeastOneChoice = true
      return choice
    }
  })

  // Max amount already reached
  if(!atLeastOneChoice) {
    return <View>

      {/* Text */}
      <Label style={s.chooseAmountMaxAmountReached} size={isDesktop ? 20 : 14}>
        {t('ProfileWalletScreen.ChooseAmount.maxAmountReached')}
      </Label>

      {/* Close button */}
      <Button
        size={isDesktop ? 'L' : 'M'}
        onPress={closeAndReset}
        palette={'primary'}
        fontStyle={'bold'}
        style={s.chooseAmountCloseButton}
      >
        {t('App.close')}
      </Button>

    </View>
  }

  // Form validation schema
  const validationSchema = Yup.object().shape({
    amount: Yup.number().required(t('Yup.mixed.required'))
  })

  // Set amount and go to next step
  const handleAmount = (value) => {
    setAmount(parseInt(value))
    setModalStep(modalSteps.CHOOSE_PAYMENT_METHOD)
  }

  // Render modal content
  return (
    <View>

      {/* Intro */}
      <Label size={isDesktop ? 20 : 14}>{t('ProfileWalletScreen.ChooseAmount.intro')}</Label>

      {/* Select amount form */}
      <Formik
        initialValues={{
          amount: ''
        }}
        validationSchema={validationSchema}
        onSubmit={values => handleAmount(values.amount)}
      >
        {(formikProps) => (
          <View style={s.chooseAmountFormContainer}>
            <View style={s.chooseAmountForm}>

              {/* Select amount input */}
              <SelectInputFormik
                size={isDesktop ? 'L' : 'M'}
                name={'amount'}
                items={availableChoices}
                style={[s.chooseAmountSelect, isDesktop && s.chooseAmountSelectDesktop]}
                fullWidth
                {...formikProps}
              />

              {/* Validate button */}
              <Button
                size={isDesktop ? 'L' : 'M'}
                onPress={formikProps.handleSubmit}
                palette={'primary'}
                fontStyle={'bold'}
                style={s.chooseAmountSubmitButton}
                disabled={!(formikProps.isValid && formikProps.dirty)}
              >
                {t('App.validate')}
              </Button>

            </View>
          </View>
        )}
      </Formik>
    </View>
  )
}

// PropsTypes
type PropsTypes = {
  modalSteps: any,
  setAmount: function,
  setModalStep: function,
  closeAndReset: function,
  currentBalance: number
}