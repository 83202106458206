import React, { type Node } from 'react'
import { View, Image, Pressable } from 'react-native'
import { useMutation } from 'relay-hooks'
import { useTranslation } from 'react-i18next'
import Label from 'src/components/common/atoms/Label'
import Icon from 'src/components/common/atoms/Icon'
import GlobalRefetchStore from 'src/store/GlobalRefetchStore'
import { opacify } from 'src/styles/colors'
import s from './ServiceDetailSection.style'

/**
 * ServiceDetailSection component
 */
export default function ServiceDetailSection ({
  style,
  styles,
  service
}: PropsTypes): Node {
  /*
   * Init state
   */
  const { t } = useTranslation()

  /*
   * Mutation to toggle the service in user's favorites
   */
  const [toggleServiceMutation] = useMutation(
    graphql`
      mutation ServiceDetailSectionMutation($serviceId: Int!) {
        ToggleServiceFavorite(serviceId: $serviceId) {
          id
          rowId
          name
          isUserFavorite
        }
      }
    `
  , {
      onCompleted: () => {
        GlobalRefetchStore.update(s => {
          s.bestServicesKey = new Date()
        })
      }
    })

  /*
   * Render component
   */
  return (
    <View style={[s.container, style, styles?.container]}>

      {/* Title section */}
      <View
        style={[
          s.titleContainer,
          { backgroundColor: opacify(service.color, 30) }
        ]}
      >
        <Image
          style={s.titleServiceLogo}
          source={{ uri: service.logoURL }}
        />
        <Label size={28} type={'bold'}>{service.name}</Label>
      </View>

      {/* Description section */}
      <View style={s.descriptionContainer}>

        {/* Description text and favorite button */}
        <View style={s.descriptionContent}>
          <Label size={12}>{service.description}</Label>

            <Pressable
              style={s.descriptionToggleFavorite}
              onPress={() => toggleServiceMutation({ variables: { serviceId: service.rowId } })}
            >
              <Icon
                name={service.isUserFavorite ? 'favorite-filled' : 'favorite'}
                color={service.color}
                size={12}
              />
              <Label size={12} style={s.descriptionText}>
                {service.isUserFavorite
                  ? t('ServiceDetailCard.favorite')
                  : t('ServiceDetailCard.notFavorite')
                }
              </Label>
            </Pressable>
        </View>

        {/* Description service picture */}
        <Image
          style={s.descriptionServicePicture}
          source={service.pictureURL
            ? { uri: service.pictureURL }
            : require('assets/img/screens/private/HomeScreen/default_qorner_banner.png')
          }
        />
      </View>
    </View>
  )
}

type PropsTypes = {
  style?: any,
  styles?: any,
  service: any
}