// @flow
import React, { type Node, useEffect, useState, useRef, useCallback } from 'react'
import { FlatList, View, TouchableWithoutFeedback } from 'react-native'
import { type StackNavigationProp } from '@react-navigation/stack'
import type { RoutesParamList } from 'src/components/Navigation'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'relay-hooks'
import { Formik } from 'formik'
import useScreenSizes from 'src/utils/useScreenSizes'
import { useFocusScreenEffect } from 'src/utils/ComponentUtils'
import PrivateLayout from 'src/components/common/layout/PrivateLayout'
import TextInputFormik from 'src/components/common/forms/TextInputFormik'
import Loader from 'src/components/common/atoms/Loader'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import Icon from 'src/components/common/atoms/Icon'
import ProductCard from 'src/components/common/moles/ProductCard'
import NewsPreview from 'src/components/common/moles/NewsPreview'
import ServiceDetailCard from 'src/components/common/moles/ServiceDetailCard'
import s from './SearchScreen.style'
import { Colors } from 'src/styles'
import { opacify } from 'src/styles/colors'
import ServiceCard from 'src/components/common/moles/ServiceCard'
import useIntercomNative from 'src/utils/useIntercom'
import IntercomButton from 'src/components/common/atoms/IntercomButton/IntercomButton'
import useFullWidth from 'src/utils/useFullWidth'

/**
 * SearchScreen.
 */
export default function SearchScreen ({
  navigation, route
}: PropsTypes): Node {

  // Init state
  const { t } = useTranslation()
  const [search, setSearch] = useState(route.params?.search ? route.params?.search : '')
  const [selectedType, setSelectedType] = useState('product')
  const searchInputRef = useRef()
  const maxItemsCount = 20
  const { isMobile, isTablet, width: screenWidth } = useScreenSizes()
  const fullWidth = useFullWidth()
  const [numColumns, setNumColumns] = useState(2)
  const resulContainerRef = useRef()

  const cardWidth = isMobile
    ? (fullWidth - 88) / numColumns
    : resulContainerRef?.current?.clientWidth / numColumns - 24

  useEffect(() => {
    if (screenWidth >= 1600) {
      setNumColumns(5)
    } else if (screenWidth >= 1024) {
      setNumColumns(4)
    } else if (screenWidth >= 768) {
      setNumColumns(3)
    } else {
      setNumColumns(2)
    }
  }, [screenWidth])


  // API Call when search change and his longer than 2 characters
  const { data, isLoading } = useQuery(
    graphql`
      query SearchScreenQuery($search: String!) {
        search(search: $search) {
          productsItems {
            rowId
            name
            catalogIconUrl
            price
            serviceId
            families {
              rowId
            }
          }
          productsTotalCount
          servicesItems {
            rowId
            name
            pictureURL
            logoURL
            families {
              rowId
              familyColor
            }
          }
          servicesTotalCount
          articlesItems {
            rowId
            title
            mainPictureUrl
            publishedAt
          }
          articlesTotalCount
          informationsItems {
            rowId
            title
            mainPictureUrl
          }
          informationsTotalCount
        }
      }
    `,
    // Variable
    { search: search },
    {
      fetchPolicy: 'network-only',
      fetchKey: search,
      networkCacheConfig: {
        force: true,
      },
      skip: search.length < 3
    }
  )
  const productsTotalCount = data?.search?.productsTotalCount || 0
  const servicesTotalCount = data?.search?.servicesTotalCount || 0
  const articlesTotalCount = data?.search?.articlesTotalCount || 0
  const informationsTotalCount = data?.search?.informationsTotalCount || 0

  // Result to display
  const results = data?.search[selectedType + 'sItems']
  const selectedTypeCount = data?.search[selectedType + 'sTotalCount']

  // No results found ?
  let noResultsFound = false
  if (
    productsTotalCount === 0 &&
    servicesTotalCount === 0 &&
    articlesTotalCount === 0 &&
    informationsTotalCount === 0
  ) {
    noResultsFound = true
  }

  // Change selected tab when results change
  useEffect(() => {
    if (!noResultsFound) {
      setSelectedType(
        productsTotalCount > 0 ? 'product' :
          servicesTotalCount > 0 ? 'service' :
            articlesTotalCount > 0 ? 'article' :
              informationsTotalCount > 0 ? 'information' : 'product'
      )
    }
  }, [data?.search])

  // Change search base on navigation
  useEffect(() => {
    if (route.params?.search) {
      setSearch(route.params.search)
    }
  }, [route.params?.search])

  // Focus search input on focus screen and refresh page
  useEffect(() => {
    searchInputRef.current && searchInputRef.current.focus()
  }, [searchInputRef.current])
  useFocusScreenEffect(() => {
    searchInputRef.current && searchInputRef.current.focus()
  })

  // Render item list
  const _getRenderItem = item => {
    switch (selectedType) {
      case 'product':
        const _navigateToProduct = () => navigation.navigate('Product', {
          familyId: item.families[0].rowId,
          serviceId: item.serviceId,
          productId: item.rowId
        })

        return (
          <ProductCard
            key={item.rowId}
            iconURL={item.catalogIconUrl}
            title={item.name}
            price={item.price}
            onPressGlobal={_navigateToProduct}
            onPress={_navigateToProduct}
            buttonLabel={t('SearchScreen.productCardButtonLabel')}
            width={cardWidth}
          />
        )

      case 'service':
        return (
          <ServiceCard
            key={item.rowId}
            width={isMobile ? screenWidth - 46 : cardWidth}
            title={item.name}
            color={Colors[item.color]}
            iconURL={item.logoURL}
            isFav={item.isUserFavorite}
            size={isMobile ? 'M' : 'L'}
            onPress={() => navigation.navigate('Service', {
              familyId: item.families[0].rowId,
              serviceId: item.rowId
            })}
          />
        )

      case 'article':
        return <NewsPreview
          key={item.id}
          width={isMobile ? screenWidth - 46 : cardWidth}
          image={item.mainPictureUrl}
          publishedAt={item.publishedAt}
          title={item.title}
          size={!isMobile ? 'L' : 'M'}
          onPress={() => navigation.navigate('News', { newsId: item.rowId })}
        />

      case 'information':
        return <NewsPreview
          key={item.id}
          width={isMobile ? screenWidth - 46 : cardWidth}
          image={item.mainPictureUrl}
          publishedAt={item.publishedAt}
          title={item.title}
          size={!isMobile ? 'L' : 'M'}
          onPress={() => navigation.navigate('PracticalInformations', { infoId: item.rowId })}
        />
    }
  }

  // Render page
  return (
    <PrivateLayout
      headerTitle={t('SearchScreen.headerTitle')}
      searchValue={search}
      searchInputRef={searchInputRef}
    >
      <View style={[s.container, !isMobile && s.containerNotMobile]}>

        {/* Search form */}
        {isMobile && (
          <Formik
            initialValues={{ search: search }}
            onSubmit={() => {}}
            enableReinitialize={true}
          >
            {(formikProps) => (
              <TextInputFormik
                {...formikProps}
                innerRef={searchInputRef}
                name={'search'}
                icon={'search'}
                size={'L'}
                placeholder={t('SearchScreen.searchPlaceholder')}
                styles={{ textInput: s.searchInput }}
                onChangeText={value => setSearch(value)}

              />
            )}
          </Formik>
        )}

        {/* Search types list and count */}
        {!noResultsFound && (
          <View style={s.typesContainer}>

            {/* Search title */}
            {isMobile
              ? <Label style={s.typesContainerTitle}>{t('SearchScreen.resultTitle')}</Label>
              : (
                <View style={[s.desktopTitleContainer, { backgroundColor: opacify(Colors.orange100, 30) }]}>
                  <Icon name={'search'} size={40} style={s.desktopTitleIcon}/>
                  <Label size={22} type={'bold'}>{t('SearchScreen.desktopTitle', { search: search })}</Label>
                </View>
              )
            }

            {/* First row */}
            <View style={!isMobile && { flexDirection: 'row', maxWidth: 700 }}>
              <View style={[s.typesContainerRow, !isMobile && { flex: 0.5, marginRight: 10 }]}>

                {/* Product count */}
                <TouchableWithoutFeedback onPress={() => setSelectedType('product')}>
                  <View
                    style={[s.searchType, s.searchTypeFirstColumn, selectedType === 'product' && s.searchTypeSelected]}
                  >
                    <Label
                      style={[selectedType === 'product' && s.searchTypeLabelSelected]}
                    >
                      {productsTotalCount > maxItemsCount ? maxItemsCount + '+' : productsTotalCount} {t('SearchScreen.productType')}
                    </Label>
                  </View>
                </TouchableWithoutFeedback>

                {/* Service count */}
                <TouchableWithoutFeedback onPress={() => setSelectedType('service')}>
                  <View
                    style={[s.searchType, selectedType === 'service' && s.searchTypeSelected]}
                  >
                    <Label
                      style={[selectedType === 'service' && s.searchTypeLabelSelected]}
                    >
                      {servicesTotalCount > maxItemsCount ? maxItemsCount + '+' : servicesTotalCount} {t('SearchScreen.serviceType')}
                    </Label>
                  </View>
                </TouchableWithoutFeedback>

              </View>

              {/* Second row */}
              <View style={[s.typesContainerRow, !isMobile && { flex: 0.5 }]}>

                {/* Article count */}
                <TouchableWithoutFeedback onPress={() => setSelectedType('article')}>
                  <View
                    style={[s.searchType, s.searchTypeFirstColumn, selectedType === 'article' && s.searchTypeSelected]}
                  >
                    <Label
                      style={[selectedType === 'article' && s.searchTypeLabelSelected]}
                    >
                      {articlesTotalCount > maxItemsCount ? maxItemsCount + '+' : articlesTotalCount} {t('SearchScreen.articleType')}
                    </Label>
                  </View>
                </TouchableWithoutFeedback>

                {/* Information count */}
                <TouchableWithoutFeedback onPress={() => setSelectedType('information')}>
                  <View
                    style={[s.searchType, selectedType === 'information' && s.searchTypeSelected]}
                  >
                    <Label
                      style={[selectedType === 'information' && s.searchTypeLabelSelected]}
                    >
                      {informationsTotalCount > maxItemsCount ? maxItemsCount + '+' : informationsTotalCount} {t('SearchScreen.informationType')}
                    </Label>
                  </View>
                </TouchableWithoutFeedback>

              </View>
            </View>
          </View>
        )}

        {/* No search yet or isLoading */}
        {data === null && (
          <View style={s.fullScreenContainer}>
            <Label>{t('SearchScreen.noSearchYet')}</Label>
          </View>
        )}

        {/* Loading */}
        {isLoading && (
          <View style={s.fullScreenContainer}>
            <Loader/>
          </View>
        )}

        {/* No Results found */}
        {data !== null && noResultsFound && !isLoading && (
          <View style={s.topScreenContainer}>
            <Label style={s.noResultsLabel}>{t('SearchScreen.noResultsFoundPart1')}</Label>
            <Label style={s.noResultsLabel}>{t('SearchScreen.noResultsFoundPart2')}</Label>
          </View>
        )}

        {/* List of result from selected type*/}

        {isMobile
          ? <FlatList
            style={s.listSearchResults}
            data={results ? [...results, { lastFakeItem: true }] : null}
            keyExtractor={item => selectedType + item.rowId}
            numColumns={selectedType === 'product' ? 2 : 1}
            key={selectedType === 'product' ? '2' : '1'}
            renderItem={({ item }) => {

              // Message at end of the list if there is more result but we reach end
              if (item.lastFakeItem) {
                if (selectedTypeCount <= maxItemsCount) { return null; }
                return (
                  <View style={s.affinateYourSearchContainer}>
                    <Label style={s.affinateYourSearch}>{t('SearchScreen.affinateYourSearch')}</Label>
                  </View>
                )
              }

              const renderItem = _getRenderItem(item)

              return (
                <View style={[s.itemContainer, selectedType === 'product' && s.productCardContainer]}>
                  {renderItem}
                </View>
              )

            }}
          />
          : <View style={s.desktopListSearchResults} ref={resulContainerRef}>
            {results?.map(item => {

              const renderItem = _getRenderItem(item)

              return (
                <View style={s.desktopListItem} key={item.rowId}>
                  {renderItem}
                </View>
              )

            })}
          </View>
        }

      </View>
    </PrivateLayout>
  )
}

// PropsTypes
type PropsTypes = {
  navigation: StackNavigationProp<RoutesParamList, 'Search'>,
  route: any
}
