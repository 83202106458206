import React, { type Node } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { formatPrice } from 'src/utils/FormatDataUtils'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import { actions } from '../constants/OrderActionsConstant'
import s from './OrderModal.style'
import useScreenSizes from 'src/utils/useScreenSizes'

/**
 * OrderPaymentModalContent component
 */
export default function OrderPaymentModalContent ({
  amount,
  payedAt,
  hasAllowedAction,
  handleAction
}: PropsTypes): Node {
  /*
   * Init vars
   */
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()

  /*
   * Render component
   */
  return (
    <View style={s.block}>
      <View style={s.blockTitle}>
        <Label size={isMobile ? 12 : 14} type={'bold'}>{t('OrderModal.payment')}</Label>
      </View>

      {payedAt &&
        <Label size={isMobile ? 10 : 12} style={s.paymentStatusText}>{t('OrderModal.orderPayedAt', {date: moment(payedAt).format('DD MMMM')})}</Label>
      }

      <View style={s.blockButtonsRow}>

        {hasAllowedAction(actions.PAY) &&
          <Button
            palette={'primary'}
            size={isMobile ? 'S' : 'M'}
            style={s.btn}
            onPress={() => handleAction(actions.PAY)}
          >
            {t('OrderModal.payBtn', {amount: formatPrice(amount)})}
          </Button>
        }

        {hasAllowedAction(actions.INVOICE) &&
          <Button
            size={isMobile ? 'S' : 'M'}
            style={s.btn}
            onPress={() => handleAction(actions.INVOICE)}
          >
            {t('OrderModal.getReceiptBtn')}
          </Button>
        }

      </View>
    </View>
  )
}

type PropsTypes = {
  amount: number,
  payedAt: string,
  hasAllowedAction: function,
  handleAction: function
}