import { StyleSheet, Platform } from 'react-native'
import { Colors } from 'src/styles'

// Styles
export default StyleSheet.create({
  resetButtonStyle: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    marginTop: 0,
    marginBottom: 0,
    backgroundColor: 'transparent',
    ...Platform.select({
      native: {
        borderWidth: 0
      },
      web: {
        border: 'none'
      }
    })
  },
  iconContainer: {
    width: 30,
    height: 30,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: 30,
    borderWidth: 0,
    backgroundColor: Colors.lightGrey90,
  },
  fixIconContainerPosition: {
    position: 'relative',
    top: 10,
  },
  icon : {
    position: 'absolute',
    top: 7,
    left: 6,
  },
  counter: {
    position: 'absolute',
    top: -5,
    right: -5,
    width: 16,
    height: 16,
    borderRadius: 30,
    backgroundColor: Colors.orange100,
    opacity: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  counterVisible: {
    opacity: 1
  },
  counterLabel: {
    lineHeight: 10
  },
  basketLabel: {
    lineHeight: 50,
    marginLeft: 10
  }
})