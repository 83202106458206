/**
 * @generated SignedSource<<b2961ed2354932b0a7887b6b57a4a6ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "code"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "code",
        "variableName": "code"
      }
    ],
    "concreteType": "Basket",
    "kind": "LinkedField",
    "name": "ApplyVoucherToBasket",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amountAfterReduction",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BasketVoucher",
        "kind": "LinkedField",
        "name": "voucher",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "percentage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "price",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PaymentSpecialOfferSectionMutation",
    "selections": (v1/*: any*/),
    "type": "ClientAppMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PaymentSpecialOfferSectionMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d81e26d8237b8c3ad801556ec67605ca",
    "id": null,
    "metadata": {},
    "name": "PaymentSpecialOfferSectionMutation",
    "operationKind": "mutation",
    "text": "mutation PaymentSpecialOfferSectionMutation(\n  $code: String!\n) {\n  ApplyVoucherToBasket(code: $code) {\n    id\n    amount\n    amountAfterReduction\n    voucher {\n      code\n      percentage\n      price\n    }\n  }\n}\n"
  }
};
})();

node.hash = "dcdfa04a77bb6f6fbf623462773b89bc";

module.exports = node;
