import { Platform, StyleSheet } from 'react-native'
import { Colors, Fonts } from 'src/styles'

// Styles
export default StyleSheet.create({
  menuItem: {
    width: '100%',
    height: 30,
    flexDirection: 'row',
    alignItems: 'center',
    ...Platform.select({ web: { cursor: 'pointer' }}),
    marginTop: 10
  },
  menuItemDesktop: {
    height: 60,
  },
  menuItemTablet: {
    height: 40,
    marginTop: 0
  },
  subMenuItem: {
    height: 20,
    marginTop: 5
  },
  subMenuItemDesktop: {
    height: 40,
    marginTop: 0
  },
  subMenuItemTablet: {
    height: 25,
    marginTop: 0
  },
  subMenuSpace: {
    width: 25
  },
  icon: {
    marginRight: 10,
    fontWeight: 'bold',
    color: Colors.white100
  },
  iconTablet: {
    marginRight: 5,
  },
  textContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  titleContainer: {
    maxWidth: '100%',
    overflow: 'hidden',
    flexDirection: 'row',
    alignItems: 'center'
  },
  title: {
    fontFamily: Fonts.primary.semiBold,
    color: Colors.white100
  },
  titleSubMenu: {
    fontFamily: Fonts.primary.regular,
  },
  titleSubMenuDesktop: {
    fontFamily: Fonts.primary.regular,
  },
  subtitle: {
    fontFamily: Fonts.primary.regular,
    color: Colors.white100,
    marginLeft: 5,
  },
  seeAllLabel: {
    marginRight: 8,
    marginBottom: 1,
    fontFamily: Fonts.primary.regular,
    color: Colors.white100,
  },
  seeAllLabelDesktop: {
    marginRight: 10,
    marginBottom: 0
  },
  arrow: {
    color: Colors.white100,
    transform: [{ rotateY: '180deg' }]
  }
})