/**
 * @generated SignedSource<<1d61f8d80e04ddc791cf0aae7bf5de81>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "RecurringCreditCard",
    "kind": "LinkedField",
    "name": "recurringCreditCards",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cardNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PayWithWalletModalContentQuery",
    "selections": (v0/*: any*/),
    "type": "ClientAppQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PayWithWalletModalContentQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "f120b4fd48b3ae12a7d23da82e0461d3",
    "id": null,
    "metadata": {},
    "name": "PayWithWalletModalContentQuery",
    "operationKind": "query",
    "text": "query PayWithWalletModalContentQuery {\n  recurringCreditCards {\n    cardNumber\n    token\n  }\n}\n"
  }
};
})();

node.hash = "8de8133e81d1f4dabed9118d0bf26029";

module.exports = node;
