import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

export default StyleSheet.create({
  container: {
    position: 'relative',
    width: 300,
    flex: 1,
    height: 'auto',
    justifyContent: 'flex-start',
    backgroundColor: Colors.white100
  },
  avatar: {
    marginLeft: -8,
    marginRight: 10
  }
})