// @flow
import React, { type Node } from 'react'
import { ActivityIndicator } from 'react-native'
import { Palette, Sizes } from 'src/styles'

/**
 * Loader atom.
 */
export default function Loader({
  style,
  color = Palette.dpLoaderColorMain,
  size = Sizes.L
}: PropsTypes): Node {
  return (
    <ActivityIndicator
      style={[style]}
      color={color}
      size={size}
    />
  )
}

// PropsTypes
type PropsTypes = {
  style?: any,
  color?: string,
  size?: number
}