import { StyleSheet } from 'react-native'

// Styles
export default StyleSheet.create({
  XL: {
    height: 80,
    padding: 20
  },
  L: {
    height: 80,
    padding: 18
  },
  M: {
    height: 50,
    padding: 14
  },
  container: {
    backgroundColor: '#fff'
  },
  cardContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  titleContainer: {
    flex: 5,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingRight: 15
  },
  fav: {
    marginRight: 8
  },
  iconContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  serviceName: {
    width: '100%',
    overflow: 'hidden',
  }
})

// Icons sizes
export const iconSize = {
  XL: 24,
  L: 22,
  M: 16
}

// Texts sizes
export const textSize = {
  XL: 18,
  L: 20,
  M: 14
}