// Styles
import { StyleSheet, Platform } from 'react-native'
import { Colors } from 'src/styles'

// Styles
export default StyleSheet.create({
  menuBar: {
    flexDirection: 'row',
    height: 40,
    backgroundColor: Colors.white100,
    zIndex: 10
  },
  menuBarButton: {
    position: 'relative',
    flex: 1,
    borderWidth: 0,
    backgroundColor: 'transparent',
    borderRadius: 0,
  },
  menuBarButtonLeft: {
    backgroundColor: '#fff',
    borderTopRightRadius: 40,
    borderBottomRightRadius: 40,
    justifyContent: 'center',
    alignItems: 'center',
    paddingRight: 4
  },
  menuBarButtonRight: {
    borderTopLeftRadius: 40,
    borderBottomLeftRadius: 40,
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 4
  },
  menuBarShadowButtonLeft: {
    paddingRight: 22,
  },
  menuBarShadowButtonRight: {
    paddingLeft: 22,
  },
  menuBarIcon: {
    fontSize: 25,
    color: Colors.mediumGrey100
  }
})