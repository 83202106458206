// @flow
import React, { type Node, useState } from 'react'
import { View } from 'react-native'
import { useQuery } from 'relay-hooks'
import { useTranslation } from 'react-i18next'
import useScreenSizes from 'src/utils/useScreenSizes'
import PrivateLayoutProfile from 'src/components/common/layout/PrivateLayoutProfile'
import Modal from 'src/components/common/atoms/Modal'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import ShareSponsorCodeModal from './ShareSponsorCodeModal'
import Icon from 'src/components/common/atoms/Icon'
import { Colors } from 'src/styles'
import s from './ProfileSponsorCodeScreen.style'

/**
 * ProfileSponsorCodeScreen screen.
 */
export default function ProfileSponsorCodeScreen ({
  navigation
}: PropsTypes): Node {

  // Init states
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const { t } = useTranslation()
  const { isMobile, isTablet, isDesktop } = useScreenSizes()

  // API call
  const { data } = useQuery(graphql`
    query ProfileSponsorCodeScreenQuery {
      user {
        id
        sponsorCode
      }
    }
  `)
  const sponsorCode = data?.user?.sponsorCode

  // Render screen
  return (
    <>
      <PrivateLayoutProfile
        navigation={navigation}
        subHeaderIconName={'sales'}
        subHeaderTitle={t('MenuDrawerContent.links.sponsorCode')}
      >

        {/* Main container */}
        <View style={[s.container, !isMobile && s.desktopContainer]}>

          {!isMobile && (
            <View style={s.desktopTitle}>
              <Icon name={'sales'} size={isTablet ? 24 : 40} color={Colors.mediumGrey100}/>
              <Label size={isTablet ? 22 : 38} style={{ marginLeft: 20 }}>{t('ProfileSponsorCodeScreen.title')}</Label>
            </View>
          )}

          {/* Intro */}
          <Label size={isMobile ? 10 : isTablet ? 14 : 22}>{t('ProfileSponsorCodeScreen.intro')}</Label>

          {/* Sponsor code */}
          <View style={[s.codeContainer, !isMobile && s.codeContainerDesktop]}>
            <Label size={isMobile ? 12 : isTablet ? 16 : 28}>{t('ProfileSponsorCodeScreen.sponsorCode')}</Label>
            <Label type={'bold'} size={isMobile ? 12 : isTablet ? 16 : 28}>{sponsorCode}</Label>
          </View>

          {/* Share sponsor code */}
          <Button
            onPress={() => setModalIsOpen(true)} size={!isDesktop ? 'M' : 'L'}
            palette={'primary'}
          >
            {t('ProfileSponsorCodeScreen.shareSponsorCode')}
          </Button>

        </View>

      </PrivateLayoutProfile>

      {/* Share sponsor code modal */}
      <Modal open={modalIsOpen} onClose={() => setModalIsOpen(false)}>
        <ShareSponsorCodeModal />
      </Modal>

    </>
  )
}

// PropsTypes
type PropsTypes = {
  navigation: StackNavigationProp<RoutesParamList, 'SponsorCode'>
}