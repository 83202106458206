// @flow
import React, { type Node } from 'react'
import { useTranslation } from 'react-i18next'
import SkeletonContent from 'react-native-skeleton-content-nonexpo'
import ProfileCard from 'src/components/common/moles/ProfileCard'
import Button from 'src/components/common/atoms/Button'
import Icon from 'src/components/common/atoms/Icon'
import Label from 'src/components/common/atoms/Label'
import { Colors } from 'src/styles'
import s from './ProfileEntertainmentCard.style'

/**
 * ProfileEntertainementCard mole.
 */
export default function ProfileEntertainementCard({
  style,
  styles,
  id,
  actionType,
  handleAction,
  handlePress,
  isLoading = false
}: PropsTypes): Node {

  // Init state
  const { t } = useTranslation()

  // Actions buttons
  // TODO: real actions button base on API
  const action = ['register'].includes(actionType) ?
    (<Button
      size={'S'}
      palette={'primary'}
      style={[styles?.validateButton]}
      fontStyle={'bold'}
      Icon={<Icon name={'edit'} color={'#fff'}/>}
      onPress={() => handleAction && handleAction('entertainment', id, actionType)}
    >
      {t('ProfileEntertainmentCard.' + (actionType ? actionType : ''))}
    </Button>)
    : (<Label
      size={11}
      fontStyle={'bold'}
      style={{color: Colors.red100}}
    >
      {t('ProfileEntertainmentCard.' + (actionType ? actionType : ''))}
    </Label>)

  const actionSkeleton = (<SkeletonContent
    containerStyle={{}}
    isLoading={true}
    layout={[s.actionSkeleton]}
  />)

  // Render mole
  // TODO: real title, primaryContent and secondaryContent base on API
  return (
    <ProfileCard
      style={style}
      styles={styles}
      type={'entertainment'}
      id={id}
      title={'Entertainment card'}
      action={isLoading ? actionSkeleton : action}
      primaryContent={'Primary Content'}
      secondaryContent={'Secondary Content'}
      handlePress={handlePress}
      isLoading={isLoading}
    />
  )
}

// PropsTypes
type PropsTypes = {
  style?: any,
  styles?: any,
  id: number,
  actionType?: ?string,
  handleAction?: function,
  handlePress: function,
  isLoading?: boolean
}