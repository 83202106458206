// @flow
import React, { type Node } from 'react'
import { Platform, TouchableWithoutFeedback, View } from 'react-native'
import Loader from 'src/components/common/atoms/Loader'
import Label from 'src/components/common/atoms/Label'
import { Sizes, Colors } from 'src/styles'
import s from './Button.style'

/**
 * Button atom.
 */
export default function Button ({
  disabled = false,
  isLoading = false,
  palette = 'default',
  size = 'L',
  children = null,
  fullWidth = false,
  Icon,
  styles,
  style,
  onPress
}: PropsTypes): Node {
  return (
    <TouchableWithoutFeedback
      disabled={disabled}
      onPress={!isLoading && onPress}
    >
      <View
        style={[
          s.default,
          palette === 'primary' && s.primary,
          s[size],
          fullWidth && s.fullWidth,
          disabled && s.disabled,
          !disabled && {...Platform.select({ web: { cursor: 'pointer' }})},
          style,
          styles?.touchableOpacity
        ]}
      >

        {/* Loading */}
        {isLoading && (
          <Loader size={Sizes[size]} color={palette === 'primary' ? 'white' : Colors.orange100}/>
        )}

        {/* Button content */}
        {!isLoading && (
        <>
            {/* Icon */}
            {Icon && Icon}

            {/* Label */}
            {children && (
                <Label
                  style={[
                    s.textDefault,
                    palette === 'primary' && s.textPrimary,
                    disabled && s.textDisabled,
                    Icon && s.hasIcon,
                    typeof children === 'string' && s[`text${size}`],
                    styles?.label
                  ]}
                  type={'bold'}
                >
                  {children}
                </Label>
            )}
          </>
        )}

      </View>
    </TouchableWithoutFeedback>
  )
}

// PropsTypes
type PropsTypes = {
  active?: boolean,
  disabled?: boolean,
  isLoading?: boolean,
  palette?: 'default' | 'primary',
  size?: 'XS' | 'S' | 'M' | 'L' | 'XL',
  fullWidth?: boolean,
  type?: string,
  children?: any,
  Icon?: any,
  style?: any,
  styles?: any,
  onPress?: function
}