import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

// Styles
export default StyleSheet.create({
  container: {
    padding: 20
  },
  form: {
    marginTop: 14
  },
  textInput: {
    marginTop: 20
  },
  textInputDesktop: {
    marginTop: 30
  },
  error: {
    marginTop: 5,
    marginLeft: 30,
    marginBottom: -9,
    color: Colors.red100  },
  errorDesktop: {
    marginTop: 10,
    marginLeft: 55,
    marginBottom: -10
  },
  generalError: {
    textAlign: 'center',
    marginTop: 20,
    marginBottom: -10,
    marginLeft: 0
  },
  submitButton: {
    marginTop: 30,
  },
  submitButtonDesktop: {
    marginTop: 60,
    width: 300
  },
  labelSuccess: {
    marginTop: 30
  },

  desktopContainer: {
    maxWidth: 560,
  },
  desktopTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30
  },
  desktopFormContainer: {
    marginHorizontal: 30,
    marginVertical: 30
  }
})