// @flow
import React, {type Node, useState, useEffect} from 'react'
import {FlatList, Image, ImageBackground, ScrollView, View, Platform} from 'react-native'
import {type StackNavigationProp} from '@react-navigation/stack'
import {useMutation, useQuery} from 'relay-hooks'
import {useStoreState} from 'pullstate'
import {Trans, useTranslation} from 'react-i18next'
import type {RoutesParamList} from 'src/components/Navigation'
import {LinearGradient} from 'expo-linear-gradient'
import SkeletonContent from 'react-native-skeleton-content-nonexpo'
import {Formik} from 'formik'
import {useFocusScreenEffect} from 'src/utils/ComponentUtils'
import useScreenSizes from 'src/utils/useScreenSizes'
import PrivateLayout from 'src/components/common/layout/PrivateLayout'
import Label from 'src/components/common/atoms/Label'
import Icon from 'src/components/common/atoms/Icon'
import Button from 'src/components/common/atoms/Button'
import OrderCard from 'src/components/common/moles/OrderCard'
import NewsPreview from 'src/components/common/moles/NewsPreview'
import ServiceCard from 'src/components/common/moles/ServiceCard'
import BasketButton from 'src/components/common/moles/BasketButton'
import WelcomeModal from 'src/components/screens/private/HomeScreen/WelcomeModal'
import GoodDealHomeCard from 'src/components/common/moles/GoodDealHomeCard/GoodDealHomeCard'
import TextInputFormik from 'src/components/common/forms/TextInputFormik'
import {Colors} from 'src/styles'
import s, {cardLayout, newsLayout} from './HomeScreen.style'
import GlobalRefetchStore from 'src/store/GlobalRefetchStore'
import UserStore from "../../../../store/UserStore";

export const ListLinearGradient = (): Node => (
    <LinearGradient
        colors={['rgba(255, 255, 255, 0.1)', 'rgba(255, 255, 255, 0.9)']}
        start={{x: 0, y: 0}}
        end={{x: 1, y: 0}}
        style={s.listLinearGradient}
    />
)

/**
 * HomeScreen screen.
 */
export default function HomeScreen({navigation}: PropsTypes): Node {

    // Init state
    const {t} = useTranslation()
    const {isMobile, isTablet, isDesktop, screenSize} = useScreenSizes()
    const [search, setSearch] = useState('')
    const [serviceCardWidth, setServiceCardWidth] = useState(216)
    const bestServicesKey = useStoreState(GlobalRefetchStore, s => s.bestServicesKey)

    // API call to get connected user information
    const {data} = useQuery(graphql`
    query HomeScreenQuery {
      user {
        id
        firstName
        creditsBalance
        cashbackWallet {
          activeAmount
        }
        companyQorner {
          id
          name
          logoURL
          pictureURL
        }
      }
      unpaidOrders {
        rowId
      }
      diary {
        date
        isLate
        event {
          ... on Order {
            __typename
            id
            rowId
            primeCustomerAction
          }
        }
      }
      userBestServices {
        id
        rowId
        name
        logoURL
        isUserFavorite
        color
        familyId
      }
      articles {
        items {
          id
          rowId
          title
          mainPictureUrl
          publishedAt
          createdAt
          updatedAt
        }
      }
      informations {
        items {
          id
          rowId
          title
          mainPictureUrl
          createdAt
          updatedAt
        }
      }
      goodDeals {
        name
      }
    }
  `, {}, {
        fetchPolicy: 'network-only',
        fetchKey: bestServicesKey,
        networkCacheConfig: {
            force: true,
        }
    })

    const user = data?.user
    const diary = data?.diary
    const userBestServices = data?.userBestServices?.slice(0, 5)
    const articles = data?.articles?.items
    const informations = data?.informations?.items
    const unpaidOrdersCount = data?.unpaidOrders?.length
    const hasGoodDeals = data?.goodDeals?.length > 0

    const sectionTitleSize = isMobile ? 15 : isTablet ? 16 : 28

    // Clear search on focus screen
    useFocusScreenEffect(() => {
        setSearch('')
    })

    // Handle pressed service : navigate to ServiceScreen
    const handlePressedService = service => {
        navigation.navigate({name: 'Service', params: {familyId: service.familyId, serviceId: service.rowId}})
    }

    // Return the list of services to display in the catalog section of the page
    // if needed, add a good deal card at the beginning of the list
    const catalogList = () => {
        const items = []

        userBestServices?.forEach(service => {
            items.push(
                <ServiceCard
                    key={service.id}
                    style={{marginBottom: 5}}
                    width={isMobile ? 200 : serviceCardWidth}
                    title={service.name}
                    isFav={service.isUserFavorite}
                    iconURL={service.logoURL}
                    size={isMobile || isTablet ? 'M' : 'L'}
                    margin={{right: isMobile ? 10 : isTablet ? 6 : 16}}
                    onPress={() => handlePressedService(service)}
                    color={service.color}
                />)
        })

        if (hasGoodDeals) {
            items.unshift(
                <GoodDealHomeCard
                    key={'goodDeals'}
                    width={isMobile ? 200 : serviceCardWidth}
                    size={isMobile || isTablet ? 'M' : 'L'}
                    color={'#F0838C'}
                    margin={{right: isMobile ? 10 : isTablet ? 6 : 16}}
                    onPress={() => navigation.navigate({name: 'GoodDeal'})}
                />)
        }

        if (isMobile) {
            return items
        }
        return items.slice(0, 4)
    }

    return (
        <View style={{flex: 1}}>

            {/* WelcomeModal */}
            {isMobile && (
                <WelcomeModal/>
            )}

            {/* Layout */}
            <PrivateLayout header={false}>

                {/* Header */}
                <ImageBackground
                    source={user?.companyQorner?.pictureURL ? user.companyQorner.pictureURL : require('assets/img/screens/private/HomeScreen/default_qorner_banner.png')}
                    style={{...Platform.select({native: {width: '100%'}})}}
                    imageStyle={[s.headerImage, !isMobile && s[`headerImage${screenSize}`]]}
                >
                    <View style={[
                        s.headerContainer,
                        !isMobile && [s.headerContainerNotMobile, s[`headerContainer${screenSize}`]]
                    ]}>

                        {/* Header content mobile */}
                        {isMobile && (
                            <View style={s.headerContentContainer}>

                                {/* Company logo */}
                                <Image style={s.headerLogo}
                                       source={{uri: user?.companyQorner?.logoURL}}
                                       resizeMode={'contain'}
                                />
                                <View style={s.walletContainer}>
                                    <Icon color={'white'} name={'cashback'} style={s.walletIcon}/>
                                    <Label color={'white'} size={12} type={'bold'} style={s.walletLabel}
                                           skeleton={{width: 20, height: 12, marginRight: 10}}
                                    >
                                        {user && user.cashbackWallet?.activeAmount + ' €'}
                                    </Label>
                                    <Icon color={'white'} name={'wallet'} style={s.walletIcon}/>
                                    <Label color={'white'} size={12} type={'bold'}
                                           skeleton={{width: 20, height: 12}}
                                    >
                                        {user && user.creditsBalance + ' €'}
                                    </Label>
                                </View>

                            </View>
                        )}

                        {/* Header content desktop */}
                        {!isMobile && (
                            <View style={s.headerContentContainerDesktop}>

                                {/* Hello message */}
                                <Label style={[s.helloHeader, s.helloHeaderTablet]} type={'bold'}
                                       size={isDesktop ? 34 : 20}>
                                    {t('HomeScreen.hello', {firstName: user?.firstName})}
                                </Label>

                                {/* Search form */}
                                <Formik
                                    initialValues={{search: search}}
                                    enableReinitialize={true}
                                    onSubmit={() => {
                                    }}
                                >
                                    {(formikProps) => (
                                        <TextInputFormik
                                            {...formikProps}
                                            name={'search'}
                                            icon={'search'}
                                            size={'L'}
                                            autoCompleteType={'off'}
                                            textContentType={'none'}
                                            palette={'primary'}
                                            placeholder={t('HomeScreen.searchPlaceholder')}
                                            styles={{textInput: s.searchInput, icon: s.searchIcon}}
                                            onChangeText={value => {
                                                setSearch(value)
                                                if (value.length >= 3) {
                                                    navigation.navigate('Search', {search: value})
                                                }
                                            }}
                                        />
                                    )}
                                </Formik>

                            </View>
                        )}

                        {/* Basket button */}
                        {isMobile && (
                            <View style={s.headerButtonContainer}>
                                <BasketButton navigation={navigation}/>
                            </View>
                        )}

                    </View>

                    {/* Shadow on background image */}
                    <LinearGradient
                        colors={['transparent', 'rgba(0,0,0,0.74)']}
                        style={[s.headerGradient, !isMobile && s[`headerGradient${screenSize}`]]}
                    />

                </ImageBackground>

                {/* Alert */}
                {unpaidOrdersCount > 0 && (
                    <View style={[s.alert, !isMobile && s[`alert${screenSize}`]]}>
                        <Label size={isMobile ? 11 : isTablet ? 12 : 18}>
                            <Trans
                                i18nKey={unpaidOrdersCount > 1 ? 'HomeScreen.alert.unpaid_plural' : 'HomeScreen.alert.unpaid'}
                                components={{b: <Label type={'bold'} size={isMobile ? 11 : isTablet ? 12 : 18}/>}}
                                values={{unpaidCount: unpaidOrdersCount}}
                            />
                        </Label>
                        <Button
                            style={!isMobile && s[`alertButton${screenSize}`]}
                            palette={'primary'}
                            size={isMobile ? 'XS' : isTablet ? 'S' : 'M'}
                            onPress={() => navigation.navigate('Private', {
                                screen: 'Profile',
                                params: {screen: 'Orders'}
                            })}
                        >
                            {unpaidOrdersCount > 1 ? t('HomeScreen.alert.pay_plural') : t('HomeScreen.alert.pay')}
                        </Button>
                    </View>
                )}

                <ScrollView style={!isMobile && s[`mainScrollView${screenSize}`]}>

                    <View style={!isMobile && s.twoColumnsLayout}>

                        {/* Agenda */}
                        <View style={isMobile ? s.listContainerMobile : s.listContainerLeft}>
                            <View style={[s.listTitleContainer, !isMobile && s.listTitleContainerDesktop]}>
                                <Label size={sectionTitleSize} type={'bold'}
                                       style={s.listTitle}>{t('HomeScreen.agenda')}</Label>
                                {!isMobile && (
                                    <Button
                                        size={isTablet ? 'S' : 'M'}
                                        style={s.listButtonDesktop}
                                        onPress={() => navigation.navigate('Profile', { screen: 'Agenda' })}>
                                        {t('HomeScreen.viewAllAgenda')}
                                    </Button>
                                )}
                            </View>
                            <SkeletonContent
                                containerStyle={{flexDirection: 'row'}}
                                layout={[cardLayout, cardLayout]}
                                isLoading={!diary}
                            >
                                <FlatList
                                    style={[s.flatList, !isMobile && s.flatListNotMobile]}
                                    horizontal={isMobile}
                                    showsHorizontalScrollIndicator={false}
                                    data={isMobile ? diary : diary && diary.slice(0, 4)}
                                    keyExtractor={item => item.event.id}
                                    numColumns={isMobile ? 1 : 2}
                                    key={isMobile ? '1' : '2'}
                                    ListEmptyComponent={<Label size={isDesktop ? 18 : 12} style={{marginBottom: 10}}>
                                        {t('HomeScreen.emptyAgenda')}
                                    </Label>}
                                    renderItem={({item}) => (
                                        <OrderCard
                                            width={isMobile ? 200 : serviceCardWidth}
                                            color={Colors.orange100}
                                            orderNumber={item.event.rowId}
                                            date={item.date}
                                            retrieveBefore={item.retrieveBefore}
                                            customerAction={item.event.primeCustomerAction}
                                            size={isDesktop ? 'L' : 'M'}
                                            navigation={navigation}
                                            onPress={() => navigation.navigate('Profile', {
                                                screen: 'Orders',
                                                params: {orderId: item.event.rowId}
                                            })}
                                        />
                                    )}
                                />
                                {isMobile && <ListLinearGradient/>}
                            </SkeletonContent>
                            {isMobile && (
                                <Button
                                    size={'XS'}
                                    style={s.listButton}
                                    onPress={() => navigation.navigate('Profile', { screen: 'Agenda' })}>
                                    {t('HomeScreen.viewAllAgenda')}
                                </Button>
                            )}
                        </View>

                        {/* Catalogue */}
                        <View
                            style={isMobile ? s.listContainerMobile : s[`listContainerRight${screenSize}`]}
                            onLayout={(e) => {
                                const layoutWidth = e.nativeEvent?.layout?.width
                                if (layoutWidth) {
                                    // -3 to show right shadow
                                    setServiceCardWidth((layoutWidth - 10) / 2 - 3)
                                }
                            }}
                        >
                            <View style={[s.listTitleContainer, !isMobile && s.listTitleContainerDesktop]}>
                                <Label size={sectionTitleSize} type={'bold'} style={s.listTitle}>
                                    {t('HomeScreen.catalog')}
                                </Label>
                                {!isMobile && (
                                    <Button
                                        size={isTablet ? 'S' : 'M'}
                                        style={s.listButtonDesktop}
                                        onPress={() => navigation.navigate({name: 'Catalog'})}>
                                        {t('HomeScreen.viewAllCatalog')}
                                    </Button>
                                )}
                            </View>
                            <SkeletonContent
                                containerStyle={{flexDirection: 'row'}}
                                layout={[cardLayout, cardLayout]}
                                isLoading={!userBestServices}
                            >
                                <FlatList
                                    style={[s.flatList, !isMobile && s.flatListNotMobile]}
                                    horizontal={isMobile}
                                    showsHorizontalScrollIndicator={false}
                                    data={catalogList()}
                                    keyExtractor={item => item.key}
                                    numColumns={isMobile ? 1 : 2}
                                    key={isMobile ? '1' : '2'}
                                    ListEmptyComponent={() => <Label
                                        size={isDesktop ? 18 : 12}>{t('HomeScreen.emptyCatalog')}</Label>}
                                    renderItem={({item}) => item}
                                />
                                {isMobile && <ListLinearGradient/>}
                            </SkeletonContent>
                            {isMobile && (
                                <Button
                                    size={'XS'}
                                    style={s.listButtonCatalog}
                                    onPress={() => navigation.navigate({name: 'Catalog'})}>
                                    {t('HomeScreen.viewAllCatalog')}
                                </Button>
                            )}
                        </View>

                    </View>

                    <View style={!isMobile && s.twoColumnsLayout}>

                        {/* Actualités */}
                        <View style={isMobile ? s.listContainerMobile : s.listContainerLeft}>
                            <View style={[s.listTitleContainer, !isMobile && s.listTitleContainerDesktop]}>
                                <Label size={sectionTitleSize} type={'bold'} style={s.listTitle}>
                                    {t('HomeScreen.news')}
                                </Label>
                                {!isMobile && articles?.length > 0 && (
                                    <Button
                                        size={isTablet ? 'S' : 'M'}
                                        style={s.listButtonDesktop}
                                        onPress={() => navigation.navigate({name: 'News'})}>
                                        {t('HomeScreen.viewAllNews')}
                                    </Button>
                                )}
                            </View>
                            <SkeletonContent
                                containerStyle={{flexDirection: 'row'}}
                                layout={[newsLayout, newsLayout, newsLayout]}
                                isLoading={!articles}
                            >
                                <FlatList
                                    horizontal={isMobile}
                                    showsHorizontalScrollIndicator={false}
                                    data={isMobile ? articles : articles && articles.slice(0, 4)}
                                    keyExtractor={item => item.id}
                                    numColumns={isMobile ? 1 : 2}
                                    key={isMobile ? '1' : '2'}
                                    ListEmptyComponent={() => <Label
                                        size={isDesktop ? 18 : 12}>{t('HomeScreen.emptyNews')}</Label>}
                                    renderItem={({item, index}) => (
                                        <NewsPreview
                                            styles={{
                                                container: {
                                                    marginRight: (index % 2 === 0) || isMobile ? 10 : 0,
                                                },
                                                image: {width: '100%'}
                                            }}
                                            image={item.mainPictureUrl}
                                            publishedAt={item.publishedAt}
                                            title={item.title}
                                            onPress={() => navigation.navigate('News', {newsId: item.rowId})}
                                            size={'S'}
                                            width={serviceCardWidth}
                                        />
                                    )}
                                />
                                {isMobile && <ListLinearGradient/>}
                            </SkeletonContent>
                            {isMobile && articles?.length > 0 && (
                                <Button
                                    size={'XS'}
                                    style={s.listButton}
                                    onPress={() => navigation.navigate({name: 'News'})}>
                                    {t('HomeScreen.viewAllNews')}
                                </Button>
                            )}
                        </View>

                        {/* Infos pratiques */}
                        <View style={isMobile ? s.listContainerMobile : s[`listContainerRight${screenSize}`]}>

                            <View style={[s.listTitleContainer, !isMobile && s.listTitleContainerDesktop]}>
                                <Label size={sectionTitleSize} type={'bold'}
                                       style={s.listTitle}>{t('HomeScreen.infos')}</Label>
                                {!isMobile && informations?.length > 0 && (
                                    <Button
                                        size={isTablet ? 'S' : 'M'}
                                        style={s.listButtonDesktop}
                                        onPress={() => navigation.navigate({name: 'PracticalInformations'})}>
                                        {t('HomeScreen.viewAllInfos')}
                                    </Button>
                                )}
                            </View>
                            <SkeletonContent
                                containerStyle={{flexDirection: 'row'}}
                                layout={[newsLayout, newsLayout, newsLayout]}
                                isLoading={!informations}
                            >
                                <FlatList
                                    horizontal={isMobile}
                                    showsHorizontalScrollIndicator={false}
                                    data={isMobile ? informations : informations && informations.slice(0, 4)}
                                    keyExtractor={item => item.id}
                                    numColumns={isMobile ? 1 : 2}
                                    key={isMobile ? '1' : '2'}
                                    ListEmptyComponent={() => <Label
                                        size={isDesktop ? 18 : 12}>{t('HomeScreen.emptyInfos')}</Label>}
                                    renderItem={({item, index}) => (
                                        <NewsPreview
                                            styles={{
                                                container: {
                                                    marginRight: ((index % 2 === 0) && index !== articles.length - 1) || isMobile ? 10 : 0,
                                                },
                                                image: {width: '100%'}
                                            }}
                                            image={item.mainPictureUrl}
                                            title={item.title}
                                            onPress={() => navigation.navigate('PracticalInformations', {infoId: item.rowId})}
                                            size={'S'}
                                            width={serviceCardWidth}
                                        />
                                    )}
                                />
                                {isMobile && <ListLinearGradient/>}
                            </SkeletonContent>
                            {isMobile && informations?.length > 0 && (
                                <Button
                                    size={'XS'}
                                    style={s.listButton}
                                    onPress={() => navigation.navigate({name: 'PracticalInformations'})}>
                                    {t('HomeScreen.viewAllInfos')}
                                </Button>
                            )}
                        </View>

                    </View>

                </ScrollView>

            </PrivateLayout>
        </View>
    )
}

// PropsTypes
type PropsTypes = {
    navigation: StackNavigationProp<RoutesParamList, 'Home'>
}
