import * as Colors from './colors'

/*
 * Primary palette
 *   prefix = Colors.pp
 */

export const ppDefaultBackground               = Colors.orange100
export const ppDefaultBorder                   = 'transparent'
export const ppDefaultInputBackground          = Colors.lightGrey80
export const ppDefaultInputBorder              = Colors.orange100
export const ppDefaultInputText                = Colors.lightGrey100
export const ppDefaultStepperCount             = Colors.darkGrey100
export const ppDefaultSwitchBackground         = Colors.lightGrey90
export const ppDefaultSwitchHandleBackground   = Colors.orange100
export const ppDefaultText                     = Colors.white100

export const ppActiveBackground       = 'linear-gradient(' + Colors.orange50 + ',' + Colors.orange100 + ')'
export const ppActiveBorder           = 'transparent'
export const ppActiveInputBackground  = Colors.white100
export const ppActiveInputBorder      = Colors.orange100
export const ppActiveInputText        = Colors.darkGrey100
export const ppActiveText             = Colors.white100

export const ppHoverBackground               = 'linear-gradient(' + Colors.orange50 + ',' + Colors.orange100 + ')'
export const ppHoverBorder                   = 'transparent'
export const ppHoverSwitchBackground         = Colors.lightGrey90
export const ppHoverSwitchHandleBackground   = 'linear-gradient(' + Colors.orange50 + ',' + Colors.orange100 + ')'
export const ppHoverText                     = Colors.white100

export const ppDisableBackground              = Colors.lightGrey100
export const ppDisableBorder                  = 'transparent'
export const ppDisableInputBackground         = Colors.lightGrey100
export const ppDisableInputBorder             = 'transparent'
export const ppDisableInputText               = Colors.lightGrey90
export const ppDisableSwitchBackground        = Colors.lightGrey100
export const ppDisableSwitchHandleBackground  = Colors.lightGrey90
export const ppDisableText                    = Colors.lightGrey90

export const ppLoaderColorMain      = Colors.white100
export const ppLoaderColorSecondary = Colors.white100

/*
 * Default palette
 *   prefix = Colors.dp
 */

export const dpDefaultBackground              = Colors.lightGrey80
export const dpDefaultBorder                  = Colors.mediumGrey100
export const dpDefaultInputBackground         = Colors.lightGrey80
export const dpDefaultInputBorder             = Colors.mediumGrey100
export const dpDefaultInputText               = Colors.lightGrey100
export const dpDefaultStepperCount            = Colors.darkGrey100
export const dpDefaultSwitchBackground        = Colors.lightGrey90
export const dpDefaultSwitchHandleBackground  = Colors.lightGrey80
export const dpDefaultText                    = Colors.mediumGrey100
export const dpDarkText                       = Colors.darkGrey100

export const dpActiveBackground       = Colors.white100
export const dpActiveBorder           = Colors.mediumGrey90
export const dpActiveInputBackground  = Colors.white100
export const dpActiveInputBorder      = Colors.mediumGrey90
export const dpActiveInputText        = Colors.darkGrey100
export const dpActiveText             = Colors.mediumGrey100

export const dpHoverBackground               = Colors.white100
export const dpHoverBorder                   = Colors.mediumGrey90
export const dpHoverSwitchBackground         = Colors.lightGrey90
export const dpHoverSwitchHandleBackground   = Colors.white100
export const dpHoverText                     = Colors.mediumGrey100

export const dpDisableBackground               = Colors.lightGrey100
export const dpDisableBorder                   = 'transparent'
export const dpDisableInputBackground          = Colors.lightGrey100
export const dpDisableInputBorder              = 'transparent'
export const dpDisableInputText                = Colors.lightGrey90
export const dpDisableSwitchBackground         = Colors.lightGrey100
export const dpDisableSwitchHandleBackground   = Colors.lightGrey90
export const dpDisableText                     = Colors.lightGrey90

export const dpLoaderColorMain      = Colors.orange100
export const dpLoaderColorSecondary = Colors.orange100