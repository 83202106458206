// @flow
import React, { type Node, useEffect, useState } from 'react'
import { FlatList, Image, ScrollView, View } from 'react-native'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'relay-hooks'
import SkeletonContent from 'react-native-skeleton-content-nonexpo'
import useFullWidth from 'src/utils/useFullWidth'
import { formatPrice } from 'src/utils/FormatDataUtils'
import useScreenSizes from 'src/utils/useScreenSizes'
import PrivateLayout from 'src/components/common/layout/PrivateLayout'
import NewsPreview from 'src/components/common/moles/NewsPreview'
import ViewShadow from 'src/components/common/atoms/ViewShadow'
import Separator from 'src/components/common/atoms/Separator'
import Button from 'src/components/common/atoms/Button'
import Label from 'src/components/common/atoms/Label'
import ServiceCard from 'src/components/common/moles/ServiceCard'
import { ListLinearGradient } from 'src/components/screens/private/HomeScreen'
import { cardLayout, newsLayout } from 'src/components/screens/private/HomeScreen/HomeScreen.style'
import Loader from 'src/components/common/atoms/Loader'
import { Colors } from 'src/styles'
import s, {newsLayoutDesktop} from './ThankYouScreen.style'

export default function ThankYouScreen ({ navigation, basketId }: PropsTypes): Node {

  const { t } = useTranslation()
  const { isMobile, isTablet, isDesktop, screenSize } = useScreenSizes()
  const [visible, setVisible] = useState(true)
  const fullWidth = useFullWidth()

  const { data } = useQuery(
    graphql`
      query ThankYouScreenQuery($basketId: Int!) {
        articles {
          items {
            id
            rowId
            title
            mainPictureUrl
            publishedAt
            createdAt
            updatedAt
          }
        }
        userBestServices {
          id
          rowId
          name
          catalogIcon {
            url
          }
          logoURL
          isUserFavorite
          color
          familyId
        }
        createdOrders(basketId: $basketId) {
          id
          rowId
          cashbackEarnedAmount
          status
          statusName
        }
      }
    `,
    {
      basketId
    }
  )
  const articles = data?.articles?.items?.slice(0, 4)
  const userBestServices = data?.userBestServices?.slice(0, 5)
  const orders = data?.createdOrders

  let cashbackEarned = 0
  orders?.forEach(order => cashbackEarned += order.cashbackEarnedAmount)

  const imgSrc = cashbackEarned > 0 ?
    require('assets/img/screens/private/shoppingTunnel/ThankYouScreen/commande_avec_cashback.gif') :
    require('assets/img/screens/private/shoppingTunnel/ThankYouScreen/commande_sans_cashback.gif')

  const isMulti = false

  // Handle pressed service : navigate to ServiceScreen
  const handlePressedService = service => {
    navigation.navigate({ name: 'Service', params: { familyId: service.familyId, serviceId: service.rowId } })
  }

  useEffect(() => {
    if (orders) {
      setTimeout(() => {
        setVisible(false)
      }, 5000)
    }
  }, [orders])

  if (!orders) {
    return (
      <View style={s.loadingContainer}>
        <Loader/>
      </View>
    )
  }

  if (visible) {
    return (
      <PrivateLayout header={!isMobile}>
        <View style={s.animationContainer}>
          <Image
            source={imgSrc}
            style={s.animation}
            resizeMode={'contain'}
          />
          <Label
            size={isMobile ? 25 : 27}
            types={['bold', 'smallMargin']}
            color={Colors.orange100}
            style={{ marginBottom: 20 }}
          >
            {t('ThankYouScreen.thankYou')}
          </Label>
          <Label
            size={isMobile ? 18 : 20}
            style={{ marginBottom: 0 }}
          >
            {t(isMulti ? 'ThankYouScreen.confirmed_plural' : 'ThankYouScreen.confirmed')}
          </Label>
          <Label size={isMobile && 12} style={{ marginTop: 0 }}>{t('ThankYouScreen.pleasure')}</Label>
        </View>
      </PrivateLayout>
    )
  } else {
    return (
      <>
        <PrivateLayout >
          {/* Header */}
          <ViewShadow
            style={[s.shadow, isDesktop && s.shadowDesktop]}
            shadowOffset={{ width: 0, height: 5 }}
            shadowRadius={5}
            shadowOpacity={0.1}
            withColor={{ color: '#000', width: isMobile ? fullWidth : isTablet ? fullWidth - 200 : fullWidth - 320, height: isDesktop ? 90 : 74, radius: 0 }}
          >
            <View style={[s.headerContainer, isDesktop && s.headerContainerDesktop]}>
              {!isMobile && <View style={s[`orderButton${screenSize}`]}></View>}
              <Label
                size={isMobile ? 25 : isTablet ? 35 : 50}
                type={'bold'}
                color={Colors.orange100}
              >
                {t('ThankYouScreen.thankYou')}
              </Label>
              <Button
                style={isDesktop && s.orderButton}
                onPress={() => navigation.navigate('Profile', { screen: 'Orders' })}
                size={isMobile ? 'S' : isTablet ? 'M' : 'L'}
              >
                {t('ThankYouScreen.goToOrder')}
              </Button>
            </View>
          </ViewShadow>

          <ScrollView style={!isDesktop ? s.scrollView : s.desktopScrollView}>
            {/* Cashback */}
            {cashbackEarned > 0 ? ( !isDesktop ? (
              <View style={s.cashbackContainer}>
                <View style={s.cashbackAmountContainer}>
                  <Image
                    source={require('assets/img/screens/private/shoppingTunnel/ThankYouScreen/cashback.png')}
                    style={s.cashbackImage}
                  />
                  <Label types={['bold', 'smallMargin']} size={isMobile ? 13 : 25} style={s.cashbackDetails}>
                    <Trans
                      i18nKey={'ThankYouScreen.cashback.won'}
                      values={{ amount: formatPrice(cashbackEarned) }}
                      components={{ amount: <Label types={['bold', 'smallMargin']} size={isMobile ? 20 : 30} color={'#F2C94C'}/> }}
                    />
                  </Label>
                </View>
                <Label size={isMobile ? 10 : 14} style={s.cashbackLabel}>{t('ThankYouScreen.cashback.info')}</Label>
                <Button
                  onPress={() => navigation.navigate('Profile', { screen: 'Cashback' })}
                  size={isMobile ? 'S' : 'M'} style={s.cashbackButton}>{t('ThankYouScreen.cashback.goTo')}</Button>
              </View>
            ) : (
                <View style={s.cashbackDesktopContainer}>
                    <Image
                      source={require('assets/img/screens/private/shoppingTunnel/ThankYouScreen/cashback.png')}
                      style={s.cashbackImageDesktop}
                    />
                    <View>
                      <Label type={'bold'} size={35} style={s.cashbackDetailsDesktop}>
                        <Trans
                          i18nKey={'ThankYouScreen.cashback.won'}
                          values={{ amount: formatPrice(cashbackEarned) }}
                          components={{
                            amount: <Label type={'bold'} size={60} color={'#F2C94C'}/>
                          }}
                        />
                      </Label>
                      <Label size={25} style={s.cashbackLabel}>{t('ThankYouScreen.cashback.info')}</Label>
                    </View>
                    <Button
                      onPress={() => navigation.navigate('Profile', { screen: 'Cashback' })}
                      size={'L'}
                      style={[s.cashbackButton, !isMobile && { marginLeft: 60 }]}
                    >
                      {t('ThankYouScreen.cashback.goTo')}
                    </Button>
                </View>
              )
            ) : (
              <View style={s.listContainer}>
                {/* Catalogue */}
                <Label size={isMobile ? 15 : 28} type={'bold'} style={[s.listTitle, !isMobile && s.listTitleDesktop]}>
                  {t('ThankYouScreen.catalog')}
                </Label>

                <SkeletonContent
                  containerStyle={{ flexDirection: 'row' }}
                  layout={[cardLayout, cardLayout]}
                  isLoading={!userBestServices}
                >
                  <FlatList
                    style={!isMobile && s.flatList}
                    horizontal={true}
                    data={userBestServices}
                    keyExtractor={item => item.id}
                    ListEmptyComponent={<Label size={12}>{t('ThankYouScreen.emptyCatalog')}</Label>}
                    renderItem={({ item: service }) => (
                      <ServiceCard
                        width={216}
                        title={service.name}
                        isFav={service.isUserFavorite}
                        iconURL={service.logoURL}
                        size={isMobile ? 'M' : 'XL'}
                        margin={{ right: 16 }}
                        onPress={() => handlePressedService(service)}
                        color={service.color}
                      />
                    )}
                  />
                  <ListLinearGradient/>
                </SkeletonContent>
                <Button
                  size={isMobile ? 'XS': 'M'}
                  style={[s.listButton, { marginTop: 20 }]}
                  onPress={() => navigation.navigate('Catalog')}
                >{t('ThankYouScreen.viewAllCatalog')}</Button>
              </View>
            )}


            <Separator style={isMobile ? s.separator : s.desktopSeparator} width={!isMobile && 60}/>

            {/* Actualités */}
            <View style={s.listContainer}>
              <Label size={isMobile ? 15 : isTablet ? 22 : 28} type={'bold'} style={[s.listTitle, !isMobile && s.listTitleDesktop]}>
                {t('ThankYouScreen.news')}
              </Label>
              <SkeletonContent
                containerStyle={{ flexDirection: 'row' }}
                layout={isMobile ? [newsLayout, newsLayout, newsLayout, newsLayout] : [newsLayoutDesktop, newsLayoutDesktop, newsLayoutDesktop, newsLayoutDesktop]}
                isLoading={!articles}
              >
                <FlatList
                  horizontal={true}
                  data={articles}
                  keyExtractor={item => item.id}
                  ListEmptyComponent={<Label size={12}>{t('ThankYouScreen.emptyNews')}</Label>}
                  renderItem={({ item }) => (
                    <NewsPreview
                      image={item.mainPictureUrl}
                      publishedAt={item.createdAt}
                      title={item.title}
                      onPress={() => console.log('article pressed')}
                      size={isMobile ? 'S' : isTablet ? 'M' : 'L'}
                    />
                  )}
                />
                <ListLinearGradient/>
              </SkeletonContent>
              <Button size={isMobile ? 'XS' : isTablet ? 'S' : 'M'} style={s.listButton}
                      onPress={() => navigation.navigate('News')}
              >{t('ThankYouScreen.viewAllNews')}</Button>
            </View>

            <Separator style={isMobile ? s.separator : s.desktopSeparator} width={!isMobile && 60}/>

            {/* Questions */}
            {!isDesktop ? (
            <View style={s.questionContainer}>
              <Image
                source={require('assets/img/screens/private/shoppingTunnel/ThankYouScreen/chat.png')}
                style={[s.questionImage, isTablet && s.questionImageTablet]}
              />
              <View>
                <Label size={isMobile ? 12 : 16} style={s.questionLabel}>{t('ThankYouScreen.questions.contactUs')}</Label>
                <Button
                  size={isMobile ? 'S' : 'M'}
                  onPress={() => navigation.navigate('Chat')}
                >{t('ThankYouScreen.questions.sendChat')}</Button>
              </View>
            </View>
            ) : (
              <View style={s.questionDesktopContainer}>
                <Image
                  source={require('assets/img/screens/private/shoppingTunnel/ThankYouScreen/chat.png')}
                  style={s.questionImageDesktop}
                />
                  <Label size={!isMobile ? 30 : 14}>{t('ThankYouScreen.questions.contactUs')}</Label>
                  <Button
                    size={isMobile ? 'M' : 'L'}
                    onPress={() => navigation.navigate('Chat')}
                    style={{ marginLeft: isMobile ? 20 : 40 }}
                  >{t('ThankYouScreen.questions.sendChat')}</Button>
              </View>
            )}

          </ScrollView>

        </PrivateLayout>
      </>
    )
  }
}

type PropsTypes = {
  basketId: number
}