import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    height: 140,
    paddingVertical: 4,
    paddingHorizontal: 4
  },
  title: {
    marginBottom: 8
  },
  contentContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  btn: {
    marginTop: 10
  }
})
