import React, { type Node } from 'react'
import { View } from 'react-native'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { formatPrice } from 'src/utils/FormatDataUtils'
import Button from 'src/components/common/atoms/Button'
import Label from 'src/components/common/atoms/Label'
import Icon from 'src/components/common/atoms/Icon'
import { actions } from '../constants/OrderActionsConstant'
import s from './OrderModal.style'
import useScreenSizes from 'src/utils/useScreenSizes'

/**
 * OrderHeaderModalContent component
 */
export default function OrderHeaderModalContent ({
  id,
  status,
  createdAt,
  amount,
  primeAction,
  handleAction
}: PropsTypes): Node {
  /*
   * Init vars
   */
  const { t } = useTranslation()
  const isCollectOrDelivery = primeAction === actions.COLLECT || primeAction === actions.DELIVERY
  const { isMobile } = useScreenSizes()

  /*
   * Render component
   */
  return (
    <View style={s.headerContainer}>

      <View style={s.headerTitleContainer}>
        <Label>{t('OrderModal.orderNumber', {orderId: id})}</Label>
        <Label size={isMobile ? 10 : 12}>{t('OrderModal.createdAt', {date: moment(createdAt).format('DD MMMM')})}</Label>
      </View>

      {primeAction ?
        <Button
          size={isMobile ? 'S' : 'M'}
          palette={'primary'}
          style={s.headerActionBtn}
          fontStyle={'bold'}
          Icon={isCollectOrDelivery && <Icon name={'edit'} color={'#fff'}/>}
          onPress={() => handleAction(primeAction)}
        >
          {t('ProfileOrderCard.' + primeAction, {amount: formatPrice(amount)})}
        </Button>
        :
        <Label
          size={isMobile ? 11 : 13}
          types={['bold', 'smallMargin']}
          style={s.primeLabel}
        >
          {status >= 29 ? t('ProfileOrderCard.ended') : t('ProfileOrderCard.processing')}
        </Label>
      }

    </View>
  )
}

type PropsTypes = {
  id: number,
  status: number,
  createdAt: string,
  amount: number,
  primeAction: string,
  handleAction: function
}