import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

export default StyleSheet.create({
  container: {
    marginVertical: 20
  },
  inputContainer: {
    marginHorizontal: 0
  },
  inputRow: {
    marginBottom: 10
  },
  inputText: {
    height: 32,
    paddingHorizontal: 10,
    backgroundColor: Colors.lightGrey80,
    borderWidth: 1,
    borderColor: Colors.darkGrey100Less50,
    borderRadius: 30
  },
  inputTextExpireDate: {
    flex: 1,
    marginRight: 10
  },
  inputTextCvc: {
    flex: 1
  },
  labelRegisterCard: {
    marginRight: 6
  },
  actionContainer: {
    marginTop: 14
  },
  loadingLabel: {
    textAlign: 'center',
    marginTop: 8
  },
  payBtn: {
    marginTop: 14,
    marginBottom: 10,
    width: '100%'
  }
})
