// @flow
import React, { type Node, useEffect, useRef, useState } from 'react'
import { Animated, Pressable, View } from 'react-native'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { formatPrice } from 'src/utils/FormatDataUtils'
import useScreenSizes from 'src/utils/useScreenSizes'
import Label from 'src/components/common/atoms/Label'
import Icon from 'src/components/common/atoms/Icon'
import Button from 'src/components/common/atoms/Button'
import ViewShadow from 'src/components/common/atoms/ViewShadow'
import s from './PaymentDeliverySection.style'
import { Colors } from 'src/styles'

export default function PaymentDeliverySection ({
  deliveryLockerName,
  collectDate,
  deliveryDate,
  disabled = false,
  setIsOpenLockerSelect,
  setIsOpenCollectDateSelect,
  setIsOpenDeliveryDateSelect,
  deliveryPrice,
  requiredCollectAndDelivery = false
}: PropsTypes): Node {

  // Init values
  const { t } = useTranslation()
  const { isMobile, isDesktop } = useScreenSizes()
  const [isOpenDelivery, setIsOpenDelivery] = useState(!isMobile)

  // Delivery settings container animation settings
  const maxHeight = 1000
  const deliveryHeightAnimation = useRef(new Animated.Value(isOpenDelivery ? maxHeight : 40)).current

  // Delivery Settings container height animation
  useEffect(() => {
    Animated.timing(deliveryHeightAnimation, {
      toValue: isOpenDelivery ? maxHeight : 40,
      duration: 500,
      useNativeDriver: false  // <-- neccessary
    }).start()
  }, [isOpenDelivery])

  return (
    <ViewShadow
      shadowOffset={{ width: 0, height: 0 }}
      shadowRadius={8}
      style={[
        s.deliveryShadowContainer,
        !isMobile && s.deliveryShadowContainerDesktop,
        !(collectDate && deliveryDate) && {
          borderLeftColor: Colors.orange100,
          borderLeftWidth: 2
        }
      ]}
    >
      <Animated.View style={[
        { maxHeight: deliveryHeightAnimation },
        s.deliveryAnimationContainer
      ]}>

        <Pressable style={[s.deliveryMinimalInfos, !isMobile && s.deliveryMinimalInfosDesktop]} onPress={() => isMobile && setIsOpenDelivery(v => !v)}>
          <Label size={!isMobile ? 20 : 14}>{t('BasketScreen.delivery.title')}</Label>

          <View style={s.deliveryPrice}>
            <Label size={isMobile ? 9 : 14}>
              {t('BasketScreen.delivery.deliveryFee', { amount: formatPrice(deliveryPrice) })}
            </Label>
            {isMobile && (<Pressable
              onPress={() => setIsOpenDelivery(v => !v)}
              style={s.deliveryCaret}
            >
              <Icon name={'caret'} size={5} style={isOpenDelivery && { transform: [{ rotateX: '180deg' }] }}/>
            </Pressable>)}
          </View>
        </Pressable>

        <View>
          <View style={[s.deliveryConfigRow, !isMobile && s.deliveryConfigRowDesktop]}>
            <Label style={!isDesktop && s.deliveryConfigLabel} size={isMobile ? 11 : 16}>
              {t('BasketScreen.delivery.chooseLockerLabel')}
            </Label>
            <Button
              style={!isDesktop && s.deliveryConfigButton}
              size={isMobile ? 'XS' : 'M'}
              onPress={() => setIsOpenLockerSelect(true)}
              disabled={disabled}
            >
              {deliveryLockerName ?
                deliveryLockerName
                : t('BasketScreen.delivery.chooseLockerButton')}
            </Button>
          </View>
          {requiredCollectAndDelivery === true && (
            <View style={[s.deliveryConfigRow, !isMobile && s.deliveryConfigRowDesktop]}>
              <Label style={!isDesktop && s.deliveryConfigLabel} size={isMobile ? 11 : 16}>
                {t('BasketScreen.delivery.chooseCollectDate')}
              </Label>
              <Button
                style={!isDesktop && s.deliveryConfigButton}
                size={isMobile ? 'XS' : 'M'}
                onPress={() => setIsOpenCollectDateSelect(true)}
                disabled={disabled}
              >
                {collectDate ?
                  moment(collectDate).format('DD MMMM')
                  : t('BasketScreen.delivery.chooseDate')}
              </Button>
            </View>
          )}
          <View style={[s.deliveryConfigRow, !isMobile && s.deliveryConfigRowDesktop]}>
            <Label style={!isDesktop && s.deliveryConfigLabel} size={isMobile ? 11 : 16}>
              {t('BasketScreen.delivery.chooseDeliveryDate')}
            </Label>
            <Button
              style={!isDesktop && s.deliveryConfigButton}
              size={isMobile ? 'XS' : 'M'}
              onPress={() => setIsOpenDeliveryDateSelect(true)}
              disabled={disabled}
            >
              {deliveryDate ?
                moment(deliveryDate).format('DD MMMM')
                : t('BasketScreen.delivery.chooseDate')}
            </Button>
          </View>
        </View>

      </Animated.View>
    </ViewShadow>
  )
}

type PropsTypes = {
  deliveryLockerName: string,
  collectDate: string,
  deliveryDate: string,
  disabled?: boolean,
  setIsOpenLockerSelect: function,
  setIsOpenCollectDateSelect: function,
  setIsOpenDeliveryDateSelect: function,
  deliveryPrice: number,
  requiredCollectAndDelivery: boolean
}