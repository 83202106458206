// @flow
import React, { type Node, useState } from 'react'
import { TouchableWithoutFeedback, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { type StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from 'relay-hooks'
import { formatNumber } from 'src/utils/FormatDataUtils'
import type { RoutesParamList } from 'src/components/Navigation'
import useScreenSizes from 'src/utils/useScreenSizes'
import PrivateLayoutProfile from 'src/components/common/layout/PrivateLayoutProfile'
import Modal from 'src/components/common/atoms/Modal'
import Avatar from 'src/components/common/atoms/Avatar'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import Icon from 'src/components/common/atoms/Icon'
import ProfilePersonalInfoScreenModal from './ProfilePersonalInfoScreenModal'
import { Palette, Sizes, Colors } from 'src/styles'
import s from './ProfilePersonalInfoScreen.style'
import NoPictureAvatar from 'src/components/common/atoms/NoPictureAvatar'

/**
 * ProfilePersonalInfoScreen screen.
 */
export default function ProfilePersonalInfoScreen ({ navigation }: PropsTypes): Node {

  // Init state
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const { t } = useTranslation()
  const availableLanguages = [
    { label: t('App.fr'), value: 'fr' },
    { label: t('App.en'), value: 'en' }
  ]

  // Responsive
  const { isMobile, isDesktop, isTablet } = useScreenSizes()
  const textSize = isMobile ? 12 : isTablet ? 16 : 28
  const textSkeleton = { width: '100%', height: textSize * 1.5 }
  const avatarSize = !isDesktop ? 60 : 140

  // API Call
  const { data } = useQuery(graphql`
    query ProfilePersonalInfoScreenQuery {
      user {
        id
        email
        firstName
        lastName
        phoneNumber
        lang
        pictureURL
      }
    }
  `)
  const userData = data?.user

  // Render Screen
  return (
    <>
      <PrivateLayoutProfile
        navigation={navigation}
        avatar={userData && userData.id && userData.pictureURL && (
          <Avatar
            source={{ uri : userData.pictureURL }}
            style={s.avatar}
            size={Sizes.L}
            isActive={true}
            skeleton={true}
          />
        )}
        subHeaderTitle={userData?.firstName && userData?.firstName + ' ' + userData?.lastName}
      >
        <View style={[s.mainContainer, !isMobile && s.mainContainerDesktop]}>
          <View style={s.dataContainer}>

            {/* Avatar edit */}
            <View>
              <TouchableWithoutFeedback onPress={() => {setModalIsOpen(true)}}>
                <View style={isDesktop && s.avatarDesktop}>
                  {userData?.pictureURL ? (
                    <Avatar
                      source={userData?.pictureURL}
                      style={s.avatar}
                      size={avatarSize}
                      isActive={true}
                    />
                  ) : (
                    <NoPictureAvatar
                      style={s.avatar}
                      size={avatarSize}
                      iconSize={!isDesktop ? 25 : 50}
                      labelSize={!isDesktop ? 25 : 40}
                      labelColor={Colors.darkGrey100}
                      initials={userData?.firstName?.charAt(0).toUpperCase() + userData?.lastName?.charAt(0).toUpperCase()}/>
                  )}
                  <Button
                    style={[s.updateAvatar, isDesktop && s.updateAvatarDesktop]}
                    Icon={<Icon name='edit' size={!isDesktop ? 12 : 30} style={isDesktop && s.updateAvatarIcon} color={Palette.ppDefaultText}/>}
                    onPress={() => {setModalIsOpen(true)}}
                  />
                </View>
              </TouchableWithoutFeedback>
            </View>

            {/* Display personal information */}
            <View style={[!isDesktop ? s.textContainer : s.textContainerDesktop]}>
                <View style={[s.personalInfoRow, isDesktop && s.personalInfoRowDesktop]}>
                  <Label color={Colors.mediumGrey100} size={textSize}>{t('ProfilePersonalInfoScreen.labels.firstName')} :</Label>
                  <Label style={s.personalInfoData} size={textSize} skeleton={textSkeleton}>{userData?.firstName}</Label>
                </View>
                <View style={[s.personalInfoRow, isDesktop && s.personalInfoRowDesktop]}>
                  <Label color={Colors.mediumGrey100} size={textSize}>{t('ProfilePersonalInfoScreen.labels.lastName')} :</Label>
                  <Label style={s.personalInfoData} size={textSize} skeleton={textSkeleton}>{userData?.lastName}</Label>
                </View>
                <View style={[s.personalInfoRow, isDesktop && s.personalInfoRowDesktop]}>
                  <Label color={Colors.mediumGrey100} size={textSize}>{t('ProfilePersonalInfoScreen.labels.email')} :</Label>
                  <Label style={s.personalInfoData} size={textSize} skeleton={textSkeleton}>{userData?.email}</Label>
                </View>
                <View style={[s.personalInfoRow, isDesktop && s.personalInfoRowDesktop]}>
                  <Label color={Colors.mediumGrey100} size={textSize}>{t('ProfilePersonalInfoScreen.labels.phone')} :</Label>
                  <Label style={s.personalInfoData} size={textSize} skeleton={textSkeleton}>{userData?.phoneNumber && formatNumber(userData.phoneNumber)}</Label>
                </View>
                {/*<View style={[s.personalInfoRow, isDesktop && s.personalInfoRowDesktop]}>*/}
                {/*  <Label color={Colors.mediumGrey100} size={textSize}>{t('ProfilePersonalInfoScreen.labels.language')} :</Label>*/}
                {/*  <Label style={s.personalInfoData} size={textSize} skeleton={textSkeleton}>{userData?.lang && t('App.'+ userData?.lang)}</Label>*/}
                {/*</View>*/}
            </View>
          </View>

          {/* Open edit modal */}
          <Button
            size={!isDesktop ? 'M' : 'XL'}
            style={[s.actionButton, isDesktop && s.actionButtonDesktop]}
            onPress={() => setModalIsOpen(true)}
          >
            {t('ProfilePersonalInfoScreen.labels.updateProfile')}
          </Button>
        </View>

      </PrivateLayoutProfile>

      {/* Edit Modal */}
      <Modal open={modalIsOpen} onClose={() => setModalIsOpen(false)}>
        <ProfilePersonalInfoScreenModal user={userData} availableLanguages={availableLanguages}/>
      </Modal>
    </>
  )
}

// PropsTypes
type PropsTypes = {
  navigation: StackNavigationProp<RoutesParamList, 'PersonalInformations'>
}
