// @flow
import React, {type Node, useState} from 'react'
import {View} from 'react-native'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'relay-hooks'
import type {StackNavigationProp} from '@react-navigation/stack'
import * as Yup from 'yup'
import {Formik} from 'formik'
import useScreenSizes from 'src/utils/useScreenSizes'
import Icon from 'src/components/common/atoms/Icon'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import TextInputFormik from 'src/components/common/forms/TextInputFormik/TextInputFormik'
import Loader from 'src/components/common/atoms/Loader'
import SwitchInputFormik from 'src/components/common/forms/SwitchInputFormik'
import type {RoutesParamList} from 'src/components/Navigation'
import {Colors} from 'src/styles'
import s from './ValidateQornerSelectionModal.style'
import UserStore from 'src/store/UserStore'
import GlobalRefetchStore from 'src/store/GlobalRefetchStore'

/**
 * ValidateQornerSelectionModal modal.
 */
export default function ValidateQornerSelectionModal({
                                                         onClose,
                                                         selectedQorner,
                                                         mustCertify,
                                                         navigation,
                                                         resetScreen
                                                     }: PropsTypes): Node {

    // Init state
    const {t} = useTranslation()
    const {isMobile, isTablet, screenSize} = useScreenSizes()
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [inputCodePS, setInputCodePS] = useState("");
    const [isValidCodePS, setIsValidCodePS] = useState(null)

    const [createOrUpdateUserQornerCodePsMutation] = useMutation(
        graphql`
              mutation ValidateQornerSelectionModalUserQornerMutation($qornerId: Int!, $userCodePs: String!) {
                createOrUpdateUserQornerCodePs(qornerId: $qornerId, userCodePs: $userCodePs)
              }
            `,
        {
            onCompleted: () => {
            },
            onError: (err) => {
                // Log error and change UI to error state
                console.error(err)
                setError(true)
            }
        },
    )

    //  eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE2NzM5Njk3NTYsImV4cCI6MTY3NDA1NjE1Niwicm9sZXMiOlsiUk9MRV9DVVNUT01FUl9CMkMiLCJST0xFX1VTRVIiXSwidXNlcm5hbWUiOiJheW0ycmljQGxpdmUuZnIifQ.OKY5acAOTipBDoqXeUnCqu3dAefqIKMlJDJaW-4hpRnToO_ATkWq3AADm43S_A6uKsFGsjkg3PfWWFwr1e0WD99LAeKPVOZ3m2FnY2SAUx2GKXZIXDK5BlHeayOi8mVUXTjVMwfWYKu7qsRXp2WiiANPY2YcsbKAsAexgWtgAjv_fLwIyG42LksD3uIUPQ3aDPCo94VtKtiAvz1mbjSAoRO_QfBWt2GwRjpIvfYxgvRJ8RuPwiMmUkEKGP6Dptv3ov5eY65pLKIlhbbedgMtO6y0ia0FM_lYBbYy_vcwowtGL4bDJez-HZPmMp4q27UsX2zBFZD2JRY6q0bUH1kacrd65ruGrg6Q6QQq8niOvMAb7cyAknRLN4Tz6pmSPGQlOt0ClMKrADj09qnWpMWs5PD1l2xCQqZNejAZDcJ0YnWqwudSf38NtPGG9cY_7HelUdFsS0EZO9uywfnFrPofAXAzEK3Y55eddEDIAPO-LCCYoKoMunjRIDIf1tM7wIJdWhL8_IvWXD18v9dBEySu3aj6vHXid2YnIK24tDrOazk3NXE_j7jg5v_pSsnz5LqM9HQTTsCoQ2Dquy8NNueaQTUmk8IVy9ykjYaRofQ1Pp_FUjjzeu-dikhsCboU_1YqE6fV04fkCi8DAZkIx4--zKaSB6TYh8nnNtFyAy9UzXc

    // Select Qorner call
    const [selectQornerMutation, {loading}] = useMutation(
        graphql`
      mutation ValidateQornerSelectionModalMutation($qornerId: Int!) {
        SelectQorner(qornerId: $qornerId) {
          id
          companyQorner {
            id
            rowId
            qornerType
          }
        }
      }
    `,
        {
            onCompleted: ({SelectQorner}) => {

                // Update current user context
                UserStore.update(s => {
                    s.currentUserHaveQorner = true
                    s.currentQornerID = SelectQorner.companyQorner?.qornerType === 'qorner_ps' ? SelectQorner.companyQorner?.rowId : null
                })
                GlobalRefetchStore.update(s => {
                    s.bestServicesKey = new Date()
                })

                // Change state to success to change the icon display in the modal and wait for 500ms
                setSuccess(true)

                // Wait for 500ms
                setTimeout(() => {

                    // Reset modal & screen
                    setSuccess(false)
                    resetScreen()

                    // Redirect to Home
                    navigation.navigate({name: 'Private', params: {screen: 'Home'}})

                }, 500)

            },
            onError: (err) => {
                // Log error and change UI to error state
                console.error(err)
                setError(true)
            }
        },
    )

    // No selected Qorner yet
    if (!selectedQorner) {
        return null
    }

    // Render screen
    return (
        <View style={!isMobile && s.containerNotMobile}>

            {/* Loading / Success / Error ModalContent */}
            {(loading || success || error) &&
                <View style={s.modalContent}>

                    {/* Qorner title */}
                    <Label style={[s.qornerTitle, !isMobile && s.qornerTitleNotMobile]}>{selectedQorner.name}</Label>

                    {/* State Icon */}
                    <View style={s.iconStateContainer}>
                        {loading && <Loader color={Colors.orange100} size={isMobile ? 20 : 22}/>}
                        {success && <Icon style={s.successIcon} name={'check'} size={isMobile ? 20 : 22}/>}
                        {error && <Icon style={s.errorIcon} name={'close'} size={isMobile ? 20 : 22}/>}
                    </View>

                </View>}

            {/* ValidateQornerSelection ModalContent */}
            {!(loading || success || error) &&
                <View style={s.modalContent}>

                    {/* Qorner title */}
                    <Label size={isMobile ? 14 : isTablet ? 20 : 25}
                           style={[s.qornerTitle, !isMobile && s.qornerTitleNotMobile]}>
                        {selectedQorner.name}
                    </Label>

                    {/* Confirm form */}
                    <Formik
                        initialValues={{certify: !mustCertify}}
                        validationSchema={Yup.object().shape({
                            certify: Yup.string().required(t('Yup.errors.required')),
                        })}
                        onSubmit={() => {
                            // Qorner PS : check code
                            if (selectedQorner.qornerType === 'qorner_ps') {
                                // Check IsValidCodePS with Ajax call
                                createOrUpdateUserQornerCodePsMutation({
                                    variables: {
                                        qornerId: selectedQorner.rowId,
                                        userCodePs: inputCodePS,
                                    }
                                }).then(function (result) {
                                        var responseRequest = result.createOrUpdateUserQornerCodePs;

                                        // Login in app with Qorner
                                        if (responseRequest === true) {
                                            selectQornerMutation({
                                                variables: {
                                                    qornerId: selectedQorner.rowId
                                                }
                                            })
                                        }

                                        // Set IsValidCodePS (if false display error message)
                                        setIsValidCodePS(responseRequest);
                                    }
                                );
                            } else {
                                // Other Qorner types, dont need CodePS to access
                                selectQornerMutation({
                                    variables: {
                                        qornerId: selectedQorner.rowId
                                    }
                                })
                            }
                        }}
                    >
                        {(formikProps) => (
                            <>
                                {/* Certify container */}
                                {mustCertify && (
                                    <View style={s.modalCertify}>

                                        {/* Certify text */}
                                        <Label size={isMobile ? 10 : isTablet ? 16 : 20} style={s.modalText}>
                                            {t('ValidateQornerSelectionModal.certify', {
                                                'qornerName': selectedQorner.name,
                                                'qornerAddress': selectedQorner.address
                                            })}
                                        </Label>

                                        {/* Certify switch */}
                                        <View style={!isMobile && s.modalSwitchDesktop}>
                                            <SwitchInputFormik
                                                size={isMobile ? 'S' : isTablet ? 'M' : 'XL'}
                                                name={'certify'}
                                                {...formikProps}
                                            />
                                        </View>

                                    </View>
                                )}

                                {/* Input Code PS - Only for Qorner PS type */}
                                <View style={s.divInputCodePS}>
                                    {selectedQorner.qornerType === 'qorner_ps' && <>
                                        <Label size={isMobile ? 10 : isTablet ? 16 : 20}>
                                            {t('ValidateQornerSelectionModal.isValidCodePS.description')}
                                        </Label>

                                        <TextInputFormik
                                            {...formikProps}
                                            name={'code_ps'}
                                            size={isMobile ? 'M' : isTablet ? 'L' : 'XL'}
                                            style={[s.inputCodePS, !isMobile && s[`inputCodePS${screenSize}`]]}
                                            icon={'lock'}
                                            maxLength={6}
                                            autoCompleteType={'off'}
                                            textContentType={'none'}
                                            palette={'primary'}
                                            placeholder={t('ValidateQornerSelectionModal.inputCodePS.placeholder')}
                                            styles={{textInput: s.searchInput}}
                                            onChangeText={value => {
                                                setInputCodePS(value)
                                            }}
                                        />

                                        {isValidCodePS === false && <>
                                            <Label size={isMobile ? 10 : isTablet ? 16 : 20}
                                                   style={s.textError}>
                                                {t('ValidateQornerSelectionModal.isValidCodePS.error')}
                                            </Label>
                                        </>
                                        }
                                    </>
                                    }
                                </View>

                                {/* Actions buttons */}
                                <View>
                                    {/* Validate */}
                                    <Button
                                        size={isMobile ? 'M' : isTablet ? 'L' : 'XL'}
                                        style={[s.actionButton, !isMobile && s[`actionButton${screenSize}`]]}
                                        fontStyle={'bold'}
                                        palette={'primary'}
                                        disabled={!formikProps.values.certify}
                                        onPress={formikProps.handleSubmit}
                                    >
                                        {t('ValidateQornerSelectionModal.confirmChoice')}
                                    </Button>

                                    {/* Cancel */}
                                    <Button
                                        size={isMobile ? 'M' : isTablet ? 'L' : 'XL'}
                                        style={[s.actionButton, !isMobile && s[`cancelButton${screenSize}`]]}
                                        fontStyle={'bold'}
                                        onPress={onClose}
                                    >
                                        {t('App.cancel')}
                                    </Button>

                                </View>

                            </>
                        )}
                    </Formik>
                </View>}

        </View>
    )
}

// PropsTypes
type PropsTypes = {
    selectedQorner: ?any,
    mustCertify: boolean,
    navigation: StackNavigationProp<RoutesParamList, 'QornerChoice'>,
    resetScreen: function
}
