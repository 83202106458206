import { StyleSheet, Platform } from 'react-native'
import { Sizes, Colors } from 'src/styles'

// Styles
export default StyleSheet.create({
  container: {
    borderRadius: 0,
    backgroundColor: '#fff',
    padding: 8,
  },
  containerDesktop: {
    padding: 15
  },
  cardContainer: {
    flex: 1,
    justifyContent: 'space-between'
  },
  title: {
    alignItems: 'flex-start',
    marginBottom: 10
  },
  iconContainer: {
    flex: 1,
    alignItems: 'center'
  },
  icon: {
    resizeMode: 'contain',
    height: '100%',
    width: '100%'
  },
  discountTag: {
    borderWidth: 0,
    margin: 0
  },
  discountPriceContainer: {
    flexDirection: 'row'
  },
  discountOriginalPrice: {
    textDecorationLine: 'line-through',
    textDecorationStyle: 'solid',
    marginRight: 6
  },
  discountPrice: {
    color: Colors.red100
  },
  cashbackTag: {
    borderWidth: 0,
    marginBottom: 5,
    alignItems: 'center'
  },
  price: {
    marginTop: 10,
    marginBottom: 10,
    alignItems: 'center',
    justifyContent: 'center'
  },
  button_container: {
  },
  button: {
    width: '90%',
    backgroundColor: Colors.orange100
  }
})