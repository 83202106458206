import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  productContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 5
  },
  productContainerDesktop: {
    marginBottom: 10
  },
  quantityContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  addDetailsButton: {
    marginTop: 5,
    alignSelf: 'flex-start'
  },
  addDetailsButtonDesktop: {
    marginTop: 10
  },
  selectPriceLabel: {
    marginLeft: 5
  },
  selectPriceLabelDesktop: {
    marginLeft: 10
  },
  productLabelContainer: {
    flex: 1
  }
})