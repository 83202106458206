// @flow
import React, { type Node, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatPrice } from 'src/utils/FormatDataUtils'
import useScreenSizes from 'src/utils/useScreenSizes'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import s from './ProductConfigModal.style'

export default function ConfigModalContent ({
  product,
  setOpenBis,
  setCurrentStep,
  steps
}: PropsTypes): Node {

  /*
   * Init
   */
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const [productConfigStep, setProductConfigStep] = useState(0)
  const stepCount = product.configs.length
  const productConfig = product.configs[productConfigStep]

  /*
   * Handle next step (decline or continue)
   */
  const handlePress = () => {
    if (productConfigStep + 1 < stepCount) {
      setProductConfigStep(productConfigStep + 1)
    } else {
      setProductConfigStep(0)
      setCurrentStep(product.supplements ? steps.SUPPLEMENTS : steps.SUMMARY)
    }
  }

  /*
   * Render component
   */
  return (<>
    <Label style={s.title} size={!isMobile ? 28 : 14}>{productConfig.name}</Label>
    <Label style={s.description} size={isMobile ? 10 : 20}>{productConfig.description}</Label>
    {productConfig.options.map(config => (
      <Button key={config.name + config.price}
              style={s.button}
              palette={'primary'}
              size={isMobile ? 'S' : 'L'}
              onPress={handlePress}
      >
        {config.name + ' + ' + formatPrice(config.price)}
      </Button>
    ))}
    {!productConfig.required &&
    <Button style={s.button} size={isMobile ? 'S' : 'L'} onPress={handlePress}>{t('ProductModal.decline')}</Button>}
    <Button style={[s.button, s.cancelButton]} size={isMobile ? 'S' : 'L'} onPress={() => {
      setOpenBis(false)
      setProductConfigStep(0)
    }}>{t('ProductModal.cancel')}</Button>
  </>)
}

type PropsTypes = {
  product: any,
  setOpenBis: function,
  setCurrentStep: function,
  steps: any
}

