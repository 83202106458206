// @flow
import React, { type Node, useState, useEffect } from 'react'
import { Image, ScrollView, View } from 'react-native'
import { type StackNavigationProp } from '@react-navigation/stack'
import type { RoutesParamList } from 'src/components/Navigation'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'relay-hooks'
import { formatPrice } from 'src/utils/FormatDataUtils'
import { useFocusScreenEffect } from 'src/utils/ComponentUtils'
import useScreenSizes from 'src/utils/useScreenSizes'
import PrivateLayout from 'src/components/common/layout/PrivateLayout'
import SelectLockerModal from 'src/components/common/moles/SelectLockerModal'
import SelectCollectDateModal from 'src/components/common/moles/SelectCollectDateModal'
import SelectDeliveryDateModal from 'src/components/common/moles/SelectDeliveryDateModal'
import CustomerCommentModal from 'src/components/common/moles/BasketProductConfig/CustomerCommentModal'
import PayModal from 'src/components/common/moles/PayModal'
import ThankYouScreen from 'src/components/screens/private/shoppingTunnel/ThankYouScreen'
import BasketServiceCard from 'src/components/common/moles/BasketServiceCard/BasketServiceCard'
import PaymentDeliverySection from 'src/components/common/moles/PaymentDeliverySection/PaymentDeliverySection'
import PaymentSpecialOfferSection
  from 'src/components/common/moles/PaymentSpecialOfferSection/PaymentSpecialOfferSection'
import PaymentCashbackSection from 'src/components/common/moles/PaymentCashbackSection/PaymentCashbackSection'
import PaymentSection from 'src/components/common/moles/PaymentSection/PaymentSection'
import Modal from 'src/components/common/atoms/Modal'
import NeedCoverModal from 'src/components/screens/private/shoppingTunnel/BasketScreen/NeedCoverModal'
import Loader from 'src/components/common/atoms/Loader'
import Label from 'src/components/common/atoms/Label'
import Icon from 'src/components/common/atoms/Icon'
import Button from 'src/components/common/atoms/Button'
import s from './BasketScreen.style'
import { Colors } from 'src/styles'

/**
 * BasketScreen screen.
 */
export default function BasketScreen ({ navigation, route }: PropsTypes): Node {
  // Init vars
  const { t } = useTranslation()
  const { isMobile, width } = useScreenSizes()
  const [selectedOrderProduct, setSelectedOrderProduct] = useState()
  const [selectedProviderTitle, setSelectedProviderTitle] = useState()
  const [modalStep, setModalStep] = useState()
  const [selectedCard, setSelectedCard] = useState()
  const [paidBasket, setPaidBasket] = useState(false)
  const [isOpenLockerSelect, setIsOpenLockerSelect] = useState(false)
  const [isOpenCollectDateSelect, setIsOpenCollectDateSelect] = useState(false)
  const [isOpenDeliveryDateSelect, setIsOpenDeliveryDateSelect] = useState(false)
  const [displayCoverModal, setDisplayCoverModal] = useState(false)
  const [basketId, setBasketId] = useState()
  const isCustomTablet = 768 <= width && width < 1024
  const [isRedirectPaymentSuccessful, setIsRedirectPaymentSuccessful] = useState(route.params?.redirect ? (route.params?.redirect === 'success') : null)

  // Used to refetch data
  const [count, setCount] = useState(0)

  // Steps for payment modal
  const modalSteps = {
    NEW_CARD: 10,
    WALLET: 20,
    RECURRING_CARD: 30,
    FILL_WALLET: 40,
    WALLET_AND_CARD: 50,
    FREE_BASKET: 60
  }

  // Increment when we display this screen. Incrementation allow graphql query to be refetched
  useFocusScreenEffect(() => {
    setCount(count + 1)
    setPaidBasket(false)
  })

  // Api call to retrieve user's basket content
  const { data } = useQuery(
    graphql`
      query BasketScreenQuery {
        basket {
          id
          rowId
          amount
          amountAfterReduction
          useCashback
          cashbackEarnedAmount
          discountByCashback
          requiredCoverAmount
          voucher {
            code
            percentage
            price
          }
          services {
            rowId
            amount
            minAmountPerOrder
            lockerMinAmountPerOrder
            name
            logoURL
            serviceType
            providerTitle
            products {
              rowId
              productId
              productPrice
              productType
              isCover
              name
              price
              quantity
              customerComment
            }
          }
          deliverySettings {
            collectDate
            deliveryDate
            price
            collectLocker {
              name
            }
            deliveryLocker {
              name
            }
            requiredCollectAndDelivery
          }
        }
        user {
          id
          availableCovers
          creditsBalance
          firstName
          lastName
          phoneNumber
          cashbackWallet {
            activeAmount
          }
        }
        recurringCreditCards {
          cardNumber
          token
        }
      }
    `,
    {},
    {
      fetchPolicy: 'network-only',
      fetchKey: count,
      networkCacheConfig: {
        force: true,
      },
    }
  )
  const basket = data?.basket
  const user = data?.user
  const recurringCreditCards = data?.recurringCreditCards

  /** Checks if all services amount respect their minimum amount*/
  const isBasketPayable = () => {
    let payable = true

    basket.services.forEach(service => {
      if (service.lockerMinAmountPerOrder && service.lockerMinAmountPerOrder > service.amount) payable = false
      else if (service.minAmountPerOrder > service.amount) payable = false
    })
    if (
      (basket.deliverySettings.requiredCollectAndDelivery && !basket.deliverySettings.collectDate) ||
      !basket.deliverySettings.deliveryDate
    ) {
      payable = false
    }

    return payable
  }

  const needCovers = () => {
    setDisplayCoverModal(basket?.requiredCoverAmount > 0 && (!user?.firstName || !user?.lastName || !user?.phoneNumber))
  }

  useEffect(() => {
    if (basket && user) needCovers()
  }, [basket, user])

  useEffect(() => {
    // otherwise, basket value is updated when basket button refetch data in the thank you page
    // then basket is set to null and thank you page can't load
    setBasketId(basket?.rowId)
  }, [paidBasket])

  const HeaderRight = () => (
    <View style={s.headerRight}>
      <Label type={!isMobile ? 'bold' : 'default'} size={!isMobile ? 26 : 14}>
        {t('BasketScreen.headerToPay', { amount: formatPrice(basket.amountAfterReduction) })}
      </Label>
      <Label size={isMobile ? 9 : 18} style={[s.headerRightCashback, !isMobile && s.headerRightCashbackDesktop]}>
        <Trans
          i18nKey={'BasketScreen.cashback.earnable'}
          components={{ b: <Label type={'bold'} size={isMobile ? 9 : 18}/> }}
          values={{ amount: formatPrice(basket.cashbackEarnedAmount) }}
        />
      </Label>
    </View>
  )

  if (isRedirectPaymentSuccessful) {
    return (
      <>
        <PrivateLayout headerTitle={t('BasketScreen.headerTitle')} headerRight={<></>}>
          <View style={s.loadingContainer}>
            <Loader/>
          </View>
        </PrivateLayout>
      </>
    )
  }

  if (paidBasket && basketId) {
    return <ThankYouScreen basketId={basketId} navigation={navigation}/>
  }

  // Loading State
  if (!data) {
    return (
      <PrivateLayout headerTitle={t('BasketScreen.headerTitle')} headerRight={<></>}>
        <View style={s.loadingContainer}>
          <Loader/>
        </View>
      </PrivateLayout>
    )
  }

  // Empty Basket State
  if (data && !basket) {
    return (
      <PrivateLayout headerTitle={t('BasketScreen.headerTitle')} headerRight={<></>}>
        <View style={s.loadingContainer}>
          <Image
            source={require('assets/img/screens/private/shoppingTunnel/BasketScreen/basket.png')}
            style={isMobile ? s.emptyBasketImageMobile : s.emptyBasketImage}
            resizeMode={'contain'}
          />
          <Label
            type={'bold'}
            size={isMobile ? 25 : 35}
            style={isMobile ? s.emptyBasketLabel : s.emptyBasketLabelMobile }
          >
            {t('BasketScreen.empty')}
          </Label>
          <Button
            palette={'primary'}
            size={isMobile ? 'S' : 'L'}
            onPress={() => navigation.navigate('Catalog')}
          >
            {t('BasketScreen.goToCatalog')}
          </Button>
        </View>
      </PrivateLayout>
    )
  }

  // Display Basket
  return (
    <>
      <PrivateLayout headerTitle={t('BasketScreen.headerTitle')} headerRight={isMobile && <HeaderRight/>}>

        {/* Header banner in desktop version */}
        {!isMobile && (
          <View style={[s.desktopHeaderContainer, isCustomTablet && {marginBottom: 10}]}>
            <Icon name={'basket'} style={s.desktopBackgroundIcon} size={154}/>

            <View style={s.desktopTitleContainer}>
              <Icon name={'basket'} style={s.desktopTitleIcon} size={60}/>
              <Label type={'bold'} size={28}>{t('BasketScreen.headerTitle')}</Label>
            </View>

            <HeaderRight/>
          </View>
        )}

        <ScrollView style={s.container}>
          <View style={!isMobile && !isCustomTablet && s.twoColumnsLayout}>

            {/* left column */}
            <View style={!isMobile && !isCustomTablet && s.leftColumn}>
              {/* ServiceBasketCard */}
              {basket.services.map((service, index) => (
                <BasketServiceCard
                  key={service.rowId}
                  basketId={basket.id}
                  products={service.products}
                  service={service}
                  hasMarginTop={index === 0}
                  setSelectedOrderProduct={setSelectedOrderProduct}
                  setSelectedProviderTitle={setSelectedProviderTitle}/>
              ))}

              {/* Special offer */}
              <PaymentSpecialOfferSection basket={basket}/>

              {/* Delivery */}
              <PaymentDeliverySection
                collectDate={basket?.deliverySettings?.collectDate}
                deliveryDate={basket?.deliverySettings?.deliveryDate}
                deliveryLockerName={basket?.deliverySettings?.deliveryLocker?.name}
                deliveryPrice={basket?.deliverySettings?.price}
                setIsOpenLockerSelect={setIsOpenLockerSelect}
                setIsOpenCollectDateSelect={setIsOpenCollectDateSelect}
                setIsOpenDeliveryDateSelect={setIsOpenDeliveryDateSelect}
                requiredCollectAndDelivery={basket?.deliverySettings?.requiredCollectAndDelivery}
              />

              {/* Cashback */}
              {user?.cashbackWallet?.activeAmount > 0 && (
                <PaymentCashbackSection
                  cashbackAmount={user?.cashbackWallet?.activeAmount}
                  usableCashback={basket?.discountByCashback}
                  useCashback={basket?.useCashback}
                  payableType={'BASKET'}
                />
              )}
            </View>

            {/* Right Column */}
            <View style={!isMobile && !isCustomTablet && s.rightColumn}>
              {/* Payment */}
              <PaymentSection
                amount={basket.amountAfterReduction}
                creditsBalance={user?.creditsBalance}
                recurringCreditCards={recurringCreditCards}
                setSelectedCard={setSelectedCard}
                setModalStep={setModalStep}
                modalSteps={modalSteps}
                isBasketPayable={isBasketPayable()}
              />

              {!isMobile && (
                <>
                  <View style={s.cardsImageContainer}>
                    <Image
                      source={require('assets/img/screens/private/profile/ProfileWalletScreen/FillWalletModal/NewCreditCardModalContent/logo_cb.png')}
                      style={s.cardsImage}
                    />
                  </View>
                  <View style={s.byAdyenContainer}>
                    <Label size={12}>{t('ProfileWalletScreen.NewCreditCard.securePaymentText')}</Label>
                    <Image
                      source={require('assets/img/screens/private/profile/ProfileWalletScreen/FillWalletModal/NewCreditCardModalContent/adyen_logo.png')}
                      style={s.byAdyenLogo}
                    />
                  </View>
                </>
              )}

            </View>
          </View>
        </ScrollView>
      </PrivateLayout>

      {/* Various modals used in the basket screen */}

      <Modal open={!!modalStep} onClose={() => {
        setModalStep()
        setCount(value => value + 1)
      }}>
        <PayModal
          amount={basket.amountAfterReduction}
          user={user}
          modalStep={modalStep}
          setModalStep={setModalStep}
          modalSteps={modalSteps}
          selectedCard={selectedCard}
          setPaidBasket={setPaidBasket}
          payableType={'BASKET'}
        />
      </Modal>

      {selectedOrderProduct && selectedProviderTitle &&
      <Modal
        open={!!selectedOrderProduct}
        onClose={() => {
          setSelectedOrderProduct(null)
          setSelectedProviderTitle(null)
        }}>
        <CustomerCommentModal
          orderProductId={selectedOrderProduct}
          comment={selectedOrderProduct.customerComment}
          selectedProviderTitle={selectedProviderTitle}
        />
      </Modal>}

      <Modal open={isOpenLockerSelect} onClose={() => setIsOpenLockerSelect(false)}>
        <SelectLockerModal/>
      </Modal>
      <Modal open={isOpenCollectDateSelect} onClose={() => setIsOpenCollectDateSelect(false)}>
        <SelectCollectDateModal/>
      </Modal>
      <Modal open={isOpenDeliveryDateSelect} onClose={() => setIsOpenDeliveryDateSelect(false)}>
        <SelectDeliveryDateModal/>
      </Modal>
      <Modal open={displayCoverModal} onClose={() => setDisplayCoverModal(false)}>
        <NeedCoverModal navigation={navigation}/>
      </Modal>
      <Modal open={isRedirectPaymentSuccessful === false} onClose={() => {
        navigation.setParams({redirect: undefined})
        setIsRedirectPaymentSuccessful(null)
      }}>
        <View style={s.errorModalContent}>
          <Icon name={'close'} color={Colors.orange100} style={{ marginBottom: 15 }}/>
          <Label type={'error'} style={{ textAlign: 'center' }}>{t('PayBasketModal.error')}</Label>
        </View>
      </Modal>
    </>
  )
}

// PropsTypes
type PropsTypes = {
  navigation: StackNavigationProp<RoutesParamList, 'Basket'>
}
