import { Platform, StyleSheet } from 'react-native'
import { Palette, Sizes } from 'src/styles'

// Styles
export default StyleSheet.create({
  // Button
  default: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: Sizes.formElementBorderRadius,
    alignSelf: 'center',
    borderColor: Palette.ppDefaultInputBorder,
    backgroundColor: Palette.dpDefaultBackground
  },
  disabled: {
    backgroundColor: Palette.ppDisableBackground,
    borderColor: Palette.ppDisableBackground,
  },
  primary: {
    backgroundColor: Palette.ppDefaultBackground,
    shadowColor: '#000',
    shadowOffset: {
      width: 0, height: 1
    },
    shadowOpacity: 0.25,
    shadowRadius: 2,
    elevation: 3,
  },
  fullWidth: {
    width: '100%'
  },

  // Label
  textDefault: {
    // height: '100%',
    color: Palette.dpDefaultText
  },
  textPrimary: {
    color: Palette.ppDefaultText
  },
  textDisabled: {
    color: Palette.ppDisableText
  },
  hasIcon: {
    marginLeft: 7
  },

  // Button Sizes
  XL: {
    height: 50,
    paddingLeft: Sizes.formElementPaddingLeftL,
    paddingRight: Sizes.formElementPaddingLeftL,
    borderWidth: 2,
  },
  L: {
    height: 40,
    paddingLeft: Sizes.formElementPaddingLeftL,
    paddingRight: Sizes.formElementPaddingLeftL,
    borderWidth: 2,
    padding: 0
  },
  M: {
    height: 30,
    paddingLeft: Sizes.formElementPaddingLeftL,
    paddingRight: Sizes.formElementPaddingLeftL,
    borderWidth: 1,
  },
  S: {
    height: 20,
    paddingLeft: Sizes.formElementPaddingLeftL,
    paddingRight: Sizes.formElementPaddingLeftL,
    borderWidth: 1,
  },
  XS: {
    height: 14,
    paddingLeft: Sizes.formElementPaddingLeftXS,
    paddingRight: Sizes.formElementPaddingLeftXS,
    borderWidth: 1,
  },

  // Label Sizes
  textXL: {
    fontSize: 18
  },
  textL: {
    fontSize: 16
  },
  textM: {
    fontSize: 12
  },
  textS: {
    fontSize: 11,
    ...Platform.select({
      android: {
        height: '100%',
      }
    })
  },
  textXS: {
    fontSize: 8,
    lineHeight: 9
  }
})