import React, { type Node } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import Button from 'src/components/common/atoms/Button'
import Label from 'src/components/common/atoms/Label'
import { actions } from '../constants/OrderActionsConstant'
import { impossibleCancelReasons } from '../constants/OrderImpossibleCancelReasonConstant'
import s from './OrderModal.style'
import useScreenSizes from 'src/utils/useScreenSizes'

/**
 * OrderCancellationModalContent component
 */
export default function OrderCancellationModalContent ({
  hasAllowedAction,
  handleAction,
  impossibleCancelReason,
  collectDate,
  activtyStartDate
}: PropsTypes): Node {
  /*
   * Init vars
   */
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()

  /*
   * Render component
   */
  return (
    <View style={s.block}>

      <View style={s.blockTitle}>
        <Label size={isMobile ? 12 : 14} type={'bold'}>{t('OrderModal.cancel')}</Label>
      </View>

      {hasAllowedAction(actions.CANCEL_ORDER) ?
        <View style={s.blockButtonsRow}>
          <Button size={isMobile ? 'S' : 'M'} style={s.btn} onPress={() => handleAction(actions.CANCEL_ORDER)}>{t('OrderModal.cancelBtn')}</Button>
        </View>
        :
        <Label size={isMobile ? 10 : 12}>
          {t([
            'OrderModal.impossibleCancelReason.' + impossibleCancelReasons[impossibleCancelReason],
            'OrderModal.impossibleCancelReason.fallback'
          ], {
            collectDate: moment(collectDate).format('DD MMMM'),
            activityStartsIn: 'todo'
          })}
        </Label>
      }
    </View>
  )
}

type PropsTypes = {
  hasAllowedAction: function,
  handleAction: function,
  impossibleCancelReason: string
}