// @flow
/*  App sizes */

// Standard sizes (height, width)
export const XL  = 40
export const L   = 30
export const M   = 20
export const XM  = 16
export const XXM = 14
export const S   = 10
export const XS  = 8
export const XXS = 6

// Flow sizes (DRY)
export type flowSizes = 'XL' | 'L' | 'M' | 'S' | 'XS'

// Standard settings of form elements
export const formElementBorderRadius    = 25
export const formElementPaddingLeftL    = 15
export const formElementPaddingLeftM    = 10
export const formElementPaddingLeftS    = 10
export const formElementPaddingLeftXS   = 7
export const formElementPaddingRightL  = 15
export const formElementPaddingRightM  = 10
export const formElementPaddingRightS  = 10
export const formElementPaddingRightXS = 7

/**
 * Font size style util to avoid issue on iOS
 * @param height
 * @returns {{fontSize, lineHeight: number, paddingTop: number, height}}
 */
export function fontSize (height) {
    return {
        height: height,
        fontSize: height,
        lineHeight: height * 0.75,
        paddingTop: height - (height * 0.75)
    }
}