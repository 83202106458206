import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  title: {
    marginBottom:36
  },
  loader: {
    marginBottom:36
  },
  icon: {
    margin: 'auto',
    marginBottom: 36,
    alignSelf: 'center'
  },
  buttonContainer: {
    marginLeft: 15,
    marginRight: 15
  },
  selectInput: {
    marginTop:30,
    marginBottom:30
  },
  selectSubmit: {
    marginBottom: 25,
    minWidth: 123
  }
})
