import React, { type Node, useState, useEffect } from 'react'
import { Image, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import LockerSecretCodeContent from './LockerSecretCodeContent'
import LockerQRCodeContent from './LockerQRCodeContent'
import s from '../CoverDeliveryModal.style'
import { useMutation } from 'relay-hooks'
import useScreenSizes from 'src/utils/useScreenSizes'

/**
 * LockerContent component
 */
export default function LockerContent ({
  orderId,
  onRetrievedOrder,
  onClose,
  lockerName
}: PropsTypes): Node {
  /*
   * Init vars
   */
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const [error, setError] = useState()
  const [secretCode, setSecretCode] = useState()
  const [qrCode, setQrCode] = useState()

  /*
   * Mutation to generate codes and instantly self execute
   */
  const [getCodesMutation, { loading }] = useMutation(graphql`
    mutation LockerContentMutation($orderId: Int!) {
      GetOrderOpeningCode(orderId: $orderId) {
        code
        QRCode
      }
    }
  `, {
    onError: () => setError(true)
  })

  /*
   * Handle choices
   */
  const handleQRCode = () => {
    getCodesMutation({variables: { orderId }}).then(({ GetOrderOpeningCode }) => {
      setQrCode(GetOrderOpeningCode.QRCode)
    })
  }

  const handleSecretCode = () => {
    getCodesMutation({variables: { orderId }}).then(({ GetOrderOpeningCode }) => {
      setSecretCode(GetOrderOpeningCode.code)
    })
  }

  /*
   * Render component
   */
  return (
    <View>
      {!(secretCode || qrCode) && <>
        <Label size={isMobile ? 10 : 12}>{t('CoverDeliveryModal.LockerContent.description', { lockerName })}</Label>
        <Image
          style={s.contentImage}
          resizeMode={'contain'}
          source={require('assets/img/screens/private/shoppingTunnel/CoverDepositModal/eqqi_cover_deposit_locker.png')}
        />

        {error && <Label type={'error'} size={isMobile ? 10 : 12}>{t('CoverDeliveryModal.error')}</Label>}

        <View style={s.buttonsContainer}>
          <Button onPress={handleSecretCode} palette={'primary'} size={isMobile ? 'S' : 'M'} isLoading={loading} fullWidth>
            {t('CoverDeliveryModal.LockerContent.retrieveBySecretCode')}
          </Button>
          <Button onPress={handleQRCode} palette={'primary'} style={s.middleButton} size={isMobile ? 'S' : 'M'} isLoading={loading} fullWidth>
            {t('CoverDeliveryModal.LockerContent.retrieveByQRCode')}
          </Button>
          <Button onPress={onClose} style={s.lastButton} size={isMobile ? 'S' : 'M'} fullWidth>
            {t('CoverDeliveryModal.cancelRecovery')}
          </Button>
        </View>
      </>}

      {secretCode &&
        <LockerSecretCodeContent
          onRetrievedOrder={onRetrievedOrder}
          onClose={onClose}
          secretCode={secretCode}
          handleQRCode={handleQRCode}
        />}

      {qrCode &&
        <LockerQRCodeContent
          onRetrievedOrder={onRetrievedOrder}
          onClose={onClose}
          qrCode={qrCode}
          handleSecretCode={handleSecretCode}
        />}
    </View>
  )
}

type PropsTypes = {
  orderId: number,
  onRetrievedOrder: function,
  onClose: function,
  lockerName: string
}