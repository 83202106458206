import { Platform, StyleSheet } from 'react-native'

// Styles
export default StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    zIndex: Platform.OS === 'web' ? undefined : 1,
    padding: 20,
    maxWidth: 570
  },
  headerContainerDesktop: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30
  },
  headerTitleDesktop: {
    marginLeft: 20
  },
  btnContainer: {
    paddingLeft: 40,
    paddingRight: 40,
    marginTop: 30
  },
  btnContainerDesktop: {
    marginTop: 60
  },
  amount: {
    marginTop: 30
  }
})