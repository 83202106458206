// @flow
import React, { type Node, useState } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'relay-hooks'
import { type StackNavigationProp } from '@react-navigation/stack'
import useScreenSizes from 'src/utils/useScreenSizes'
import type { RoutesParamList } from 'src/components/Navigation'
import PrivateLayoutProfile from 'src/components/common/layout/PrivateLayoutProfile'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import Icon from 'src/components/common/atoms/Icon'
import Loader from 'src/components/common/atoms/Loader'
import Modal from 'src/components/common/atoms/Modal'
import { Colors } from 'src/styles'
import s from './ProfileDeleteMyAccountScreen.style'
import moment from 'moment'

/**
 * ProfileDeleteMyAccountScreen screen.
 */
export default function ProfileDeleteMyAccountScreen({
  navigation
}: PropsTypes) : Node {

  // Init state
  const { t } = useTranslation()
  const { isMobile, isDesktop, isTablet } = useScreenSizes()
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  // API Call
  const { data } = useQuery(graphql`
    query ProfileDeleteMyAccountScreenQuery {
      user {
        id
        toDeleteAt
      }
    }
  `)
  const userData = data?.user

  // DeleteMyAccount mutation API call
  const [deleteMyAccountMutation, { loadingDelete }] = useMutation(
    graphql`
      mutation ProfileDeleteMyAccountScreenDeleteMutation {
        DeleteMyAccount {
          id
          toDeleteAt
        }
      }
    `,
    {
      onCompleted: () => setOpenConfirmModal(false)
    }
  )

  // CancelMyAccount mutation API call
  const [cancelDeleteMyAccountMutation, { loadingCancel }] = useMutation(
    graphql`
      mutation ProfileDeleteMyAccountScreenCancelMutation {
        CancelDeleteMyAccount {
          id
          toDeleteAt
        }
      }
    `
  )

  // Render screen
  return (
    <PrivateLayoutProfile
      navigation={navigation}
      subHeaderIconName={'basket'}
      subHeaderTitle={t('MenuDrawerContent.links.deleteMyAccount')}
    >
      <View style={[s.mainContainer, isDesktop && s.mainContainerDesktop]}>

        {/* Desktop title */}
        {!isMobile && (
          <View style={s.desktopTitle}>
            <Icon name={'lock'} size={isTablet ? 24 : 40} color={Colors.mediumGrey100}/>
            <Label size={isTablet ? 22 : 38} style={{ marginLeft: 20 }}>{t('ProfileDeleteMyAccountScreen.title')}</Label>
          </View>
        )}

        {!userData && (
          <Loader />
        )}

        {/* Confirm delete */}
        {userData && !userData.toDeleteAt && (
          <Button
            style={[s.submitButton, !isMobile && s.submitButtonDesktop]}
            size={!isDesktop ? 'M' : 'L'}
            onPress={() => setOpenConfirmModal(true)}
            palette={'primary'}
            isLoading={loadingDelete}
          >
            {t('ProfileDeleteMyAccountScreen.labels.submitDelete')}
          </Button>
        )}

        {/* Delete in progress */}
        {userData && userData.toDeleteAt && (
          <>
            <Label size={isMobile ? 10 : isTablet ? 14 : 22}>
              {t('ProfileDeleteMyAccountScreen.labels.deleteInProgress')} {moment(userData.toDeleteAt).format('DD/MM/YYYY')}
            </Label>
            <Button
              style={[s.submitButton]}
              size={!isDesktop ? 'M' : 'L'}
              onPress={() => cancelDeleteMyAccountMutation({ variables: {} })}
              palette={'primary'}
              isLoading={loadingCancel}
            >
              {t('ProfileDeleteMyAccountScreen.labels.submitCancel')}
            </Button>
            </>
        )}

        {/* Modals */}
        <Modal open={openConfirmModal}>
          <View>
            <Label style={s.modalTitle}>{t('ProfileDeleteMyAccountScreen.confirmModal.title')}</Label>
            <Button
              style={s.modalButton}
              size={'M'}
              palette={'primary'}
              onPress={() => deleteMyAccountMutation({ variables: {} })}
              isLoading={loadingDelete}
            >
              {t('ProfileDeleteMyAccountScreen.confirmModal.confirm')}
            </Button>
            <Button
              style={s.modalButton}
              size={'M'}
              onPress={() => setOpenConfirmModal(false)}
            >
              {t('App.cancel')}
            </Button>
          </View>
        </Modal>

      </View>
    </PrivateLayoutProfile>
  )
}

// PropsTypes
type PropsTypes = {
  navigation: StackNavigationProp<RoutesParamList, 'Security'>
}
