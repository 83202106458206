// @flow
import React, { type Node, useRef, useState, useEffect, useCallback } from 'react'
import { View, FlatList } from 'react-native'
import { type StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from 'relay-hooks'
import type { RoutesParamList } from 'src/components/Navigation'
import SkeletonContent from 'react-native-skeleton-content-nonexpo'
import useScreenSizes from 'src/utils/useScreenSizes'
import PrivateLayout from 'src/components/common/layout/PrivateLayout'
import CatalogTabs from 'src/components/common/moles/CatalogTabs'
import ServiceDetailCard from 'src/components/common/moles/ServiceDetailCard'
import ProductCard from 'src/components/common/moles/ProductCard'
import ProductModal from 'src/components/common/moles/ProductModal'
import ServiceDetailSection from 'src/components/common/moles/ServiceDetailSection'
import Modal from 'src/components/common/atoms/Modal'
import s, { productsSkeleton, productsSkeletonDesktop } from './ServiceScreen.style'
import { Colors } from 'src/styles'
import useFullWidth from 'src/utils/useFullWidth'

/**
 * ServiceScreen screen.
 */
export default function ServiceScreen({
  navigation,
  route
}: PropsTypes) : Node {

  // Prevent error on navigation go back
  if (!route.params) {
    return null
  }

  // Init state
  const [viewableItem, setViewableItem] = useState(null)
  const refProductsList = useRef(null)
  const refCatalogTabs = useRef(null)
  const [currentTab, setCurrentTab] = useState(route.params.serviceId)
  const [selectedProductId, setSelectedProductId] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const fullWidth = useFullWidth()
  const { isMobile, isTablet, width } = useScreenSizes()
  const [numColumns, setNumColumns] = useState(2)

  const cardWidth = isMobile
    ? (fullWidth - 88) / numColumns
    : isTablet
      ? ((fullWidth - 200 - 80) / numColumns) - 26
      : (fullWidth - 320 - 80) / numColumns - 26

  useEffect(() => {
    if (width >= 1600) {
      setNumColumns(5)
    } else if (width >= 1024) {
      setNumColumns(4)
    } else if (width >= 768) {
      setNumColumns(3)
    } else {
      setNumColumns(2)
    }
  }, [width])

  // Opens the modal if the user come from another page with product id specified
  useEffect(() => {
    if (route.params?.productId) {
      setSelectedProductId(route.params?.productId)
      setIsOpen(true)
    }
  }, [route.params?.productId])

  // API Query
  const { data } = useQuery(graphql`
    query ServiceScreenQuery($familyId: Int!) {
      services(familyId: $familyId) {
        id
        rowId
        name
        color
        isUserFavorite
        description
        logoURL
        pictureURL
        products {
          id
          rowId
          name
          type
          catalogIcon {
            url
          }
          price
          discountPerProduct {
            discountPercentage
            discountPrice
          }
          isCashbackActive
          cashbackPercentage
        }
        catalogIcon {
          url
        }
      }
      families(familyId: $familyId) {
        name
      }
    }
  `, {familyId: route.params?.familyId})
  const services = data?.services.filter(service => service.products.length > 0)
  const selectedService = services?.find(service => service.rowId.toString() === route.params.serviceId.toString())
  const currentFamilyName = data?.families[0].name

  /*
   * Update web title
   */
  useEffect(() => {
    if (!selectedService) { return }
    if (isMobile) { return }

    navigation.setOptions({ title: 'Quatre Epingles - ' + selectedService.name })
  }, [selectedService])

  /*
   * Scroll to matching services with route.params.serviceId on first render
   */
  useEffect(() => {
    if (!isMobile) { return }

    // Quit early if no services
    if (!services || services.length === 0) { return }

    // Set current tab to corresponding service base on route param
    if (currentTab.toString() !== route.params.serviceId.toString()) {
        setCurrentTab(route.params.serviceId)
    }

    // Scroll to proper service tab
    const activeIndex = services.findIndex(service => service.rowId.toString() === route.params.serviceId.toString())

    if (activeIndex === -1) {
      console.error('Service not found in list : ' + route.params.serviceId.toString())
      return
    }

    refProductsList.current.scrollToIndex({
      index: activeIndex,
      viewPosition: 0
    })
  }, [route.params.serviceId, data])

  /*
   * Handle scroll & update catalog tab according to viewableItem
   */
  useEffect(() => {
    if (!isMobile) { return }

    // Quit early if no viewable item or services
    if (!viewableItem || !services) { return }

    // Get current tab index base on viewable item
    const shouldBeActiveTab = viewableItem.item.rowId
    const activeIndex = services.findIndex(service => service.rowId === shouldBeActiveTab)

    // Scroll to proper service tab
    if (currentTab !== shouldBeActiveTab.toString()) {
      setCurrentTab(shouldBeActiveTab)
      refCatalogTabs.current.scrollToIndex({
        index: activeIndex,
        viewPosition: 0.5
      })
    }

  }, [viewableItem])

  /*
   * Handle scroll and prepare data to update catalog tab (goes with previous useEffect)
   */
  const handleScrollProductsList = useCallback((items) => {
    const middleItem = items.viewableItems.slice(Math.trunc(items.viewableItems.length / 2))[0]
    setViewableItem(middleItem)
  }, [])

  /*
   * Main FlatList getItemLayout prop : mandatory for scrollToIndex
   */
  const _getItemLayout = (data, index) => {
    const serviceDetailCardHeight = isMobile ? 88 : 234
    const productCardHeight = isMobile ? 204 : 324

    let itemOffset = 0

    for (let i = 0; i < index; i++) {
      const nbProducts = data[i].products.length
      const tmpOffset = (((productCardHeight * nbProducts) / numColumns) + serviceDetailCardHeight)
      itemOffset += tmpOffset
    }

    // Return info to FlatList in order to help him positionate the window for automatic scrolling
    return {
      index,
      length: productCardHeight + serviceDetailCardHeight,
      offset: itemOffset
    }
  }

  /*
   * Handle pressed tab
   */
  const handlePressedTab = (tab, index) => {
    refProductsList.current.scrollToIndex({
      index: index,
      viewPosition: 0
    })
  }

  /*
   * Handle pressed product button (Buy)
   */
  const handlePressedProduct = useCallback(product => {
    setSelectedProductId(product.rowId)
    setIsOpen(true)
  })

  /*
  * Render product card in nested FlatList (see next render)
  * (Avoid anonymous function in FlatList-renderItem)
  */
  const renderProduct = ({item: product}) => (
    <View style={!isMobile && { marginRight: 14, marginBottom: 14 }}>
      <ProductCard
        title={product.name}
        color={Colors[product.color]}
        iconURL={product.catalogIcon?.url || selectedService.catalogIcon?.url} /* TODO require default icon*/
        price={product.price}
        margin={isMobile ? 10 : 4}
        hasPadding={true}
        onPress={() => handlePressedProduct(product)}
        width={cardWidth}
        type={product.type}
        discountPercentage={product.discountPerProduct?.discountPercentage}
        discountPrice={product.discountPerProduct?.discountPrice}
      />
    </View>
  )

  /*
   * Render products list in main FlatList
   * (Avoid anonymous function in FlatList-renderItem)
   */
  const renderProductsList = ({item: service}) => {
    if (service.products.length === 0) { return null }

    return <FlatList
      data={service.products}
      numColumns={numColumns}
      key={numColumns}
      keyExtractor={service => service.id}
      maxToRenderPerBatch={20}
      initialNumToRender={100}
      ListHeaderComponent={isMobile
        ? <ServiceDetailCard service={service} />
        : <ServiceDetailSection service={service} />
      }
      renderItem={renderProduct}
    />
  }

  /*
   * Render component
   */
  return (
    <>
      <PrivateLayout
        headerTitle={
          isMobile && <CatalogTabs
            tabs={services}
            activeTabId={currentTab}
            onPress={handlePressedTab}
            ref={refCatalogTabs}
          />
        }
        headerBackOnPress={() => navigation.navigate('Catalog')}
        headerBackTitle={currentFamilyName}
      >
        <View style={s.container}>

          {/* Services list with products */}
          <SkeletonContent
            containerStyle={!services
              ? isMobile
                ? s.skeletonContainer
                : s.skeletonContainerDesktop
              : s.subContainer
            }
            isLoading={!data}
            layout={isMobile ? productsSkeleton : productsSkeletonDesktop}
          >
            {services && <FlatList
              ref={refProductsList}
              contentContainerStyle={[s.listContentContainer, !isMobile && s.listContentContainerDesktop]}
              style={s.list}
              onScrollToIndexFailed={error => console.error(error)}
              onViewableItemsChanged={handleScrollProductsList}
              getItemLayout={_getItemLayout}
              removeClippedSubviews={true}
              updatedCellsBatchingPeriod={100}
              initalNumToRender={20}
              data={isMobile ? services : selectedService ? [selectedService] : []}
              keyExtractor={item => item.id}
              renderItem={renderProductsList}
            />}
          </SkeletonContent>

        </View>
      </PrivateLayout>

      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <ProductModal productId={selectedProductId} navigation={navigation} />
      </Modal>
    </>
  )
}

// PropsTypes
type PropsTypes = {
  navigation: StackNavigationProp<RoutesParamList, 'Service'>,
  route: any
}
