// @flow
import React, { type Node } from 'react'
import { View, Image, Pressable } from 'react-native'
import { useTranslation } from 'react-i18next'
import useScreenSizes from 'src/utils/useScreenSizes'
import Button from 'src/components/common/atoms/Button'
import Label from 'src/components/common/atoms/Label'
import Tag from 'src/components/common/atoms/Tag'
import Icon from 'src/components/common/atoms/Icon'
import ViewShadow from 'src/components/common/atoms/ViewShadow'
import { formatPrice } from 'src/utils/FormatDataUtils'
import { Colors } from 'src/styles'
import s from './ProductCard.style'

/**
 * ProductCard mole.
 */
const ProductCard = ({
  style,
  styles,
  iconURL,
  title,
  price,
  color,
  margin,
  onPress,
  onPressGlobal,
  discountPercentage = null,
  discountPrice = null,
  withCashback = false,
  buttonLabel,
  width,
  type = "standard"
}: PropsTypes): Node => {

  // Init state
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const cardWidth = width ? width : isMobile ? 130 : 280
  const cardHeight = isMobile ? 180 : 300

  // Render mole
  return (
    <ViewShadow
      style={[
        style,
        styles?.container,
        s.container,
        !isMobile && s.containerDesktop,
        { width: cardWidth, height: cardHeight }
      ]}
      shadowOpacity={0.8}
      shadowOffset={{ width: 0, height: 2 }}
      withColor={{ color: color, width: cardWidth, height: cardHeight, radius: 0 }}
      margin={{ top: margin, right: margin, bottom: margin, left: margin }}
      hasPadding={true}
      onPress={onPressGlobal}
    >
      <Pressable style={s.cardContainer} onPress={onPress}>

        {/* Title */}
        <Label style={s.title} size={isMobile ? 13 : 16}>{title}</Label>

        {/* Icon */}
        <View style={s.iconContainer}>
          <Image
            source={iconURL && { uri: iconURL } }
            style={s.icon}
          />
        </View>

        {/* Price */}
        {type !== "content" ?
            <View style={s.price}>
              {discountPercentage && discountPrice ?
                  <>
                    <Tag
                        style={[s.cashbackTag, styles?.cashbackTag]}
                        Icon={<Icon name={'cashback'} color={Colors.red100} size={isMobile ? 12 : 14}/>}
                        styles={{label: {color: Colors.red100, fontSize: isMobile ? 12 : 14}}}>
                      {t('ProductModal.sale', {sale: discountPercentage})}
                    </Tag>
                    <View style={[s.discountPriceContainer, styles?.discountPriceContainer]}>
                      <Label size={!isMobile ? 16 : 14}
                             style={[s.discountOriginalPrice, styles?.discountOriginalPrice]}>
                        {formatPrice(price)}
                      </Label>
                      <Label size={!isMobile ? 16 : 14} style={[s.discountPrice, styles?.discountPrice]} type={'bold'}>
                        {formatPrice(discountPrice)}
                      </Label>
                    </View>
                  </>
                  : withCashback ?
                      <>
                        <Tag
                            style={[s.cashbackTag, styles?.cashbackTag]}
                            Icon={<Icon name={'cashback'} color={Colors.red100} size={isMobile ? 12 : 14}/>}
                            styles={{label: {color: Colors.red100, fontSize: isMobile ? 12 : 14}}}>
                          {'extra-cashback'}
                        </Tag>
                        <Label size={!isMobile ? 16 : 14}>{formatPrice(price)}</Label>
                      </>
                      :
                      <Label size={!isMobile ? 16 : 14}>{formatPrice(price)}</Label>
              }
            </View>
            :null
        }
        {/* Add to Cart button */}
          <View style={s.buttonContainer}>
            <Button
              style={s.button}
              palette={'primary'}
              fontStyle={'bold'}
              onPress={onPress}
              size={isMobile ? 'S' : 'M'}
            >
              {buttonLabel ? buttonLabel : type==="content" ? t('App.display'):t('App.buy')}
            </Button>
          </View>

      </Pressable>
    </ViewShadow>
  )
}

const areEqual = (prev, next) => {
  return prev.iconURL === next.iconURL &&
    prev.title === next.title &&
    prev.price === next.price &&
    prev.discount === next.discount &&
    prev.withCashback === next.withCashback &&
    prev.width === next.width
}

export default React.memo(ProductCard, areEqual)

// PropsTypes
type PropsTypes = {
  style?: any,
  styles?: any,
  iconURL: string,
  title: string,
  price: number,
  color: string,
  margin?: number,
  onPress: any,
  onPressGlobal?: function,
  discountPercentage?: number,
  discountPrice?: number,
  withCashback?: boolean,
  buttonLabel?: string,
  width?: number
}
