import { StyleSheet, Platform } from 'react-native'
import { widthPercentageToDP as wp , heightPercentageToDP as hp } from 'react-native-responsive-screen'
import { Colors } from 'src/styles'

// Styles
export default StyleSheet.create({
  mainContainer: {
    width: wp('100%'),
    maxWidth: 500,
    alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
  },
  mainContainerDesktop: {
    maxWidth: '100%',
    width: '50%'
  },
  mainContainerTablet: {
    maxWidth: '100%',
    width: '80%'
  },
  formContainer: {
    width: '100%',
    justifyContent: 'center',
    marginTop: 15
  },
  formContainerDesktop: {
    marginTop: 30
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    width: '100%',
    position: 'absolute',
    bottom: 20,
  },
  buttonContainerDesktop: {
    bottom: 60,
    width: 'unset'
  },
  textInput: {
    width: '100%',
    marginTop: 15,
  },
  textInputDesktop: {
    marginTop: 30,
  },
  forgotPasswordButton: {
    borderWidth: 0,
    backgroundColor: 'transparent'
  },
  forgotPasswordLabel: {
    color: Colors.darkGrey100,
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textDecorationColor: Colors.darkGrey100
  },
  forgotPasswordLabelDesktop: {
    fontFamily: 'Raleway',
    fontSize: 22
  },
  submitButton: {
    marginTop: 30,
    width: '50%',
  },
  error: {
    marginTop: 5,
    marginLeft: 30,
    marginBottom: -9,
    color: Colors.red100
  },
  errorDesktop: {
    marginTop: 10,
    marginLeft: 65,
    marginBottom: -10
  },
  generalError: {
    textAlign: 'center',
    marginTop: 20,
    marginBottom: -10,
    marginLeft: 0
  },
})
