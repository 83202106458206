/**
 * @generated SignedSource<<1254aa801a08c743ac1fda8b17241194>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logoURL",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Service",
  "kind": "LinkedField",
  "name": "service",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "familyId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CatalogIcon",
      "kind": "LinkedField",
      "name": "catalogIcon",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/)
  ],
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GoodDeal",
    "kind": "LinkedField",
    "name": "goodDeals",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "goodDealType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startDateTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endDateTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AffiliateLink",
        "kind": "LinkedField",
        "name": "affiliateLinks",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          },
          (v0/*: any*/),
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "targetLink",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GoodDealCashbackService",
        "kind": "LinkedField",
        "name": "cashbackServices",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "cashbackPercentage",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GoodDealDiscountService",
        "kind": "LinkedField",
        "name": "discountServices",
        "plural": true,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discountPercentage",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GoodDealScreenQuery",
    "selections": (v3/*: any*/),
    "type": "ClientAppQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GoodDealScreenQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "e423fa070963cf2f28e94c93c9d2535f",
    "id": null,
    "metadata": {},
    "name": "GoodDealScreenQuery",
    "operationKind": "query",
    "text": "query GoodDealScreenQuery {\n  goodDeals {\n    name\n    goodDealType\n    startDateTime\n    endDateTime\n    affiliateLinks {\n      token\n      name\n      logoURL\n      targetLink\n    }\n    cashbackServices {\n      service {\n        id\n        rowId\n        name\n        familyId\n        catalogIcon {\n          url\n        }\n        logoURL\n      }\n      cashbackPercentage\n    }\n    discountServices {\n      service {\n        id\n        rowId\n        name\n        familyId\n        catalogIcon {\n          url\n        }\n        logoURL\n      }\n      discountPercentage\n    }\n  }\n}\n"
  }
};
})();

node.hash = "0cd02f1b088c2c8cec0f2336989315e1";

module.exports = node;
