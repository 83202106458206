/**
 * @generated SignedSource<<ebc8c22b6234b9ddc86a769e14e09743>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "comment"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "orderProductId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "comment",
        "variableName": "comment"
      },
      {
        "kind": "Variable",
        "name": "orderProductId",
        "variableName": "orderProductId"
      }
    ],
    "concreteType": "BasketProduct",
    "kind": "LinkedField",
    "name": "SetBasketProductCustomerComment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rowId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customerComment",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerCommentModalMutation",
    "selections": (v2/*: any*/),
    "type": "ClientAppMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CustomerCommentModalMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "3fb1368ee89e90ef8c89a32c477c0ba5",
    "id": null,
    "metadata": {},
    "name": "CustomerCommentModalMutation",
    "operationKind": "mutation",
    "text": "mutation CustomerCommentModalMutation(\n  $orderProductId: Int!\n  $comment: String\n) {\n  SetBasketProductCustomerComment(orderProductId: $orderProductId, comment: $comment) {\n    id\n    rowId\n    customerComment\n  }\n}\n"
  }
};
})();

node.hash = "6aa56242b2a3beb30f8a3112ab36afab";

module.exports = node;
