import React, { type Node } from 'react'
import { Image, View } from 'react-native'
import { Trans, useTranslation } from 'react-i18next'
import useScreenSizes from 'src/utils/useScreenSizes'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import s from '../CoverCollectModal.style'
import { orange100Less80, orange50 } from 'src/styles/colors'

/**
 * DepositReadyClosetContent component
 */
export default function DepositReadyClosetContent ({
  handleDeposit,
  onClose,
  openingCode
}: PropsTypes): Node {
  /*
   * Init vars
   */
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()

  /*
   * Render component
   */
  return (
    <View>
      <Label size={isMobile ? 10 : 12}>
        {t('CoverDepositModal.DepositReadyModalContent.DepositReadyClosetContent.description')}
      </Label>
      <Label size={isMobile ? 10 : 12} style={{ marginTop: 10 }}>
        <Trans
          i18nKey={'CoverDepositModal.DepositReadyModalContent.mandatoryDeposit'}
          components={{ b: <Label type={'bold'} size={isMobile ? 10 : 12}/> }}
        />
      </Label>
      <View style={{marginTop: 10, flexDirection: 'row', alignItems: 'center'}}>
        <Label size={isMobile ? 10 : 12} type={'bold'}>
          {t('CoverDepositModal.DepositReadyModalContent.DepositReadyClosetContent.secretCode')}
        </Label>
        <Label size={isMobile ? 12 : 16} type={'bold'} style={{background: orange100Less80, padding: 8, letterSpacing: 1}}>{openingCode}</Label>
      </View>
      <Image
        style={[s.contentImage, !isMobile && s.contentImageDesktop]}
        resizeMode={'contain'}
        source={require('assets/img/screens/private/shoppingTunnel/CoverDepositModal/animation_serrure_code.gif')}
      />
      <View style={s.buttonsContainer}>
        <Button onPress={handleDeposit} palette={'primary'} size={isMobile ? 'S' : 'M'}
                fullWidth>{t('CoverDepositModal.DepositReadyModalContent.DepositReadyClosetContent.doneBtn')}</Button>
        <Button onPress={onClose} style={s.lastButton} size={isMobile ? 'S' : 'M'}
                fullWidth>{t('CoverDepositModal.cancelDeposit')}</Button>
      </View>
    </View>
  )
}

type PropsTypes = {
  handleDeposit: function,
  onClose: function,
  openingCode: string
}