// @flow
import React, {type Node, useEffect, useState} from 'react'
import {getPathFromState, getStateFromPath} from '@react-navigation/core'
import {NavigationContainer, useNavigation} from '@react-navigation/native'
import {createStackNavigator} from '@react-navigation/stack'
import {createDrawerNavigator} from '@react-navigation/drawer'
import {SafeAreaProvider} from 'react-native-safe-area-context'
import {useTranslation} from 'react-i18next'
import {useInitialRender} from 'src/utils/ComponentUtils'
import {useStoreState} from 'pullstate'
import useScreenSizes from 'src/utils/useScreenSizes'
import UserStore from 'src/store/UserStore'
import StorybookUI from 'src/components/screens/dev/Storybook/Storybook'
import MenuDrawerContent from 'src/components/common/layout/MenuDrawerContent'
import HomeScreen from 'src/components/screens/private/HomeScreen'
import LoginScreen from 'src/components/screens/public/LoginScreen'
import RegisterScreen from 'src/components/screens/public/RegisterScreen'
import ForgotPasswordScreen from 'src/components/screens/public/ForgotPasswordScreen'
import SearchScreen from 'src/components/screens/private/SearchScreen'
import ProfileMenuScreen from 'src/components/screens/private/profile/ProfileMenuScreen'
import BasketScreen from 'src/components/screens/private/shoppingTunnel/BasketScreen'
import CatalogScreen from 'src/components/screens/private/shop/CatalogScreen'
import ServiceScreen from 'src/components/screens/private/shop/ServiceScreen'
import GoodDealScreen from 'src/components/screens/private/shop/GoodDealScreen'
import NewsScreen from 'src/components/screens/private/NewsScreen'
import PracticalInformationsScreen from 'src/components/screens/private/PracticalInformationsScreen'
import ProfileAgendaScreen from 'src/components/screens/private/profile/ProfileAgendaScreen'
import ProfileCashbackScreen from 'src/components/screens/private/profile/ProfileCashbackScreen'
import ProfileOrdersScreen from 'src/components/screens/private/profile/ProfileOrdersScreen'
import ProfilePersonalInfoScreen from 'src/components/screens/private/profile/ProfilePersonalInfoScreen'
import ProfileSecurityScreen from 'src/components/screens/private/profile/ProfileSecurityScreen'
import ProfileWalletScreen from 'src/components/screens/private/profile/ProfileWalletScreen'
import ProfileSponsorCodeScreen from 'src/components/screens/private/profile/ProfileSponsorCodeScreen'
import UpdatePasswordScreen from 'src/components/screens/public/UpdatePasswordScreen'
import QornerChoiceScreen from 'src/components/screens/private/QornerChoiceScreen'
import NotFoundScreen from 'src/components/screens/public/NotFoundScreen'
import BookScreen from 'src/components/screens/private/shop/BookScreen'
import ThankYouScreen from 'src/components/screens/private/shoppingTunnel/ThankYouScreen'
import PaymentOrderScreen from 'src/components/screens/private/shoppingTunnel/PaymentOrderScreen/PaymentOrderScreen'
import {Colors} from 'src/styles'
import ProfileDeleteMyAccountScreen from 'src/components/screens/private/profile/ProfileDeleteMyAccountScreen'
import {useMutation} from 'relay-hooks'

/**
 * Navigation.
 */
export default function Navigation(): Node {
    // Init state
    const {t} = useTranslation()
    const {currentUserHaveQorner, isAuthenticated} = useStoreState(UserStore, s => ({
        currentUserHaveQorner: s.currentUserHaveQorner,
        isAuthenticated: s.jwtToken !== null,
    }));

    const initialRouteName = !isAuthenticated ? 'Public' : currentUserHaveQorner ? 'Private' : 'QornerChoice';

    // Alterate path handling system
    const getLinkingPathOptions = () => {
        let currentUrl = ''
        return {
            getStateFromPath: (path, config) => {
                currentUrl = path
                return getStateFromPath(currentUrl, config)
            },
            // Change behaviour for 404 page not found
            getPathFromState: (state, config) => {
                const path = getPathFromState(state, config)
                return path === '/NotFound' ? currentUrl : path
            }
        }
    }

    // Routing tables for web behaviour
    // Routes are translated base on language
    const linking = {
        prefixes: ['eqqi://'],
        config: {
            screens: {
                Public: {
                    screens: {
                        Login: t('Navigation.linking.Login'),
                        Register: t('Navigation.linking.Register'),
                        ForgotPassword: t('Navigation.linking.ForgotPassword'),
                        UpdatePassword: t('Navigation.linking.UpdatePassword'),
                    }
                },
                Private: {
                    screens: {
                        Home: t('Navigation.linking.Home'),
                        Search: t('Navigation.linking.Search'),
                        Basket: t('Navigation.linking.Basket'),
                        Profile: {
                            path: t('Navigation.linking.Profile'),
                            screens: {
                                ProfileMenu: t('Navigation.linking.ProfileMenu'),
                                Agenda: t('Navigation.linking.Agenda'),
                                Cashback: t('Navigation.linking.Cashback'),
                                Estimates: t('Navigation.linking.Estimates'),
                                Orders: t('Navigation.linking.Orders'),
                                // PaymentCards: t('Navigation.linking.PaymentCards'),
                                PersonalInformations: t('Navigation.linking.PersonalInformations'),
                                Security: t('Navigation.linking.Security'),
                                Wallet: t('Navigation.linking.Wallet'),
                                SponsorCode: t('Navigation.linking.SponsorCode'),
                                DeleteMyAccount: t('Navigation.linking.DeleteMyAccount')
                            }
                        },
                        Catalog: t('Navigation.linking.Catalog'),
                        Service: t('Navigation.linking.Service'),
                        Product: t('Navigation.linking.Product'),
                        GoodDeal: t('Navigation.linking.GoodDeal'),
                        News: t('Navigation.linking.News'),
                        PracticalInformations: t('Navigation.linking.PracticalInformations'),
                        ThankYou: t('Navigation.linking.ThankYou'),
                        Book: t('Navigation.linking.Book'),
                        PaymentOrder: t('Navigation.linking.PaymentOrder')
                    }
                },
                QornerChoice: t('Navigation.linking.QornerChoice'),
                NotFound: '*',
            },
        },
        ...getLinkingPathOptions()
    }

    // Navigation root
    const Stack = createStackNavigator<RoutesParamList, any, any>()
    return (
        <SafeAreaProvider>
            <NavigationContainer linking={linking}>
                <Stack.Navigator
                    initialRouteName={initialRouteName}
                    screenOptions={{ headerShown: false }}
                >
                    {!isAuthenticated && <Stack.Screen name={'Public'} component={PublicNavigationRoot}/>}
                    {isAuthenticated && currentUserHaveQorner &&
                        <Stack.Screen name={'Private'} component={PrivateNavigationRoot}/>}
                    {isAuthenticated && <Stack.Screen name={'QornerChoice'} component={QornerChoiceScreen}/>}
                    {StorybookUI && <Stack.Screen name={'Storybook'} component={StorybookUI}/>}
                    <Stack.Screen name={'NotFound'} component={NotFoundScreen}/>
                </Stack.Navigator>
            </NavigationContainer>
        </SafeAreaProvider>
    )
}

/**
 * PublicNavigationRoot.
 */
function PublicNavigationRoot(): Node {
    const {t} = useTranslation()
    const Stack = createStackNavigator<RoutesParamList, any, any>()
    return (
        <Stack.Navigator
            initialRouteName={'Login'}
            screenOptions={{ headerShown: false }}
        >
            <Stack.Screen name={'Login'} component={LoginScreen} options={{title: t('Navigation.naming.Login')}}/>
            <Stack.Screen name={'Register'} component={RegisterScreen}/>
            <Stack.Screen name={'ForgotPassword'} component={ForgotPasswordScreen}/>
            <Stack.Screen name={'UpdatePassword'} component={UpdatePasswordScreen}/>
        </Stack.Navigator>
    )
}

/**
 * PrivateNavigationRoot.
 */
function PrivateNavigationRoot(): Node {
    // Init state
    const {t} = useTranslation()
    const Drawer = createDrawerNavigator<RoutesParamList, any, any>()
    const isInitialRender = useInitialRender()
    const {width, isMobile, isTablet} = useScreenSizes()
    const options = ({route}) => ({title: 'Quatre Epingles - ' + t('Navigation.naming.' + route.name)})
    const navigation = useNavigation()

    // -----------------------------------------------------------------------------------------------------------------
    // CHECH CODE PS
    // -----------------------------------------------------------------------------------------------------------------

    // Get Current Qorner Id of connected User
    const {currentQornerID} = useStoreState(UserStore, s => ({
        currentQornerID: s.currentQornerID
    }));

    // Mutation checkUserQornerCodePsMutation
    const [checkUserQornerCodePsMutation] = useMutation(
        graphql`
            mutation NavigationUserQornerMutation($qornerId: Int!) {
                checkUserQornerCodePs(qornerId: $qornerId)
            }
            `,
        {
            onCompleted: (result) => {
                // console.log(result)
            },
            onError: (err) => {
                // Log error and change UI to error state
                console.error(err)
            }
        },
    );

    const [resetQornerPs, setResetQornerPs] = useState(false)

    // Function call mutation checkUserQornerCodePsMutation
    const checkUserQornerCodePs = function () {
        // Not possible but
        if (currentQornerID != null) {
            checkUserQornerCodePsMutation({
                variables: {
                    qornerId: currentQornerID
                }
            }).then(function (result) {
                    // Si check false alors on reset la connexion User et Qorner
                    if (result.checkUserQornerCodePs === false) {
                        // Enlever le currentQornerID dans la session UserStore
                        UserStore.update(s => {
                            s.currentQornerID = null
                            s.currentUserHaveQorner = false
                        });
                        // Rediriger sur la page de Choix de Qorner
                        navigation.navigate({name: 'QornerChoice', params: {expired: currentQornerID}});
                    }
                }
            );
        }
    };

    // Call check 1 time loading application
    useEffect(
        checkUserQornerCodePs,
        []
    );
    // .----------------------------------------------------------------------------------------------------------------

    // Render navigator
    return (
        <Drawer.Navigator
            initialRouteName={'Home'}
            screenOptions={{
                headerShown: false,
                drawerStyle: {
                    backgroundColor: Colors.orange100,
                    width: isInitialRender ? 0 : isMobile ? width * 0.85 : isTablet ? 200 : 320
                },
                overlayColor: 'transparent',
                drawerType: isMobile ? 'slide' : 'permanent'
            }}
            drawerContent={(props) => <MenuDrawerContent {...props} t={t}/>}
        >
            <Drawer.Screen name={'Home'} component={HomeScreen} options={options}/>
            <Drawer.Screen name={'Search'} component={SearchScreen} options={options}/>
            <Drawer.Screen name={'Basket'} component={BasketScreen} options={options}/>
            <Drawer.Screen name={'Profile'} component={ProfileNavigation} options={options}/>
            <Drawer.Screen name={'Catalog'} component={CatalogScreen} options={options}/>
            <Drawer.Screen name={'Service'} component={ServiceScreen} options={options}/>
            <Drawer.Screen name={'Product'} component={ServiceScreen} options={options}/>
            <Drawer.Screen name={'GoodDeal'} component={GoodDealScreen} options={options}/>
            <Drawer.Screen name={'News'} component={NewsScreen} options={options}/>
            <Drawer.Screen name={'PracticalInformations'} component={PracticalInformationsScreen} options={options}/>
            <Drawer.Screen name={'Book'} component={BookScreen} options={options}/>
            <Drawer.Screen name={'ThankYou'} component={ThankYouScreen} options={options}/>
            <Drawer.Screen name={'PaymentOrder'} component={PaymentOrderScreen} options={options}/>
        </Drawer.Navigator>
    )
}

/**
 * PrivateNavigationWithoutMenuRoot.
 */
function PrivateNavigationWithoutMenuRoot(): Node {

    // Init state
    const Stack = createStackNavigator<RoutesParamList, any, any>()

    const {t} = useTranslation()
    const options = ({route}) => ({title: 'Quatre Epingles - ' + t('Navigation.naming.' + route.name)})

    // Render navigator
    return (
        <Stack.Navigator
            initialRouteName={'QornerChoice'}
            screenOptions={{ headerShown: false }}
        >
            <Stack.Screen name={'QornerChoice'} component={QornerChoiceScreen} options={options}/>
        </Stack.Navigator>
    )

}


/**
 * ProfileNavigation.
 */
function ProfileNavigation(): Node {
    const {t} = useTranslation()
    const options = ({route}) => ({title: 'Quatre Epingles - ' + t('Navigation.naming.' + route.name)})
    const Stack = createStackNavigator<RoutesParamList, any, any>()
    return (
        <Stack.Navigator initialRouteName={'ProfileMenu'} screenOptions={{ headerShown: false }}>
            <Stack.Screen name={'ProfileMenu'} component={ProfileMenuScreen} options={options}/>
            <Stack.Screen name={'Agenda'} component={ProfileAgendaScreen} options={options}/>
            <Stack.Screen name={'Cashback'} component={ProfileCashbackScreen} options={options}/>
            {/*<Stack.Screen name={'Estimates'} component={ProfileEstimatesScreen} options={options} />*/}
            <Stack.Screen name={'Orders'} component={ProfileOrdersScreen} options={options}/>
            {/*<Stack.Screen name={'PaymentCards'} component={ProfilePaymentCardsScreen} options={options} />*/}
            <Stack.Screen name={'PersonalInformations'} component={ProfilePersonalInfoScreen} options={options}/>
            <Stack.Screen name={'Security'} component={ProfileSecurityScreen} options={options}/>
            <Stack.Screen name={'Wallet'} component={ProfileWalletScreen} options={options}/>
            <Stack.Screen name={'SponsorCode'} component={ProfileSponsorCodeScreen} options={options}/>
            <Stack.Screen name={'DeleteMyAccount'} component={ProfileDeleteMyAccountScreen} options={options}/>
        </Stack.Navigator>
    )
}

/**
 * List of route with theirs parameters typed
 */
export type RoutesParamList = {
    Storybook: {},
    Public: {},
    Private: {},
    NotFound: {},
    Login: {},
    Register: {},
    QornerChoice: {},
    ForgotPassword: {},
    UpdatePassword: {},
    Home: {},
    Search: {},
    Basket: {},
    Profile: {},
    Catalog: {},
    Service: {},
    News: {},
    PracticalInformations: {},
    ProfileMenu: {},
    Agenda: {},
    Cashback: {},
    Estimates: {},
    Orders: {},
    PaymentCards: {},
    PersonalInformations: {},
    Security: {},
    Wallet: {},
    SponsorCode: {},
    BookScreen: {},
    ThankYou: {},
    PaymentOrder: {}
}
