import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

export const s = StyleSheet.create({
  container: {
  },
  monthIconContainer: {
    width: 22,
    height: 22,
    padding: 4,
    borderWidth: 1,
    borderColor: Colors.orange100,
    borderRadius: 22,
    justifyContent: 'center',
    alignItems: 'center'
  },
  monthIcon: {
    color: Colors.darkGrey100Less50,
  },
  nextMonthIcon: {
    transform: [{ rotate: "180deg" }]
  }
})

export const calendarTheme = {
  textSectionTitleColor: '#b6c1cd',
  textSectionTitleDisabledColor: '#d9e1e8',
  selectedDayBackgroundColor: Colors.orange100,
  selectedDayTextColor: '#fff',
  todayTextColor: Colors.orange50,
  dayTextColor: '#2d4150',
  dotColor: Colors.red100,
  selectedDotColor: '#fff',
  textDayFontFamily: 'Raleway',
  textMonthFontFamily: 'Raleway',
  textDayHeaderFontFamily: 'Raleway'
}