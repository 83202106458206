import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

export default StyleSheet.create({
  header: {
    height: 60,
    paddingLeft: 30,
    alignItems: 'flex-start',
    justifyContent: 'center',
    borderBottomColor: Colors.lightGrey100,
    borderBottomWidth: 1
  },
  container: {
    flex: 1,
    flexDirection: 'row'
  },
  profileMenuContainer: {
    flex: 1,
    minWidth: 260,
    marginLeft: 40,
  },
  profileMenuContainerDesktop: {
    flex: 1,
    minWidth: 360,
    marginLeft: 40,
  },
  profileMenu: {
    shadowColor: Colors.black100,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 3,
  },
  profileContentContainer: {
    flex: 4,
    padding: 40,
    paddingTop: 20,
  },

  desktopHeaderContainer: {
    height:120,
    backgroundColor: Colors.orange100Less80,
    marginBottom: 40,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  desktopBackgroundIcon: {
    position: 'absolute',
    color: Colors.white100Less50,
    right: -4,
    transform: [{ rotate: '-15deg' }]
  },
  desktopTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  desktopTitleIcon: {
    color: Colors.orange100,
    marginRight: 50,
    marginLeft: 50
  },
})
