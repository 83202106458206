import React, { type Node } from 'react'
import { View, FlatList, Pressable } from 'react-native'
import SkeletonContent from 'react-native-skeleton-content-nonexpo'
import Label from 'src/components/common/atoms/Label'
import Icon from 'src/components/common/atoms/Icon'
import { Colors } from 'src/styles'
import s, { skeleton } from './CatalogTabs.style'

// CatalogTabs mole.
const CatalogTabs = React.forwardRef(({
  style,
  tabs,
  activeTabId,
  onPress,
}: PropsTypes, ref: any): Node => {
  // Render component
  return (
    <SkeletonContent
      containerStyle={[s.container, style]}
      isLoading={!tabs}
      layout={skeleton}
    >
      <FlatList
        ref={ref}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        data={tabs}
        extraData={activeTabId}
        keyExtractor={item => item.id}
        renderItem={({item, index}) => {
          const isActive = (activeTabId?.toString() === item.rowId?.toString())
          return (<Pressable onPress={() => onPress(item, index)}>
              <View style={[s.titleItemContainer, isActive && { borderBottomWidth: 2, borderColor: Colors[item.color] }]}>
                <Label style={s.titleItemLabel} size={12} type={isActive ? 'bold' : 'inactive'}>{item.name}</Label>
              </View>
            </Pressable>)
        }}
      />
    </SkeletonContent>
  )
})

export default CatalogTabs

// PropsTypes
type PropsTypes = {
  style?: any,
  tabs: any,
  activeTabId: number,
  onPress: function,
  ref?: any
}