// @flow
import React, { type Node } from 'react'
import { TouchableWithoutFeedback, View } from 'react-native'
import Icon from 'src/components/common/atoms/Icon'
import Label from 'src/components/common/atoms/Label'
import s from './MenuScreenItem.style'

/**
 * MenuScreenItem mole.
 */
export default function MenuScreenItem ({
  iconName,
  icon,
  iconSize = 14,
  title,
  onPress,
  isDesktop = false,
  active = false
}: PropsTypes): Node {
  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View style={[s.menuItem, isDesktop && s.menuItemDesktop]}>

        {/* Icon */}
        <View style={[s.iconContainer, isDesktop && s.iconContainerDesktop]}>
          {iconName && <Icon style={[s.icon, isDesktop && s.iconDesktop]} name={iconName} size={iconSize}/>}
          {icon}
        </View>

        {/* Title */}
        <Label
          style={[s.title, isDesktop && s.titleDesktop, active && s.active]}
          skeleton={{ width: 150, height: 16 }}
          size={isDesktop ? 22 : 14}
        >
          {title}
        </Label>

        {/* Arrow nav */}
        {!isDesktop && (
          <Icon style={s.arrow} name={'back'} size={10}/>
        )}

      </View>
    </TouchableWithoutFeedback>
  )
}

// PropsTypes
type PropsTypes = {
  iconName?: 'cashback' | 'wallet' | 'home' | 'search' | 'menu' | 'basket' | 'check' | 'edit' | 'calendar' | 'back' |
    'next' | 'plus' | 'minus' | 'sales' | 'favorite' | 'favorite-filled' | 'caret' | 'profile-rounded' | 'chat' |
    'locker' | 'news' | 'infos' | 'bank-cards' | 'email' | 'phone' | 'lock' | 'profile' | 'estimate' | 'order' |
    'notify' | 'close' | 'qorner-company' | 'qorner-public',
  icon?: any,
  iconSize?: number,
  title: string,
  onPress: function,
  isDesktop?: boolean,
  active?: boolean
}

