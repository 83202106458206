import { StyleSheet } from 'react-native'
import { Colors, Palette } from 'src/styles'

// Styles
export default StyleSheet.create({
  loader: {
    marginTop: 20
  },
  mainContainer: {
    flex: 1,
    marginVertical: 20,
    marginHorizontal: 15
  },
  mainContainerDesktop: {
    marginVertical: 30,
    marginHorizontal: 40
  },
  avatar: {
    margin: 7
  },
  avatarDesktop: {
    marginRight: 23
  },
  dataContainer: {
    flexDirection: 'row'
  },
  textContainer: {
    paddingLeft: 15,
    flex: 1
  },
  textContainerDesktop: {
    flex: 1
  },
  personalInfoRow: {
    flexDirection: 'row',
    marginBottom: 10,
    flexWrap: 'wrap'
  },
  personalInfoRowDesktop: {
    marginBottom: 30
  },
  personalInfoData: {
    paddingLeft: 6,
    color: Palette.dpActiveInputText,
  },
  actionButton: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 20
  },
  actionButtonDesktop: {
    marginTop: 30,
    paddingLeft: 40,
    paddingRight: 40
  },
  updateAvatar: {
    width: 20,
    height: 20,
    borderRadius: 20,
    paddingLeft: 0,
    paddingRight: 0,
    position: 'absolute',
    bottom: 0,
    borderColor: Colors.orange100,
    backgroundColor: Colors.orange100
  },
  updateAvatarDesktop: {
    width: 60,
    height: 60,
    borderRadius: 60,
    bottom: -20,
  },
  updateAvatarIcon: {
    paddingTop: 0
  }
})