/**
 * @generated SignedSource<<7a6a96f679ab927d3a81fd72420badbc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lang",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NewsScreenUserQuery",
    "selections": (v0/*: any*/),
    "type": "ClientAppQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NewsScreenUserQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "d007dc8c539bfc8abb0e29831e9bac61",
    "id": null,
    "metadata": {},
    "name": "NewsScreenUserQuery",
    "operationKind": "query",
    "text": "query NewsScreenUserQuery {\n  user {\n    lang\n  }\n}\n"
  }
};
})();

node.hash = "567ff28f85d5cd79e0ed0e8ff637cb51";

module.exports = node;
