import React, { type Node } from 'react'
import { View, Image } from 'react-native'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import useFullWidth from 'src/utils/useFullWidth'
import Button from 'src/components/common/atoms/Button'
import Label from 'src/components/common/atoms/Label'
import Icon from 'src/components/common/atoms/Icon'
import ViewShadow from 'src/components/common/atoms/ViewShadow'
import s, { backgroundType } from './GoodDeal.style'
import { Colors } from 'src/styles'
import useScreenSizes from 'src/utils/useScreenSizes'

/**
 * GoodDeal Component
 */
const GoodDeal = ({
  goodDeal,
  onPress,
  width
}: PropsTypes): Node => {
  const { t } = useTranslation()
  const fullWidth = useFullWidth()
  const {screenSize, isMobile, isTablet} = useScreenSizes()

  /*
   * Retrieve icons from goodDealType
   */
  let icons = []
  let backgroundIcon = null
  let backgroundIconColor = null
  switch (goodDeal.goodDealType) {
    case 'CASHBACK_CAMPAIGN':
      icons = goodDeal.cashbackServices.slice(0, 4)
      backgroundIcon = 'cashback'
      backgroundIconColor = Colors.yellow100Less30
      break
    case 'DISCOUNT_CAMPAIGN':
      icons = goodDeal.discountServices.slice(0, 4)
      backgroundIcon = 'sales'
      backgroundIconColor = Colors.red100Less50
      break
  }

  /*
   * Render left and right content from number of icons
   */
  let leftContent = null
  let rightContent = null
  if (icons.length === 1) {
    leftContent = (
      <ViewShadow style={[s.largeIconContainer, s.largeIconLeft, !isMobile && [s[`largeIconContainer${screenSize}`], s[`largeIconLeft${screenSize}`]]]}>
        <Image source={{ uri: icons[0].service.icon }} style={[s.largeIcon, !isMobile && s[`largeIcon${screenSize}`]]}/>
      </ViewShadow>
    )
  } else if (icons.length === 2) {
    leftContent = (
      <ViewShadow style={[s.largeIconContainer, s.largeIconLeft, !isMobile && [s[`largeIconContainer${screenSize}`], s[`largeIconLeft${screenSize}`]]]}>
        <Image source={{ uri: icons[0].service.icon }} style={[s.largeIcon, !isMobile && s[`largeIcon${screenSize}`]]}/>
      </ViewShadow>
    )
    rightContent = (
      <View style={{ alignItems: 'flex-end' }}>
        <ViewShadow style={[s.largeIconContainer, s.largeIconRight,
          !isMobile && [s[`largeIconContainer${screenSize}`], s[`largeIconRight${screenSize}`]]]}>
          <Image source={{ uri: icons[1].service.icon }} style={[s.largeIcon, !isMobile && s[`largeIcon${screenSize}`]]}/>
        </ViewShadow>
      </View>
    )
  } else if (icons.length === 3) {
    leftContent = (
      <>
        <ViewShadow style={[s.smallIconContainer, s.smallIconLeftFirst,
          !isMobile && [s[`smallIconContainer${screenSize}`], s[`smallIconLeftFirst${screenSize}`]]]}>
          <Image source={{ uri: icons[0].service.icon }} style={[s.smallIcon, !isMobile && s[`smallIcon${screenSize}`]]}/>
        </ViewShadow>
        <ViewShadow style={[s.smallIconContainer, s.smallIconLeftSecond,
          !isMobile && [s[`smallIconContainer${screenSize}`], s[`smallIconLeftSecond${screenSize}`]]]}>
          <Image source={{ uri: icons[1].service.icon }} style={[s.smallIcon, !isMobile && s[`smallIcon${screenSize}`]]}/>
        </ViewShadow>
      </>
    )
    rightContent = (
      <View style={{ alignItems: 'flex-end' }}>
        <ViewShadow style={[s.largeIconContainer, s.largeIconRight,
          !isMobile && [s[`largeIconContainer${screenSize}`], s[`largeIconRight${screenSize}`]]]}>
          <Image source={{ uri: icons[2].service.icon }} style={[s.largeIcon, !isMobile && s[`largeIcon${screenSize}`]]}/>
        </ViewShadow>
      </View>
    )
  } else if (icons.length === 4) {
    leftContent = (
      <>
        <ViewShadow style={[s.smallIconContainer, s.smallIconLeftFirst,
          !isMobile && [s[`smallIconContainer${screenSize}`], s[`smallIconLeftFirst${screenSize}`]]]}>
          <Image source={{ uri: icons[0].service.icon }} style={[s.smallIcon, !isMobile && s[`smallIcon${screenSize}`]]}/>
        </ViewShadow>
        <ViewShadow style={[s.smallIconContainer, s.smallIconLeftSecond,
          !isMobile && [s[`smallIconContainer${screenSize}`], s[`smallIconLeftSecond${screenSize}`]]]}>
          <Image source={{ uri: icons[1].service.icon }} style={[s.smallIcon, !isMobile && s[`smallIcon${screenSize}`]]}/>
        </ViewShadow>
      </>
    )
    rightContent = (
      <View style={s.rightContentContainer}>
        <ViewShadow style={[s.smallIconContainer, s.smallIconRightFirst,
          !isMobile && [s[`smallIconContainer${screenSize}`], s[`smallIconRightFirst${screenSize}`]]]}>
          <Image source={{ uri: icons[2].service.icon }} style={[s.smallIcon, !isMobile && s[`smallIcon${screenSize}`]]}/>
        </ViewShadow>
        <ViewShadow style={[s.smallIconContainer, s.smallIconRightSecond,
          !isMobile && [s[`smallIconContainer${screenSize}`], s[`smallIconRightSecond${screenSize}`]]]}>
          <Image source={{ uri: icons[3].service.icon }} style={[s.smallIcon, !isMobile && s[`smallIcon${screenSize}`]]}/>
        </ViewShadow>
      </View>
    )
  }

  /*
   * Render main content : AFFILIATE_LINK has different layout
   */
  const renderContent = goodDeal.goodDealType === 'AFFILIATE_LINK' ?
    (
      <>
        {goodDeal.affiliateLinks.length === 1 ? (
            <View style={[s.contentContainer, s.affiliationContainer, !isMobile && s[`contentContainer${screenSize}`]]}>
              <View style={s.affiliationSubContainer}>
                <Image
                  style={[s.affiliationImage, !isMobile && s[`affiliationImage${screenSize}`]]}
                  source={{ uri: goodDeal.affiliateLinks[0].logoURL }}
                  resizeMode={'contain'}
                />
                <View style={s.affiliationLabelContainer}>
                  <Label
                    type={'bold'}
                    style={s.centerLabel}
                    size={isMobile ? 14 : isTablet ? 28 : 76}
                  >
                    {goodDeal.affiliateLinks[0].name}
                  </Label>
                </View>
              </View>
            </View>
          )
          : goodDeal.affiliateLinks.length > 1 && (
          <View style={[s.contentContainer, !isMobile && s[`contentContainer${screenSize}`]]}>
            <View style={[s.affiliationSubContainer, { flex: 6 }]}>
              <Image
                style={s.affiliationImage}
                source={{ uri: goodDeal.affiliateLinks[0].logo }}
                resizeMode={'contain'}
              />
              <View style={s.affiliationLabel}>
                <Label types={['bold', 'smallMargin']} style={s.centerLabel}>{goodDeal.affiliateLinks[0].name}</Label>
              </View>
            </View>
            <View style={s.affiliationSeparationContainer}>
              <View style={s.affiliationSeparationBar}/>
            </View>
            <View style={[s.affiliationSubContainer, { flex: 6, backgroundColor: Colors.red100Less90 }]}>
              <Image
                style={s.affiliationImage}
                source={{ uri: goodDeal.affiliateLinks[1].logo }}
                resizeMode={'contain'}
              />
              <View style={s.affiliationLabel}>
                <Label types={['bold', 'smallMargin']} style={s.centerLabel}>{goodDeal.affiliateLinks[1].name}</Label>
              </View>
            </View>
          </View>
        )}
      </>
    )
    : (
      <View style={[s.contentContainer, !isMobile && s[`contentContainer${screenSize}`], backgroundType[goodDeal.goodDealType]]}>
        {backgroundIcon && <Icon style={s.backgroundIcon} name={backgroundIcon} color={backgroundIconColor} size={isMobile ? 64 : 186}/>}
        <View style={s.leftContent}>
          {leftContent}
        </View>
        <View style={s.centerContent}>
          <Label
            style={s.centerLabel}
            type={'bold'}
            size={isMobile ? 22 : 76}
            color={goodDeal.goodDealType === 'DISCOUNT_CAMPAIGN' && '#fff'}
          >
            {goodDeal.name}
          </Label>
        </View>
        <View style={s.rightContent}>
          {rightContent}
        </View>
      </View>
    )

  /*
   * Render mole
   */
  return (
    <View style={[s.container, { width: width }]}>
      <Button onPress={() => onPress(goodDeal)} size={isMobile ? 'XS' : isTablet ? 'M' : 'L'} style={[s.titleButton, isTablet && s.titleButtonTablet]}>
        {t('GoodDeals.mainAction')}
      </Button>
      <View style={[s.titleContainer, !isMobile && s[`titleContainer${screenSize}`]]}>
        <Label type={'bold'} color={'#fff'} size={isMobile ? 14 : isTablet ? 18 : 40}>
          {t('GoodDeals.type.' + goodDeal.goodDealType).toUpperCase()}
        </Label>
        <Label color={'#fff'} type={'bold'} size={isMobile ? 10 : isTablet ? 14 : 25} style={[s.titleDate, !isMobile && s.titleDateDesktop]}>
          {t('GoodDeals.period', {
            start: moment(goodDeal.startDateTime, 'Y-M-D').format('DD/MM'),
            end: moment(goodDeal.endDateTime, 'Y-M-D').format('DD/MM')
          })}
        </Label>
      </View>
        {renderContent}
    </View>
  )
}

type PropsTypes = {
  goodDeal: any,
  onPress: function,
  width: number
}

export default React.memo(GoodDeal)