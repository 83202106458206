/**
 * @generated SignedSource<<c62dc2aa95066b290f0ff2dfe04f5fd2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "page"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "page",
        "variableName": "page"
      }
    ],
    "concreteType": "PaginatedArticlesOutputPayload",
    "kind": "LinkedField",
    "name": "articles",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Article",
        "kind": "LinkedField",
        "name": "items",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rowId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "title",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mainPictureUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "publishedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NewsScreenQuery",
    "selections": (v1/*: any*/),
    "type": "ClientAppQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NewsScreenQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "38cf15f6324ae8c45f93ecd6b15ccd5c",
    "id": null,
    "metadata": {},
    "name": "NewsScreenQuery",
    "operationKind": "query",
    "text": "query NewsScreenQuery(\n  $page: Int\n) {\n  articles(page: $page) {\n    items {\n      id\n      rowId\n      title\n      mainPictureUrl\n      publishedAt\n    }\n    totalCount\n  }\n}\n"
  }
};
})();

node.hash = "7a8f231ec436e2be9e0b854dbd3c926d";

module.exports = node;
