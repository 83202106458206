import LocalStorage from 'src/utils/LocalStorage'
import { get, pick, set } from 'lodash'

/**
 * Allow to store pullstate Store inside LocalStorage.
 * @url https://gist.github.com/lostpebble/be0ed70f07fa30e45fc47986dfbfa635
 */
export class StoreLocalStorage {

  // All stores
  storesWithValues: [] = []
  namespace: string

  /**
   * Constructor
   * @param namespace
   */
  constructor(namespace: string = 'eqqi') {
    this.namespace = namespace
  }

  /**
   * Create Store.
   *
   * @param key
   * @param store
   * @param values
   * @param deepValues
   */
  addStore({ key, store, values = [], deepValues = [] }) {
    this.storesWithValues.push({
      key,
      store,
      values,
      deepValues
    })
  }

  /**
   * Initiate LocalStorage values.
   */
  initiateLocalStorageValues() {

    // Try to retrieve local data for all stores
    return Promise.all(this.storesWithValues.map(SV => {

      // Retrieve data from async storage for corresponding store
      return LocalStorage.getItem(`${this.namespace}_${SV.key}`).then(localStorageValue => {

        // Local data
        const foundLocal = JSON.parse(localStorageValue != null ? localStorageValue : '{}')

        // Try to find deep values inside it
        let foundDeepValues: any = {}
        LocalStorage.getItem(`${this.namespace}_${SV.key}_deep`).then(localStorageDeepValue => {

          // Local data deep value
          foundDeepValues = JSON.parse(localStorageDeepValue != null ? localStorageDeepValue : '{}')

          // Update store according to found values in LocalStorage
          SV.store.update(s => {
            for (const [key, value] of Object.entries(foundLocal)) {
              s[key] = value
            }
            for (const deepValPath of SV.deepValues) {
              if (foundDeepValues.hasOwnProperty(deepValPath)) {
                set(s, deepValPath, foundDeepValues[deepValPath])
              }
            }
          })

          // Save data in LocalStorage on any modification
          // If values specified, only subscribe for this values change, else store everything
          SV.store.subscribe(
            s => {
              return pick(s, SV.values.length > 0 ? SV.values : Object.keys(s))
            },
            (filteredState, allState) => {
              LocalStorage.setItem(`${this.namespace}_${SV.key}`, JSON.stringify(SV.values.length > 0 ? filteredState : allState))
            }
          )

          // If there are deep values, save data for them on any modification
          if (SV.deepValues.length > 0) {
            SV.store.subscribe(
              s => {
                const resp: any = {}

                for (const deepValPath of SV.deepValues) {
                  resp[deepValPath] = get(s, deepValPath)
                }

                return resp
              },
              keepLocal => {
                LocalStorage.setItem(`${this.namespace}_${SV.key}_deep`, JSON.stringify(keepLocal))
              }
            )
          }

        })

      })
    }))

  }

}
