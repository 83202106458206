// @flow
import React, { type Node, useEffect, useState } from 'react'
import { Image, ScrollView, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'relay-hooks'
import { Formik } from 'formik'
import moment from 'moment'
import { formatPrice } from 'src/utils/FormatDataUtils'
import TextInputFormik from 'src/components/common/forms/TextInputFormik'
import PrivateLayout from 'src/components/common/layout/PrivateLayout'
import ViewShadow from 'src/components/common/atoms/ViewShadow'
import Button from 'src/components/common/atoms/Button'
import Label from 'src/components/common/atoms/Label'
import Icon from 'src/components/common/atoms/Icon'
import s from './BookScreen.style'
import { Colors } from 'src/styles'

moment.locale('fr')

export default function BookScreen ({
  navigation
}: PropsTypes): Node {

  const [open, setOpen] = useState(true)
  const [weekStart, setWeekStart] = useState(moment().startOf('isoWeek'))
  const [weekDays, setWeekDays] = useState([])
  const [selectedDate, setSelectedDate] = useState()
  const currentWeek = moment().startOf('isoWeek')
  const today = moment().startOf('day')

  const { t } = useTranslation()

  const { data } = useQuery(graphql`
    query BookScreenQuery {
      user {
        cashbackWallet {
          activeAmount
        }
      }
      recurringCreditCards {
        cardNumber
        token
      }
    }
  `)
  const cashbackWallet = data?.user?.cashbackWallet
  const recurringCreditCards = data?.recurringCreditCards

  // fake data
  const fakeProduct = {
    name: 'Coiffeur',
    productType: 'activity',
    price: '21,00',
    quantityInBasket: 1,
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
    discountPerProduct: null,
    isCashbackActive: false,
  }

  // Set the days of the selected week
  const getWeekDays = () => {
    let week = []
    for (let i = 0; i < 7; i++) {
      week.push(moment(weekStart).add(i, 'day'))
    }
    setWeekDays(week)
  }

  useEffect(() => {
    getWeekDays()
  }, [weekStart])

  return (
    <PrivateLayout headerTitle={'Book screen'}>
      <ScrollView style={s.scrollView}>

        {/* Product info */}
        <ViewShadow
          style={s.productInfoContainer}
          hasPadding={true}
        >
          <View style={s.productName}>
            <Image
              style={s.productImage}
              source={fakeProduct.icon}
            />
            <Label types={['default', 'smallMargin']}>{fakeProduct.name}</Label>
          </View>
          <View style={s.productPrice}>
            <Label types={['default', 'smallMargin']}>{formatPrice(fakeProduct.price)}</Label>
            <Icon name={'caret'} size={5} style={s.priceIcon}/>
          </View>
        </ViewShadow>

        {/* Calendar */}
        <ViewShadow
          style={s.calendarContainer}
          hasPadding={true}
        >
          <View style={s.calendarTitle}>
            <Label types={['default', 'smallMargin']}>{t('BookScreen.calendar.title')}</Label>
            <Icon name={'caret'} size={5} style={s.calendarIcon}/>
          </View>

          {selectedDate ? (
            <View style={s.selectedDateContainer}>
              <Label size={9}>Mercredi 24 juin de 13h00 à 13h30 - Place optionnelle</Label>
              <Button size={'S'} Icon={<Icon name={'edit'} size={10}/>}
                      style={s.editSelectedDate}
                      onPress={() => setSelectedDate(null)}/>
            </View>
          ) : (
            <>
              <View style={s.selectDateContainer}>
                <Button size={'XS'} disabled={weekStart.isSame(currentWeek)}
                        onPress={() => setWeekStart(moment(weekStart).subtract(1, 'week'))}
                        Icon={<Icon size={6} name={'back'}/>}
                        style={s.changeWeekButton}/>
                <Label types={['default', 'smallMargin']}>{weekStart.format('MMMM YYYY')}{weekStart.isSame(currentWeek) && t('BookScreen.calendar.thisWeek')}</Label>
                <Button size={'XS'}
                        onPress={() => setWeekStart(moment(weekStart).add(1, 'week'))}
                        Icon={<Icon size={6} name={'next'}/>}
                        style={s.changeWeekButton}/>
              </View>

              <View style={s.daysContainer}>
                {weekDays.map((day, index) => {
                  const isToday = day.startOf('day').isSame(today)
                  return (<Label style={[isToday && { color: 'red' }]} key={index}>{day.format('ddd DD')}</Label>)
                })}
              </View>
            </>
          )}
        </ViewShadow>

        {/* Special offer */}
        <View style={s.specialOfferContainer}>
          <Label size={9}>{t('BookScreen.specialOffer.question')}</Label>
          <Formik
            initialValues={{ code: '' }}
            onSubmit={(values) => console.log(values)}
          >
            {(formikProps) => (
              <View style={s.inputContainer}>
                <TextInputFormik size={'XS'} placeholder={t('BookScreen.specialOffer.enter')} {...formikProps}
                                 name={'code'}/>
                <Button
                  style={{ marginLeft: 5 }}
                  size={'XS'}
                  onPress={formikProps.handleSubmit}
                >{t('BookScreen.specialOffer.ok')}</Button>
              </View>
            )}
          </Formik>
        </View>

        {/* Cashback */}
        {cashbackWallet?.activeAmount > 0 && (
          <ViewShadow
            style={s.cashbackContainer}
            hasPadding={true}
          >
            <View style={s.available}>
              <Label types={['default', 'smallMargin']}>Cashback</Label>
              <Label size={10}>{t('BookScreen.cashback.available', { amount: cashbackWallet?.activeAmount })}</Label>
            </View>
            <View style={s.deduce}>
              <Label size={10}>{t('BookScreen.cashback.deduce', { amount: cashbackWallet?.activeAmount })}</Label>
              <Button size={'XS'} onPress={() => console.log('déduire casback')}>{t('BookScreen.cashback.yes')}</Button>
            </View>
          </ViewShadow>
        )}

        {/* Payment */}
        <ViewShadow
          style={s.paymentContainer}
          hasPadding={true}
        >
          <View style={s.paymentHeader}>
            <Label types={['default', 'smallMargin']}>{t('BookScreen.payment.title')}</Label>
            <Label size={12}>{t('BookScreen.cashback.available', { amount: cashbackWallet?.activeAmount })}</Label>
          </View>
          <Button fullWidth palette={'primary'} size={'M'} style={s.paymentButton}>
            {t('BookScreen.payment.wallet')}
          </Button>
          {recurringCreditCards && (
            <Button fullWidth palette={'primary'} size={'M'} style={s.paymentButton}>
              {t('BookScreen.payment.savedCard', { number: recurringCreditCards?.cardNumber })}
            </Button>
          )}
          <Button fullWidth size={'M'} style={s.paymentButton}>
            {t('BookScreen.payment.card')}
          </Button>
        </ViewShadow>

      </ScrollView>
    </PrivateLayout>
  )
}

// PropsTypes
type PropsTypes = {
  navigation: any
}