// @flow
import React, { type Node, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'relay-hooks'
import { Dimensions, Platform, View } from 'react-native'
import { formatPrice } from 'src/utils/FormatDataUtils'
import useScreenSizes from 'src/utils/useScreenSizes'
import Icon from 'src/components/common/atoms/Icon'
import Label from 'src/components/common/atoms/Label'
import Loader from 'src/components/common/atoms/Loader'
import Button from 'src/components/common/atoms/Button'
import AdyenPaymentForm from 'src/components/common/moles/AdyenPaymentForm/AdyenPaymentForm'
import { Colors } from 'src/styles'
import { payWithWalletModalContent as s } from './PayModal.style'
import GlobalRefetchStore from 'src/store/GlobalRefetchStore'

export default function PayWithWalletAndCardModalContent ({
  amount,
  onClose,
  creditsBalance,
  setModalStep,
  setPaidBasket,
  handlePay,
  payableType = 'basket',
  payableId = null
}: PropsTypes): Node {

  // init values
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [withNewCard, setWithNewCard] = useState(false)

  const { data } = useQuery(
    graphql`
      query PayWithWalletAndCardModalContentQuery {
        recurringCreditCards {
          cardNumber
          token
        }
      }
    `
  )
  const recurringCreditCards = data?.recurringCreditCards

  const handleRecurringCardPaymentSubmit = cardToken => {
    setError(false)
    setLoading(true)

    handlePay({
      variables: {
        paymentMethod: 'METHOD_REGISTERED_CREDIT_CARD_AND_CREDITS',
        browserInfo: {
          browser: Platform.OS === 'web' ? {
            acceptHeader: 'application/json',
            colorDepth: window.screen.colorDepth,
            javaEnabled: navigator.javaEnabled(),
            language: navigator.language,
            screenHeight: Dimensions.get('window').height,
            screenWidth: Dimensions.get('window').width,
            timeZoneOffset: new Date().getTimezoneOffset(),
            userAgent: navigator.userAgent
          } : {},
          isFromAndroid: Platform.OS === 'android',
          isFromIos: Platform.OS === 'ios'
        },
        recurringCreditCard: {
          contractId: cardToken
        }
      }
    }).then(() => {
      setLoading(false)
      setSuccess(true)
      setModalStep()
      setPaidBasket(true)
    }).catch(() => {
      setLoading(false)
      setError(true)
    })
  }

  // Handle native payment completed
  const handlePaymentCompleted = () => {
    setModalStep()
    setPaidBasket(true)
    setSuccess(true)
    GlobalRefetchStore.update(s => {
      s.bestServicesKey = new Date()
    })
  }

  if (!recurringCreditCards) {
    return (
      <View style={s.loadingContainer}>
        <Loader size={20}/>
      </View>
    )
  }

  return (
    <>
      <Label size={!isMobile ? 16 : 14}>{t('PayBasketModal.PayWithWalletAndCardModalContent.title')}</Label>

      {
        loading ? (
            <View style={s.iconContainer}>
              <Loader size={20}/>
            </View>
          ) :
          success ? (
            <View style={s.iconContainer}>
              <Icon name={'check'} color={Colors.orange100}/>
            </View>
          ) : (
            error ? (
              <View style={[s.iconContainer, s.errorContainer]}>
                <Icon name={'close'} color={Colors.orange100} style={{ marginBottom: 15 }}/>
                <Label type={'error'} style={s.textCenter}>{t('PayBasketModal.error')}</Label>
              </View>
            ) : (
              <>

                <Label size={isMobile ? 10 : 12} style={s.amountToPay}>
                  <Trans
                    i18nKey={'PayBasketModal.toPay'}
                    values={{ amount: formatPrice(amount) }}
                    components={{ b: <Label type={'bold'} size={isMobile ? 10 : 12}/> }}
                  />
                </Label>
                <Label size={isMobile ? 10 : 12} style={s.creditsBalance}>
                  <Trans
                    i18nKey={'PayBasketModal.PayWithWalletModalContent.creditsBalance'}
                    values={{ creditsBalance: formatPrice(creditsBalance) }}
                    components={{ b: <Label type={'bold'} size={isMobile ? 10 : 12}/> }}
                  />
                </Label>

                {withNewCard ? (
                  <>
                    <AdyenPaymentForm
                      amount={amount - creditsBalance}
                      onCompleted={handlePaymentCompleted}
                      isWithCredits={true}
                      payableType={payableType}
                      payableId={payableId}
                    />
                  </>
                ) : (
                  <>
                    {recurringCreditCards.map(card => (
                      <Button
                        key={card.cardNumber}
                        fullWidth
                        palette={'primary'}
                        size={'M'}
                        style={s.button}
                        onPress={() => handleRecurringCardPaymentSubmit(card.token)}>
                        {t('BasketScreen.payment.savedCard', { number: card.cardNumber })}
                      </Button>
                    ))}
                    <Button fullWidth size={'M'} style={s.button} onPress={() => setWithNewCard(true)}>
                      {t('BasketScreen.payment.card')}
                    </Button>
                  </>
                )}

                <Button onPress={onClose} size={'M'} fullWidth style={s.button}>
                  {t('PayBasketModal.changePaymentMethod')}
                </Button>

                <View style={s.securePayments}>
                  <Label size={9}>{t('PayBasketModal.securePayments')}</Label>
                  <Icon name={'lock'} size={15} style={s.adyenLogo}/>
                </View>

              </>
            )
          )
      }
    </>
  )
}

type PropsTypes = {
  amount: number,
  creditsBalance: number,
  onClose: function,
  setModalStep: function,
  modalSteps: any,
  setPaidBasket: function,
  handlePay: function,
  payableType?: string,
  payableId?: number,
  useCashback?: boolean
}