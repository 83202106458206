import React, { type Node } from 'react'
import { View, FlatList } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'relay-hooks'
import moment from 'moment'
import SkeletonContent from 'react-native-skeleton-content-nonexpo'
import useScreenSizes from 'src/utils/useScreenSizes'
import useFullWidth from 'src/utils/useFullWidth'
import PrivateLayout from 'src/components/common/layout/PrivateLayout'
import Label from 'src/components/common/atoms/Label'
import Icon from 'src/components/common/atoms/Icon'
import AffiliationCard from 'src/components/common/moles/AffiliationCard'
import ServiceCard from 'src/components/common/moles/ServiceCard'
import s from './GoodDealScreen.style'

/**
 * GoodDealScreen component
 */
export default function GoodDealScreen ({
  navigation
}: PropsTypes): Node {
  /*
   * API Query
   */
  const { data } = useQuery(graphql`
    query GoodDealScreenQuery {
      goodDeals {
        name
        goodDealType
        startDateTime
        endDateTime
        affiliateLinks {
          token
          name
          logoURL
          targetLink
        }
        cashbackServices {
          service {
            id
            rowId
            name
            familyId
            catalogIcon {
              url
            }
            logoURL
          }
          cashbackPercentage
        }
        discountServices {
          service {
            id
            rowId
            name
            familyId
            catalogIcon {
              url
            }
            logoURL
          }
          discountPercentage
        }
      }
    }
  `)
  const goodDeals = data?.goodDeals

  /*
   * Init vars
   */
  const { t } = useTranslation()
  const fullWidth = useFullWidth()
  const { isMobile } = useScreenSizes()
  const numColumns = isMobile ? 1 : 3
  const cardWidth = isMobile
    ? fullWidth - 40
    : (fullWidth - 320 - 80) / numColumns - 24

  /*
   * Nested list Header (see below)
   */
  const nestedListHeader = goodDeal => (
    <View style={[s.nestedListHeaderContainer, !isMobile && s.nestedListHeaderContainerDesktop]}>
      <Icon name={'sales'} style={s.nestedListHeaderIconBackground} size={isMobile ? 56 : 78}/>

      <Icon name={'sales'}
            style={[s.nestedListHeaderIcon, !isMobile && s.nestedListHeaderIconDesktop]}
            size={isMobile ? 13 : 30}/>
      <Label type={'bold'} size={isMobile ? 13 : 28}>
        {t('GoodDealScreen.listHeaderTitle.' + goodDeal.goodDealType, {
          start: moment(goodDeal.startDateTime, 'Y-M-D').format('DD/MM'),
          end: moment(goodDeal.endDateTime, 'Y-M-D').format('DD/MM')
        })}
      </Label>
    </View>
  )

  /*
   * Nested list of products/services
   */
  const goodDealProductsList = ({ item: goodDeal }) => {
    const type = goodDeal.goodDealType

    if (type === 'AFFILIATE_LINK') {
      return (
        <FlatList
          style={isMobile ? { width: '100%' } : { width: '100%', marginBottom: 20 }}
          data={goodDeal.affiliateLinks}
          keyExtractor={item => item.token}
          ListHeaderComponent={() => nestedListHeader(goodDeal)}
          renderItem={({ item, index }) => {

            const length = goodDeal.affiliateLinks.length

            if (index % numColumns !== 0) return null

            let items = []
            for (let i = index; i < index + numColumns; i++) {
              if (i >= length) {
                break

              }
              const currentItem = goodDeal.affiliateLinks[i]

              items.push(
                <AffiliationCard
                  width={cardWidth}
                  title={currentItem.name}
                  logoURL={currentItem.logoURL ? currentItem.logoURL : ''}
                  margin={isMobile ? { bottom: 10 } : { bottom: 20, left: 20 }}
                  isMobile={isMobile}
                  targetLink={currentItem.targetLink}
                />
              )
            }

            return <View style={s.listRowContainer}> {items} </View>
          }}
        />
      )
    } else if (
      type === 'DISCOUNT_CAMPAIGN' ||
      type === 'CASHBACK_CAMPAIGN'
    ) {
      const goodDeals = type === 'DISCOUNT_CAMPAIGN' ? goodDeal.discountServices : goodDeal.cashbackServices
      return (
        <FlatList
          style={isMobile ? { width: '100%' } : { width: '100%', marginBottom: 20 }}
          data={goodDeals}
          keyExtractor={item => item.service.id}
          ListHeaderComponent={() => nestedListHeader(goodDeal)}
          renderItem={({ item, index }) => {

            const length = goodDeals.length

            if (index % numColumns !== 0) return null

            let items = []
            for (let i = index; i < index + numColumns; i++) {
              if (i >= length) {
                break

              }
              const currentItem = goodDeals[i]

              items.push(
                <ServiceCard
                  width={cardWidth}
                  margin={isMobile ? { bottom: 10 } : { bottom: 20, left: 20 }}
                  size={isMobile ? 'M' : 'L'}
                  title={currentItem.service.name}
                  discount={type === 'DISCOUNT_CAMPAIGN' && currentItem.discountPercentage}
                  cashback={type === 'CASHBACK_CAMPAIGN' && currentItem.cashbackPercentage}
                  iconURL={currentItem.service.logoURL ? currentItem.service.logoURL : ''}
                  onPress={() => navigation.navigate({
                    name: 'Service',
                    params: { familyId: currentItem.service.familyId, serviceId: currentItem.service.rowId }
                  })}
                  displayNonFavoriteIcon={false}
                />
              )
            }

            return <View style={s.listRowContainer}> {items} </View>
          }}
        />
      )
    } else {
      return null
    }
  }

  /*
   * Render component
   */
  return (
    <PrivateLayout
      headerTitle={t('GoodDealScreen.headerTitle')}
      headerBackOnPress={() => navigation.navigate('Catalog')}
    >
      <View style={s.container}>
        <SkeletonContent
          containerStyle={s.skeletonContainer}
          isLoading={false}
          layout={[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, i) => {
            if (i === 0 || i === 5) return { width: fullWidth, height: 50, marginBottom: 18 }
            return { width: fullWidth - 50, height: 40, marginBottom: 18 }
          })}
        >
          <FlatList
            style={[s.mainList, !isMobile && s.mainListDesktop]}
            data={goodDeals}
            keyExtractor={(item, index) => item.name + index}
            renderItem={goodDealProductsList}
          />
        </SkeletonContent>
      </View>
    </PrivateLayout>
  )
}

type PropsTypes = {
  navigation: any
}