import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

export default StyleSheet.create({
  scrollView: { padding: 20 },

  // Product info
  productInfoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: 40,
    padding: 10,
    backgroundColor: 'white'
  },
  productName: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  productImage: {
    width: 20,
    height: 20,
    borderRadius: 20,
    marginRight: 10
  },
  productPrice: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  priceIcon: {
    marginLeft: 15,
    marginRight: 5
  },

  // Calendar
  calendarContainer: {
    marginTop: 20,
    width: '100%',
    borderLeftColor: Colors.orange100,
    borderLeftWidth: 2,
    padding: 10,
    backgroundColor: 'white'
  },
  calendarTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20
  },
  calendarIcon: {
    marginLeft: 15,
    marginRight: 5
  },
  selectedDateContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  editSelectedDate: {
    height: 20,
    width: 20,
    borderRadius: 20,
    padding: 0
  },
  selectDateContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 18
  },
  changeWeekButton: {
    width: 14,
    height: 14,
    borderRadius: 14,
    padding: 0
  },
  daysContainer: {
    flexDirection: 'row'
  },

  // Special Offer
  specialOfferContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: 20,
    alignItems: 'center'
  },
  inputContainer: {
    flexDirection: 'row'
  },

  // Cashback
  cashbackContainer: {
    marginTop: 20,
    padding: 10,
    backgroundColor: 'white'
  },
  available: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  deduce: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15
  },

  // Payment
  paymentContainer: {
    marginTop: 20,
    width: '100%',
    borderLeftColor: Colors.orange100,
    borderLeftWidth: 2,
    padding: 10,
    backgroundColor: 'white'
  },
  paymentHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
    alignItems: 'flex-start',
  },
  paymentButton: {
    marginTop:10
  }

})