// @flow
import React, { type Node, useRef } from 'react'
import { Platform, Pressable, View } from 'react-native'
import { Formik } from 'formik'
import { Trans, useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import Constants from 'expo-constants'
import { useMutation } from 'relay-hooks'
import { type StackNavigationProp } from '@react-navigation/stack'
import type { RoutesParamList } from 'src/components/Navigation'
import UserStore from 'src/store/UserStore'
import useScreenSizes from 'src/utils/useScreenSizes'
import PublicLayout from 'src/components/common/layout/PublicLayout'
import Button from 'src/components/common/atoms/Button'
import TextInputFormik from 'src/components/common/forms/TextInputFormik'
import SwitchInputFormik from 'src/components/common/forms/SwitchInputFormik'
import Label from 'src/components/common/atoms/Label'
import ExternalLink from 'src/components/common/atoms/ExternalLink'
import s from './RegisterScreen.style'
import { VERSION } from 'src/environment'
import useInputRefs from 'src/utils/useInputRefs'

/**
 * RegisterScreen screen.
 */
export default function RegisterScreen ({
  navigation
}: PropsTypes): Node {

  // Get translator
  const { t, i18n } = useTranslation()
  const { isMobile, screenSize, isDesktop } = useScreenSizes()
  const currentUserLanguage= i18n.language
  const formRef: any = useRef()

  // Form validation schema
  const registerSchema = Yup.object().shape({
    firstName: Yup.string().min(1, t('Yup.string.min', { length: 1 })),
    lastName: Yup.string().min(1, t('Yup.string.min', { length: 1 })),
    email: Yup.string().email(t('Yup.string.emailInvalid')),
    password: Yup.string().min(8, t('Yup.string.min', { length: 8 }))
      .matches(/^\S*(?=\S{8,})(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[\d])\S*$/, t('RegisterScreen.errors.updatePasswordDetail')),
    sponsorCode: Yup.string().min(6, t('Yup.string.min', { length: 6 })),
    personalData: Yup.boolean().oneOf([true], t('RegisterScreen.errors.personalDataError')),
    isNotificationAllowed: Yup.boolean().required(t('Yup.mixed.required')),
  })

  // Form initial values
  const initialValues = { lastName: '', firstName: '', email: '', password: '', sponsorCode: '', personalData: true, isNotificationAllowed: false }

  const [inputRefs, focusNextInputWithName] = useInputRefs(initialValues)

  // Register call
  const [registerMutation, { loading }] = useMutation(
    graphql`
      mutation RegisterScreenMutation($email: String!, $password: String!, $registrationSource: String!, $discoveredAppVersion: String!, $lang: UserLang!, $firstName: String!, $lastName: String!, $sponsorCode: String!, $deviceId: String!, $isNotificationAllowed: Boolean = false) {
        UserRegistration(email: $email, password: $password, registrationSource: $registrationSource, discoveredAppVersion: $discoveredAppVersion, lang: $lang, firstName: $firstName, lastName: $lastName, sponsorCode: $sponsorCode, deviceId: $deviceId, isNotificationAllowed: $isNotificationAllowed) {
          id
          rowId
          jwtToken
          refreshJwtToken
          email
          firstName
          lastName
          lang
          creditsBalance
          cashbackWallet {
            activeAmount
          }
          companyQorner {
            id
            rowId
            pictureURL
          }
        }
      }
    `,
    {
      onCompleted: ({ UserRegistration }) => {

        // Store authentication tokens and info about current user qorner
        UserStore.update(s => {
          s.jwtToken = UserRegistration.jwtToken
          s.refreshJwtToken =  UserRegistration.refreshJwtToken
          s.currentUserHaveQorner = false
          s.currentUserEmail = UserRegistration.email
          s.currentUserFullname = (UserRegistration.firstName + ' ' + UserRegistration.lastName).trim()
        })

      },
      onError: (errors) => {
        // Handle errors
        for(let error of errors) {
          switch (error.errorCode) {
            case '130001': formRef.current.setFieldError('password', t('RegisterScreen.errors.malformedCredentials'))
              break
            case '130002': formRef.current.setFieldError('email', t('RegisterScreen.errors.emailAlreadyUsed'))
              break
            case '130003': formRef.current.setFieldError('general', t('RegisterScreen.errors.createAccountFailed'))
              break
            case '130004': formRef.current.setFieldError('email', t('RegisterScreen.errors.emailFormatNotValid'))
              break
            case '146005': formRef.current.setFieldError('sponsorCode', t('RegisterScreen.errors.sponsorCodeNotValid'))
              break
            case '130005': formRef.current.setFieldError('firstName', t('RegisterScreen.errors.missingFirstName'))
              break
            case '130006': formRef.current.setFieldError('lastName', t('RegisterScreen.errors.missingLastName'))
              break
            default: formRef.current.setFieldError('general', t('RegisterScreen.errors.createAccountFailed'))
              break
          }
        }
      }
    },
  )

  // Render screen
  return (
    <PublicLayout navigation={navigation} loginCompanion={true}>
      <View style={[
        s.mainContainer,
        !isMobile && s[`mainContainer${screenSize}`]
      ]}>

        {/* Register form */}
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={registerSchema}
          onSubmit={values => {
            registerMutation({ variables: {
              ...values,
              registrationSource: Platform.OS,
              discoveredAppVersion: VERSION,
              lang: currentUserLanguage.toUpperCase(),
              deviceId: Constants.installationId
            }})
          }}
        >
          {(formikProps) => (
            <View style={[s.formContainer, !isMobile && s.formContainerDesktop]}>
              <View>

                {/* LastName */}
                <TextInputFormik
                  innerRef={inputRefs['lastName']}
                  style={[s.textInput, isDesktop && s.textInputDesktop]}
                  name={'lastName'}
                  placeholder={t('RegisterScreen.placeholders.lastName')}
                  textContentType={'familyName'}
                  hasError={formikProps.errors.lastName && formikProps.touched.lastName}
                  icon={'profile'}
                  onSubmitEditing={() => focusNextInputWithName('firstName')}
                  outsideIcon={true}
                  size={isDesktop ? 'XL' : 'M'}
                  iconSize={isDesktop ? 42 : 18}
                  returnKeyType={'next'}
                  {...formikProps}
                />
                {formikProps.errors.lastName && formikProps.touched.lastName && (
                  <Label style={[s.error, isDesktop && s.errorDesktop]} size={isDesktop ? 16 : 8}>
                    {formikProps.errors.lastName}
                  </Label>
                )}

                {/* FirstName */}
                <TextInputFormik
                  innerRef={inputRefs['firstName']}
                  style={[s.textInput, isDesktop && s.textInputDesktop]}
                  name={'firstName'}
                  placeholder={t('RegisterScreen.placeholders.firstName')}
                  textContentType={'givenName'}
                  hasError={formikProps.errors.firstName && formikProps.touched.firstName}
                  icon={'profile'}
                  onSubmitEditing={() => focusNextInputWithName('email')}
                  outsideIcon={true}
                  size={isDesktop ? 'XL' : 'M'}
                  iconSize={isDesktop ? 42 : 18}
                  returnKeyType={'next'}
                  {...formikProps}
                />
                {formikProps.errors.firstName && formikProps.touched.firstName && (
                  <Label style={[s.error, isDesktop && s.errorDesktop]} size={isDesktop ? 16 : 8}>
                    {formikProps.errors.firstName}
                  </Label>
                )}

                {/* Email */}
                <TextInputFormik
                  innerRef={inputRefs['email']}
                  style={[s.textInput, isDesktop && s.textInputDesktop]}
                  name={'email'}
                  placeholder={t('RegisterScreen.placeholders.email')}
                  textContentType={'emailAddress'}
                  autoCompleteType={'email'}
                  hasError={formikProps.errors.email && formikProps.touched.email}
                  icon={'email'}
                  onSubmitEditing={() => focusNextInputWithName('password')}
                  outsideIcon={true}
                  size={isDesktop ? 'XL' : 'M'}
                  iconSize={isDesktop ? 46 : 19}
                  returnKeyType={'next'}
                  autoCorrect={false}
                  {...formikProps}
                />
                {formikProps.errors.email && formikProps.touched.email && (
                  <Label style={[s.error, isDesktop && s.errorDesktop]} size={isDesktop ? 16 : 8}>
                    {formikProps.errors.email}
                  </Label>
                )}

                {/* Password */}
                <TextInputFormik
                  innerRef={inputRefs['password']}
                  style={[s.textInput, isDesktop && s.textInputDesktop]}
                  name={'password'}
                  placeholder={t('RegisterScreen.placeholders.password')}
                  autoCompleteType={'password'}
                  textContentType={'password'}
                  secureTextEntry={true}
                  hasError={formikProps.errors.password && formikProps.touched.password}
                  icon={'lock'}
                  onSubmitEditing={() => focusNextInputWithName('sponsorCode')}
                  outsideIcon={true}
                  size={isDesktop ? 'XL' : 'M'}
                  iconSize={isDesktop ? 46 : 19}
                  returnKeyType={'next'}
                  {...formikProps}
                />
                {formikProps.errors.password && formikProps.touched.password && (
                  <Label style={[s.error, isDesktop && s.errorDesktop]} size={isDesktop ? 16 : 8}>
                    {formikProps.errors.password}
                  </Label>
                )}

                {/* Affiliation code */}
                <TextInputFormik
                  innerRef={inputRefs['sponsorCode']}
                  style={[s.textInput, isDesktop && s.textInputDesktop]}
                  name={'sponsorCode'}
                  placeholder={t('RegisterScreen.placeholders.sponsorCode')}
                  hasError={formikProps.errors.sponsorCode && formikProps.touched.sponsorCode}
                  icon={'sales'}
                  iconSize={isDesktop ? 25 : 10}
                  styles={{ icon: { top: 10 } }}
                  onSubmitEditing={formikProps.handleSubmit}
                  outsideIcon={true}
                  size={isDesktop ? 'XL' : 'M'}
                  {...formikProps}
                />
                {formikProps.errors.sponsorCode && formikProps.touched.sponsorCode && (
                  <Label style={[s.error, isDesktop && s.errorDesktop]} size={isDesktop ? 16 : 8}>
                    {formikProps.errors.sponsorCode}
                  </Label>
                )}

                {/* Personal data acceptation */}
                <View style={s.switchContainer}>
                  <SwitchInputFormik size={isDesktop ? 'L' : 'S'} {...formikProps} name={'personalData'}/>
                  <Label style={s.switchLabel} size={isDesktop ? 18 : 10}>
                    {t('RegisterScreen.labels.personalData')}
                    {' ('}
                    <ExternalLink
                      url={'https://www.quatreepingles.fr/politique-de-confidentialite/'}
                      title={t('RegisterScreen.labels.cgu')}
                      styles={{ title: [s.switchLink, isDesktop && s.switchLinkDesktop] }}
                    />
                    {')'}
                  </Label>
                </View>
                {formikProps.errors.personalData && (
                  <Label style={s.error} size={isDesktop ? 16 : 8}>{formikProps.errors.personalData}</Label>
                )}

                {/* isNotificationAllowed acceptation */}
                <View style={s.switchContainer}>
                  <SwitchInputFormik size={isDesktop ? 'L' : 'S'} {...formikProps} name={'isNotificationAllowed'}/>
                  <Label style={s.switchLabel}
                         size={isDesktop ? 18 : 10}>{t('RegisterScreen.labels.isNotificationAllowed')}</Label>
                </View>
                {formikProps.errors.isNotificationAllowed && formikProps.touched.isNotificationAllowed && (
                  <Label style={s.error} size={isDesktop ? 16 : 8}>{formikProps.errors.isNotificationAllowed}</Label>
                )}

              </View>

              {/* General error */}
              {formikProps.errors.general && (
                <Label style={[s.error, s.generalError]} size={!isDesktop ? 8 : 16}>{formikProps.errors.general}</Label>
              )}

              {/* Submit register and go to login button */}
              <View style={[s.buttonContainer, isDesktop ? { marginTop: 30 } : { marginTop: 10 }]}>
                <Button style={s.actionButton}
                        onPress={formikProps.handleSubmit}
                        palette={'primary'}
                        size={isDesktop ? 'L' : 'M'}
                        isLoading={loading}
                        disabled={!(formikProps.isValid && formikProps.dirty)}>
                  {t('RegisterScreen.labels.register')}
                </Button>
              </View>
            </View>

          )}
        </Formik>
      </View>

      {isMobile && (
        <Pressable
          style={s.loginButton}
          onPress={() => navigation.navigate({ name: 'Login' })}
        >
          <Label size={12} style={{textDecorationLine: 'underline'}}>
            <Trans
              i18nKey={'RegisterScreen.labels.login'}
              components={{ b: <Label type={'bold'} size={12}/> }}
            />
          </Label>
        </Pressable>
      )}
    </PublicLayout>
  )
}

// PropsTypes
type PropsTypes = {
  navigation: StackNavigationProp<RoutesParamList, 'Register'>
}