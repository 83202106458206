import {Environment, RecordSource, Store} from 'relay-runtime';
import {
  RelayNetworkLayer,
  urlMiddleware,
  authMiddleware,
  cacheMiddleware,
  uploadMiddleware,
} from 'react-relay-network-modern';
import UserStore from 'src/store/UserStore';
import {EQQI_API_PRIVATE_ENDPOINT} from 'src/environment';

// Custom network layer for relay
const networkLayerProvider = (jwtToken, refreshJwtToken) => new RelayNetworkLayer(
  [
    cacheMiddleware({
      size: 100, // max 100 requests
      ttl: 900000, // 15 minutes
    }),
    urlMiddleware({
      url: EQQI_API_PRIVATE_ENDPOINT,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-QE-SKI': 'tatatata'
      }
    }),
    //__DEV__ ? loggerMiddleware() : null,
    //__DEV__ ? errorMiddleware() : null,
    //__DEV__ ? perfMiddleware() : null,
    // retryMiddleware({
    //   fetchTimeout: 15000,
    //   retryDelays: (attempt) => Math.pow(2, attempt + 4) * 100, // or simple array [3200, 6400, 12800, 25600, 51200, 102400, 204800, 409600],
    //   beforeRetry: ({ forceRetry, abort, delay, attempt, lastError, req }) => {
    //     if (attempt > 10) abort();
    //     window.forceRelayRetry = forceRetry;
    //     console.log(attempt)
    //     console.log(lastError)
    //     console.log(req)
    //     console.log('call `forceRelayRetry()` for immediately retry! Or wait ' + delay + ' ms.');
    //   },
    //   statusCodes: [500, 503, 504],
    // }),
    authMiddleware({
      token: () => new Promise(resolve => resolve(jwtToken)),
      tokenRefreshPromise: (req, res) => {

        // I don't know why this function is called at first request.. so we must return a null refresh jwt token
        if(!res || res.status !== 401) {
          return Promise.resolve(null)
        }

        // Try to refresh jwt token
        const query = JSON.stringify({
          query: `mutation {
            UserRefreshLogin(refreshJwtToken: "${refreshJwtToken}") {
              id
              jwtToken
              refreshJwtToken
            }
          }`
        })
        return fetch(EQQI_API_PRIVATE_ENDPOINT, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: query
        })
          .then(res => res.json())
          .then(res => {

            // Expired authentication
            if(res.errors) {
              UserStore.update(s => {
                s.jwtToken = null
                s.refreshJwtToken = null
              })
              return Promise.reject('Expired authentification')
            }

            // Success refresh ! Store JWT token of user and his refresh token, then resolve !
            UserStore.update(s => {
              s.jwtToken = res.data.UserRefreshLogin.jwtToken
              s.refreshJwtToken = res.data.UserRefreshLogin.refreshJwtToken
            })
            resolve(res.data.UserRefreshLogin.jwtToken)

          })
          .catch((err) => console.log('[client.js] ERROR can not refresh token', err))

      },
      allowEmptyToken: true
    }),
    /*progressMiddleware({
      onProgress: (current, total) => {
        console.log('Downloaded: ' + current + ' B, total: ' + total + ' B');
      },
    }),*/
    uploadMiddleware(),

    /* ERROR HANDLER */
    (next) => async (req) => {
      const res = await next(req)

      if (res && res.errors && res.errors.length > 0) {
        throw res.errors
      }

      return res
    },
  ]
)

// Export a singleton instance of Relay Environment configured with our network function:
export default (jwtToken, refreshJwtToken) => new Environment({
  network: networkLayerProvider(jwtToken, refreshJwtToken),
  store: new Store(new RecordSource())
})
