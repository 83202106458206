/*  App fonts */

// Primary font
export const primary = {
  regular: 'Raleway',
  bold: 'Raleway-Bold',
  light: 'Raleway-Light',
  medium: 'Raleway-Medium',
  semiBold: 'Raleway-SemiBold'
}

// Secondary font
export const secondary = {
  regular: 'Quicksand',
  bold: 'Quicksand-Bold',
  light: 'Quicksand-Light'
}

