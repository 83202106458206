import { StyleSheet } from 'react-native'
import { Colors, Sizes } from 'src/styles'

// Styles
export default StyleSheet.compose({
  container: {
    display: 'flex',
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: Colors.mediumGrey100,
    width: 140,
    padding: 2,
    borderRadius: Sizes.formElementBorderRadius,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  button: {
    width: 40,
    height: 40,
    borderRadius: 40,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
    paddingLeft: 0,
    borderWidth: 1
  },
  label: {
    fontWeight: 'bold',
    fontSize: 22,
    lineHeight: 26,
    color: Colors.darkGrey100
  },
  containerM: {
    width: 100
  },
  containerS: {
    width: 50,
    height:20
  },
  buttonM: {
    height: 30,
    width: 30,
  },
  buttonS: {
    height: 16,
    width: 16,
  },
  labelM: {
    fontSize: 18,
    lineHeight: 24
  },
  labelS: {
    fontSize: 12,
    lineHeight: 14
  }
})