import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  title: {
    marginBottom:36
  },
  loader: {
    marginBottom:36
  },
  icon: {
    alignSelf: 'center',
    marginBottom: 36
  },
  buttonContainer: {
    marginLeft: 15,
    marginRight: 15
  },
  selectInput: {
    marginTop:30,
    marginBottom:30
  },
  selectSubmit: {
    marginBottom: 25,
    minWidth: 123
  }
})

export const modalLockerConfigStyle = StyleSheet.create({
  title: {
    marginBottom: 20
  },
  configInfo: {
    marginBottom: 10
  },
  configDetail: {
    marginBottom: 10
  },
  buttonContainer: {
    marginLeft: 35,
    marginRight: 35,
    marginBottom: 20
  }
})