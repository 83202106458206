import { StyleSheet } from 'react-native'
import { Sizes } from 'src/styles'

// Styles
export default StyleSheet.create({
  // Containers
  container: {
    backgroundColor: '#fff',
  },
  cardContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    margin: Sizes.S
  },

  // Image
  image: {
    borderRadius: 4
  },
  imageM: { width: 36, height: 36, marginRight: 5 },
  imageL: { width: 60, height: 60, marginRight: 10 },
  imageXL: { width: 60, height: 60, marginRight: 10 },
  dateImage: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 10
  },
  dateDay: {
    lineHeight: 21,
    zIndex: 2
  },
  dateMonth: {
    lineHeight: 10,
    zIndex: 2
  },

  textContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    flex: 1
  },

  // Button
  buttonContainer: {
    flex: 1,
    marginTop: 3
  },

  // Gradient
  gradient: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
    borderRadius: 5
  }
})

// Shadow config
export const shadow = {
  M: { width: 216, height: 50 },
  L: { width: 360, height: 80 },
  XL: { width: 485, height: 80 }
}

export const gradientColors = ['rgba(48, 48, 48, 0)', 'rgba(48, 48, 48, 0.7)']