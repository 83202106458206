// @flow
import React, { type Node } from 'react'
import { StyleSheet, View } from 'react-native'
import { type StackNavigationProp } from '@react-navigation/stack'
import { useTranslation } from 'react-i18next'
import type { RoutesParamList } from 'src/components/Navigation'
import useScreenSizes from 'src/utils/useScreenSizes'
import PrivateLayout from 'src/components/common/layout/PrivateLayout'
import ProfileMenu from 'src/components/common/moles/ProfileMenu/ProfileMenu'
import { Colors } from 'src/styles'

/**
 * ProfileMenuScreen screen.
 */
export default function ProfileMenuScreen({ navigation }: PropsTypes) : Node {

  // Init
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()

  // If desktop, redirect to ProfileInformations Screen
  if (!isMobile) {
    navigation.navigate({'name': 'PersonalInformations'})
  }

  // Render screen
  return (
    <PrivateLayout headerTitle={t('MenuDrawerContent.title.profile')}>
      <View style={s.container}>
        <ProfileMenu navigation={navigation}/>
      </View>
    </PrivateLayout>
  )
}

// PropsTypes
type PropsTypes = {
  navigation: StackNavigationProp<RoutesParamList, 'ProfileMenu'>
}

// Styles
const s = StyleSheet.create({
  container: {
    position: 'relative',
    flex: 1,
    height: 'auto',
    justifyContent: 'flex-start',
    backgroundColor: Colors.white100,
    marginTop: 10
  }
})