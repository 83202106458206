// @ flow
import React, { type Node, useState } from 'react'
import { View } from 'react-native'
import { useMutation, useQuery } from 'relay-hooks'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import * as  Yup from 'yup'
import { Formik } from 'formik'
import { formatPrice } from 'src/utils/FormatDataUtils'
import useScreenSizes from 'src/utils/useScreenSizes'
import Icon from 'src/components/common/atoms/Icon'
import Label from 'src/components/common/atoms/Label'
import Loader from 'src/components/common/atoms/Loader'
import Button from 'src/components/common/atoms/Button'
import SelectInputFormik from 'src/components/common/forms/SelectInputFormik'
import { Colors } from 'src/styles'
import modalStyle from './SelectDeliveryDateModal.style'
import { useFocusScreenEffect } from 'src/utils/ComponentUtils'

/**
 * SelectDeliveryDateModal
 */
export default function SelectDeliveryDateModal (): Node {

  // Init values
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [refetchKey, setRefetchKey] = useState(new Date())

  // Refetch basketAvailableCollectDates on focus screen
  useFocusScreenEffect(() => {
    setRefetchKey(new Date())
  })

  // Fetch available dates
  const { data } = useQuery(graphql`
    query SelectDeliveryDateModalQuery {
      basketAvailableDeliveryDates {
        date
        price
      }
    }
    `,
    {},
    {
      fetchPolicy: 'network-only',
      fetchKey: refetchKey,
      networkCacheConfig: {
        force: true,
      },
    }
  )
  const availableDeliveryDates = data?.basketAvailableDeliveryDates

  // Set the basket delivery date
  const [setDeliveryDateForBasket, { loading }] = useMutation(
    graphql`
      mutation SelectDeliveryDateModalMutation($date: Date!) {
        SetDeliveryDateOfBasket(date: $date) {
          id
          rowId
          amount
          deliverySettings {
            collectDate
            deliveryDate
            price
            collectLocker {
              name
            }
            deliveryLocker {
              name
            }
          }
        }
      }
    `,
    {
      onCompleted: () => setSuccess(true),
      onError: () => setError(true)
    })

  const availableChoices = availableDeliveryDates?.map((date, index) => ({
    label: `${moment(date.date).format('DD MMMM')} - ${formatPrice(date.price? date.price: 0)}`,
    value: index.toString()
  }))

  return (
    <View>
      <Label style={modalStyle.title} size={!isMobile ? 16 : 14}>{t('BasketScreen.delivery.chooseDeliveryDate')}</Label>

      {!availableDeliveryDates || loading ? (
        <Loader style={modalStyle.loader}/>
      ) : success ? (
        <Icon color={Colors.orange100} name={'check'} style={modalStyle.icon}/>
      ) : error ? (
        <Icon color={Colors.orange100} name={'close'} style={modalStyle.icon}/>
      ) : (

        <Formik
          initialValues={{ availableDateIndex: null }}
          validationSchema={Yup.object().shape({
            availableDateIndex: Yup.number().required(t('Yup.mixed.required'))
          })}
          onSubmit={({ availableDateIndex }) => {
            setDeliveryDateForBasket({ variables: { date: availableDeliveryDates[availableDateIndex].date } })
          }}
        >
          {(formikProps) => (
            <>
              <SelectInputFormik
                style={modalStyle.selectInput}
                name={'availableDateIndex'}
                items={availableChoices}
                fullWidth
                {...formikProps}
              />

              <Button
                style={modalStyle.selectSubmit}
                size={'M'}
                onPress={formikProps.handleSubmit}
                palette={'primary'}
                fontStyle={'bold'}
                disabled={!(formikProps.isValid && formikProps.dirty)}
              >
                {t('App.choose')}
              </Button>
            </>
          )}
        </Formik>
      )}
    </View>
  )
}