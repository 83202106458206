/**
 * @generated SignedSource<<93ecd2238abb99f9f7f30ed87e85560b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "payableId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "payableId",
        "variableName": "payableId"
      },
      {
        "kind": "Variable",
        "name": "type",
        "variableName": "type"
      }
    ],
    "kind": "ScalarField",
    "name": "LockForPayment",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdyenPaymentFormLockPaymentMutation",
    "selections": (v2/*: any*/),
    "type": "ClientAppMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AdyenPaymentFormLockPaymentMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "890862e9338b0588b933fbac6fccdfc1",
    "id": null,
    "metadata": {},
    "name": "AdyenPaymentFormLockPaymentMutation",
    "operationKind": "mutation",
    "text": "mutation AdyenPaymentFormLockPaymentMutation(\n  $type: PayableType!\n  $payableId: Int\n) {\n  LockForPayment(type: $type, payableId: $payableId)\n}\n"
  }
};
})();

node.hash = "5a7f3ddcf579481471f297e62e7849ff";

module.exports = node;
