// @flow
import React, { type Node, useRef, useState } from 'react'
import { TextInput, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { useMutation } from 'relay-hooks'
import * as Yup from 'yup'
import { type StackNavigationProp } from '@react-navigation/stack'
import useScreenSizes from 'src/utils/useScreenSizes'
import type { RoutesParamList } from 'src/components/Navigation'
import PrivateLayoutProfile from 'src/components/common/layout/PrivateLayoutProfile'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import TextInputFormik from 'src/components/common/forms/TextInputFormik'
import Icon from 'src/components/common/atoms/Icon'
import s from './ProfileSecurityScreen.style'
import { Colors } from 'src/styles'
import useInputRefs from 'src/utils/useInputRefs'

/**
 * ProfileSecurityScreen screen.
 */
export default function ProfileSecurityScreen({
  navigation
}: PropsTypes) : Node {

  // Init state
  const { t } = useTranslation()
  const { isMobile, isDesktop, isTablet } = useScreenSizes()
  const formRef: any = useRef()
  const [success, setSuccess] = useState(false)

  // Form validation schema
  const schema = Yup.object().shape({
    password: Yup.string().min(8, t('Yup.string.min', { length: 8 }))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{4,}$/, t('ProfileSecurityScreen.errors.updatePasswordDetail')),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], t('ProfileSecurityScreen.errors.passwordMismatch'))
  })

  // UpdatePassword mutation API call
  const [updatePasswordMutation, { loading }] = useMutation(
    graphql`
      mutation ProfileSecurityScreenMutation($password: String!, $passwordConfirmation: String!) {
        UpdatePassword(password: $password, passwordConfirmation: $passwordConfirmation) {
          id
          rowId
        }
      }
    `,
    {
      onCompleted: () => {
        // Update state to show success content
        formRef.current.resetForm()
        setSuccess(true)
      },
      onError: () => {
        // Treat any error as global error
        formRef.current.setFieldError('passwordConfirmation', t('Yup.global.errorTryAgain'))
      }
    },
  )

  const initialValues = { password: '', passwordConfirmation: '' }

  const [inputRefs, focusNextInputWithName] = useInputRefs(initialValues)

  // Render screen
  return (
    <PrivateLayoutProfile
      navigation={navigation}
      subHeaderIconName={'lock'}
      subHeaderTitle={t('MenuDrawerContent.links.security')}
    >
      <View style={[s.container, isDesktop && s.desktopContainer]}>

        {/* Desktop title */}
        {!isMobile && (
          <View style={s.desktopTitle}>
            <Icon name={'lock'} size={isTablet ? 24 : 40} color={Colors.mediumGrey100}/>
            <Label size={isTablet ? 22 : 38} style={{ marginLeft: 20 }}>{t('ProfileSecurityScreen.title')}</Label>
          </View>
        )}

        {/* Intro */}
        <Label size={isMobile ? 10 : isTablet ? 14 : 22}>
          {t('ProfileSecurityScreen.labels.intro')}
        </Label>

        {/* Update password form */}
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={schema}
          onSubmit={values => {
            setSuccess(false)
            updatePasswordMutation({ variables : values })
          }}
        >
          {(formikProps) => (
            <View style={s.form && isDesktop && s.desktopFormContainer}>

              {/* Mandatory or else browser autocomplete the searchbar */}
              {/* Remove at your own risk :) */}
              <TextInput style={{ width: 0, height: 0 }}/>

              {/* New password field */}
              <TextInputFormik
                innerRef={inputRefs['password']}
                style={[s.textInput, { marginTop: 30 }, isDesktop && s.textInputDesktop]}
                size={!isDesktop ? 'M' : 'L'}
                name={'password'}
                placeholder={t('ProfileSecurityScreen.placeholders.password')}
                secureTextEntry={true}
                hasError={formikProps.errors.password && formikProps.touched.password}
                icon={'lock'}
                outsideIcon={true}
                onSubmitEditing={() => focusNextInputWithName('passwordConfirmation')}
                returnKeyType={'next'}
                iconSize={!isDesktop ? 25 : 34}
                {...formikProps}
              />
              {formikProps.errors.password && formikProps.touched.password && (
                <Label style={[s.error, !isMobile && s.errorDesktop]} size={!isDesktop ? 10 : 16}>
                  {formikProps.errors.password}
                </Label>
              )}

              {/* New password confirmation */}
              <TextInputFormik
                innerRef={inputRefs['passwordConfirmation']}
                style={[s.textInput, isDesktop && s.textInputDesktop]}
                size={!isDesktop ? 'M' : 'L'}
                name={'passwordConfirmation'}
                placeholder={t('ProfileSecurityScreen.placeholders.passwordConfirmation')}
                secureTextEntry={true}
                hasError={formikProps.errors.passwordConfirmation && formikProps.touched.passwordConfirmation}
                icon={'lock'}
                outsideIcon={true}
                onSubmitEditing={formikProps.handleSubmit}
                iconSize={!isDesktop ? 25 : 34}
                {...formikProps}
              />
              {formikProps.errors.passwordConfirmation && formikProps.touched.passwordConfirmation && (
                <Label style={[s.error, !isMobile && s.errorDesktop]} size={!isDesktop ? 10 : 16}>
                  {formikProps.errors.password}
                </Label>
              )}

              {success &&
                <Label types={['success']} style={s.labelSuccess} size={isDesktop ? 16 : 14}>
                  {t('ProfileSecurityScreen.labels.success')}
                </Label>}

              {/* Submit button */}
              <Button
                style={[s.submitButton, !isMobile && s.submitButtonDesktop]}
                size={!isDesktop ? 'M' : 'L'}
                onPress={formikProps.handleSubmit}
                palette={'primary'}
                isLoading={loading}
                disabled={!(formikProps.isValid && formikProps.dirty)}
              >
                {t('ProfileSecurityScreen.labels.submit')}
              </Button>

            </View>
          )}
        </Formik>
      </View>
    </PrivateLayoutProfile>
  )
}

// PropsTypes
type PropsTypes = {
  navigation: StackNavigationProp<RoutesParamList, 'Security'>
}
