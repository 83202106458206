import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

// Styles
export default StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    padding: 20,
    maxWidth: 570
  },
  headerContainerDesktop: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30
  },
  headerTitleDesktop: {
    marginLeft: 20
  },
  currentCashbackContainer: {
    flexDirection: 'row'
  },
  currentCashbackContainerDesktop: {
    marginTop: 30
  },
  currentCashbackSubContainer: {
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: 28
  },
  currentCashbackTitle: {
    marginBottom: 10
  },
  tableTitle: {
    marginTop: 40
  },
  tableTitleDesktop: {
    marginTop: 30
  },
  table: {
    marginTop: 10
  },
  tableDesktop: {
    marginTop: 30
  },
  tableHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderWidth: 1,
    borderColor: Colors.darkGrey100
  },
  tableRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: Colors.darkGrey100
  },
  tableRowEmpty: {
    flex: 1,
    minHeight: 25,
    padding: 6,
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: Colors.darkGrey100
  },
  tableRowEmptyLabel: {
    textAlign: 'center'
  },
  tableCell: {
    flex: 1,
    borderRightWidth: 1,
    borderColor: Colors.darkGrey100,
    padding: 6
  },
  tableCellDesktop: {
    paddingVertical: 8,
    paddingHorizontal: 10
  },
  tableLastCell: {
    flex: 1,
    padding: 6
  },
  seeMoreButton: {
    marginTop: 30
  },
  seeMoreButtonDesktop: {
    marginTop: 60
  }
})