import React, { type Node, useState, useEffect } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'relay-hooks'
import SkeletonContent from 'react-native-skeleton-content-nonexpo'
import moment from 'moment'
import useScreenSizes from 'src/utils/useScreenSizes'
import Icon from 'src/components/common/atoms/Icon'
import Loader from 'src/components/common/atoms/Loader'
import Button from 'src/components/common/atoms/Button'
import Label from 'src/components/common/atoms/Label'
import DepositQuestionModalContent from './DepositQuestionModalContent'
import DepositBadgeModalContent from './DepositBadgeModalContent'
import DepositReadyModalContent from './DepositReadyModalContent'
import DepositCancelModalContent from './DepositCancelModalContent'
import s, { modalSkeletonLayout } from './CoverCollectModal.style'
import { Colors } from 'src/styles'
import GlobalRefetchStore from 'src/store/GlobalRefetchStore'

/**
 * CoverDepositModal component
 */
export default function CoverCollectModal ({
  onClose,
  setOnCloseParam,
  setAllowClosing,
  orderId
}: PropsTypes): Node {

  // API Call
  const { data } = useQuery(graphql`
    query CoverCollectModalQuery($id: Int!) {
      user {
        id
        lang
      }
      order(id: $id) {
        id
        rowId
        collectDate
        status
        createdAt
        collectLocker {
          name
          lockerType
        }
      }
    }
  `, { id: orderId })

  // Init vars
  moment.locale(data?.user?.lang)
  const order = data?.order
  const lockerType = order?.collectLocker.lockerType
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const steps = {
    DEPOSIT_QUESTION: 'DEPOSIT_QUESTION',
    DEPOSIT_BADGE: 'DEPOSIT_BADGE',
    DEPOSIT_DONE: 'DEPOSIT_DONE',
    DEPOSIT_VALIDATION: 'DEPOSIT_VALIDATION',
    DEPOSIT_PROCESSING: 'DEPOSIT_PROCESSING',
    DEPOSIT_SUCCESSFUL: 'DEPOSIT_SUCCESSFUL',
    DEPOSIT_CANCEL: 'DEPOSIT_CANCEL',
    LOADING_ERROR: 'LOADING_ERROR',
  }
  const [step, setStep] = useState(steps.DEPOSIT_QUESTION)
  const [errorCode, setErrorCode] = useState(null)

  /*
  * Add "true" param to onClose when collect is successful
  * Triggers isDone param in handleCloseDeliverModal (ProfileOrdersScreen)
  */
  useEffect(() => {
    if (step === steps.DEPOSIT_SUCCESSFUL) {
      setOnCloseParam(true)
    }
  }, [step])

  /*
   * Global next step function
   */
  const nextStep = () => {
    switch (step) {
      case steps.DEPOSIT_QUESTION:
        setStep(steps.DEPOSIT_BADGE)
        break
      case steps.DEPOSIT_BADGE:
        setStep(steps.DEPOSIT_DONE)
        break
      case steps.DEPOSIT_DONE:
        setStep(steps.DEPOSIT_VALIDATION)
        break
      case steps.DEPOSIT_VALIDATION:
        setStep(steps.DEPOSIT_PROCESSING)
        break
      case steps.DEPOSIT_CANCEL:
        setStep(steps.DEPOSIT_PROCESSING)
        break
      case steps.DEPOSIT_PROCESSING:
        setStep(steps.DEPOSIT_SUCCESSFUL)
        break
    }
  }

  const [userSubmittedOrderMutation] = useMutation(
    graphql`
      mutation CoverCollectModalSubmitMutation($orderId: Int!) {
        UserSubmittedOrder(orderId: $orderId) {
          id
          status
          statusName
          allowedCustomerActions
          primeCustomerAction
        }
      }
    `,
    {
      onCompleted: () => {
        setStep(steps.DEPOSIT_SUCCESSFUL)
        setAllowClosing(true)
        GlobalRefetchStore.update(s => {
          s.bestServicesKey = new Date()
        })
      }
    }
  )

  // Handle last (fake) processing steps
  useEffect(() => {
    if (step === steps.DEPOSIT_PROCESSING) {
      setAllowClosing(false)
      setTimeout(() => {
        setStep(steps.DEPOSIT_SUCCESSFUL)
        setAllowClosing(true)
      }, 1000)
    }
  }, [step])

  const handleDeposit = () => {
    setStep(steps.DEPOSIT_PROCESSING)
    userSubmittedOrderMutation({ variables: { orderId } })
  }

  const handleError = (code = null) => {
    setErrorCode(code)
    setStep(steps.LOADING_ERROR)
  }

  /*
   * Render component
   */
  return (
    <View>

      <SkeletonContent
        containerStyle={(!order) ? { width: '100%', alignItems: 'center' } : {}}
        isLoading={!order}
        layout={modalSkeletonLayout}
      >
        <Label style={s.modalTitle} size={!isMobile ? 16 : 14}>{t('CoverDepositModal.title')}</Label>
        {step === steps.DEPOSIT_QUESTION &&
        <DepositQuestionModalContent nextStep={nextStep} onClose={onClose} type={lockerType}
                                     onCancel={() => setStep(steps.DEPOSIT_CANCEL)}/>
        }
        {step === steps.DEPOSIT_BADGE &&
        <DepositBadgeModalContent nextStep={nextStep} onClose={onClose} onCancel={() => setStep(steps.DEPOSIT_CANCEL)}/>
        }
        {step === steps.DEPOSIT_DONE &&
          <DepositReadyModalContent
            nextStep={nextStep}
            onClose={onClose}
            type={lockerType}
            handleDeposit={handleDeposit}
            orderRowId={order.rowId}
            onCancel={() => setStep(steps.DEPOSIT_CANCEL)}
            onError={handleError}
          />
        }
        {step === steps.DEPOSIT_CANCEL && (
          <DepositCancelModalContent
            nextStep={nextStep}
            onClose={onClose}
            collectDate={moment(order.collectDate).format('DD MMMM YYYY')}
          />
        )}
        {(step === steps.DEPOSIT_PROCESSING) &&
        <View style={s.processingContainer}>
          <Loader color={Colors.orange100} size={20}/>
        </View>
        }
        {step === steps.DEPOSIT_SUCCESSFUL &&
        <>
          <View style={s.processingContainer}>
            <Icon name={'check'} color={Colors.orange100} size={16}/>
          </View>
          <Button
            onPress={() => onClose(true)}
            palette={'primary'}
            size={isMobile ? 'S' : 'M'}
            style={s.successCloseButton}
          >
            {t('App.close')}
          </Button>
        </>
        }
        {step === steps.LOADING_ERROR &&
        <>
          <View style={s.processingContainer}>
            <Icon name={'close'} color={Colors.orange100} size={16} style={{marginBottom: 20}}/>
            {
              {
                232005: <Label size={!isMobile ? 16 : 14}>{t('CoverDepositModal.error.spartimeConflict')}</Label>
              }[errorCode]
              || <Label size={!isMobile ? 16 : 14}>{t('CoverDepositModal.error.global')}</Label>
            }
          </View>
          <Button
            onPress={onClose}
            palette={'primary'}
            size={isMobile ? 'S' : 'M'}
            style={s.successCloseButton}
          >
            {t('App.close')}
          </Button>
        </>
        }
      </SkeletonContent>

    </View>
  )
}

type PropsTypes = {
  orderId: number
}