import React, { useState, useEffect } from 'react'
import { Dimensions, Platform } from 'react-native'

/**
 * Get screen sizes as state which change on dimensions change.
 *
 * @returns {{isDesktop: boolean, screenSize: string, isTablet: boolean, width: integer, isMobile: boolean, height: integer}}
 */
export default function useScreenSizes() {

  // Init state
  const screen = Platform.OS === 'web' ? 'window' : 'screen'
  const [screenSizes, setScreenSizes] = useState(buildScreenSizesState(
    Dimensions.get(screen).width,
    Dimensions.get(screen).height
  ))

  // Update screen sizes info on dimensions change
  const onDimensionsChange = ({ window }) => {
    setScreenSizes(buildScreenSizesState(window.width, window.height));
  }

  // Listen for screen dimensions change
  useEffect(() => {
    const eventDim = Dimensions.addEventListener('change', onDimensionsChange)
    return () => {
      eventDim?.remove()
    }
  })

  // Return dimensions info
  return screenSizes

}

/**
 * Build state for screen size.
 */
function buildScreenSizesState(width, height) {
  const screenSize = screenSizeFromWidth(width);
  return {
    width: width,
    height: height,
    screenSize: screenSize,
    isDesktop: screenSize === 'Desktop',
    isTablet: screenSize === 'Tablet',
    isMobile: screenSize === 'Mobile'
  }
}

/**
 * Responsive design info, in which size we need to display the screen ?
 * Breakpoints to implements : - Desktop : >= 1600px - Tablette : < 1600px & >= 768px - Mobile : < 768px
 */

// "Quickwin" so the app on iPad can pass verification on the app store
const tabletMinWidth = Platform.OS === "ios" ? 1280 : 768

function screenSizeFromWidth(width) {
  if(width >= 1280) {
    return 'Desktop';
  }
  else if(width >= tabletMinWidth) {
    return 'Tablet';
  }
  else {
    return 'Mobile';
  }
}