import React, { type Node } from 'react'
import { View, Pressable, Image } from 'react-native'
import s from './StarsInputFormik.style'

/**
 * StarsInputFormik component
 */
export default function StarsInputFormik ({
  style,
  styles,
  name,
  values,
  setFieldValue,
}: PropsTypes): Node {
  /*
   * Handle pressed star
   */
  const handlePress = i => {
    setFieldValue(name, i + 1)
  }

  /*
   * Render component
   */
  return (
    <View style={[s.container, style, styles?.container]}>
      {[...Array(5)].map((e, i) => (
        <Pressable
          key={i}
          onPress={() => handlePress(i)}
        >
          <Image
            style={s.star}
            source={
              i < values[name]
                ? require('assets/img/common/filled_star.png')
                : require('assets/img/common/empty_star.png')
            }
          />
        </Pressable>
      ))}
    </View>
  )
}

type PropsTypes = {
  style?: any,
  styles?: any
}