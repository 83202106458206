// @flow
import React, { type Node, useState } from 'react'
import { Image, View } from 'react-native'
import { Trans, useTranslation } from 'react-i18next'
import { formatPrice } from 'src/utils/FormatDataUtils'
import useScreenSizes from 'src/utils/useScreenSizes'
import Icon from 'src/components/common/atoms/Icon'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import AdyenPaymentForm from 'src/components/common/moles/AdyenPaymentForm/AdyenPaymentForm'
import { Colors } from 'src/styles'
import { payWithNewCardModalContentStyle as s } from './PayModal.style'
import GlobalRefetchStore from 'src/store/GlobalRefetchStore'

export default function PayWithNewCardModalContent ({
  amount,
  onClose,
  setPaidBasket,
  setModalStep,
  payableType = 'basket',
  payableId = null,
  setAllowClosing = () => {}
}: PropsTypes): Node {

  // init values
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const [success, setSuccess] = useState(false)

  // Handle native payment completed
  const handlePaymentCompleted = () => {
    setModalStep()
    setSuccess(true)
    setPaidBasket(true)
    GlobalRefetchStore.update(s => {
      s.bestServicesKey = new Date()
    })
  }

  return (
    <>
      <Label size={!isMobile ? 16 : 14}>{t('PayBasketModal.PayWithNewCardModalContent.title')}</Label>

      {success ? (
        <View style={s.iconContainer}>
          <Icon name={'check'} color={Colors.orange100}/>
        </View>
      ) : (
        <>
          <Label size={isMobile ? 10 : 12} style={s.amountToPay}>
            <Trans
              i18nKey={'PayBasketModal.toPay'}
              values={{ amount: formatPrice(amount) }}
              components={{ b: <Label type={'bold'} size={isMobile ? 10 : 12}/> }}
            />
          </Label>

          <AdyenPaymentForm
            amount={amount}
            onCompleted={handlePaymentCompleted}
            payableType={payableType}
            payableId={payableId}
            setAllowClosing={setAllowClosing}
          />

          <Button onPress={onClose} size={'M'} fullWidth>{t('PayBasketModal.changePaymentMethod')}</Button>

          <View style={s.cardLogosContainer}>
            <Image
              source={require('assets/img/screens/private/profile/ProfileWalletScreen/FillWalletModal/NewCreditCardModalContent/logo_cb.png')}
              style={s.cardsImage}
            />
          </View>
          <View style={s.securePaymentContainer}>
            <Label size={8}>{t('PayBasketModal.securePaymentsByPartner')}</Label>
            <Image
              source={require('assets/img/screens/private/profile/ProfileWalletScreen/FillWalletModal/NewCreditCardModalContent/adyen_logo.png')}
              style={s.adyenLogo}
            />
          </View>
        </>
      )}

    </>
  )
}

type PropsTypes = {
  amount: number,
  onClose: function,
  setPaidBasket: function,
  payableType?: string,
  payableId?: number
}