/**
 * @generated SignedSource<<05a3d35c8299d5aaaa9396d9cd8e187b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "encodedImage"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "encodedImage",
        "variableName": "encodedImage"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "UpdateProfileImage",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pictureURL",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfilePersonalInfoScreenModalUpdateProfileImageMutation",
    "selections": (v1/*: any*/),
    "type": "ClientAppMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProfilePersonalInfoScreenModalUpdateProfileImageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "08b41c3045db431065a20f903f343504",
    "id": null,
    "metadata": {},
    "name": "ProfilePersonalInfoScreenModalUpdateProfileImageMutation",
    "operationKind": "mutation",
    "text": "mutation ProfilePersonalInfoScreenModalUpdateProfileImageMutation(\n  $encodedImage: String!\n) {\n  UpdateProfileImage(encodedImage: $encodedImage) {\n    id\n    pictureURL\n  }\n}\n"
  }
};
})();

node.hash = "3f3079592cc96756bea160ebad1f08ef";

module.exports = node;
