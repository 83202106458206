import numbro from 'numbro'

/**
 * Return french phone number with spaces as so : 01 23 45 67 89
 *
 * @param phoneNumber
 * @return string
 */
export function formatNumber (phoneNumber) {
  if (phoneNumber.length === 10) {
    return phoneNumber.replace(/(.{2})(?!$)/g, '$1 ')
  }
  return phoneNumber
}

/**
 * Return the first characters of a srting with ...
 *
 * @param string
 * @param length
 * @returns {string|*}
 */
export function formatStringLength(string, length) {
    if (string.length > length) {
      return string.substring(0, length) + '...'
    }
    return string
}

/**
 * Format price string.
 *
 * TODO Antoine?
 * @param price
 * @param locale
 * @returns string
 */
export function formatPrice(price, locale = 'fr-FR') {
  numbro.registerLanguage({
    languageTag: "fr-FR",
    delimiters: {
      thousands: " ",
      decimal: ","
    },
    abbreviations: {
      thousand: "k",
      million: "m",
      billion: "b",
      trillion: "t"
    },
    ordinal: () => {
      return ""
    },
    currency: {
      symbol: " €",
      position: "postfix",
      code: "EUR"
    },
    formats: {
      fourDigits: {
        totalLength: 4,
        spaceSeparated: true,
        average: true
      },
      fullWithTwoDecimals: {
        output: "currency",
        mantissa: 2,
        spaceSeparated: true,
        thousandSeparated: true
      },
      fullWithTwoDecimalsNoCurrency: {
        mantissa: 2,
        thousandSeparated: true
      },
      fullWithNoDecimals: {
        output: "currency",
        spaceSeparated: true,
        thousandSeparated: true,
        mantissa: 0
      }
    }
  })
  numbro.setLanguage(locale)
  return numbro(price).formatCurrency({mantissa: 2})
}
