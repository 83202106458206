// @flow
import React, { type Node, useRef } from 'react'
import { View } from 'react-native'
import RNPickerSelect from 'react-native-picker-select'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'
import Icon from 'src/components/common/atoms/Icon'
import s from './SelectInputFormik.style'

/**
 * SelectInputFormik atom.
 */
export default function SelectInputFormik ({
  styles,
  style,
  name,
  items,
  placeholder,
  values,
  setFieldValue,
  size = 'M',
  isActive = false,
  disabled = false,
  fullWidth = false
}: PropsTypes): Node {

  // Init state
  const { t } = useTranslation()
  const selectRef: any = useRef()
  const placeholderItem = {
    label: placeholder ? placeholder : t('SelectInputFormik.defaultPlaceholder'),
    value: ''
  }

  // Render Input
  return (
    <Field
      name={name}
      component={() => (
        <View style={[s.container, s['container'+size], style, fullWidth && s.fullWidth]}>
          <RNPickerSelect
            onValueChange={value => {
              setFieldValue(name, value)
            }}
            value={values && values[name]}
            placeholder={placeholderItem}
            items={items}
            disabled={disabled}
            useNativeAndroidPickerStyle={false}
            style={{
              inputAndroid: [
                s.default,
                s.android,
                s[size],
                fullWidth && s.fullWidth,
                isActive && s.active,
                disabled && s.disabled,
                styles?.picker
              ],
              inputIOS: [
                s.default,
                s.ios,
                s[size],
                fullWidth && s.fullWidth,
                isActive && s.active,
                disabled && s.disabled,
                styles?.picker
              ],
              inputWeb: [
                s.default,
                s.web,
                s[size],
                fullWidth && s.fullWidth,
                isActive && s.active,
                disabled && s.disabled,
                styles?.picker
              ],
              iconContainer: [
                s.icon,
                s['icon'+size],
                styles?.icon
              ]
            }}
            Icon={() => (
              <Icon name={'caret'} color={s.icon.color} size={9}/>
            )}
            ref={selectRef}
          />
        </View>
      )}
    />
  )
}

// PropsTypes
type PropsTypes = {
  styles?: any,
  style?: any,
  name: string,
  items: any,
  placeholder?: any,
  values: any,
  setFieldValue: function,
  size?: 'XL' | 'L' | 'M' | 'S',
  isActive?: boolean,
  disabled?: boolean,
  fullWidth?: boolean
}