import { Platform, StyleSheet } from 'react-native'

export default StyleSheet.create({
  deliveryShadowContainer: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 20,
    marginBottom:10,
    backgroundColor: 'white'
  },
  deliveryShadowContainerDesktop: {
    marginTop: 40,
    paddingBottom: 20
  },
  deliveryAnimationContainer: {
    overflow: 'hidden',
    paddingLeft: 10,
    paddingRight: 15,
    width: '100%'
  },
  deliveryMinimalInfos: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 40
  },
  deliveryMinimalInfosDesktop: {
    height: 'unset',
    marginTop: 20,
    ...Platform.select({ web: { cursor: 'default' }})
  },
  deliveryPrice: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  deliveryCaret: {
    marginLeft: 10
  },
  deliveryConfigRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  deliveryConfigRowDesktop: {
    marginBottom: 'unset',
    marginTop: 30
  },
  deliveryConfigLabel: {
    flex: 0.65
  },
  deliveryConfigButton: {
    flex: 0.35
  }
})