import { Platform, StyleSheet } from 'react-native'
import { Colors, Sizes } from 'src/styles'

// Styles
export default StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    ...Platform.select({ web: { overflow: 'auto' }}),
    paddingHorizontal: 20,
    paddingVertical: 20
  },
  containerNotMobile: {
    paddingHorizontal: 40,
  },
  fullScreenContainer: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center'
  },
  topScreenContainer: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  searchInput: {
    borderColor: Colors.orange100
  },
  desktopTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 20,
    marginHorizontal: -40,
    marginBottom: 20
  },
  desktopTitleIcon: {
    color: Colors.orange100,
    marginRight: 20
  },
  typesContainer: {
    flexDirection: 'column',
    marginBottom: 20
  },
  typesContainerTitle: {
    marginTop: 20,
    marginBottom: 20,
    fontSize: 16,
    fontWeight: 'bold'
  },
  typesContainerRow: {
    flexDirection: 'row'
  },
  searchType: {
    height: 80,
    marginBottom: 10,
    flex: 1,
    borderWidth: 1,
    borderColor: Colors.lightGrey100,
    borderRadius: 10,
    backgroundColor: Colors.lightGrey80,
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 80,
    ...Platform.select({ web: { cursor: 'pointer' }})
  },
  searchTypeFirstColumn: {
    marginRight: 10
  },
  searchTypeSelected: {
    borderColor: Colors.orange100
  },
  searchTypeLabelSelected: {
    fontWeight: 'bold'
  },
  listSearchResults: {
    flex: 1,
    paddingTop: 5,
    width: '100%'
  },
  desktopListSearchResults: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    ...Platform.select({ web: { overflow: 'auto' }}),
    paddingVertical: 10,
    marginRight: -24
  },
  desktopListItem: {
    marginRight: 20,
    marginBottom: 20,
    height: 'auto'
  },
  serviceCard: {
    width: '95%',
    marginLeft: 5,
    marginRight: 5
  },
  affinateYourSearchContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  affinateYourSearch: {
    marginVertical: 20,
    width: '50%',
    textAlign: 'center',
  },
  noResultsLabel: {
    marginTop: 20,
    width: '80%',
    textAlign: 'center'
  },
  noResultsButton: {
    marginTop: 30,
    width: '80%',
    backgroundColor: Colors.orange100,
    height: 30,
    alignSelf: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    borderRadius: Sizes.formElementBorderRadius,
  },
  noResultsLabelLabel: {
    fontSize: 14,
    fontWeight: 'bold',
    color: Colors.white100
  },
  itemContainer: {
    marginBottom: 20
  },
  productCardContainer: {
    width: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 20,
    padding: 2
  },
})