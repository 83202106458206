import React, { type Node, useEffect, useRef, useState } from 'react'
import { Animated, Image, ImageBackground, Pressable, View } from 'react-native'
import { useMutation } from 'relay-hooks'
import { useTranslation } from 'react-i18next'
import { LinearGradient } from 'expo-linear-gradient'
import Icon from 'src/components/common/atoms/Icon'
import Label from 'src/components/common/atoms/Label'
import ViewShadow from 'src/components/common/atoms/ViewShadow'
import { Colors } from 'src/styles'
import s, { gradientColors } from './ServiceDetailCard.style'

/**
 * ServiceDetailCard mole.
 */
export function ServiceDetailCard ({
  open = false,
  service,
  knowMore = true,
  style,
  styles,
  onPress
}: PropsTypes): Node {

  // Init state
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(open)
  const [error, setError] = useState()

  // Max height of the main content container
  const maxHeight = 1000

  // Initiate both animations
  const mainContentHeightAnimation = useRef(new Animated.Value(isOpen ? maxHeight : 20)).current
  const headerHeightAnimation = useRef(new Animated.Value(isOpen ? 100 : 40)).current

  // Mutation to toggle the service in user's favorites
  const [toggleServiceMutation] = useMutation(
    graphql`
      mutation ServiceDetailCardMutation($serviceId: Int!) {
        ToggleServiceFavorite(serviceId: $serviceId) {
          id
          rowId
          name
          isUserFavorite
        }
      }
    `,
    {
      onCompleted: () => setError(),
      onError: () => setError(t('Yup.global.errorTryAgain'))
    },
  )

  useEffect(() => {
    // Manage main content height animation
    Animated.timing(mainContentHeightAnimation, {
      toValue: isOpen ? maxHeight : 20,
      duration: 500,
      useNativeDriver: false  // <-- neccessary
    }).start()

    // Manage header height animation
    Animated.timing(headerHeightAnimation, {
      toValue: isOpen ? 100 : 40,
      duration: 500,
      useNativeDriver: false  // <-- neccessary
    }).start()
  }, [isOpen])

  // Render mole
  return (
    <ViewShadow
      style={[s.shadowContainer, style, styles?.shadowContainer, { marginVertical: 14 }]}
      onPress={onPress}
    >

      {/* Image Header */}
      <Animated.View style={{ height: headerHeightAnimation }}>
        <ImageBackground
          source={{ uri: service.pictureURL }}
          style={[s.imageBackground, styles?.imageBackground]}
          imageStyle={s.image}>
          <Image source={{ uri: service.logoURL }}
                 style={s.serviceIcon}/>
          <Label size={12} color={'white'} types={['bold', 'smallMargin']} style={s.imageLegend}>
            {service.name}
          </Label>
          <View style={s.gradientContainer}>
            <LinearGradient
              colors={gradientColors}
              start={{ x: 0, y: 0 }}
              end={{ x: 1, y: 0 }}
              style={[s.linearGradient]}
            />
          </View>
        </ImageBackground>
      </Animated.View>

      {/* Main Content */}
      <Animated.View style={[
        { maxHeight: mainContentHeightAnimation },
        s.detailsContainer
      ]}>

        {/* Close / Open buttons */}
        <View style={[s.cardButtonContainer, styles?.cardButtonContainer]}>
          {knowMore && (
            <Pressable
              size={'S'}
              onPress={() => setIsOpen(!isOpen)}
              style={s.detailsButton}
            >
              <Label size={10} color={Colors.darkGrey100}>
                {isOpen ? t('ServiceDetailCard.close') : t('ServiceDetailCard.open')}
              </Label>
              <Icon size={5} style={s.buttonIcon} name={'caret'} color={Colors.lightGrey100}/>
            </Pressable>
          )}
        </View>

        {/* Card content */}
        <View style={[s.details, styles?.details]}>

          {/* Description */}
          <Label types={['default', 'smallMargin']}>
            {service.description}
          </Label>

          {error && <Label types={['alert', 'error']} size={10}>{error}</Label>}

          {/* Favorite text */}
          <View style={[s.favoriteContainer, styles?.favoriteContainer]}>
            <Pressable onPress={() => toggleServiceMutation({ variables: { serviceId: service.rowId } })}>
              <Icon name={service.isUserFavorite ? 'favorite-filled' : 'favorite'} color={service.color} size={10}/>
            </Pressable>
            <Label size={9} style={s.favoriteLabel}>
              {service.isUserFavorite ? t('ServiceDetailCard.favorite') : t('ServiceDetailCard.notFavorite')}
            </Label>
          </View>

        </View>
      </Animated.View>

    </ViewShadow>
  )
}

// Memo
export default React.memo(ServiceDetailCard, (prev, next) => {
  return prev.open === next.open &&
    prev.service === next.service
})

// PropsTypes
type PropsTypes = {
  open?: boolean,
  service: any,
  knowMore?: boolean,
  style?: any,
  styles?: any,
  onPress?: function
}
