import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

// Styles
export default StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop:12,
    marginBottom: 10,
    paddingLeft: 20
  },
  listContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  list: {
    width: '100%'
  },
  listSectionHeader: {
    paddingLeft: 20,
    paddingBottom: 6,
    paddingTop: 12
  },
  filterDateContainer: {
    flexDirection: 'row'
  },
  filterDateDelete: {
    borderWidth: 0,
    backgroundColor: null,
    marginLeft: -6
  },
  filterDateDeleteIcon: {
    transform: [{ rotate: "45deg" }]
  },
  modalHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10
  },
  closeModalButton: {
    borderWidth: 0,
    backgroundColor: null,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    height: 'auto'
  },
  closeModalButtonIcon: {
    padding: 6,
    borderRadius: 100,
    backgroundColor: Colors.lightGrey90,
    color: Colors.darkGrey100,
    transform: [{ rotate: "45deg" }]
  },
  emptyListContainer: {
    height: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  emptyListMessage: {
    width: '70%',
    textAlign: 'center'
  },
  desktopListTitle: {
    marginTop: 20,
    marginBottom: 20,
    marginLeft: 10
  },
  skeletonContainer: {
    flex:1,
    width: '100%'
  },
  skeletonContainerActive: {
    paddingHorizontal: 20
  },
})

export const listSkeletonLayout = [
  { width: '100%', height: 150, marginVertical: 5 },
  { width: '100%', height: 150, marginVertical: 5 },
  { width: '100%', height: 150, marginVertical: 5 }
]