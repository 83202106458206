// @flow
import React, { type Node, useEffect, useState } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { type StackNavigationProp } from '@react-navigation/stack'
import type { RoutesParamList } from 'src/components/Navigation'
import { useRoute } from '@react-navigation/native'
import PrivateLayoutProfile from 'src/components/common/layout/PrivateLayoutProfile'
import Button from 'src/components/common/atoms/Button'
import ActiveOrdersList from './ActiveOrdersList'
import FinishedOrdersList from './FinishedOrdersList'
import useOrderModals from 'src/utils/useOrderModals'
import s from './ProfileOrdersScreen.style'

/**
 * ProfileOrdersScreen screen.
 */
export default function ProfileOrdersScreen({
  navigation
}: PropsTypes) : Node {

  // Init state
  const { t } = useTranslation()
  const route = useRoute()
  const [displayOldOrders, setDisplayOldOrders] = useState(false)
  const [fadeOutOrderId, setFadeOutOrderId] = useState(null)
  const { OrderModals, handlePressOrder, setModalState, setSelectedOrderId }  = useOrderModals()

  useEffect(() => {
    if (route.params?.orderId) {
      setSelectedOrderId(route.params.orderId)
      setModalState(v => ({ ...v, detail: true }))
    }
  }, [route.params?.orderId])

  // Switch display old order or not
  const switchOrderList = () => {
    setDisplayOldOrders(!displayOldOrders)
  }

  // Render screen
  return (
    <>
      <PrivateLayoutProfile
        navigation={navigation}
        subHeaderIconName={'order'}
        subHeaderTitle={t('MenuDrawerContent.links.orders')}
      >

        {/* Actions buttons */}
        <View style={[s.container]}>
          {displayOldOrders ? (
            <Button fontStyle={'bold'} size={'M'} onPress={switchOrderList}>
              {t('ProfileOrdersScreen.seeActiveOrders')}
            </Button>
          ) : (
            <Button fontStyle={'bold'} size={'M'} onPress={switchOrderList}>
              {t('ProfileOrdersScreen.seeFinishedOrders')}
            </Button>
          )}
        </View>

        {/* List of orders */}
        <View style={[s.listContainer]}>
          {displayOldOrders ? (
            <FinishedOrdersList
              handleOrderDetails={handlePressOrder}
            />
          ) : (
            <ActiveOrdersList
              handleOrderMainAction={handlePressOrder}
              handleOrderDetails={handlePressOrder}
              fadeOutOrderId={fadeOutOrderId}
            />
          )}
        </View>
      </PrivateLayoutProfile>

      <OrderModals />
    </>
  )
}

// PropsTypes
type PropsTypes = {
  navigation: StackNavigationProp<RoutesParamList, 'Orders'>
}
