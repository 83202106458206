// @flow
import React, { type Node, useRef, useState, useEffect } from 'react'
import { View } from 'react-native'
import { useMutation } from 'relay-hooks'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import useScreenSizes from 'src/utils/useScreenSizes'
import Label from 'src/components/common/atoms/Label'
import TextInputFormik from 'src/components/common/forms/TextInputFormik'
import Button from 'src/components/common/atoms/Button'
import Loader from 'src/components/common/atoms/Loader'
import Icon from 'src/components/common/atoms/Icon'
import { Colors } from 'src/styles'
import s from './ShareSponsorCodeModal.style'

/**
 * ShareSponsorCodeModal modal.
 */
export default function ShareSponsorCodeModal ({
  onClose,
  setAllowClosing,
}: PropsTypes): Node {

  // Init states
  const [requestSuccess, setRequestSuccess] = useState(false)
  const { t } = useTranslation()
  const { isMobile, isDesktop, isTablet } = useScreenSizes()
  const formRef: any = useRef()

  // Form validation schema
  const sponsorCodeSchema = Yup.object().shape({
    email: Yup.string().email(t('Yup.string.emailInvalid')).required(t('Yup.mixed.required')),
  })

  // API Call
  const [shareSponsorCodeMutation, { loading }] = useMutation(
    graphql`
      mutation ShareSponsorCodeModalMutation($email: String!) {
        ShareSponsorCode(email: $email)
      }
    `,
    {
      onCompleted: () => {
        // Set success state
        setRequestSuccess(true)
      },
      onError: () => {
        // Show general error on UI
        formRef.current.setFieldError('general', t('Yup.global.error'))
      }
    },
  )

  // Prevent close modal when loading
  useEffect(() => {
    loading ? setAllowClosing(false) : setAllowClosing(true)
  }, [loading])

  // Render modal
  return (
    <View style={isDesktop && s.desktopContainer}>

      {/* Title */}
      <Label size={isMobile ? 14 : isTablet ? 16 : 28}>{t('ProfileSponsorCodeScreen.title')}</Label>

      {/* Loading state */}
      {loading && (
        <Loader color={Colors.orange100} size={!isDesktop ? 20 : 40} style={s.loader}/>
      )}

      {/* Success state */}
      {requestSuccess && (
          <View>
            <Icon name={'check'} size={!isDesktop ? 20 : 40} style={s.successIcon}/>
            <Button
              size={!isDesktop ? 'S' : 'L'}
              palette={'primary'}
              onPress={() => {
                setRequestSuccess(false)
                onClose()
              }}
              style={s.btnSuccessClose}
            >
              {t('App.close')}
            </Button>
          </View>
      )}

      {/* Share state */}
      {!loading && !requestSuccess && (
        <View>

          {/* Text */}
          <Label style={[s.body, !isMobile && s.bodyDesktop]} size={isMobile ? 10 : isTablet ? 12 : 20}>
            {t('ProfileSponsorCodeScreen.modal.body')}
          </Label>

          {/* Share form */}
          <Formik
            innerRef={formRef}
            initialValues={{ email: '' }}
            validationSchema={sponsorCodeSchema}
            onSubmit={values => shareSponsorCodeMutation({ variables: values })}
          >
            {(formikProps) => (
              <View style={isDesktop && s.desktopFormContainer}>

                {/* Email */}
                <TextInputFormik
                  style={[s.input, isDesktop && s.desktopInput]}
                  size={!isDesktop ? 'M' : 'XL'}
                  name={'email'}
                  placeholder={t('ForgotPasswordScreen.placeholders.email')}
                  hasError={formikProps.errors.email && formikProps.touched.email}
                  autoCorrect={false}
                  {...formikProps}
                />
                {formikProps.errors.email && formikProps.touched.email && (
                  <Label types={['alert', 'error']} style={!isMobile && s.desktopError} size={isDesktop ? 16 : 14}>
                    {formikProps.errors.email}
                  </Label>
                )}

                {/* Global error */}
                {formikProps.errors.general && (
                  <Label types={['alert', 'error']} style={s.generalError} size={isDesktop ? 16 : 14}>
                    {formikProps.errors.general}
                  </Label>
                )}

                {/* Actions buttons */}
                <Button style={[s.submit, isDesktop && s.submitDesktop]}
                        onPress={formikProps.handleSubmit}
                        palette={'primary'}
                        size={!isDesktop ? 'M' : 'L'}
                        disabled={!formikProps.isValid || formikProps.values.email === ''}>
                  {t('ProfileSponsorCodeScreen.modal.confirmAndShare')}
                </Button>

              </View>
            )}
          </Formik>
        </View>
      )}

    </View>
  )
}

// PropsTypes
type PropsTypes = {
}