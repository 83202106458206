/**
 * @generated SignedSource<<22c1708d852f97b792e40e33c3be59ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "qornerId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userCodePs"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "qornerId",
        "variableName": "qornerId"
      },
      {
        "kind": "Variable",
        "name": "userCodePs",
        "variableName": "userCodePs"
      }
    ],
    "kind": "ScalarField",
    "name": "createOrUpdateUserQornerCodePs",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ValidateQornerSelectionModalUserQornerMutation",
    "selections": (v1/*: any*/),
    "type": "ClientAppMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ValidateQornerSelectionModalUserQornerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b111c7559a9905ff11e50011d981f731",
    "id": null,
    "metadata": {},
    "name": "ValidateQornerSelectionModalUserQornerMutation",
    "operationKind": "mutation",
    "text": "mutation ValidateQornerSelectionModalUserQornerMutation(\n  $qornerId: Int!\n  $userCodePs: String!\n) {\n  createOrUpdateUserQornerCodePs(qornerId: $qornerId, userCodePs: $userCodePs)\n}\n"
  }
};
})();

node.hash = "eec8afad35c50a7fa4a0c184a4d3368c";

module.exports = node;
