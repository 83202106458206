import { StyleSheet, Platform } from 'react-native'
import { Colors } from 'src/styles'

// Styles
export default StyleSheet.create({
  container: {
    position: Platform.OS === 'web' ? 'fixed' : 'absolute',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: Platform.OS === 'android' ? 3 : 9999,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
    backgroundColor: Colors.darkGrey100Less50
  },
  modal: {
    zIndex: 99,
    padding: 10,
    backgroundColor: '#fff',
    width: '100%',
    opacity: 1,
    ...Platform.select({
      ios: {
        shadowColor: '#000',
        shadowOffset: { width: 10, height: 10 },
        shadowOpacity: 0.8,
        shadowRadius: 50,
      },
      android: {
        elevation: 6,
      }
    })
  },
  modalDesktop: {
    width: '50%',
    minWidth: 400,
    padding: 20
  },
  closeButton: {
    borderColor: Colors.lightGrey90,
    ...Platform.select({
      web: {
        position: 'absolute',
        right: 10
      },
      android: {
        display: 'none',
        borderColor: 'transparent'
      }
    }),
    width: 30,
    height: 30,
    borderRadius: 30,
    backgroundColor: Colors.lightGrey90
  }
})