import React, { type Node, useState } from 'react'
import { Image, View } from 'react-native'
import { useTranslation, Trans } from 'react-i18next'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import s from '../CoverDeliveryModal.style'
import { useMutation } from 'relay-hooks'
import Loader from 'src/components/common/atoms/Loader'
import useScreenSizes from 'src/utils/useScreenSizes'

/**
 * ClosetContent component
 */
export default function ClosetContent ({
  orderId,
  onRetrievedOrder,
  onClose
}: PropsTypes): Node {
  /*
   * Init vars
   */
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const [secretCode, setSecretCode] = useState()
  const [error, setError] = useState()

  /*
   * Mutation to get secret code
   */
  const [getSecretCodeMutation, { loading }] = useMutation(graphql`
    mutation ClosetContentMutation($orderId: Int!) {
      GetOrderOpeningCode(orderId: $orderId) {
        code
      }
    }
  `, {
    onCompleted: ({ GetOrderOpeningCode }) => {
      setSecretCode(GetOrderOpeningCode.code)
    },
    onError: () => {
      setError(true)
    }
  })

  /*
   * Handle get secret code button
   */
  const handleGetSecretCode = () => {
    getSecretCodeMutation({ variables: { orderId }})
  }

  /*
   * Render component
   */
  return (
    <View>
      {!secretCode ?
        <>
          <Label size={isMobile ? 10 : 12}>{t('CoverDeliveryModal.ClosetContent.description')}</Label>
          <Image
            style={s.contentImage}
            resizeMode={'contain'}
            source={require('assets/img/screens/private/shoppingTunnel/CoverDepositModal/eqqi_cover_deposit_closet.png')}
          />

          {error && <Label type={'error'} size={isMobile ? 10 : 12}>{t('CoverDeliveryModal.error')}</Label>}

          <View style={s.buttonsContainer}>
            <Button
              onPress={handleGetSecretCode}
              palette={'primary'}
              size={isMobile ? 'S' : 'M'}
              isLoading={loading}
              fullWidth
            >
              {t('CoverDeliveryModal.ClosetContent.retrieveBySecretCode')}
            </Button>
            <Button
              onPress={onClose}
              style={s.lastButton}
              size={isMobile ? 'S' : 'M'}
              fullWidth
            >
              {t('CoverDeliveryModal.cancelRecovery')}
            </Button>
          </View>
        </>
        :
        <>
          <Label size={isMobile ? 10 : 12}>{t('CoverDeliveryModal.ClosetContent.SecretCode.description')}</Label>
          <Label size={isMobile ? 10 : 12} style={s.subLabel}>
            <Trans
              i18nKey={'CoverDeliveryModal.ClosetContent.SecretCode.secretCode'}
              components={{ b: <Label types={['bold', 'smallMargin']} size={isMobile ? 10 : 12}/> }}
              values={{ code: secretCode }}
            />
          </Label>

          <Image
            style={[s.contentImage, !isMobile && s.contentImageDesktop]}
            resizeMode={'contain'}
            source={require('assets/img/screens/private/shoppingTunnel/CoverDepositModal/animation_serrure_code.gif')}
          />

          <View style={s.buttonsContainer}>
            <Button
              onPress={onRetrievedOrder}
              palette={'primary'}
              size={isMobile ? 'S' : 'M'}
              fullWidth
            >
              {t('CoverDeliveryModal.ClosetContent.SecretCode.doneBtn')}
            </Button>
            <Button
              onPress={onClose}
              style={s.lastButton}
              size={isMobile ? 'S' : 'M'}
              fullWidth
            >
              {t('CoverDeliveryModal.cancelRecovery')}
            </Button>
          </View>
        </>
      }
    </View>
  )
}

type PropsTypes = {
  orderId: number,
  onRetrievedOrder: function,
  onClose: function
}