/*  App colors */

// Black
export const black100 = '#000000'

// Orange
export const orange100 = '#DB541A'
export const orange50  = '#F27511'
export const orange100Less80 = opacify(orange100, 20)

// Blue
export const blue100 = '#0274D5'

// Dark Grey
export const darkGrey100    = '#303030'
export const darkGrey100Less25 = opacify(darkGrey100, 75)
export const darkGrey100Less50 = opacify(darkGrey100, 50)
export const darkGrey100Less75 = opacify(darkGrey100, 25)

// Medium Grey
export const mediumGrey100 = '#999999'
export const MG100 = mediumGrey100 // TODO must be change on backoffice (better than an alias)
export const mediumGrey90  = '#AAAAAA'

// Light Grey
export const lightGrey100 = '#CCCCCC'
export const lightGrey90  = '#EEEEEE'
export const lightGrey80  = '#FAFAFA'

// White
export const white100 = '#FFFFFF'
export const white100Less50 = opacify(white100, 50)

// Purple
export const purple100    = '#716BAF'
export const purple100Less10 = opacify(purple100, 90)

// Green
export const green100    = '#1DA0A4'
export const green100Less10 = opacify(green100, 90)

// Yellow
export const yellow100    = '#FFEFAB'
export const yellow100Less10 = opacify(yellow100, 90)
export const yellow100Less30 = opacify(yellow100, 70)
export const yellow100Less70 = opacify(yellow100, 30)

// Pink
export const pink100    = '#F29BC1'
export const pink100Less10 = opacify(pink100, 90)

// Light Blue
export const lightBlue100    = '#AFDEF4'
export const lightBlue100Less10 = opacify(lightBlue100, 90)

// Salmon Pink
export const salmonPink100    = '#F1A083'
export const salmonPink100Less10 = opacify(salmonPink100, 90)

// Red
export const red100    = '#E94E5B'
export const red100Less10 = opacify(red100, 90)
export const red100Less50 = opacify(red100, 50)
export const red100Less70 = opacify(red100, 30)
export const red100Less80 = opacify(red100, 20)
export const red100Less90 = opacify(red100, 10)

/**
 * Transparentize a color.
 */
export function opacify(hexCode, opacity) {
  let hex = hexCode.replace('#', '')
  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
  }
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)
  return `rgba(${r},${g},${b},${opacity / 100})`
}