import { Platform, StyleSheet } from 'react-native'

export default StyleSheet.create({
  modal: {
    width: '100%',
    maxHeight: '100%',
    minHeight:200,
    paddingTop: 20,
    paddingBottom: 25,
    justifyContent: 'center'
  },

  // Title
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10,
    alignItems: 'center'
  },
  priceContainer: {
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  saleLabel: {
    alignSelf: 'flex-end',
    margin: 0
  },
  dashedPrice: {
    textDecorationLine: 'line-through',
    margin: 0
  },
  price: {
    margin: 0,
    marginLeft: 3
  },

  // Error
  error: {
    marginBottom: 10
  },

  // Buttons
  buttonToCartContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 30
  },
  buttonContainer: {
    marginBottom: 15,
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  buttonContainerDesktop: {
    marginBottom: 20,
    flexDirection: 'row',
  },
  button: {
    flex: 0.5,
    paddingLeft: 0,
    paddingRight: 0
  },
  bottomButton: {
    marginTop: 20,
    alignSelf: 'flex-end'
  },
  basketButton: {
    marginHorizontal: 10,
    marginTop: 10
  },
  basketButtonDesktop: {
    marginHorizontal: 30,
  },
  stepper: {
    // todo Vérifier le comportement sur ios
    marginTop: Platform.OS === 'web' ? 10 : 5
  },

  // Cashback
  cashbackContainer: {
    flexDirection: 'row',
    marginBottom: 20
  },
  cashbackLabel: {
    marginLeft: 10,
    marginBottom: 0
  },

  // Content
  content: {
    marginBottom: 20
  },

  // Images
  imageContainer: {
    flexDirection: 'row'
  },
  mainImage: {
    height: 70,
    width: '100%'
  },
  mainImageDesktop: {
    height: 167,
  },
  secondaryImage: {
    marginTop: 10,
    height: 70,
  },
  secondaryImageDesktop: {
    marginTop: 30,
    height: 167
  },
  picturesContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  }
})