// @flow
import React, { type Node } from 'react'
import { View } from 'react-native'
import s from './Separator.style'

export default function Separator ({
  width = 20,
  color = '#000000',
  style,
  styles
}: PropsTypes): Node {
  return (
    <View style={[s.container, style, styles?.container]}>
      <View style={[
        s.border,
        width && { width },
        color && { color },
        styles?.border
      ]}/>
    </View>
  )
}

type PropsTypes = {
  width?: number,
  color?: string,
  style?: any,
  styles?: any
}