import { Platform, StyleSheet } from 'react-native'

// Styles
export default StyleSheet.create({
  container: {
    width: '100%',
    zIndex: 100
  },
  imageBackground: {
    height: '100%'
  },
  shadowBackround: {
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.6)'
  },
  halfVoid: {
    flex: 1
  },
  content: {
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: Platform.OS === 'web' ? 0 : 100
  },
  avatar: {
    borderWidth: 2,
    borderColor: '#fff',
    marginBottom: 40
  },
  subContainer: {
    alignItems: 'center'
  },
  button: {
    borderWidth: 0,
    backgroundColor: 'transparent',
    paddingBottom: 0,
    paddingTop: 0,
    marginBottom: 20,
    marginTop: 40
  },
  caret: {
    transform: [{rotate: "-90deg"}]
  }
})
