import { StyleSheet } from 'react-native'
import { Colors, Fonts, Palette } from 'src/styles'

// Styles
export default StyleSheet.create({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    width: '100%',
    paddingLeft: 15,
    paddingRight: 15,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 25,
    fontFamily: Fonts.primary.regular,
    fontStyle: 'normal',
    fontWeight: 'normal',
    paddingTop: 0,
    paddingBottom: 0,
    alignItems: 'center'
  },
  default: {
    backgroundColor: Palette.dpDefaultInputBackground,
    borderColor: Palette.dpDefaultInputBorder,
    color: Colors.darkGrey100,
  },
  primary: {
    backgroundColor: Palette.ppDefaultInputBackground,
    borderColor: Palette.ppDefaultInputBorder,
    color: Colors.darkGrey100,
  },
  hasError: {
    borderColor: Colors.red100
  },
  XL: {
    height: 50,
    fontSize: 18,
    lineHeight: 21,
  },
  L: {
    height: 40,
    fontSize: 16,
    lineHeight: 19,
  },
  M: {
    height: 30,
    fontSize: 14,
    lineHeight: 16,
  },
  S: {
    height: 20,
    fontSize: 10,
    lineHeight: 12,
  },
  XS: {
    height: 15,
    fontSize: 8,
    lineHeight: 9,
  },
  multiline: {
    height: 120,
    padding: 10,
    textAlignVertical: 'top'
  },
  iconEnable: {
    paddingLeft: 30
  },
  activeDefault: {
    backgroundColor: Palette.dpActiveInputBackground,
    borderColor: Palette.dpActiveInputBorder,
    color: Palette.dpActiveInputText,
  },
  disabledDefault: {
    backgroundColor: Palette.dpDisableInputBackground,
    borderColor: Palette.dpDisableBorder,
    color: Palette.dpDisableInputText,
  },
  activePrimary: {
    backgroundColor: Palette.ppActiveInputBackground,
    borderColor: Palette.ppActiveInputBorder,
    color: Palette.ppActiveInputText,
  },
  disabledPrimary: {
    backgroundColor: Palette.ppDisableInputBackground,
    borderColor: Palette.ppDisableBorder,
    color: Palette.ppDisableInputText,
  },
  icon: {
    position: 'absolute',
    zIndex: 1
  },

  // icon width + margin with input
  // because of weird behaviour on android
  outsideIconContainerXL: {
    paddingLeft: 65
  },
  outsideIconContainerL: {
    paddingLeft: 65
  },
  outsideIconContainerM: {
    paddingLeft: 30
  },
  outsideIconContainerS: {
    paddingLeft: 30
  },
  outsideIconContainerXS: {
    paddingLeft: 30
  },
  outsideIconStyle: {
    // height:'100%',
    marginRight: 15,
    // reset position manually as unset does not work in react native
    position: 'absolute',
    left: 0
  },
})

// Icons styles
export const iconSizes = {
  XL: {
    width: 21,
    left: 11,
  },
  iconInputXL: {
    paddingLeft: 38
  },
  L: {
    width: 19,
    left: 10,
  },
  iconInputL: {
    paddingLeft: 36
  },
  M: {
    width: 17,
    left: 10,
  },
  iconInputM: {
    paddingLeft: 33
  },
  S: {
    width: 12,
    left: 10,
  },
  iconInputS: {
    paddingLeft: 29
  },
  XS: {
    width: 9,
    left: 10,
  },
  iconInputXS: {
    paddingLeft: 26
  },

  outsideXL: {
    height: 50,
    width: 50

  },
  outsideL: {
    height: 40,
    width: 40
  },
  outsideM: {
    width: 20,
    marginRight: 10
  },
  outsideS: {
    height: 20,
    width: 20
  },
  outsideXS: {
    height: 15,
    width: 15
  }
}
