// @flow
import React, { type Node } from 'react'
import { View, Image, StyleSheet, Platform } from 'react-native'
import SkeletonContent from 'react-native-skeleton-content-nonexpo'
import { Sizes, Colors } from 'src/styles'
import Label from 'src/components/common/atoms/Label'
import Icon from 'src/components/common/atoms/Icon'

/**
 * NoPictureAvatar atom.
 */
export default function NoPictureAvatar ({
  initials = null,
  isActive = true,
  size = Sizes.XL,
  style,
  labelSize = 20,
  iconSize = 30,
  labelColor = Colors.white100
}: PropsTypes) : Node {

  // Dynamic style rules
  const dynamicStyles = {
    width: size,
    height: size,
    borderRadius: size,
    borderColor: isActive ? Colors.orange100 : Colors.darkGrey100,
  }

  // Render component
  return (
    <View
      style={[
        s.container,
        dynamicStyles,
        style
      ]}
    >
      {initials ? (
          <Label type={'bold'} color={labelColor} size={labelSize} style={Platform.os !== 'web' && {lineHeight: labelSize*1.2}}>
            {initials}
          </Label>
        ) :
        <Icon name={'profile'} size={iconSize} color={labelColor}/>
      }
    </View>
  )
}

// PropsTypes
type PropsTypes = {
  style?: any,
  initals?: string,
  isActive?: boolean,
  size?: number,
  labelSize?: number,
  iconSize?: number,
  labelColor?: string
}

// Styles
const s = StyleSheet.create({
  container: {
    borderWidth: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
})