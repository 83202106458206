import { StyleSheet } from 'react-native'
import { Fonts, Palette, Colors } from 'src/styles'

// Styles
export default StyleSheet.create({
  default: {
    fontFamily: Fonts.primary.regular,
    fontSize: 14,
    color: Colors.darkGrey100
  },
  bold: {
    fontFamily: Fonts.primary.bold,
    fontSize: 14,
    color: Colors.darkGrey100
  },
  smallMargin: {
    marginTop: 2,
    marginBottom: 2
  },
  small: {
    fontFamily: Fonts.primary.regular,
    fontSize: 11,
    color: Palette.dpDefaultText,
    marginTop: 4,
    marginBottom: 4
  },
  h1: {
    fontFamily: Fonts.primary.regular,
    fontSize: 26,
    color: Colors.darkGrey100,
    marginTop: 12,
    marginBottom: 12
  },
  h2: {
    fontFamily: Fonts.primary.regular,
    fontSize: 22,
    color: Colors.darkGrey100,
    marginTop: 10,
    marginBottom: 10
  },
  h3: {
    fontFamily: Fonts.primary.regular,
    fontSize: 18,
    color: Colors.darkGrey100,
    marginTop: 8,
    marginBottom: 8
  },
  alert: {
    fontFamily: Fonts.primary.regular,
    fontSize: 11,
    marginTop: 4,
    marginBottom: 4
  },
  warning: {
    color: Colors.orange50,
  },
  error: {
    color: Colors.red100
  },
  success: {
    color: Colors.green100
  },
  inactive: {
    fontFamily: Fonts.primary.regular,
    fontSize: 14,
    color: Colors.mediumGrey90
  }
})