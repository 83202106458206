import { StyleSheet } from 'react-native'

export const payWithNewCardModalContentStyle = StyleSheet.create({
  iconContainer: {
    height: 194,
    alignSelf: 'center',
    justifyContent: 'center'
  },
  errorContainer: {
    textAlign: 'center'
  },
  amountToPay: {
    marginTop: 10,
    marginBottom: 10
  },
  cardLogosContainer: {
    alignItems: 'center',
    marginTop: 27
  },
  cardsImage: {
    width: '60%',
    height: 15,
    resizeMode: 'contain'
  },
  securePaymentContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 3
  },
  adyenLogo: {
    width: 40,
    height: 12,
    marginLeft: 2,
    resizeMode: 'contain'
  },
  textCenter: {
    textAlign: 'center'
  }
})

export const payWithWalletModalContent = StyleSheet.create({
  iconContainer: {
    height: 194,
    alignSelf: 'center',
    justifyContent: 'center'
  },
  errorContainer: {
    textAlign: 'center'
  },
  amountToPay: {
    marginTop: 20
  },
  creditsBalance: {
    marginTop: 10,
    marginBottom: 20
  },
  button: {
    marginBottom: 10
  },
  securePayments: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 12
  },
  adyenLogo: {
    marginLeft: 2
  },
  textCenter: {
    textAlign: 'center'
  }
})

export const payWithRecurringCardModalContent = StyleSheet.create({
  iconContainer: {
    height: 194,
    alignSelf: 'center',
    justifyContent: 'center'
  },
  errorContainer: {
    alignItems: 'center'
  },
  amountToPay: {
    marginTop: 20,
    marginBottom: 20
  },
  button: {
    marginBottom: 10
  },
  cardsLogoContainer: {
    alignItems: 'center',
    marginTop: 28
  },
  cardsImage: {
    width: '60%',
    height: 15,
    resizeMode: 'contain'
  },
  securePaymentContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 3
  },
  adyenLogo: {
    width: 40,
    height: 12,
    marginLeft: 2,
    resizeMode: 'contain'
  },
  textCenter: {
    textAlign: 'center'
  }
})

export const freeBasketModalContent = StyleSheet.create({
  container: {
    height: 194,
    justifyContent: 'center'
  },
  iconContainer: {
    height: 194,
    alignSelf: 'center',
    justifyContent: 'center'
  },
  errorContainer: {
    textAlign: 'center'
  },
  button: {
    marginBottom: 10
  },
  errorIcon: {
    marginBottom: 15
  },
  textCenter: {
    textAlign: 'center'
  }
})