// @flow
import React, {useRef, type Node} from 'react'
import {View} from 'react-native'
import {useTranslation} from 'react-i18next'
import {Formik} from 'formik'
import * as Yup from 'yup'
import {useMutation} from 'relay-hooks'
import {type StackNavigationProp} from '@react-navigation/stack'
import type {RoutesParamList} from 'src/components/Navigation'
import UserStore from 'src/store/UserStore'
import useScreenSizes from 'src/utils/useScreenSizes'
import PublicLayout from 'src/components/common/layout/PublicLayout'
import Button from 'src/components/common/atoms/Button'
import TextInputFormik from 'src/components/common/forms/TextInputFormik'
import Label from 'src/components/common/atoms/Label'
import s from './LoginScreen.styles'
import useInputRefs from 'src/utils/useInputRefs'

/**
 * LoginScreen screen.
 */
export default function LoginScreen({navigation}: PropsTypes): Node {

    // Init state
    const {t, i18n} = useTranslation()
    const {isMobile, screenSize, isDesktop} = useScreenSizes()
    const currentUserLanguage = i18n.language
    const formRef: any = useRef()

    // Login call
    const [loginMutation, {loading}] = useMutation(
        graphql`
      mutation LoginScreenMutation($email: String!, $password: String!, $lang: UserLang!) {
        UserLogin(email: $email, password: $password, lang: $lang) {
          id
          rowId
          jwtToken
          refreshJwtToken
          email
          firstName
          lastName
          lang
          creditsBalance
          cashbackWallet {
              activeAmount
          }
          usersQorners {
              id
              userId
              qornerId
              lastCheckAt
              userCodePs
          }
          companyQorner {
              id
              rowId
              pictureURL
              qornerType
          }
        }
      }
    `,
    {
      onCompleted: ({ UserLogin }) => {

        // Store authentication tokens and info about current user qorner
        UserStore.update(s => {
          s.jwtToken = UserLogin.jwtToken
          s.refreshJwtToken =  UserLogin.refreshJwtToken
          s.currentUserHaveQorner = UserLogin.companyQorner?.rowId !== undefined
          s.currentUserEmail = UserLogin.email
          s.currentUserFullname = (UserLogin.firstName + ' ' + UserLogin.lastName).trim()
        })

      },
      onError: (errors) => {
        // Handle errors
        for (let error of errors) {
          switch (error.errorCode) {
            case '110001': formRef.current.setFieldError('password', t('LoginScreen.errors.wrongCredentials'))
              break
            default :
              formRef.current.setFieldError('general', t('Yup.global.errorTryAgain'))
          }
        }
      }
    },
  )

    // Form validation schema
    const loginSchema = Yup.object().shape({
        email: Yup.string().email(t('Yup.string.emailInvalid')),
        password: Yup.string(),
    })

    // Form initial values
    const initialValues = {email: '', password: ''}

    const [inputRefs, focusNextInputWithName] = useInputRefs(initialValues)

    // Render page
    return (
        <PublicLayout registerCompanion={true} navigation={navigation}>
            <View style={[
                s.mainContainer,
                !isMobile && s[`mainContainer${screenSize}`]
            ]}>
                <Formik
                    innerRef={formRef}
                    initialValues={initialValues}
                    validationSchema={loginSchema}
                    onSubmit={(values) => {
                        loginMutation({variables: {...values, lang: currentUserLanguage.toUpperCase()}})
                    }}
                >
                    {(formikProps) => (
                        <View style={[s.formContainer, !isMobile && s.formContainerDesktop]}>

                            {/* Email */}
                            <TextInputFormik
                                innerRef={inputRefs['email']}
                                style={[s.textInput, isDesktop && s.textInputDesktop]}
                                name={'email'}
                                placeholder={t('LoginScreen.placeholders.email')}
                                hasError={formikProps.errors.email && formikProps.touched.email}
                                icon={'email'}
                                autoCompleteType={'email'}
                                textContentType={'emailAddress'}
                                onSubmitEditing={() => focusNextInputWithName('password')}
                                returnKeyType={'next'}
                                outsideIcon={true}
                                size={isDesktop ? 'XL' : 'M'}
                                iconSize={isDesktop ? 46 : 19}
                                autoCorrect={false}
                                {...formikProps}
                            />
                            {formikProps.errors.email && formikProps.touched.email && (
                                <Label style={[s.error, isDesktop && s.errorDesktop]} size={isDesktop ? 16 : 8}>
                                    {formikProps.errors.email}
                                </Label>
                            )}

                            {/* Password */}
                            <TextInputFormik
                                innerRef={inputRefs['password']}
                                style={[s.textInput, isDesktop && s.textInputDesktop]}
                                name={'password'}
                                placeholder={t('LoginScreen.placeholders.password')}
                                autoCompleteType={'password'}
                                textContentType={'password'}
                                secureTextEntry={true}
                                hasError={formikProps.errors.password && formikProps.touched.password}
                                icon={'lock'}
                                onSubmitEditing={formikProps.handleSubmit}
                                outsideIcon={true}
                                size={isDesktop ? 'XL' : 'M'}
                                iconSize={isDesktop ? 46 : 19}
                                {...formikProps}
                            />
                            {formikProps.errors.password && formikProps.touched.password && (
                                <Label style={[s.error, isDesktop && s.errorDesktop]} size={isDesktop ? 16 : 8}>
                                    {formikProps.errors.password}
                                </Label>
                            )}

                            {/* General error */}
                            {formikProps.errors.general && (
                                <Label style={[s.error, s.generalError]}
                                       size={isMobile ? 8 : 16}>{formikProps.errors.general}</Label>
                            )}

                            {/* Submit */}
                            <Button
                                style={[s.submitButton, !isMobile && {marginTop: 60}]}
                                onPress={formikProps.handleSubmit}
                                palette={'primary'}
                                disabled={!(formikProps.isValid && formikProps.dirty)}
                                isLoading={loading}
                                size={isDesktop ? 'L' : 'M'}
                            >
                                {t('LoginScreen.labels.login')}
                            </Button>
                        </View>
                    )}
                </Formik>

                {/* Link to other screens */}

            </View>

            <View style={[s.buttonContainer, !isMobile && s.buttonContainerDesktop]}>

                {/* Forgot password */}
                <Button styles={{
                    touchableOpacity: s.forgotPasswordButton,
                    label: [s.forgotPasswordLabel, isDesktop && s.forgotPasswordLabelDesktop]
                }}
                        size={'M'}
                        onPress={() => navigation.navigate({name: 'ForgotPassword'})}>{t('LoginScreen.labels.forgotPassword')}</Button>

                {/* Register button */}
                {isMobile && (
                    <Button
                        size={'M'}
                        onPress={() => navigation.navigate({name: 'Register'})}
                    >
                        {t('LoginScreen.labels.register')}
                    </Button>
                )}

            </View>

        </PublicLayout>
    )
}

// PropsTypes
type PropsTypes = {
    navigation: StackNavigationProp<RoutesParamList, 'Login'>
}
