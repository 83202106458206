// @flow
import React, { type Node, useState, useRef } from 'react'
import { View } from 'react-native'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useMutation } from 'relay-hooks'
import { useTranslation } from 'react-i18next'
import useScreenSizes from 'src/utils/useScreenSizes'
import TextInputFormik from 'src/components/common/forms/TextInputFormik'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import Loader from 'src/components/common/atoms/Loader'
import Icon from 'src/components/common/atoms/Icon'
import { Colors } from 'src/styles'
import s from './CustomerCommentModal.style'

export default function CustomerCommentModal ({
  comment,
  orderProductId,
  selectedProviderTitle
}: PropsTypes): Node {

  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const formRef: any = useRef()
  const [error, setError] = useState()
  const [success, setSuccess] = useState()

  // add comment mutation
  const [addComment, { loading }] = useMutation(
    graphql`
      mutation CustomerCommentModalMutation($orderProductId:Int!, $comment:String) {
        SetBasketProductCustomerComment(orderProductId: $orderProductId, comment: $comment) {
          id
          rowId
          customerComment
        }
      }
    `,
    {
      onCompleted: () => {
      setSuccess(true)
        setError()
      },
      onError: () => setError(t('Yup.global.errorTryAgain'))
    }
  )

  return (
    <View>
      <Label size={!isMobile ? 16 : 14}>
        {t('BasketProductConfig.addComment', { providerTitle: selectedProviderTitle })}
      </Label>

      {
        loading ? (
            <View style={s.iconContainer}>
              <Loader size={20}/>
            </View>
          ) :
          success ? (
            <View style={s.iconContainer}>
              <Icon name={'check'} color={Colors.orange100}/>
            </View>
          ) : (
            error ? (
              <View style={[s.iconContainer, s.errorContainer]}>
                <Icon name={'close'} color={Colors.orange100} style={s.errorIcon}/>
                <Label type={'error'}>{t('PayBasketModal.error')}</Label>
              </View>
            ) : (
              <Formik
                innerRef={formRef}
                initialValues={{ comment }}
                validationSchema={Yup.object().shape({ password: Yup.string() })}
                onSubmit={(values) => {
                  addComment({ variables: { ...values, orderProductId } })
                }}
              >
                {formikProps => (
                  <>
                    <TextInputFormik
                      styles={{
                        container: { marginTop: 20, marginBottom: 10 },
                        textInput: { paddingTop: 10, paddingBottom: 10 }
                      }}
                      name={'comment'}
                      placeholder={t('BasketProductConfig.addComment', { providerTitle: selectedProviderTitle })}
                      hasError={formikProps.errors.comment && formikProps.touched.comment}
                      multiline
                      {...formikProps}
                    />
                    {formikProps.errors.comment && formikProps.touched.comment && (
                      <Label type={'error'}>{formikProps.errors.comment}</Label>
                    )}

                    {/* Submit */}
                    <Button
                      onPress={formikProps.handleSubmit}
                      palette={'primary'}
                      disabled={!(formikProps.isValid && formikProps.dirty)}
                      isLoading={loading}
                      size={'M'}
                    >
                      {t('App.validate')}
                    </Button>
                  </>
                )}
              </Formik>
            )
          )
      }
    </View>
  )
}

type PropsTypes = {
  comment?: string,
  orderProductId: number,
  selectedProviderTitle: string
}