import React, { useState, useEffect } from 'react'
import { Dimensions } from 'react-native'

const useFullWidth = () => {
  const [fullWidth, setFullWidth] = useState(Dimensions.get('window').width)
  const onDimensionsChange = ({ window }) => {
    setFullWidth(window.width)
  }

  // Refresh MenuBar width on window width change
  useEffect(() => {
    const eventDim = Dimensions.addEventListener('change', onDimensionsChange)
    return () => {
      eventDim?.remove()
    }
  })

  return fullWidth
}

export default useFullWidth