import { StyleSheet, Platform } from 'react-native'

// Styles
export default StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    overflow: 'hidden'
  },
  titleItemContainer: {
    flexDirection: 'row',
    margin: 8
  },
  titleItemIcon: {
    height: 20,
    marginTop: -2,
    marginRight: 6,
    lineHeight: 20,
    ...Platform.select({
      web: {
        lineHeight: 17
      },
      ios: {
        lineHeight: 15
      }
    })
  },
  titleItemLabel: {

  }
})

// Skeleton
export const skeleton = [
  {width: 120, height: 18, marginRight: 6, marginTop: 4},
  {width: 120, height: 18, marginRight: 6, marginTop: 4},
  {width: 120, height: 18, marginRight: 6, marginTop: 4}
]
