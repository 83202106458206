import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    width: '100%',
    marginBottom: 40
  },
  titleContainer: {
    height: 120,
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 14,
    marginLeft: -40,
    marginRight: -40,
    paddingHorizontal: 40,
  },
  titleServiceLogo: {
    width: 80,
    height: 80,
    marginRight: 20
  },
  descriptionContainer: {
    marginTop: 20,
    flexDirection: 'row',
    marginLeft: -20,
    marginRight: -20,
  },
  descriptionContent: {
    flex: 1,
    justifyContent: 'space-between',
    marginRight: 20
  },
  descriptionToggleFavorite: {
    marginTop: 10,
    flexDirection: 'row'
  },
  descriptionText: {
    marginLeft: 8
  },
  descriptionServicePicture: {
    flex: 1,
    resizeMode: 'cover',
    borderRadius: 10,
    minHeight: 100
  }
})
