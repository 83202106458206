/**
 * @generated SignedSource<<93300fcade2ceb5faf02df22d6774c0e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "creditsBalance",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileWalletScreenQuery",
    "selections": (v0/*: any*/),
    "type": "ClientAppQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProfileWalletScreenQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "e3940191c0bbad187c60c1b0ac44d82d",
    "id": null,
    "metadata": {},
    "name": "ProfileWalletScreenQuery",
    "operationKind": "query",
    "text": "query ProfileWalletScreenQuery {\n  user {\n    id\n    creditsBalance\n  }\n}\n"
  }
};
})();

node.hash = "17cc4785c572125dd4950ef1a4d0e0dc";

module.exports = node;
