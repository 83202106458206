import { StyleSheet } from 'react-native'

// Styles
export default StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20,
    marginBottom: 15,
    paddingRight: 20
  },
  listSkeletonContainer: {
    flex: 1,
    width: '100%'
  },
  listSkeletonContainerActive: {
    paddingHorizontal: 20
  },
  listContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  list: {
    width: '100%',
    height: '100%'
  },
  emptyListContainer: {
    height: '100%',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  emptyListMessage: {
    width: '70%',
    textAlign: 'center'
  }
})

export const listSkeletonLayout = [
  {width: '100%', height: 150, marginVertical: 5},
  {width: '100%', height: 150, marginVertical: 5},
  {width: '100%', height: 150, marginVertical: 5}
]