import { StyleSheet } from 'react-native'
import { Colors, Palette, Fonts } from 'src/styles'

// Styles
export default StyleSheet.create({
  default: {
    paddingVertical: 4,
    color: Colors.darkGrey100,
    borderWidth: 1,
    borderColor: Colors.mediumGrey100,
    paddingRight: 30, // to ensure the text is never behind the icon
  },
  disabled: {
    backgroundColor: Palette.ppDisableInputBackground,
    borderColor: Palette.ppDisableBorder,
    color: Colors.mediumGrey100
  },
  android: {},
  ios: {},
  web: {
    backgroundColor: Colors.lightGrey80,
    fontFamily: Fonts.primary.regular,
    paddingLeft: 10
  },
  icon: {
    color: Colors.mediumGrey100
  },
  containerXL: {
    width: 250,
  },
  XL: {
    width: 250,
    height: 50,
    borderRadius: 50,
    paddingHorizontal: 18,
    fontSize: 18
  },
  iconXL: {
    top: 22,
    right: 18
  },
  containerL: {
    width: 200
  },
  L: {
    width: 200,
    height: 40,
    borderRadius: 40,
    paddingHorizontal: 16,
    fontSize: 14
  },
  iconL: {
    top: 16,
    right: 16
  },
  containerM: {
    width: 150
  },
  M: {
    width: 150,
    height: 30,
    borderRadius: 30,
    paddingHorizontal: 14,
    fontSize: 13
  },
  iconM: {
    top: 11,
    right: 14
  },
  containerS: {
    width: 100
  },
  S: {
    width: 100,
    height: 20,
    borderRadius: 20,
    paddingHorizontal: 10,
    paddingVertical: 2,
    fontSize: 10
  },
  iconS: {
    top: 6,
    right: 10
  },
  fullWidth: {
    width: '100%',
    marginHorizontal: 0
  },
  active: {
    borderColor: Colors.orange100
  }
})