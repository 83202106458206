/**
 * @generated SignedSource<<0efc1df1348d9793e9b1de3dada581fd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rowId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "intercomUserIdentity",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "IntercomButtonQuery",
    "selections": (v0/*: any*/),
    "type": "ClientAppQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "IntercomButtonQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "93c8255faa66c21255d4f6a1c1119df6",
    "id": null,
    "metadata": {},
    "name": "IntercomButtonQuery",
    "operationKind": "query",
    "text": "query IntercomButtonQuery {\n  user {\n    id\n    rowId\n    intercomUserIdentity\n    email\n    firstName\n    lastName\n  }\n}\n"
  }
};
})();

node.hash = "03941ef1228c68d77d70898c917b80b3";

module.exports = node;
