// @flow
import React, { type Node } from 'react'
import { Image, View, ScrollView, useWindowDimensions, Platform, Linking } from 'react-native'
import { useQuery } from 'relay-hooks'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import HTML from 'react-native-render-html'
import useScreenSizes from 'src/utils/useScreenSizes'
import Label from 'src/components/common/atoms/Label'
import Loader from 'src/components/common/atoms/Loader'
import Button from 'src/components/common/atoms/Button'
import { Colors } from 'src/styles'
import s from './NewsScreenModal.style'

/**
 * NewsScreenModal.
 */
export default function NewsScreenModal ({
  onClose,
  newsId
}: PropsTypes): Node {

  // Init
  const { t } = useTranslation()
  const { isMobile, isTablet, isDesktop } = useScreenSizes()
  const contentWidth = useWindowDimensions().width
  const linkStyles = Platform.OS === 'android' ? { fontSize: 11, height: '100%' } : { fontSize: 11 }

  // API call to get connected article details
  const { data } = useQuery(
    graphql`
      query NewsScreenModalQuery($articleId: Int!) {
        user {
          id
          rowId
          intercomUserIdentity
          email
          firstName
          lastName
          lang
        }
        article (articleId: $articleId) {
          id
          rowId
          title
          content
          mainPictureUrl
          buttonLabel
          buttonDestinationUrl
          publishedAt
        }
      }
    `,
    // Variable
    { articleId: newsId }
  )

  // Extract data from API response
  const article = data?.article
  moment.locale(data?.user?.lang)

  const handleDestination = (url) => {
    if (Platform.OS === 'web') {
      window.open(url, '_blank')
    } else {
      Linking
        .openURL(url)
        .catch(err => {
          console.error('Failed opening page because: ', err)
        })
    }
  }

  // Render Modal
  return (
    <ScrollView>

      {!article ?
        <Loader style={s.loader}/>
        :
        <>

          {/* Title */}
          <Label type={'bold'} size={isMobile ? 13 : isTablet ? 16 : 28}>{article.title}</Label>

          {/* Image */}
          <Image
            source={{ uri: article.mainPictureUrl }}
            style={[s.image, !isMobile && s.imageDesktop]}
          />

          {/* Content */}
          <HTML
            source={{ html: article.content }}
            contentWidth={contentWidth}
            // Doesn't work if style is externalized
            baseStyle={{
              color: Colors.darkGrey100,
              fontSize: isMobile ? 12 : isTablet ? 14 : 22
            }}
          />

          {/* Published At */}
          <Label size={isMobile ? 9 : isTablet ? 12 : 18} style={s.date}>
            {t('NewsScreenModal.publishedAt', { date: moment(article.publishedAt).format('LLLL') })}
          </Label>

          {/* Actions buttons */}
          <View style={!isDesktop ? s.buttonsContainer : s.desktopButtonsContainer}>
            {!!article.buttonLabel && !!article.buttonDestinationUrl && (
              <Button
                fullWidth
                size={isMobile ? 'S' : isTablet ? 'M' : 'L'}
                palette={'primary'}
                onPress={() => handleDestination(article.buttonDestinationUrl)}
                style={[s.customButton, !isMobile && s.customButtonNotMobile]}
                styles={{ label: linkStyles }}
              >
                {article.buttonLabel}
              </Button>
            )}
            <Button
              fullWidth
              style={[s.customButton, !isMobile && s.customButtonNotMobile]}
              size={isMobile ? 'S' : isTablet ? 'M' : 'L'}
              onPress={onClose}
            >
              {t('NewsScreenModal.goBack')}
            </Button>
          </View>

        </>
      }

    </ScrollView>
  )
}

// PropsTypes
type PropsTypes = {
  onClose?: function,
  newsId?: number
}