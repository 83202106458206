import React, { useEffect } from 'react'
import { Platform } from 'react-native'
import { INTERCOM_APP_ID } from 'src/environment'

/**
 * useIntercom hook
 * How to: const Intercom = useIntercom(user) -> Intercom.displayMessenger()
 * @returns Intercom object
 */
const useIntercom = (user) => {

  // Import Intercom Library and attach it to window
  if (Platform.OS === 'web') {
    window.intercomSettings = {
      app_id: INTERCOM_APP_ID,
      hide_default_launcher: true
    };
    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + window.intercomSettings.app_id;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
  }

  // Send to intercom the user identity
  useEffect(() => {
    if(user !== undefined) {
      Intercom('boot', {
        'name'      : user.firstName + ' ' + user.lastName,
        'user_id'   : user.rowId,
        'user_hash' : user.intercomUserIdentity,
        'email'     : user.email,
        'Backoffice Profile URL' : 'https://backoffice.quatreepingles.fr/#!/user/' + user.rowId
      })
    }
  }, [user])

  return {
    start: () => {
      Intercom('show')
    }
  }
}

export default useIntercom