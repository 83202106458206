import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

// Styles
export default StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: Colors.mediumGrey100,
    paddingTop: 1,
    paddingBottom: 1,
    paddingLeft: 2,
    paddingRight: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  containerPrimary: {
    borderColor: 'transparent',
    backgroundColor: Colors.orange100,
    opacity: 0.6
  },
  rounded: {
    borderRadius: 5,
    paddingTop: 3,
    paddingBottom: 3,
    paddingLeft: 10,
    paddingRight: 10,

  },
  label: {
    fontSize: 9,
    lineHeight: 11,
    fontWeight: 'bold'
  },
  labelPrimary: {
    color: '#fff'
  },
  labelL: {
    fontSize: 12,
  },
  hasIcon: {
    marginLeft: 3
  }
})