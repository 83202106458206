import i18n, {LanguageDetectorModule} from 'i18next';
import {initReactI18next} from 'react-i18next';
import * as RNLocalize from 'react-native-localize';
import enTranslations from '../../assets/locales/en/translation.json';
import frTranslations from '../../assets/locales/fr/translation.json';
import {setLocale} from 'yup';
import yupMessage from 'yup/lib/locale';

const languageDetector: LanguageDetectorModule = {
  init: function () {
    return
  },
  type: 'languageDetector',
  detect: () => {
    const locales = RNLocalize.getLocales();
    if (locales && locales.length) {
      return locales[0].languageCode;
    }
  },
  cacheUserLanguage: function () {
    return
  },
}

// Load translations manually because it's difficult to find a module which work
// on web and native...
const translations = {
  en: { translation: enTranslations },
  fr: { translation: frTranslations }
}

// Configure the i18next instance
i18n
  // Pass the i18n instance to react-i18next
  .use(initReactI18next)
    // Enable language detector for browser and react native
    .use(languageDetector)
    // Init i18next - for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        compatibilityJSON: 'v3',
        fallbackLng: ['en', 'fr'],
        preload: ['fr'],
        debug: false,
        resources: translations,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        }
    })

// Set default language manually, otherwise the i18n.language property remain undefined
i18n.changeLanguage('fr')

// Call this method to translate default yup messages (form validation rules)
i18n.formTranslate = function() {
  // eslint-disable-next-line no-unused-vars
  for (let [type, errors] of Object.entries(yupMessage)) {
    // eslint-disable-next-line no-unused-vars
    for (let [rule] of Object.entries(errors)) {
      yupMessage[type][rule] = i18n.t('Yup.' + type + '.' + rule);
    }
  }
  setLocale(yupMessage);
};

// Export our translator
export { i18n as Translator }
