import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

export default StyleSheet.create({
  container: {
    marginLeft: 10,
    paddingRight: 10,
    paddingBottom: 10
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerRight: {
    alignItems: 'flex-end',
    marginRight: 20
  },
  headerRightCashback: {
    marginTop: 5
  },
  headerRightCashbackDesktop: {
    marginTop: 10
  },
  headerPlaceholder: {
    flex: 8
  },

  desktopHeaderContainer: {
    height: 120,
    paddingLeft: 30,
    paddingRight: 10,
    backgroundColor: Colors.orange100Less80,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  desktopBackgroundIcon: {
    position: 'absolute',
    color: Colors.white100Less50,
    right: 40,
    transform: [{ rotate: '-29deg' }]
  },
  desktopTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  desktopTitleIcon: {
    color: Colors.orange100,
    marginRight: 30
  },
  twoColumnsLayout: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 40,
    marginTop: 30
  },
  leftColumn: {
    marginLeft: 20,
    marginRight: 10,
    flex:2
  },
  rightColumn: {
    marginLeft: 10,
    marginRight: 20,
    flex:1
  },
  cardsImageContainer: {
    alignItems: 'center',
    marginTop: 10
  },
  cardsImage: {
    width: '100%',
    height: 15,
    resizeMode: 'contain'
  },
  byAdyenContainer: {
    flexDirection: 'row',
    marginBottom: 15,
    marginTop: 10,
    justifyContent: 'center',
    alignItems: 'center'
  },
  byAdyenLogo: {
    width: 40,
    height: 20,
    marginLeft: 2,
    marginBottom: -3,
    resizeMode: 'contain'
  },
  emptyBasketImage: {
    height: 273,
    width: 235
  },
  emptyBasketImageMobile: {
    height: 213,
    width: 180
  },
  emptyBasketLabel: {
    textAlign: 'center',
    marginBottom: 30
  },
  emptyBasketLabelMobile: {
    textAlign: 'center',
    marginBottom: 60,
    marginTop: 40
  },
  errorModalContent: {
    height: 194,
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center'
  }
})

export const modalStyle = StyleSheet.create({
  title: {
    marginBottom:36
  },
  loader: {
    marginBottom:36
  },
  icon: {
    margin: 'auto',
    marginBottom: 36
  },
  buttonContainer: {
    marginLeft: 15,
    marginRight: 15
  },
  selectInput: {
    marginTop:30,
    marginBottom:30
  },
  selectSubmit: {
    marginBottom: 25,
    minWidth: 123
  }
})