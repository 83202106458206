// @flow
import React, { type Node } from 'react'
import { View, ImageBackground } from 'react-native'
import Label from 'src/components/common/atoms/Label'
import ViewShadow from 'src/components/common/atoms/ViewShadow'
import s from './ProfileCard.style'
import { LinearGradient } from 'expo-linear-gradient'

/**
 * ProfileCard mole.
 */
export default function ProfileCard({
  style,
  styles,
  type,
  id,
  title,
  action,
  pictureURL,
  primaryContent,
  secondaryContent,
  handlePress
}: PropsTypes): Node {
  // Render mole
  return (
    <ViewShadow
      style={[s.container, style, styles?.container]}
      styles={styles}
      onPress={() => handlePress(type, id)}
    >
        {/* Card title and actions buttons */}
        <View style={[s.header, styles?.header]}>
          <Label size={14} types={['bold', 'smallMargin']} skeleton={s.skeletonTitle}>{title}</Label>
          {action}
        </View>

        {/* Card content with background image */}
        <ImageBackground
          style={[s.imageBackground, styles?.imageBackground]}
          source={pictureURL ? { uri: pictureURL } : require('assets/img/screens/private/HomeScreen/default_qorner_banner.png')}
          imageStyle={[s.image, styles?.image]}
        >
          {/* Card secondary content */}
          <Label color={'#fff'} style={[s.imageText]} size={12}>
            {secondaryContent}
          </Label>

          {/* Card primary content */}
          <Label size={18} types={['bold', 'smallMargin']} color={'#fff'} style={[s.imageText, styles?.imageText]}>
            {primaryContent}
          </Label>

          <LinearGradient
            colors={['transparent', 'rgba(0, 0, 0, 0.7)']}
            start={{ x: 0, y: 1 }}
            end={{ x: 0, y: 1 }}
            style={s.linearGradient}
          />
        </ImageBackground>
    </ViewShadow>
  )
}

// PropsTypes
type PropsTypes = {
  style?: any,
  styles?: any,
  type: string,
  id: number,
  title: any,
  action: any,
  pictureURL?: string,
  primaryContent: string,
  secondaryContent?: string,
  handlePress: function
}