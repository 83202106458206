// @flow
import React, { type Node } from 'react'
import { Image, View } from 'react-native'
import { DrawerContentScrollView } from '@react-navigation/drawer'
import moment from 'moment'
import { useQuery } from 'relay-hooks'
import { VERSION } from 'src/environment'
import { PullstateCore } from 'src/store/store'
import { formatPrice } from 'src/utils/FormatDataUtils'
import useScreenSizes from 'src/utils/useScreenSizes'
import Avatar from 'src/components/common/atoms/Avatar'
import Icon from 'src/components/common/atoms/Icon'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import MenuDrawerItem from 'src/components/common/layout/MenuDrawerItem'
import NoPictureAvatar from 'src/components/common/atoms/NoPictureAvatar'
import s from './MenuDrawerContent.style'

/**
 * MenuDrawerContent component.
 */
export default function MenuDrawerContent({
  style,
  navigation,
  t
}: PropsTypes): Node {

  // Retrieve stores & init
  const stores = PullstateCore.useStores()
  const { isMobile, isDesktop, isTablet, screenSize } = useScreenSizes()

  // API call to get connected user information
  const { data } = useQuery(graphql`
    query MenuDrawerContentQuery {
      user {
        id
        firstName
        lastName
        creditsBalance
        cashbackWallet {
          activeAmount
        }
        companyQorner {
          name
          logoURL
        }
        pictureURL
      }
      basket {
        id
        productCount
      }
      userBestServices {
          id
          rowId
          familyId
          name
          isUserFavorite
      }
      goodDeals {
          name
      }
    }
  `)
  const user = data?.user
  const basket = data?.basket
  let userBestServices = data?.userBestServices ? [...data?.userBestServices] : undefined
  if (userBestServices && data.goodDeals.length > 0) {
    userBestServices.unshift({ name: t('MenuDrawerContent.links.discount'), thereIsGoodDeals: true })
  }

  // Render layout
  return (
    <DrawerContentScrollView contentContainerStyle={[s.drawerContent, style]}>

      {/* Drawer header */}
      <Label style={s.companyName} size={isDesktop ? 14 : 13} type={'bold'}>{user?.companyQorner?.name}</Label>
      <Image
        style={[s.companyLogo, isDesktop && s.companyLogoDesktop]}
        source={user?.companyQorner?.logoURL ? { uri: user?.companyQorner?.logoURL } : require('assets/img/common/qe_logo_white.png')}/>
      <View style={[s.profileContainer, !isMobile && s.profileContainerDesktop]}>
        {user?.pictureURL ? (
          <Avatar source={{ uri: user.pictureURL }} size={isDesktop ? 60 : 40}
                  style={[s.avatar, !isMobile && s.avatarDesktop]} skeleton={true}
          />
        ) : (
          <NoPictureAvatar
            style={[s.avatar, !isMobile && s.avatarDesktop]}
            size={isDesktop ? 60 : 40}
            labelSize={isMobile ? 14 : isTablet ? 16 : 20}
            iconSize={isMobile ? 14 : isTablet ? 16 : 30}
            initials={user?.firstName?.charAt(0).toUpperCase() + user?.lastName?.charAt(0).toUpperCase()}/>
        )}
        <View style={s.moneyContainer}>
          <View style={s.moneyRow}>
            <Icon style={s.moneyIcon} name={'cashback'} size={isDesktop ? 14 : 10} />
            <Label style={s.moneyLabel} size={isDesktop ? 14 : 11}>
              {formatPrice(user?.creditsBalance)}
            </Label>
          </View>
          <View style={s.moneyRow}>
            <Icon style={s.moneyIcon} name={'wallet'} size={isDesktop ? 14 : 10} />
            <Label style={s.moneyLabel} size={isDesktop ? 14 : 11}>
              {formatPrice(user?.cashbackWallet?.activeAmount)}
            </Label>
          </View>
        </View>
      </View>

      {/* Drawer Menu */}
      <View style={[s.navigationLinkContainer, isTablet && s.navigationLinkContainerTablet]}>
        <MenuDrawerItem title={t('MenuDrawerContent.links.home')} iconName={'home'} onPress={() => navigation.navigate({ name: 'Home' })} />
        <MenuDrawerItem title={t('MenuDrawerContent.links.basket')} iconName={'basket'} onPress={() => navigation.navigate({name: 'Basket'})} />
        <MenuDrawerItem title={t('MenuDrawerContent.links.profile')} hasLabelSeeAll={true} iconName={'profile'} onPress={() => navigation.navigate('Private', { screen: 'Profile', params: { screen : isMobile ? 'ProfileMenu' : 'PersonalInformations'} })} />
        <MenuDrawerItem title={t('MenuDrawerContent.links.agenda')} isSubmenuItem={true} onPress={() => navigation.navigate('Profile', { screen: 'Agenda', params: { orderId: '' } })} />
        <MenuDrawerItem title={t('MenuDrawerContent.links.orders')} isSubmenuItem={true} onPress={() => navigation.navigate('Profile', { screen: 'Orders', params: { orderId: '' } })} />
        <MenuDrawerItem title={t('MenuDrawerContent.links.catalog')} hasLabelSeeAll={true} iconName={'infos'} onPress={() => navigation.navigate({ name: 'Catalog' })} />

        {/* User best services */}
        {userBestServices && userBestServices.map(service => (
          <MenuDrawerItem
            key={service.name}
            title={service.name}
            isSubmenuItem={true}
            iconName={service.isUserFavorite ? 'favorite-filled' : service.thereIsGoodDeals ? 'sales' : undefined}
            onPress={() =>  service.thereIsGoodDeals ?
              navigation.navigate({
                name: 'GoodDeal'
              }) :
              navigation.navigate({
                name: 'Service',
                params: { familyId: service.familyId, serviceId: service.rowId }
              })
            } />
        )).slice(0, 3)}

        <MenuDrawerItem title={t('MenuDrawerContent.links.news')} iconName={'news'} onPress={() => navigation.navigate({ name: 'News' })} />
        <MenuDrawerItem title={t('MenuDrawerContent.links.practicalInformations')} iconName={'infos'} onPress={() => navigation.navigate({ name: 'PracticalInformations' })} />
      </View>

      {/* Change qorner button */}
      <View style={s[`buttonContainer${screenSize}`]}>
        <Button
          fullWidth={!isMobile}
          styles={!isMobile && { touchableOpacity: { paddingLeft: 8, paddingRight: 9 }, label: { marginLeft: 5 } }}
          size={isDesktop ? 'L' : 'S'}
          onPress={() => navigation.navigate({ name: 'QornerChoice', params: { change: true } })}
          Icon={<Image style={s[`changeQornerIcon${screenSize}`]}
                       source={require('assets/img/common/layout/MenuDrawer/change_qorner_icon.png')}/>}
        >
          {t('MenuDrawerContent.links.qornerSelection')}
        </Button>

        {/* Logout button */}
        <Button
          children={t('MenuDrawerContent.logout')}
          labelFontWeight={'bold'}
          style={s.logoutButton}
          fullWidth={!isMobile}
          onPress={() => {
            // Reset stores to their initial states
            for (let store of Object.keys(stores)) {
              stores[store].update(s => stores[store].initialState)
            }
          }}
          size={isDesktop ? 'L' : 'S'}
        />
      </View>

      {/* Copyright and version */}
      <Label style={s.brand} size={isDesktop ? 12 : 10}>
        {t('MenuDrawerContent.brand', { year: moment().format('YYYY'), version: VERSION })}
      </Label>

    </DrawerContentScrollView>
  )
}

// PropsTypes
type PropsTypes = {
  style?: any,
  navigation: any,
  t: function
}