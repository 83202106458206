import React, { type Node, useState } from 'react'
import { Image, View } from 'react-native'
import { useMutation } from 'relay-hooks'
import { useTranslation } from 'react-i18next'
import useScreenSizes from 'src/utils/useScreenSizes'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import s from './CoverCollectModal.style'

/**
 * DepositBadgeModalContent component
 */
export default function DepositBadgeModalContent ({
  style,
  styles,
  nextStep,
  onCancel
}: PropsTypes): Node {
  /*
   * Init vars
   */
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  /*
   * Download badge mutation
   */
  const [downloadBadgeMutation, { loading }] = useMutation(
    graphql`
      mutation DepositBadgeModalContentMutation($channel: CommunicationChannel!) {
        SendUserBadge(channel: $channel)
      }
    `,
    {
      onCompleted: () => setSuccess(true),
      onError: () => setError(true)
    }
  )

  /*
   * Render component
   */
  return (
    <View style={[style, styles?.container]}>
      <Label size={isMobile ? 10 : 12}>{t('CoverDepositModal.DepositBadgeModalContent.description')}</Label>
      <Image
        style={[s.contentImage, !isMobile && s.contentImageDesktop]}
        resizeMode={'contain'}
        source={require('assets/img/screens/private/shoppingTunnel/CoverDepositModal/animation_depot_badge.gif')}
      />
      {error && <Label size={isMobile ? 10 : 12} type={'error'}>{t('CoverDepositModal.DepositBadgeModalContent.downloadError')}</Label>}
      {success && <Label size={isMobile ? 10 : 12}>{t('CoverDepositModal.DepositBadgeModalContent.downloadSuccess')}</Label>}
      <View style={s.buttonsContainer}>
        <Button
          onPress={() => {
            setSuccess(false)
            setError(false)
            nextStep()
          }}
          palette={'primary'}
          size={isMobile ? 'S' : 'M'}
          fullWidth
        >
          {t('CoverDepositModal.DepositBadgeModalContent.continueBtn')}
        </Button>
        <Button
          onPress={() => {
            setSuccess(false)
            setError(false)
            downloadBadgeMutation({ variables: { channel: 'mail' } })
          }}
          style={s.middleButton}
          size={isMobile ? 'S' : 'M'}
          fullWidth
          isLoading={loading}
        >
          {t('CoverDepositModal.DepositBadgeModalContent.downloadBtn')}
        </Button>
        <Button
          onPress={() => {
            setSuccess(false)
            setError(false)
            onCancel()
          }}
          style={s.lastButton}
          size={isMobile ? 'S' : 'M'}
          fullWidth
        >
          {t('CoverDepositModal.DepositBadgeModalContent.cancelDeposit')}
        </Button>
      </View>
      <View style={s.smallFooterContainer}>
        <Label type={'small'}>{t('CoverDepositModal.DepositBadgeModalContent.depositProtectedBy')}</Label>
        <Image
          style={s.smallFooterLogo}
          resizeMode={'contain'}
          source={require('assets/img/screens/private/shoppingTunnel/CoverDepositModal/generali_logo.png')}
        />
      </View>
    </View>
  )
}

type PropsTypes = {
  style?: any,
  styles?: any,
  nextStep: function,
  onClose: function
}