import React, { type Node, useEffect, useState } from 'react'
import { Formik } from 'formik'
import { View } from 'react-native'
import { useMutation, useRelayEnvironment } from 'relay-hooks'
import { useTranslation } from 'react-i18next'
import { commitLocalUpdate } from 'react-relay'
import * as Yup from 'yup'
import { formatPrice } from 'src/utils/FormatDataUtils'
import useScreenSizes from 'src/utils/useScreenSizes'
import Icon from 'src/components/common/atoms/Icon'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import StepperFormik from 'src/components/common/forms/StepperFormik'
import s from './BasketProductConfig.style'

/**
 * Mole BasketProductConfig
 */
export default function BasketProductConfig ({
  orderProductId,
  productId,
  name,
  price,
  quantity,
  setSelectedOrderProduct,
  setSelectedProviderTitle,
  selectedProviderTitle,
  basketId,
  disableQuantity = false,
  isCover = false
}: PropsTypes): Node {

  // Init Values
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const environment = useRelayEnvironment()
  const [basketCount, setBasketCount] = useState(quantity)
  const [error, setError] = useState()

  useEffect(() => {
    setBasketCount(quantity)
  }, [quantity])

  // Mutation to add product to basket
  const [addProductToBasketMutation, { loading }] = useMutation(
    graphql`
      mutation BasketProductConfigAddQuantityMutation($productId: Int!) {
        AddProductToBasket(productId: $productId) {
          basket {
            id
            amount
            productCount
            cashbackEarnedAmount
            amountAfterReduction
            services {
              rowId
              amount
              name
              logoURL
              serviceType
              providerTitle
              products {
                id
                rowId
                productId
                productPrice
                productType
                isCover
                name
                price
                quantity
                customerComment
              }
            }          
          }
          product {
            id
            quantityInBasket
          }
        }
      }
    `,
    {
      onCompleted: () => setError(),
      onError: () => setError(t('Yup.global.errorTryAgain'))
    },
  )

  // Mutation to remove product from basket
  const [removeProductToBasketMutation, { loading: loadingRemove }] = useMutation(
    graphql`
      mutation BasketProductConfigRemoveQuantityMutation($productId: Int!) {
        RemoveProductFromBasket(productId: $productId) {
          basket {
            id
            amount
            productCount
            cashbackEarnedAmount
            amountAfterReduction
            services {
              rowId
              amount
              name
              logoURL
              serviceType
              providerTitle
              products {
                id
                rowId
                productId
                productPrice
                productType
                isCover
                name
                price
                quantity
                customerComment
              }
            }
          }
          product {
            id
            quantityInBasket
          }
        }
      }
    `,
    {
      onCompleted: ({ RemoveProductFromBasket }) => {
        setError()
        if (!RemoveProductFromBasket.basket) {
            commitLocalUpdate(environment, store => {
              store.delete(basketId)
            })
          }
      },
      onError: () => setError(t('Yup.global.errorTryAgain'))
    },
  )

  return (
    <View style={[s.productContainer, !isMobile && s.productContainerDesktop]}>
      {error && <Label type={'error'} size={isMobile ? 10 : 12}>{error}</Label>}
      <View style={s.productLabelContainer}>
        <Label size={isMobile ? 11 : 22}>{name}</Label>
        {!isCover && (
          <Button
            Icon={<Icon name={'locker'} size={isMobile ? 10 : 16}/>}
            style={[s.addDetailsButton, !isMobile && s.addDetailsButtonDesktop]}
            size={isMobile ? 'XS' : 'M'}
            onPress={() => {
              setSelectedOrderProduct(orderProductId)
              setSelectedProviderTitle(selectedProviderTitle)
            }}>
            {t('BasketProductConfig.addComment', { providerTitle: selectedProviderTitle })}
          </Button>
        )}
      </View>
      <View style={s.quantityContainer}>

        <Formik
          initialValues={{ count: basketCount }}
          validationSchema={Yup.object().shape({
            count: Yup.number().min(0)
          })}
        >
          {(formikProps) => (
            <StepperFormik
              {...formikProps}
              name={'count'}
              size={isMobile ? 'S' : 'M'}
              minimum={0}
              setQueryValue={setBasketCount}
              disabledMinus={disableQuantity || (loading || loadingRemove) || isCover}
              disabledPlus={disableQuantity || (loading || loadingRemove) || isCover}
              onIncrementPress={() => addProductToBasketMutation({ variables: { productId } })}
              onDecrementPress={() => removeProductToBasketMutation({ variables: { productId } })}
              val={basketCount}
            />
          )}
        </Formik>

        <Label size={isMobile ? 10 : 22} style={[s.selectPriceLabel, !isMobile && s.selectPriceLabelDesktop]}>
          x {formatPrice(price)}
        </Label>
      </View>
    </View>
  )
}

type PropsTypes = {
  orderProductId: number,
  productId: number,
  name: string,
  price: number,
  unitPrice: number,
  quantity: number,
  setSelectedOrderProduct: function,
  setSelectedProviderTitle: function,
  selectedProviderTitle: string,
  basketId?: number,
  disableQuantity?: boolean,
  isCover?: boolean
}
