// @flow
import React, { type Node, useState } from 'react'
import { formatPrice } from 'src/utils/FormatDataUtils'
import { useQuery } from 'relay-hooks'
import { Trans, useTranslation } from 'react-i18next'
import { View } from 'react-native'
import useScreenSizes from 'src/utils/useScreenSizes'
import Icon from 'src/components/common/atoms/Icon'
import Label from 'src/components/common/atoms/Label'
import Loader from 'src/components/common/atoms/Loader'
import Button from 'src/components/common/atoms/Button'
import { payWithWalletModalContent as s } from './PayModal.style'
import { Colors } from 'src/styles'

export default function PayWithWalletModalContent ({
  amount,
  creditsBalance,
  onClose,
  setModalStep,
  modalSteps,
  setPaidBasket,
  handlePay
}: PropsTypes): Node {

  // init values
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  const { data } = useQuery(
    graphql`
      query PayWithWalletModalContentQuery {
        recurringCreditCards {
          cardNumber
          token
        }
      }
    `
  )
  const recurringCreditCards = data?.recurringCreditCards

  // Handle payment submit
  const handlePaymentSubmit = () => {
    setError(false)
    setLoading(true)

    handlePay({ variables: { paymentMethod: 'METHOD_CREDITS' } })
      .then(() => {
        setLoading(false)
        setSuccess(true)
        setModalStep()
        setPaidBasket(true)
      })
      .catch(() => {
        setLoading(false)
        setError(true)
      })
  }

  if (!recurringCreditCards) {
    return (
      <View style={s.loadingContainer}>
        <Loader size={20}/>
      </View>
    )
  }

  return (
    <>
      <Label size={!isMobile ? 16 : 14}>{t('PayBasketModal.PayWithWalletModalContent.title')}</Label>

      {
        loading ? (
            <View style={s.iconContainer}>
              <Loader size={20}/>
            </View>
          ) :
          success ? (
            <View style={s.iconContainer}>
              <Icon name={'check'} color={Colors.orange100}/>
            </View>
          ) : (
            <>
              <Label size={isMobile ? 10 :  12} style={s.amountToPay}>
                <Trans
                  i18nKey={'PayBasketModal.toPay'}
                  values={{ amount: formatPrice(amount) }}
                  components={{ b: <Label type={'bold'} size={isMobile ? 10 :  12}/> }}
                />
              </Label>
              <Label size={isMobile ? 10 :  12} style={s.creditsBalance}>
                <Trans
                  i18nKey={'PayBasketModal.PayWithWalletModalContent.creditsBalance'}
                  values={{ creditsBalance: formatPrice(creditsBalance) }}
                  components={{ b: <Label type={'bold'} size={isMobile ? 10 :  12}/> }}
                />
              </Label>

              {amount > creditsBalance ? (
                <>
                  <Button palette={'primary'}
                          style={s.button}
                          onPress={() => setModalStep(modalSteps.WALLET_AND_CARD)}
                          size={'M'}
                          fullWidth>
                    {t('PayBasketModal.PayWithWalletModalContent.walletAndCard')}
                  </Button>
                  <Button style={s.button}
                          onPress={() => setModalStep(modalSteps.FILL_WALLET)}
                          size={'M'}
                          fullWidth>
                    {t('PayBasketModal.PayWithWalletModalContent.fillWallet')}
                  </Button>
                  <Button style={s.button}
                          onPress={() => setModalStep(modalSteps.NEW_CARD)}
                          size={'M'}
                          fullWidth>
                    {t('PayBasketModal.PayWithWalletModalContent.allByCard')}
                  </Button>
                </>
              ) : (
                <Button palette={'primary'}
                        style={s.button}
                        onPress={() => handlePaymentSubmit()}
                        size={'M'}
                        fullWidth>
                  {t('PayBasketModal.PayWithWalletModalContent.wallet')}
                </Button>
              )}

              <Button style={s.button} onPress={onClose} size={'M'} fullWidth>
                {t('PayBasketModal.changePaymentMethod')}
              </Button>

              <View style={s.securePayments}>
                <Label size={9}>{t('PayBasketModal.securePayments')}</Label>
                <Icon name={'lock'} size={15} style={s.adyenLogo}/>
              </View>
            </>
          )
      }
    </>
  )
}

type PropsTypes = {
  amount: number,
  creditsBalance: number,
  onClose: function,
  setModalStep: function,
  modalSteps: any,
  setPaidBasket: function,
  handlePay: function
}