// @flow
import React, { type Node, useRef, useState } from 'react'
import { View } from 'react-native'
import { Trans, useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { useMutation } from 'relay-hooks'
import * as Yup from 'yup'
import { type StackNavigationProp } from '@react-navigation/stack'
import type { RoutesParamList } from 'src/components/Navigation'
import useScreenSizes from 'src/utils/useScreenSizes'
import PublicLayout from 'src/components/common/layout/PublicLayout'
import Button from 'src/components/common/atoms/Button'
import TextInputFormik from 'src/components/common/forms/TextInputFormik'
import Label from 'src/components/common/atoms/Label'
import Icon from 'src/components/common/atoms/Icon'
import { Sizes } from 'src/styles'
import s from './ForgotPasswordScreen.styles'

/**
 * ForgotPasswordScreen screen.
 */
export default function ForgotPasswordScreen ({
  navigation
}: PropsTypes): Node {

  // Init state
  const { t } = useTranslation()
  const { isMobile, isDesktop, screenSize, isTablet } = useScreenSizes()
  const [mailSent, setMailSent] = useState(false)
  const formRef: any = useRef()

  // Form validation schema
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email(t('Yup.string.emailInvalid')),
  })

  // Request reset token API call
  const [requestResetMutation, { loading }] = useMutation(
    graphql`
      mutation ForgotPasswordScreenMutation($email: String!) {
        RequestResetToken(email: $email)
      }
    `,
    {
      onCompleted: () => {
        // Change page state to display success content
        setMailSent(true)
      },
      onError: () => {
        // Handle all cases as a general error because we do not want to give too much information for potential hacker
        formRef.current.setFieldError('email', t('ForgotPasswordScreen.errors.errorTryAgain'))
      }
    },
  )

  // Mail sent case
  if(mailSent === true) {
    return (
      <PublicLayout registerCompanion={true} navigation={navigation}>
        <View style={[s.mainContainerSuccess, !isMobile && s.mainContainerSuccessDesktop]}>

          {/* Icon success */}
          <Icon style={!isDesktop ? s.successIcon : s.successIconDesktop} name={'check'} size={!isDesktop ? 20 : 58} />

          {/* Title success */}
          <Label style={s.successTitle} size={!isDesktop ? 16 : 34}>{t('ForgotPasswordScreen.labels.success')}</Label>

          {/* Text success */}
          <View style={s.successTextContainer}>
            <Label size={isMobile ? 8 : isTablet ? 12 : 20}>
              <Trans
                i18nKey={'ForgotPasswordScreen.labels.emailSent'}
                values={{ email: formRef?.current?.values?.email }}
                components={{ email: <Label type={'bold'} size={isMobile ? 8 : isTablet ? 12 : 20}/> }}
              />
            </Label>
          </View>

          {/* Back to login */}
          <Button style={!isMobile && s.backToLoginButton}
                  palette={'primary'}
                  onPress={() => navigation.navigate({ name: 'Login' })}
                  size={isDesktop ? 'L' : 'M'}
          >
            {t('ForgotPasswordScreen.labels.loginReturn')}
          </Button>

        </View>
      </PublicLayout>
    )
  }

  // Render page
  return (
    <PublicLayout registerCompanion={true} navigation={navigation} >
      <View style={[s.mainContainer, !isMobile && s[`mainContainer${screenSize}`]]}>

        {/* Forgot password form */}
        <Formik
          innerRef={formRef}
          initialValues={{ email: '' }}
          validationSchema={forgotPasswordSchema}
          onSubmit={values => requestResetMutation({ variables: values })}
        >
          {(formikProps) => (
            <View style={[s.formContainer, !isMobile && s.formContainerDesktop]}>

              {/* Email */}
              <Label style={s.helper} size={!isDesktop ? 12 : 25}>
                {t('ForgotPasswordScreen.labels.resetPasswordInstructions')}
              </Label>
              <TextInputFormik
                style={[s.textInput, isDesktop && s.textInputDesktop]}
                name={'email'}
                placeholder={t('ForgotPasswordScreen.placeholders.email')}
                hasError={formikProps.errors.email && formikProps.touched.email}
                icon={'email'}
                autoCompleteType={'email'}
                textContentType={'emailAddress'}
                onSubmitEditing={formikProps.handleSubmit}
                outsideIcon={true}
                size={isDesktop ? 'XL' : 'M'}
                iconSize={isDesktop ? 46 : 19}
                autoCorrect={false}
                {...formikProps}
              />
              {formikProps.errors.email && formikProps.touched.email && (
                <Label style={[s.error, isDesktop && s.errorDesktop]} size={isDesktop ? 16 : 8}>
                  {formikProps.errors.email}
                </Label>
              )}

              {/* Actions buttons */}
              <Button style={isMobile ? s.submitButton : s.submitButtonDesktop}
                      onPress={formikProps.handleSubmit}
                      palette={'primary'}
                      size={isDesktop ? 'L' : 'M'}
                      isLoading={loading}
                      disabled={!formikProps.isValid || formikProps.values.email === ''}>
                {t('ForgotPasswordScreen.labels.resetPasswordButton')}
              </Button>
              <Button style={isMobile ? s.cancelButton : s.cancelButtonDesktop}
                      onPress={() => navigation.navigate({ name: 'Login' })}
                      size={isDesktop ? 'L' : 'M'}
              >
                {t('App.cancel')}
              </Button>

            </View>
          )}
        </Formik>

      </View>
    </PublicLayout>
  )
}

// PropsTypes
type PropsTypes = {
  navigation: StackNavigationProp<RoutesParamList, 'ForgotPassword'>
}