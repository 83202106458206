// @flow
import React, { type Node } from 'react'
import { ImageBackground, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { LinearGradient } from 'expo-linear-gradient'
import useScreenSizes from 'src/utils/useScreenSizes'
import moment from 'moment'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import Icon from 'src/components/common/atoms/Icon'
import ViewShadow from 'src/components/common/atoms/ViewShadow'
import s, { gradientColors } from './OrderCard.style'

/**
 * OrderCard mole.
 */
export default function OrderCard ({
  style,
  styles,
  orderNumber,
  color,
  retrieveBefore,
  date,
  width = 216,
  size = 'L',
  pictureURL,
  customerAction,
  onPress = () => {}
}: PropsTypes): Node {

  // Init state
  const { t } = useTranslation()
  const { isMobile, isTablet, isDesktop } = useScreenSizes()

  // Get user lang if we ever go mulitlingual
  moment.locale('fr')

  const margin= { right: isMobile ? 10 : isTablet ? 6 : 16, bottom:5 }

  // Render mole
  return (
    <ViewShadow
      onPress={onPress}
      style={[s.container, style, styles?.container, {width: width}]}
      shadowOpacity={0.8}
      shadowRadius={4}
      shadowOffset={{width: 0, height: 2}}
      withColor={{color: color, width: width, height: size === 'M' ? 50 : 80, radius: 0}}
      margin={margin}
      hasPadding={true}
    >
      <View style={[s.cardContainer, styles?.cardContainer]}>

        {/* Background image */}
        <ImageBackground
          source={pictureURL ? { uri: pictureURL } : require('assets/img/screens/private/HomeScreen/default_qorner_banner.png')}
          style={[s.dateImage, s[`image${size}`], styles?.dateImage]}
          imageStyle={[s.image, styles?.image]}
        >

          {/* Date */}
          <Label
            color={'white'}
            type={'bold'}
            size={isDesktop ? 34 : 24}
            style={[s.dateDay, styles?.dateDay]}>
            {moment(date).format('DD')}
          </Label>
          <Label
            color={'white'}
            style={[s.dateMonth, styles?.dateMonth]}
            size={isDesktop ? 14 : 11}
            type={'bold'}>
            {moment(date).format('MMM')}
          </Label>

          <LinearGradient
            colors={gradientColors}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
            style={[s.gradient, styles?.gradient]}
          />
        </ImageBackground>

        {/* Text container */}
        <View style={[s.textContainer, styles?.textContainer]}>

          {/* Order number */}
          <Label size={isDesktop ? 18 : 12}>
            {t('OrderCard.order', { id: orderNumber })}
          </Label>

          {/* Button? */}
          <View style={[s.buttonContainer, styles?.buttonContainer]}>
            <Button
              onPress={onPress}
              palette={'primary'}
              size={size === 'M' ? 'XS' : 'S'}
              Icon={<Icon name={'edit'} color={'#fff'}/>}
            >
              {t(`OrderCard.${customerAction}`)}
            </Button>
          </View>

        </View>
      </View>
    </ViewShadow>
  )
}

// PropsTypes
type PropsTypes = {
  style?: any,
  styles?: any,
  orderNumber: string,
  color: string,
  retrieveBefore: string,
  date: string,
  size?: 'M' | 'L' | 'XL',
  pictureURL?: string,
  customerAction: string,
  onPress?: function,
  width: number
}