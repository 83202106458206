/**
 * @generated SignedSource<<a912c99688377750e5355b04b5cfac9c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "pictureURL",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "PrivateLayoutQuery",
    "selections": (v0/*: any*/),
    "type": "ClientAppQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "PrivateLayoutQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "220d346772046e450c1659c1241455f8",
    "id": null,
    "metadata": {},
    "name": "PrivateLayoutQuery",
    "operationKind": "query",
    "text": "query PrivateLayoutQuery {\n  user {\n    id\n    firstName\n    pictureURL\n  }\n}\n"
  }
};
})();

node.hash = "a418de39717f23aac39b5a5e05f01b47";

module.exports = node;
