import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  title: {
    marginBottom: 10
  },
  titleDesktop: {
    marginBottom: 30
  },
  content: {
    marginBottom: 35
  },
  formContainer: {
    marginBottom: 20
  },
  formRow: {
    flexDirection: 'row',
    marginBottom:10,
    alignItems: 'center'
  },
  textInputContainer: {
    flex: 1,
  },
  textInputDesktop: {
    marginBottom: 20,
  },
  button: {
    marginBottom: 10
  },
  buttonDesktop: {
    marginBottom: 20
  },
  buttonContainer: {
    marginRight: 35,
    marginLeft: 35,
    marginBottom: 35
  },
  buttonContainerDesktop: {
    width: '60%',
    alignSelf: 'center'
  },
  protectedBy: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  protectedByLogo: {
    marginLeft: 5,
    height: 8,
    width: 36
  },
  successStep: {
    marginTop: 72,
    marginBottom:97,
    textAlign: 'center'
  },
  loaderStep: {
    marginTop: 72,
    marginBottom:90,
  }
})