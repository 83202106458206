// @flow
import React, { type Node } from 'react'
import { View, Image } from 'react-native'
import { useTranslation } from 'react-i18next'
import { SafeAreaView } from 'react-native-safe-area-context'
import useScreenSizes from 'src/utils/useScreenSizes'
//import LanguageSwitcher from 'src/components/common/moles/LanguageSwitcher/LanguageSwitcher'
import Button from 'src/components/common/atoms/Button'
import Label from 'src/components/common/atoms/Label'
import s from './PublicLayout.style'
//import { Colors } from 'src/styles'

/**
 * PublicLayout layout.
 */
export default function PublicLayout({
  navigation,
  children = null,
  registerCompanion = false,
  loginCompanion = false
}: PropsTypes): Node {

  // Init state
  const { isMobile, isTablet, width } = useScreenSizes()
  const { t } = useTranslation()

  // Render layout
  return (
    <SafeAreaView style={[
      s.publicLayout,
      registerCompanion && !isMobile && s.registerCompanionLayout
    ]}>

      {/* Register companion bar for desktop */}
      {registerCompanion && !isMobile && (
        <View style={s.companion}>

          {/* Language switcher */}
          {/*<View style={s.languageSwitcherContainerRegisterCompanion}>*/}
          {/*  <LanguageSwitcher color={Colors.white100}/>*/}
          {/*</View>*/}

          {/* Texts */}
          <Label style={[s.companionTitle, isTablet && s.companionTitleTablet]} size={isTablet ? 25 : 56}>{t('PublicLayout.registerCompanion.title')}</Label>
          <Label style={[s.companionText, isTablet && s.companionTextTablet]} size={isTablet ? 14 : 36}>{t('PublicLayout.registerCompanion.content1')}</Label>
          <Label style={[s.companionText, isTablet && s.companionTextTablet]} size={isTablet ? 14 : 36}>{t('PublicLayout.registerCompanion.content2')}</Label>

          {/* Register button */}
          <Button
            style={[s.companionButton, isTablet && s.companionButtonTablet]}
            size={isTablet ? 'S' : 'L'}
            onPress={() => navigation.navigate({ name : 'Register' })}
          >
            {t('PublicLayout.registerCompanion.register')}
          </Button>

        </View>
      )}

      {/* Page content */}
      <View style={s.pageContainer}>

        {/* Header */}
        {/*<View style={s.header}>*/}

          {/* Language switcher */}
          {/*{isMobile && (*/}
          {/*  <View style={s.languageSwitcherContainer}>*/}
          {/*    <LanguageSwitcher />*/}
          {/*  </View>*/}
          {/*)}*/}

          {/* Brand Logo */}

        {/*</View>*/}

        {/* Main content */}
        <View style={s.mainContent}>
          <Image
            style={[s.logo, !isMobile && s.logoTablet, width >= 1024 && s.logoDesktop]}
            source={require('assets/img/common/layout/PublicLayout/logo_horizontal_orange.png')}
          />
          {children}
        </View>

      </View>

      {/* Login companion bar for desktop */}
      {loginCompanion && !isMobile && (
        <View style={s.companion}>

          {/* Language switcher */}
          {/*<View style={s.languageSwitcherContainerLoginCompanion}>*/}
          {/*  <LanguageSwitcher color={Colors.white100}/>*/}
          {/*</View>*/}

          {/* Texts */}
          <Label style={[s.companionTitle, isTablet && s.companionTitleTablet]} size={isTablet ? 25 : 56}>{t('PublicLayout.loginCompanion.title')}</Label>
          <Label style={[s.companionText, isTablet && s.companionTextTablet]} size={isTablet ? 14 : 36}>{t('PublicLayout.loginCompanion.content1')}</Label>
          <Label style={[s.companionText, isTablet && s.companionTextTablet]} size={isTablet ? 14 : 36}>{t('PublicLayout.loginCompanion.content2')}</Label>

          {/* Register button */}
          <Button
            style={[s.companionButton, isTablet && s.companionButtonTablet]}
            size={isTablet ? 'S' : 'L'}
            onPress={() => navigation.navigate({ name : 'Login' })}
          >
            {t('PublicLayout.loginCompanion.login')}
          </Button>

        </View>
      )}

    </SafeAreaView>
  )
}

// PropsTypes
type PropsTypes = {
  navigation: any,
  children?: any,
  registerCompanion?: boolean,
  loginCompanion?: boolean
}
