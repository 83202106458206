// @flow
import React, { type Node, useEffect, useState } from 'react'
import moment from 'moment'
import { graphql } from 'relay-runtime'
import { View, FlatList, SectionList } from 'react-native'
import { useQuery } from 'relay-hooks'
import { useTranslation } from 'react-i18next'
import type { RoutesParamList } from 'src/components/Navigation'
import { type StackNavigationProp } from '@react-navigation/stack'
import { useFocusScreenEffect } from 'src/utils/ComponentUtils'
import useScreenSizes from 'src/utils/useScreenSizes'
import PrivateLayout from 'src/components/common/layout/PrivateLayout'
import Modal from 'src/components/common/atoms/Modal'
import NewsPreview from 'src/components/common/moles/NewsPreview'
import Loader from 'src/components/common/atoms/Loader'
import Label from 'src/components/common/atoms/Label'
import NewsScreenModal from './NewsScreenModal'
import Icon from 'src/components/common/atoms/Icon'
import s from './NewsScreen.style'
import { isTablet } from 'react-native-calendars/src/expandableCalendar/commons'

/**
 * NewsScreen.
 */
export default function NewsScreen ({ navigation, route }: PropsTypes): Node {

  // Init values
  const { t } = useTranslation()
  const { isMobile, width: fullWidth, isTablet } = useScreenSizes()
  const [selectedNewsId, setSelectedNewsId] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [articlesByPage, setArticlesByPage] = useState({})
  const [articlesByDate, setArticlesByDate] = useState([])
  const itemHeight = 100
  const cardWidth = isMobile ? fullWidth - 30 : isTablet ? (fullWidth - 260) / 3 - 20 : (fullWidth - 390) / 3 - 20

  // Opens the modal if the user come from the click on an article
  useFocusScreenEffect(() => {
    if (route.params?.newsId) {
      setSelectedNewsId(route.params?.newsId)
    }
  })

  // Retrieve articles query
  const { data, isLoading } = useQuery(graphql`
    query NewsScreenQuery($page: Int) {
      articles (page: $page) {
        items {
          id
          rowId
          title
          mainPictureUrl
          publishedAt
        }
        totalCount
      }
    }
  `,
    { page: currentPage },
    {
      fetchPolicy: 'store-and-network',
      fetchKey: currentPage,
      networkCacheConfig: {
        force: true,
      },
    }
  )
  const lastPageNumber = data?.articles.totalCount ? Math.ceil(data.articles.totalCount / 5) : 1

  // Retrieve articles query
  const { data: user } = useQuery(graphql`
    query NewsScreenUserQuery {
        user {
            lang
        }
    }`
  )

  // Append new page of article to our list of page
  useEffect(() => {
    if (!data) { return }
    setArticlesByPage({ ...articlesByPage, [currentPage]: data.articles.items })
    moment.locale(user?.lang)
  }, [data])

  // Group articles by date and not by page
  useEffect(() => {

    // Iterate on all the page of articles
    let articlesGroupByDate = []
    for(let page in articlesByPage) {
      let articles = articlesByPage[page]

      // For each article of these page
      articles.forEach(article => {

        // Append it to the proper group of date
        let articleDate = moment(article.publishedAt).format('LL');
        if (articlesGroupByDate[articleDate] === undefined) { articlesGroupByDate[articleDate] = { date: article.publishedAt, data: [] } }
        articlesGroupByDate[articleDate].data.push(article)

      })
    }

    // Convert associative array of date => { date: XXX, items: XXX } to numerical array and save the value
    let articlesByDays = []
    for(let day in articlesGroupByDate) {
      articlesByDays.push(articlesGroupByDate[day])
    }
    setArticlesByDate(articlesByDays)

  }, [articlesByPage])

  // Render loading state
  if (!articlesByPage[1]) {
    return (
      <PrivateLayout headerTitle={t('NewsScreen.title')}>
        <View style={s.loadingContainer}>
          <Loader/>
        </View>
      </PrivateLayout>
    )
  }

// Render screen
  return (
    <>
      <PrivateLayout
        headerTitle={t('NewsScreen.title')}
        headerBackOnPress={() => navigation.navigate({ name: 'Home'} )}
        headerBackTitle={t('Navigation.naming.Home')}
      >

        {/* Header banner in desktop version */}
        {!isMobile && (
          <View style={s.desktopHeaderContainer}>
            <Icon name={'news'} style={s.desktopBackgroundIcon} size={154}/>

            <View style={s.desktopTitleContainer}>
              <Icon name={'news'} style={s.desktopTitleIcon} size={60}/>
              <Label type={'bold'} size={28}>{t('NewsScreen.title')}</Label>
            </View>
          </View>
        )}

        {/* Article list */}
        {isMobile ? (
          <View style={s.container}>
            <SectionList
              keyExtractor={item => item.id}
              sections={articlesByDate}
              onEndReached={() => {
                if(currentPage < lastPageNumber && !isLoading) {
                  setCurrentPage(currentPage + 1)
                }
              }}
              getItemLayout={(data, index) => ({ length: itemHeight, offset: itemHeight * index, index: index })}
              onEndReachedThreshold={0.5}
              ListEmptyComponent={<Label size={12}>{t('NewsScreen.empty')}</Label>}
              renderItem={({ item: news }) => (
                <NewsPreview
                  image={news.mainPictureUrl}
                  publishedAt={news.publishedAt}
                  title={news.title}
                  styles={{ container: { width: cardWidth }, image: { width: cardWidth } }}
                  onPress={() => setSelectedNewsId(news.rowId)}
                />)
              }
              renderSectionHeader={({ section: { date } }) => (
                <Label type={'bold'} size={15} style={s.dateLabel}>
                  {moment(date).format('LL')}
                </Label>
              )
              }
            />
          </View>
        ) : (
          // Desktop view
          <View style={s.desktopListContainer}>
            <FlatList
              data={articlesByDate}
              keyExtractor={(item) => item.date.toString()}
              onEndReached={() => {
                if(currentPage < lastPageNumber && !isLoading) {
                  setCurrentPage(currentPage + 1)
                }
              }}
              extraData={{ fullWidth: fullWidth }}
              onEndReachedThreshold={0.5}
              ListEmptyComponent={<Label size={18}>{t('NewsScreen.empty')}</Label>}
              getItemLayout={(data, index) => ({ length: itemHeight, offset: itemHeight * index, index: index })}
              renderItem={({ item: articlesOfDay }) => {

                return (
                  <>
                    <Label type={'bold'} size={isMobile ? 15 : 20} style={[s.dateLabel, !isMobile && s.dateLabelDesktop]}>
                      {moment(articlesOfDay.date).format('LL')}
                    </Label>

                    <FlatList
                      numColumns={3}
                      data={articlesOfDay.data}
                      keyExtractor={item => item.id}
                      renderItem={({ item: news }) => (
                        <NewsPreview
                          size={'L'}
                          publishedAt={news.publishedAt}
                          image={news.mainPictureUrl}
                          title={news.title}
                          styles={{ container: { width: cardWidth }, image: { width: cardWidth } }}
                          onPress={() => setSelectedNewsId(news.rowId)}
                        />
                      )}
                    />
                  </>
                )
              }}
            />
          </View>
        )}

      </PrivateLayout>

      {/* Modal */}
      <Modal open={!!selectedNewsId} onClose={() => setSelectedNewsId()} style={{ maxHeight: '80%' }}>
        <NewsScreenModal newsId={selectedNewsId}/>
      </Modal>
    </>
  )
}

// PropsTypes
type PropsTypes = {
  route: any,
  navigation: StackNavigationProp<RoutesParamList, 'News'>
}
