// @flow
import React, { type Node } from 'react'
import { View } from 'react-native'
import { useQuery } from 'relay-hooks'
import { useStoreState } from 'pullstate'
import { useTranslation } from 'react-i18next'
import GlobalRefetchStore from 'src/store/GlobalRefetchStore'
import Button from 'src/components/common/atoms/Button'
import Icon from 'src/components/common/atoms/Icon'
import Label from 'src/components/common/atoms/Label'
import { Colors, Sizes } from 'src/styles'
import s from './BasketButton.style'

/**
 * BasketButton.
 */
export default function BasketButton ({
  navigation,
  small = true
}: PropsTypes): Node {

  // Init
  const { t } = useTranslation()

  // Global fetch key which allow to ask for basket count refresh from any other component
  const basketCountFetchKey = useStoreState(GlobalRefetchStore, s => s.basketCountFetchKey)

  // Retrieve basket count
  const { data } = useQuery(
    graphql`
      query BasketButtonQuery {
        basket {
          productCount
          id
          rowId
          amount
          amountAfterReduction
          useCashback
          cashbackEarnedAmount
          requiredCoverAmount
          voucher {
            code
            percentage
            price
          }
          services {
            rowId
            amount
            minAmountPerOrder
            lockerMinAmountPerOrder
            name
            logoURL
            serviceType
            providerTitle
            products {
              rowId
              productId
              productPrice
              productType
              isCover
              name
              price
              quantity
              customerComment
            }
          }
          deliverySettings {
            collectDate
            deliveryDate
            price
            collectLocker {
              name
            }
            deliveryLocker {
              name
            }
            requiredCollectAndDelivery
          }
        }
      }
    `,
    {},
    {
      fetchPolicy: 'network-only',
      fetchKey: basketCountFetchKey,
      networkCacheConfig: {
        force: true,
      },
    }
  )
  const basket = data?.basket

  // Render mole.
  return (
    <View style={s.container}>

      {/* Button with Icon */}
      <Button
        style={s.resetButtonStyle}
        onPress={() => navigation.navigate({ name: 'Basket' })}
      >

        {/* Icon & counter */}
        <View style={[s.iconContainer, !small && s.fixIconContainerPosition]}>

          {/* Icon */}
          <Icon style={s.icon} name={'basket'} size={Sizes.XM} color={Colors.mediumGrey100}/>

          {/* Product count */}
          <View style={[
            s.counter,
            // Use this trick to avoid counter bad position on web
            basket?.productCount !== undefined && s.counterVisible
          ]}>
            <Label size={10} type={'bold'} color={Colors.white100} style={s.counterLabel}>
              {basket?.productCount}
            </Label>
          </View>

        </View>

        {/* Label on desktop */}
        {!small && (
          <Label style={s.basketLabel}>{t('BasketButton.label')}</Label>
        )}
      </Button>

    </View>
  )
}

// PropsTypes
type PropsTypes = {
  navigation: any
}