import { Platform, StyleSheet } from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import { Colors } from 'src/styles'

// Styles
export default StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  container: {
    flexDirection: 'column',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    paddingTop: 20,
    paddingBottom: 20,
  },
  containerDesktop: {
    paddingTop: 60,
  },
  sideMargin: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  sideMarginDesktop: {
    paddingLeft: 60,
    paddingRight: 20,
  },
  actionButton: {
    width: 190,
    marginBottom: 10
  },
  mainTitle: {
    marginBottom: 30
  },
  descriptionText: {
    maxWidth: 1100,
  },
  profileTypeChoiceContainerNotMobile: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  chooseType: {
    marginTop: 60,
    marginBottom: 10
  },
  chooseTypeNotMobile: {
    textAlign: 'center',
    marginBottom: 60
  },
  chooseTypeButtonContainer: {
    width: wp('30%'),
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  chooseTypeButtonContainerTablet: {
    width: wp('50%'),
  },
  chooseTypeButton: {
    width: '100%',
    marginTop: 20
  },
  chooseTypeButtonLabel: {
    textAlign: 'center',
    fontSize: 20,
    color: Colors.white100,
    fontWeight: 'bold'
  },
  chooseTypeButtonNotMobile: {
    width: 200,
    height: 200,
    backgroundColor: Colors.orange100,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 5,
    ...Platform.select({ web: { cursor: 'pointer' }})
  },
  publicUserIcon: {
    marginTop: 10,
    width: 100,
    height: 100
  },
  companyUserIcon: {
    marginTop: 10,
    width: 90,
    height: 90
  },
  changeType: {
    flexDirection: 'row',
    marginTop: 20,
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  changeTypeNotMobile: {
    justifyContent: 'flex-start',
    marginTop: 60,
    alignItems: 'center'
  },
  changeTypeButtonNotMobile: {
    width: 150,
    marginLeft: 30
  },
  searchInput: {
    marginTop: 20,
    marginBottom: 20
  },
  searchInputNotMobile: {
    width: 350,
    marginTop: 30,
    marginBottom: 30
  },
  knownMailQorners: {
    marginBottom: 20
  },
  knownMailQornersDesktop: {
    marginBottom: 30
  },
  flatListContainer: {
    paddingTop: 2,
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20
  },
  flatListContainerDesktop: {
    paddingLeft: 10,
    paddingRight:0
  },
  flatListContainerTablet: {
    paddingLeft: 10,
    paddingRight:10
  },
  resultsContainer: {
    flex: 1
  },
  resultsContainerNotMobile: {
    marginLeft: 50,
    marginRight: 60,
    flexDirection: 'row'
  },
  validateQornerSelectionDesktop: {
    flex: 2,
    borderLeftWidth: 1,
    borderLeftColor: Colors.lightGrey100
  },
  rowSpace: {
    height: 10
  },
  rowSpaceDesktop: {
    height: 20
  },
  backAndTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  backButtonContainer: {
    height: 25,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 20
  },
  backButtonContainerNotMobile: {
    height: 43
  },
  backButton: {
    width: 30,
    height: 30,
    lineHeight: 30,
    fontSize: 30,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: 60,
    borderWidth: 0,
    backgroundColor: Colors.lightGrey90
  },
  backButtonIcon: {
    color: Colors.mediumGrey100
  },
  qornerCardContainerNotMobile: {
    paddingRight: 30,
    width: '50%'
  }
})