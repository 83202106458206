// @flow
import React, { type Node } from 'react'
import { Pressable, ImageBackground, View } from 'react-native'
import { LinearGradient } from 'expo-linear-gradient'
import { useTranslation } from 'react-i18next'
import { formatStringLength } from 'src/utils/FormatDataUtils'
import useScreenSizes from 'src/utils/useScreenSizes'
import Label from 'src/components/common/atoms/Label'
import s, { gradientColors, imageLegendSizes, titleSizes } from './NewsPreview.style'

/**
 * NewsPreview Mole
 */
export default function NewsPreview ({
  size = 'M',
  image,
  publishedAt,
  title,
  fullWidth,
  onPress,
  width,
  style,
  styles
}: PropsTypes): Node {

  // Init
  const { t } = useTranslation()
  const { isMobile, isDesktop } = useScreenSizes()

  // Render mole
  return (
    <Pressable
      style={[
        s.container,
        isDesktop && s.containerDesktop,
        s[size],
        fullWidth && { width: '100%' },
        width && { width: width },
        style,
        styles?.container
      ]}
      onPress={onPress}
    >

      {/* News image */}
      <ImageBackground
        source={{ uri: image }}
        style={[
          s.image,
          s[`image${size}`],
          fullWidth && { width: '100%' },
          width && { width: width },
          styles?.image
        ]}
        imageStyle={s.imageStyle}
      >
        {publishedAt && (
          <Label color={'white'} size={imageLegendSizes[size]} style={[s.imageLegend, styles?.imageLegend]}>
            {t('NewsPreview.publishedAt', { date: new Date(publishedAt).toLocaleDateString() })}
          </Label>
        )}

        {/* Gradient over image, helps date reading */}
        <LinearGradient
          colors={gradientColors}
          style={[s.gradient, s[`gradient${size}`], styles?.gradient]}
        />

      </ImageBackground>

      {/* News title */}
      <View style={[s.title, s[`title${size}`], styles?.title]}>
        <Label size={titleSizes[size]} numberOfLines={2}>{title}</Label>
      </View>

    </Pressable>
  )
}

type PropsTypes = {
  size?: 'S' | 'M' | 'L',
  image: string,
  publishedAt?: string,
  title: string,
  fullWidth?: boolean,
  onPress?: function,
  width?: number,
  style?: any,
  styles?: any
}