import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

export default StyleSheet.create({
  container: {
    position: 'relative',
    overflow: 'hidden'
  },
  titleContainer: {
    height: 30,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.red100,
    paddingHorizontal: 8
  },
  titleContainerDesktop: {
    height:80,
    paddingHorizontal: 20
  },
  titleContainerTablet: {
    height:50,
    paddingHorizontal: 20
  },
  titleDate: {
    marginLeft: 4,
    marginBottom: 0
  },
  titleDateDesktop: {
    marginLeft: 15,
  },
  titleButton: {
    position: 'absolute',
    zIndex: 1,
    top: 22,
    right: 8
  },
  titleButtonTablet: {
    top: 35,
    right: 15
  },
  contentContainer: {
    height: 100,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  contentContainerDesktop: {
    height: 380
  },
  contentContainerTablet: {
    height: 250
  },
  affiliationContainer: {
    backgroundColor: Colors.red100Less80
  },
  affiliationSubContainer: {
    alignItems: 'center'
  },
  affiliationSeparationContainer: {
    flex: 1,
    height: '100%',
    overflow: 'hidden',
    alignItems: 'center',
  },
  affiliationSeparationBar: {
    width: '100%',
    height: '110%',
    backgroundColor: Colors.red100Less90,
    top: -3,
    right: '-50%',
    transform: [{rotate: '10deg'}]
  },
  affiliationImage: {
    flex: 1,
    width: '100%',
    maxHeight: 40,
    marginTop: 14,
    marginBottom: 6
  },
  affiliationImageDesktop: {
    maxHeight: 120,
    height: 120,
    marginBottom: 16
  },
  affiliationImageTablet: {
    maxHeight: 100,
    height: 100,
    marginBottom: 16
  },
  affiliationLabelContainer: {
    flex: 1,
  },
  centerLabel: {
    textAlign: 'center'
  },
  centerContent: {
    flex: 2,
    textAlign: 'center',
    alignItems: 'center'
  },
  backgroundIcon: {
    position: 'absolute'
  },
  leftContent: {
    flex: 1,
    height: '100%',
    position: 'relative'
  },
  rightContentContainer: {
    alignItems: 'flex-end'
  },
  rightContent: {
    flex: 1,
    height: '100%',
    position: 'relative'
  },
  largeIconContainer: {
    width: 80,
    height: 80,
    borderRadius: 80
  },
  largeIconContainerDesktop: {
    alignItems: 'flex-end',
    width: 240,
    height: 240,
    borderRadius: 240
  },
  largeIconContainerTablet: {
    alignItems: 'flex-end',
    width: 150,
    height: 150,
    borderRadius: 150
  },
  smallIconContainer: {
    width: 40,
    height: 40,
    borderRadius: 40
  },
  smallIconContainerDesktop: {
    width: 120,
    height: 120,
    borderRadius: 120
  },
  smallIconContainerTablet: {
    width: 80,
    height: 80,
    borderRadius: 80
  },
  largeIcon: {
    width: 80,
    height: 80,
    borderRadius: 80,
    position: 'absolute'
  },
  largeIconDesktop: {
    width: 240,
    height: 240,
    borderRadius: 240,
  },
  largeIconTablet: {
    width: 150,
    height: 150,
    borderRadius: 150,
  },
  smallIcon: {
    width: 40,
    height: 40,
    borderRadius: 40,
    position: 'absolute'
  },
  smallIconDesktop: {
    width: 120,
    height: 120,
    borderRadius: 120
  },
  smallIconTablet: {
    width: 80,
    height: 80,
    borderRadius: 80
  },
  smallIconLeftFirst: {
    top: 12,
    left: -10
  },
  smallIconLeftSecond: {
    top: 12,
    left: 14
  },
  smallIconLeftFirstDesktop: {
    marginTop: 64,
    left: 30
  },
  smallIconLeftSecondDesktop: {
    left: 150,
    top: 0
  },
  smallIconLeftFirstTablet: {
    marginTop: 40,
    left: 30,
    top: 0
  },
  smallIconLeftSecondTablet: {
    left: 100,
    top: 0
  },
  smallIconRightFirst: {
    top: 12,
    right: -10
  },
  smallIconRightSecond: {
    top: 12,
    right: 14
  },
  smallIconRightFirstDesktop: {
    marginTop: 64,
    right: 30
  },
  smallIconRightSecondDesktop: {
    top: 0,
    right: 150
  },
  smallIconRightFirstTablet: {
    marginTop: 40,
    right: 30,
    top: 0
  },
  smallIconRightSecondTablet: {
    top: 0,
    right: 100
  },
  largeIconLeft: {
    top: 14,
    left: -28
  },
  largeIconRight: {
    top: 14,
    right: -28
  },
  largeIconLeftDesktop: {
    top: 64,
    left: -85
  },
  largeIconRightDesktop: {
    top: 64,
    right: -85
  },
  largeIconLeftTablet: {
    top: 52,
    left: -65
  },
  largeIconRightTablet: {
    top: 52,
    right: -65
  },
})

export const backgroundType = {
  AFFILIATE_LINK: {
    backgroundColor: '#fff',
  },
  CASHBACK_CAMPAIGN: {
    backgroundColor: Colors.yellow100Less70,
  },
  DISCOUNT_CAMPAIGN: {
    backgroundColor: Colors.red100Less70,
  }
}