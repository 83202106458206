/**
 * @generated SignedSource<<2b87505d2c690642c7001d6d08df9306>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "qornerId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "qornerId",
        "variableName": "qornerId"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "SelectQorner",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Qorner",
        "kind": "LinkedField",
        "name": "companyQorner",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rowId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "qornerType",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ValidateQornerSelectionModalMutation",
    "selections": (v2/*: any*/),
    "type": "ClientAppMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ValidateQornerSelectionModalMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "78e54f1230d30bf81c204e12f4f99e78",
    "id": null,
    "metadata": {},
    "name": "ValidateQornerSelectionModalMutation",
    "operationKind": "mutation",
    "text": "mutation ValidateQornerSelectionModalMutation(\n  $qornerId: Int!\n) {\n  SelectQorner(qornerId: $qornerId) {\n    id\n    companyQorner {\n      id\n      rowId\n      qornerType\n    }\n  }\n}\n"
  }
};
})();

node.hash = "04e537e971c153bccbd83ea1499823db";

module.exports = node;
