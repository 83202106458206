// @flow
import React, {useRef, useEffect, type Node} from 'react'
import {View, TextInput} from 'react-native'
import {useFocus} from 'react-native-web-hooks'
import Icon from 'src/components/common/atoms/Icon'
import type {flowSizes} from 'src/styles/sizes'
import s, {iconSizes} from './TextInputFormik.style'
import {Fonts} from 'src/styles'

/**
 * TextInputFormik atom.
 */
export default function TextInputFormik({
                                            styles,
                                            style,
                                            size = 'M',
                                            palette = 'default',
                                            placeholder = '',
                                            autoCompleteType = 'off',
                                            textContentType = 'none',
                                            editable = true,
                                            multiline = false,
                                            icon = null,
                                            iconSize = null,
                                            name = '',
                                            handleChange,
                                            handleBlur,
                                            values,
                                            secureTextEntry = false,
                                            hasError = false,
                                            mask = null,
                                            setFieldValue,
                                            onChangeText,
                                            numberOfLines = 1,
                                            autoFocus = false,
                                            innerRef = null,
                                            placeholderColor = "#CCC",
                                            onSubmitEditing,
                                            outsideIcon = false,
                                            returnKeyType = null,
                                            autocapitalize = 'none',
                                            autoCorrect = true,
                                            maxLength = null
                                        }: PropsTypes): Node {

    // Handle hover and focus state
    const ref = innerRef ? innerRef : (useRef(null): any)
    const isFocused = useFocus(ref)

    /*
     * Mask formatting:
     * 0: Any number
     * A: Any alphatical character
     * *: Anything
     */
    const formatWithMask = val => {
        // Iterate on the whole list of char in the value
        let newVal = ''
        for (let i = 0, j = 0; i < val.length; i++, j++) {

            // Is there a char in the mask at this position ?
            if (!mask[j]) break;

            // Only accept the char in value if mask authorize it
            switch (mask[j]) {
                // Number
                case '0':
                    newVal += val[i].replace(/[^0-9]+/g, '')
                    break
                // Letter
                case 'A':
                    newVal += val[i].replace(/[^a-zA-Z]+/g, '')
                    break
                // Any
                case '*':
                    newVal += val[i]
                    break
                // Specific ONE char in mask (probably don't work for multiple specific chars ?)
                default:
                    if (val[i] !== mask[j]) {
                        newVal += mask[j] + val[i]
                        j++
                    } else {
                        newVal += val[i]
                    }
                    break
            }
        }

        // Return value filtered by mask
        return newVal

    }

    // Set default value for mask input
    useEffect(() => {
        if (mask && values && values[name] && setFieldValue) {
            setFieldValue(name, formatWithMask(values[name]))
        }
    }, [])

    // This fixes the problem of font-family with secureTextEntry on Android Devices
    // The problem should be fix in react-native 0.64
    useEffect(() => {
        if (!ref.current) {
            return
        }
        ref.current.setNativeProps({
            style: {fontFamily: Fonts.primary.regular}
        })
    }, [ref?.current])

    // Default onChangeText
    let onChangeTextCallback = (value) => {
        handleChange(name)(value)
        if (onChangeText) {
            onChangeText(value)
        }
    }
    // Override for mask input
    if (mask && setFieldValue) {
        onChangeTextCallback = val => setFieldValue(name, formatWithMask(val))
    }

    // Render Input
    return (
        <View style={[s.container, style, styles?.container, outsideIcon && s[`outsideIconContainer${size}`]]}>

            {/* Icon */}
            {icon && <Icon
                name={icon}
                size={iconSize ? iconSize : iconSizes[size].width - 2}
                style={[
                    s.icon, iconSizes[size], styles?.icon,
                    outsideIcon && ([s.outsideIconStyle, iconSizes[`outside${size}`]])
                ]}
            />}

            {/* Input */}
            <TextInput
                ref={ref}
                style={[
                    // Default style
                    s.input,
                    // Palette ?
                    s[palette],
                    // Size
                    s[size],
                    // Multiline
                    multiline && s.multiline,
                    // Icon
                    icon && !outsideIcon && {paddingLeft: iconSizes['iconInput' + size].paddingLeft},
                    // Focus state
                    isFocused && s['active' + palette[0].toUpperCase() + palette.slice(1)],
                    // Disable state
                    !editable && s['disabled' + palette[0].toUpperCase() + palette.slice(1)],
                    // Error state
                    hasError && s.hasError,
                    // Custom style
                    styles?.textInput,
                ]}
                autoFocus={autoFocus}
                value={values && values[name] ? values[name] : ''}
                placeholder={placeholder}
                placeholderTextColor={placeholderColor}
                editable={editable}
                multiline={multiline}
                autoCompleteType={autoCompleteType}
                textContentType={textContentType}
                onChangeText={onChangeTextCallback}
                onBlur={handleBlur(name)}
                secureTextEntry={secureTextEntry}
                numberOfLines={numberOfLines}
                onSubmitEditing={onSubmitEditing}
                returnKeyType={returnKeyType}
                autoCapitalize={autocapitalize}
                autoCorrect={autoCorrect}
                maxLength={maxLength}
            />

        </View>
    )
}

// PropsTypes
type PropsTypes = {
    styles?: any,
    style?: any,
    size?: flowSizes,
    palette?: string,
    handleChange: function,
    handleBlur: function,
    values: any,
    name?: string,
    placeholder: string,
    autoCompleteType?: 'cc-csc' | 'cc-exp' | 'cc-exp-month' | 'cc-exp-year' | 'cc-number' | 'email' | 'name' | 'password' | 'postal-code' | 'street-address' | 'tel' | 'username' | 'off',
    textContentType?: 'none' | 'URL' | 'addressCity' | 'addressCityAndState' | 'addressState' | 'countryName' | 'creditCardNumber' | 'emailAddress' | 'familyName' | 'fullStreetAddress' | 'givenName' | 'jobTitle' | 'location' | 'middleName' | 'name' | 'namePrefix' | 'nameSuffix' | 'nickname' | 'organizationName' | 'postalCode' | 'streetAddressLine1' | 'streetAddressLine2' | 'sublocality' | 'telephoneNumber' | 'username' | 'password' | 'newPassword' | 'oneTimeCode',
    editable?: boolean,
    multiline?: boolean,
    icon?: 'cashback' | 'wallet' | 'home' | 'search' | 'menu' | 'basket' | 'check' | 'edit' | 'calendar' | 'back' |
        'next' | 'plus' | 'minus' | 'sales' | 'favorite' | 'favorite-filled' | 'caret' | 'profile-rounded' | 'chat' |
        'locker' | 'news' | 'infos' | 'bank-cards' | 'email' | 'phone' | 'lock' | 'profile' | 'estimate' | 'order' |
        'notify' | 'close' | 'qorner-company' | 'qorner-public',
    iconSize?: number,
    secureTextEntry?: boolean,
    hasError?: boolean,
    mask?: string,
    onChangeText?: function,
    setFieldValue?: function,
    numberOfLines?: number,
    autoFocus?: boolean,
    innerRef?: any,
    placeholderColor?: string,
    onSubmitEditing?: function,
    outsideIcon?: boolean,
    returnKeyType?: 'done' | 'go' | 'next' | 'search' | 'send',
    autocapitalize?: 'none' | 'sentences' | 'words' | 'characters',
    autoCorrect?: boolean
}
