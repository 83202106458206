/**
 * @generated SignedSource<<405812e2f794104205fd7ca72baefe50>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "productId",
        "variableName": "productId"
      }
    ],
    "concreteType": "RemoveProductFromBasketOutputPayload",
    "kind": "LinkedField",
    "name": "RemoveProductFromBasket",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Basket",
        "kind": "LinkedField",
        "name": "basket",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "productCount",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "amountAfterReduction",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "BasketService",
            "kind": "LinkedField",
            "name": "services",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "logoURL",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serviceType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "providerTitle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "BasketProduct",
                "kind": "LinkedField",
                "name": "products",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "productId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "productPrice",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "productType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isCover",
                    "storageKey": null
                  },
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "price",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "quantity",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customerComment",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Product",
        "kind": "LinkedField",
        "name": "product",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "quantityInBasket",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductModalRemoveProductMutation",
    "selections": (v5/*: any*/),
    "type": "ClientAppMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductModalRemoveProductMutation",
    "selections": (v5/*: any*/)
  },
  "params": {
    "cacheID": "1b7d0cd6db61255d340e38f333f812b7",
    "id": null,
    "metadata": {},
    "name": "ProductModalRemoveProductMutation",
    "operationKind": "mutation",
    "text": "mutation ProductModalRemoveProductMutation(\n  $productId: Int!\n) {\n  RemoveProductFromBasket(productId: $productId) {\n    basket {\n      id\n      productCount\n      amount\n      amountAfterReduction\n      services {\n        rowId\n        amount\n        name\n        logoURL\n        serviceType\n        providerTitle\n        products {\n          id\n          rowId\n          productId\n          productPrice\n          productType\n          isCover\n          name\n          price\n          quantity\n          customerComment\n        }\n      }\n    }\n    product {\n      id\n      quantityInBasket\n    }\n  }\n}\n"
  }
};
})();

node.hash = "e404a881b193ffebe8e47abb788685d6";

module.exports = node;
