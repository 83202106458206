import { StyleSheet } from 'react-native'

// Styles
export default StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  mainContainer: {
    flex: 1,
    marginVertical: 20,
    marginHorizontal: 15
  },
  mainContainerDesktop: {
    marginVertical: 30,
    marginHorizontal: 40
  },
  modalButton: {
    marginTop: 20
  },
  submitButton: {
    marginTop: 50
  },
  desktopTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30
  }
})