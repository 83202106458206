import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  modalTitle: {
    marginBottom: 10
  },
  processingContainer: {
    height: 120,
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentImage: {
    width: '100%',
    height: 130,
    marginTop: 14
  },
  contentImageDesktop: {
    height: 300
  },
  buttonsContainer: {
    alignItems: 'center',
    marginHorizontal: '10%',
    marginTop: 14
  },
  subText: {
    marginTop: 10
  },
  middleButton: {
    marginTop: 10
  },
  lastButton: {
    marginVertical: 10
  },
  smallFooterContainer: {
    marginTop: 10,
    justifyContent: 'center',
    flexDirection: 'row'
  },
  smallFooterLogo: {
    marginLeft: 4,
    height: 24,
    width: 54
  },
  validationLimitDate: {
    marginTop: 8,
    marginBottom: 16
  },
  validationButton: {
    marginBottom: 10
  },
  successCloseButton: {
    marginBottom: 6
  },
  secretCodeContainer: {
    flexDirection: 'row',
    marginTop: 10
  }
})

export const modalSkeletonLayout = [
  {width: '100%', height: 20, marginBottom: 10, marginTop: 10},
  {width: '100%', height: 40, marginBottom: 20},
  {width: '60%', height: 150, marginBottom: 20},
  {width: '80%', height: 20, marginBottom: 10},
  {width: '80%', height: 20, marginBottom: 10},
  {width: '80%', height: 20, marginBottom: 10}
]
