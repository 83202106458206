import React, { type Node, useRef } from 'react'
import { Platform, Animated, ImageBackground, View, Dimensions } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'relay-hooks'
import Avatar from 'src/components/common/atoms/Avatar'
import Button from 'src/components/common/atoms/Button'
import Icon from 'src/components/common/atoms/Icon'
import Label from 'src/components/common/atoms/Label'
import s from './WelcomeModal.style'
import NoPictureAvatar from 'src/components/common/atoms/NoPictureAvatar'

/**
 * WelcomeModal organism.
 */
export default function WelcomeModal (): Node {

  // Init state
  const { t } = useTranslation()
  const swipeTopAnim = useRef(new Animated.Value(0)).current

  // API Call
  const { data, isLoading } = useQuery(graphql`
    query WelcomeModalQuery {
      user {
        id
        rowId
        firstName
        lastName
        email
        intercomUserIdentity
        pictureURL
      }
    }
  `)
  const user = data?.user

  // Animation out
  const fullHeight = Platform.OS === 'web' ? Dimensions.get('window').height : Dimensions.get('screen').height
  const swipeTop = () => {
    Animated.timing(swipeTopAnim, {
      toValue: - fullHeight,
      duration: 500,
      useNativeDriver: false
    }).start()
  }

  // Render organism.
  return (
    <Animated.View style={[s.container, { marginTop: swipeTopAnim, height: fullHeight }]}>
      
      {/* Background image base on current user Qorner TODO: real qorner image */}
      <ImageBackground
        source={require('assets/img/screens/private/HomeScreen/default_welcome.jpg')}
        style={s.imageBackground}
      >
        <View style={s.shadowBackround}>

          {/* Content */}
          <View style={s.content}>

            {/* Current user avatar */}
            {user?.pictureURL ? (
              <Avatar size={100} style={s.avatar} source={user.pictureURL}/>
            ):  (
              <NoPictureAvatar
                style={s.avatar}
                size={100}
                iconSize={50}
                labelSize={40}
                initials={user?.firstName?.charAt(0).toUpperCase() + user?.lastName?.charAt(0).toUpperCase()}/>
            )}

            {/* Welcome message */}
            <Label style={s.subContainer}>
              <Label type={'bold'} size={27} color={'#fff'}>{t('HomeScreen.WelcomeModal.hello')}</Label>
              {user?.firstName && (
                <Label type={'bold'} size={27} color={'#fff'} skeleton={{ width: 110, height: 28, marginTop: 4 }}>
                  {' ' + user.firstName + ' !'}
                </Label>
              )}
            </Label>

            {/* Show home button */}
            <Button
              style={s.button}
              Icon={<Icon name={'back'} color={'#fff'} style={s.caret}/>}
              onPress={swipeTop}
            />

          </View>

        </View>
      </ImageBackground>
    </Animated.View>
  )
}