import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  specialOfferContainer: {
    maxWidth: '100%',
    marginTop: 20,
    marginRight: 10,
    marginLeft: 10,
  },
  specialOfferContainerDesktop: {
    marginTop: 40
  },
  specialOfferContent: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  specialOfferError: {
    textAlign: 'center',
    marginBottom: 5
  },
  specialOfferLabel: {
    flex: 0.6
  },
  specialOfferForm: {
    flex: 0.4,
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  specialOfferInput: {
    width: 80
  },
  specialOfferInputDesktop: {
    width: 126
  },
  specialOfferButton: {
    marginLeft: 5
  },
  specialOfferButtonDestop: {
    marginLeft: 10
  }
})