import React, { type Node, useState } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'relay-hooks'
import { Formik } from 'formik'
import * as Yup from 'yup'
import StarsInputFormik from 'src/components/common/forms/StarsInputFormik'
import TextInputFormik from 'src/components/common/forms/TextInputFormik'
import Label from 'src/components/common/atoms/Label'
import Loader from 'src/components/common/atoms/Loader'
import Icon from 'src/components/common/atoms/Icon'
import Button from 'src/components/common/atoms/Button'
import s from './RateOrderModal.style'
import { Colors } from 'src/styles'

/**
 * RateOrderModal component
 */
export default function RateOrderModal ({
  onClose,
  setAllowClosing,
  orderId
}: PropsTypes): Node {
  /*
   * Init vars
   */
  const steps = {
    RATING: 1,
    LOADING: 2,
    SUCCESS: 3
  }
  const { t } = useTranslation()
  const [error, setError] = useState()
  const [step, setStep] = useState(steps.RATING)

  /*
   * API Mutation
   */
  const [rateOrderMutation]  = useMutation(graphql`
    mutation RateOrderModalMutation(
      $orderId: Int!,
      $qualityRating: Int!,
      $deliveryRating: Int!,
      $customerRelationRating: Int!,
      $comment: String
    ) {
      RateOrder (
        orderId: $orderId,
        qualityRating: $qualityRating,
        deliveryRating: $deliveryRating,
        customerRelationRating: $customerRelationRating,
        comment: $comment
      ) {
        id
        rowId
        allowedCustomerActions
        primeCustomerAction
        status
      }
    }
  `, {
    onCompleted: () => {
      setAllowClosing(true)
      setStep(steps.SUCCESS)
    },
    onError: () => {
      setAllowClosing(true)
      setError(true)
      setStep(steps.RATING)
    }
  })

  /*
   * Handle submit rating
   */
  const handleSubmit = (values) => {
    setAllowClosing(false)
    setStep(steps.LOADING)
    rateOrderMutation({
      variables: {
        orderId: orderId,
        ...values
      }
    })
  }

  // Form validation schema
  const formValidationSchema = Yup.object().shape({
    qualityRating: Yup.number().min(1, t('Yup.number.min')),
    deliveryRating: Yup.number().min(1, t('Yup.number.min')),
    customerRelationRating: Yup.number().min(1, t('Yup.number.min'))
  })

  /*
   * Render component
   */
  return (
    <View style={s.container}>
      <Label style={s.title}>{t('RateOrderModal.title', { orderId })}</Label>
      {{
        [steps.RATING]: (
          <View>
            <Label size={10}>{t('RateOrderModal.baseline')}</Label>
            <Formik
              validationSchema={formValidationSchema}
              initialValues={{
                qualityRating: 0,
                deliveryRating: 0,
                customerRelationRating: 0,
                comment: ''
              }}
              onSubmit={handleSubmit}
            >
              {(formikProps) => (
                <View style={s.formContainer}>
                  <View style={s.ratingRow}>
                    <Label size={10}>{t('RateOrderModal.qualityRating')}</Label>
                    <StarsInputFormik
                      name={'qualityRating'}
                      {...formikProps}
                    />
                  </View>

                  <View style={s.ratingRow}>
                    <Label size={10}>{t('RateOrderModal.deliveryRating')}</Label>
                    <StarsInputFormik
                      name={'deliveryRating'}
                      {...formikProps}
                    />
                  </View>

                  <View style={s.ratingRow}>
                    <Label size={10}>{t('RateOrderModal.customerRelationRating')}</Label>
                    <StarsInputFormik
                      name={'customerRelationRating'}
                      {...formikProps}
                    />
                  </View>

                  <TextInputFormik
                    style={s.commentContainer}
                    styles={{ textInput: s.commentTextInput}}
                    name={'comment'}
                    size={'S'}
                    multiline={true}
                    numberOfLines={5}
                    placeholder={t('RateOrderModal.comment')}
                    {...formikProps}
                  />

                  {error && <Label size={10} type={'error'}>{t('RateOrderModal.error')}</Label>}

                  <View style={s.btnContainer}>
                    <Button
                      style={s.btn}
                      onPress={formikProps.handleSubmit}
                      palette={'primary'}
                      disabled={!(formikProps.isValid && formikProps.dirty)}
                      size={'S'}
                    >
                      {t('App.validate')}
                    </Button>
                    <Button
                      style={s.btn}
                      onPress={onClose}
                      size={'S'}
                    >
                      {t('App.cancel')}
                    </Button>
                  </View>
                </View>
              )}
            </Formik>
          </View>
        ),
        [steps.LOADING]: (
          <View style={s.loaderContainer}>
            <Loader />
          </View>
        ),
        [steps.SUCCESS]: (
          <View style={s.successContainer}>
            <Icon name={'check'} color={Colors.orange100} />
          </View>
        )
      }[step]}
    </View>
  )
}

type PropsTypes = {
  orderId: number
}