// @flow
import React, { type Node, useState, useEffect } from 'react'
import { TouchableWithoutFeedback, View, Platform, StatusBar } from 'react-native'
import useScreenSizes from 'src/utils/useScreenSizes'
import s from './Modal.style'

/**
 * Modal mole.
 *
 * Container to use on screens
 */
const Modal = ({
  open,
  onClose,
  preventOverlayClose = false,
  children,
  style,
  styles
}: PropsTypes): Node => {
  /*
   * Init vars
   */
  const { height, isMobile } = useScreenSizes()
  const [allowClosing, setAllowClosing] = useState(!preventOverlayClose)
  const [onCloseParam, setOnCloseParam] = useState(null)

  /*
   * Update allow closing state
   */
  useEffect(() => {
    if (preventOverlayClose === allowClosing) {
      setAllowClosing(!preventOverlayClose)
    }
  }, [preventOverlayClose])

  /*
   * Close function wrapper
   */
  const handleClose = () => {
    allowClosing && (onCloseParam !== null ? onClose(onCloseParam) : onClose())
  }

  /*
   * Render component
   */
  return open && (
    <TouchableWithoutFeedback
      onPress={handleClose}
    >
      {/* Overlay */}
      <View style={[
        s.container,
        {
          height: height,
          marginTop: Platform.OS === 'web' ? 0 : -StatusBar.currentHeight
        },
        styles?.container
      ]}>

        {/* Modal white container */}
        <TouchableWithoutFeedback onPress={(e) => { e.stopPropagation() }}>
          <View style={[s.modal, !isMobile && s.modalDesktop, style]}>

            {/* Modal content */}
            {React.cloneElement(children, {
              onClose: handleClose,
              setOnCloseParam: setOnCloseParam,
              setAllowClosing: setAllowClosing
            })}

          </View>
        </TouchableWithoutFeedback>

      </View>
    </TouchableWithoutFeedback>
  )
}

export default Modal

// PropsTypes
type PropsTypes = {
  open: boolean,
  onClose: function,
  preventOverlayClose?: boolean,
  children: any,
  style?: any,
  styles?: any
}
