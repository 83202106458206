/**
 * @generated SignedSource<<3a49237baa9ec7377da1440e922e25f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "date"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "date",
        "variableName": "date"
      }
    ],
    "concreteType": "Basket",
    "kind": "LinkedField",
    "name": "SetCollectDateOfBasket",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rowId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BasketDeliverySettings",
        "kind": "LinkedField",
        "name": "deliverySettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collectDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deliveryDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "price",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Locker",
            "kind": "LinkedField",
            "name": "collectLocker",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Locker",
            "kind": "LinkedField",
            "name": "deliveryLocker",
            "plural": false,
            "selections": (v1/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectCollectDateModalMutation",
    "selections": (v2/*: any*/),
    "type": "ClientAppMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelectCollectDateModalMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "26307af291262873fade0881c1eb72bf",
    "id": null,
    "metadata": {},
    "name": "SelectCollectDateModalMutation",
    "operationKind": "mutation",
    "text": "mutation SelectCollectDateModalMutation(\n  $date: Date!\n) {\n  SetCollectDateOfBasket(date: $date) {\n    id\n    rowId\n    amount\n    deliverySettings {\n      collectDate\n      deliveryDate\n      price\n      collectLocker {\n        name\n      }\n      deliveryLocker {\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "361a8f46d6c0cc0fa7052034729ac894";

module.exports = node;
