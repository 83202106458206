// @flow
import React, { type Node, useState } from 'react'
import { View, Image } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useRelayEnvironment } from 'relay-hooks'
import { commitLocalUpdate } from 'react-relay'
import { formatPrice } from 'src/utils/FormatDataUtils'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import AdyenPaymentForm from 'src/components/common/moles/AdyenPaymentForm/AdyenPaymentForm'
import s from './FillWalletModal.style'

/**
 * NewCreditCardModalContent.
 */
export default function NewCreditCardModalContent({
  modalSteps,
  setModalStep,
  amount,
  userId
}: PropsTypes): Node {

  // Init state
  const { t } = useTranslation()
  const environment = useRelayEnvironment()

  // Back to choose payment method
  const backToChoosePaymentMethod = () => {
    setModalStep(modalSteps.CHOOSE_PAYMENT_METHOD)
  }

  // Handle native payment completed
  const handlePaymentCompleted = platform => {
    if (platform === 'native') {
      // Update Relay environment and display success step
      commitLocalUpdate(environment, store => {
        // Update local creditsBalance
        const user = store.get(userId)
        const creditsBalance = user.getValue('creditsBalance')
        user.setValue(creditsBalance + amount, 'creditsBalance')

        // Change modal to success step
        setModalStep(modalSteps.SUCCESS)
      })
    } else if (platform === 'web') {
      setModalStep(modalSteps.SUCCESS)
    }
  }

  // Render modal content
  return (
    <View style={s.newPaymentMethodContainer}>

      {/* Intro recap chosen amount */}
      <Label>
        {t('ProfileWalletScreen.ChoosePaymentMethod.amountToPay')}
        <Label types={['bold', 'smallMargin']}>
          {amount && formatPrice(amount)}
        </Label>
      </Label>

      {/* Add new credit card form */}
      <View style={s.newPaymentMethodForm}>
        <AdyenPaymentForm payableType={'WALLET'} amount={amount} onCompleted={handlePaymentCompleted} />
      </View>

      {/* Back to choose payment method step button */}
      <Button
        size={'M'}
        onPress={backToChoosePaymentMethod}
        fontStyle={'bold'}
        style={s.newPaymentMethodChangeButton}
        fullWidth
      >
        {t('ProfileWalletScreen.NewCreditCard.changePaymentMethod')}
      </Button>

      {/* Bottom images */}
      <View style={s.newPaymentMethodCardsContainer}>
        <Image
          source={require('assets/img/screens/private/profile/ProfileWalletScreen/FillWalletModal/NewCreditCardModalContent/logo_cb.png')}
          style={s.newPaymentMethodCards}
        />
      </View>
      <View style={s.newPaymentMethodAdyen}>
        <Label size={10}>{t('ProfileWalletScreen.NewCreditCard.securePaymentText')}</Label>
        <Image
          source={require('assets/img/screens/private/profile/ProfileWalletScreen/FillWalletModal/NewCreditCardModalContent/adyen_logo.png')}
          style={s.newPaymentMethodAdyenLogo}
        />
      </View>

    </View>
  )
}

// PropsTypes
type PropsTypes = {
  modalSteps: any,
  setModalStep: function,
  amount: number,
  userId: number
}