// @flow
import React, { type Node, useState, useEffect } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Calendar, LocaleConfig } from 'react-native-calendars'
import Icon from 'src/components/common/atoms/Icon'
import { locales } from './EqqiCalendar.locales'
import { s, calendarTheme } from './EqqiCalendar.style'

export default function EqqiCalendar ({
  style,
  styles,
  handleDayPress,
  handleMonthChange = null,
  currentDay,
  handleSelectedDay = null,
  defaultSelectedDay = null,
  defaultMarkedDates = {}
}: PropsTypes): Node {
  // Get language
  const { i18n } = useTranslation()

  // Setup Calendar locales
  LocaleConfig.locales['fr'] = locales.fr
  LocaleConfig.locales['en'] = locales.en
  LocaleConfig.defaultLocale = i18n.language

  const [selectedDay, setSelectedDay] = useState(defaultSelectedDay)
  const [markedDates, setMarkedDates] = useState(defaultMarkedDates)

  useEffect(() => {
    if (selectedDay) {
      setMarkedDates({
        ...defaultMarkedDates,
        [selectedDay.dateString]: {
          ...markedDates[selectedDay.dateString],
          selected: true
        }
      })
    }
    handleSelectedDay && handleSelectedDay(selectedDay)
  }, [selectedDay])

  const onDayPress = (day) => {
    setSelectedDay(day)
    handleDayPress(day)
  }

  return (
    <View style={[s.container, style, styles?.container]}>
      <Calendar
        current={currentDay}
        onDayPress={onDayPress}
        monthFormat={'MMMM yyyy'}
        onMonthChange={handleMonthChange}
        markedDates={markedDates}
        renderArrow={dir => <MonthArrow direction={dir}/>}
        theme={calendarTheme}
        disableAllTouchEventsForDisabledDays={true}
      />
    </View>
  )
}

function MonthArrow ({direction}) {
  return (
    <View style={s.monthIconContainer}>
      <Icon
        name={'back'}
        style={[
          s.monthIcon,
          direction === 'right' && s.nextMonthIcon
        ]}
      />
    </View>
  )
}

type PropsTypes = {
  style?: any,
  styles?: any,
  handleDayPress: function,
  handleMonthChange?: function,
  currentDay?: string,
  handleSelectedDay?: function,
  defaultSelectedDay?: ?any,
  defaultMarkedDates?: any,
}