import React, { type Node, useState } from 'react'
import { Image, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import s from '../CoverDeliveryModal.style'
import useScreenSizes from 'src/utils/useScreenSizes'

/**
 * ReceptionContent component
 */
export default function ReceptionContent ({
  onRetrievedOrder,
  onClose
}: PropsTypes): Node {
  /*
   * Init vars
   */
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const [displayRetrievedContent, setDisplayRetrievedContent] = useState(false)

  /*
   * Render component
   */
  return (
    <View>
      {!displayRetrievedContent ? <>
          <Label size={isMobile ? 10 : 12}>{t('CoverDeliveryModal.ReceptionContent.description')}</Label>
          <Image
            style={s.contentImage}
            resizeMode={'contain'}
            source={require('assets/img/screens/private/shoppingTunnel/CoverDepositModal/eqqi_cover_deposit_reception.png')}
          />
          <View style={s.buttonsContainer}>
            <Button onPress={() => setDisplayRetrievedContent(true)} palette={'primary'} size={isMobile ? 'S' : 'M'}
                    fullWidth>{t('CoverDeliveryModal.ReceptionContent.retrieveCover')}</Button>
            <Button onPress={onClose} style={s.lastButton} size={isMobile ? 'S' : 'M'}
                    fullWidth>{t('CoverDeliveryModal.cancelRecovery')}</Button>
          </View>
        </>
        : <>
          <Label size={isMobile ? 10 : 12}>{t('CoverDeliveryModal.ClosetContent.SecretCode.description')}</Label>
          <Image
            style={[s.contentImage, !isMobile && s.contentImageDesktop]}
            resizeMode={'contain'}
            source={require('assets/img/screens/private/shoppingTunnel/CoverDepositModal/animation_depot_accueil.gif')}
          />
          <View style={s.buttonsContainer}>
            <Button onPress={onRetrievedOrder} palette={'primary'} size={isMobile ? 'S' : 'M'}
                    fullWidth>{t('CoverDeliveryModal.ReceptionContent.doneBtn')}</Button>
            <Button onPress={onClose} style={s.lastButton} size={isMobile ? 'S' : 'M'}
                    fullWidth>{t('CoverDeliveryModal.cancelRecovery')}</Button>
          </View>
        </>
      }
    </View>
  )
}

type PropsTypes = {
  onRetrievedOrder: function,
  onClose: function
}