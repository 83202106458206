/**
 * @generated SignedSource<<dbaae68c4e1c835b1732b87c5e665662>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "rowId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "logoURL",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "address",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "zipCode",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "city",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "qornerType",
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "QornersOutput",
    "kind": "LinkedField",
    "name": "qorners",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Qorner",
        "kind": "LinkedField",
        "name": "publicQorners",
        "plural": true,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Qorner",
        "kind": "LinkedField",
        "name": "privateQorners",
        "plural": true,
        "selections": (v0/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "matchesDomainOfPrivateQorners",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "QornerChoiceScreenQuery",
    "selections": (v1/*: any*/),
    "type": "ClientAppQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "QornerChoiceScreenQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "3c1a06a4d827596acc8f167ceaffd75f",
    "id": null,
    "metadata": {},
    "name": "QornerChoiceScreenQuery",
    "operationKind": "query",
    "text": "query QornerChoiceScreenQuery {\n  qorners {\n    publicQorners {\n      id\n      rowId\n      name\n      logoURL\n      address\n      zipCode\n      city\n      qornerType\n    }\n    privateQorners {\n      id\n      rowId\n      name\n      logoURL\n      address\n      zipCode\n      city\n      qornerType\n    }\n    matchesDomainOfPrivateQorners\n  }\n}\n"
  }
};
})();

node.hash = "88686b453f5bd3f033311669efe971d6";

module.exports = node;
