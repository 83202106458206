// @flow
import React, { type Node, useState } from 'react'
import { View } from 'react-native'
import { useQuery } from 'relay-hooks'
import { useTranslation } from 'react-i18next'
import { type StackNavigationProp } from '@react-navigation/stack'
import type { RoutesParamList } from 'src/components/Navigation'
import { formatPrice } from 'src/utils/FormatDataUtils'
import useScreenSizes from 'src/utils/useScreenSizes'
import PrivateLayoutProfile from 'src/components/common/layout/PrivateLayoutProfile'
import FillWalletModal from 'src/components/common/moles/FillWalletModal'
import Modal from 'src/components/common/atoms/Modal'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import s from './ProfileWalletScreen.style'
import Icon from 'src/components/common/atoms/Icon'
import { Colors } from 'src/styles'

/**
 * ProfileWalletScreen screen.
 */
export default function ProfileWalletScreen({
  navigation
}: PropsTypes) : Node {

  // Init state
  const { t } = useTranslation()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [refetchUserKey, setRefetchUserKey] = useState(0)
  const { isMobile, isDesktop, isTablet } = useScreenSizes()

  // API Call
  const { data } = useQuery(graphql`
    query ProfileWalletScreenQuery {
      user {
        id
        creditsBalance
      }
    }
  `,
    {},
    {
      fetchPolicy: 'network-only',
      fetchKey: refetchUserKey,
      networkCacheConfig: {
        force: true,
      },
    })

  const handleCloseModal = () => {
    setRefetchUserKey(v => v+1)
    setIsModalOpen(false)
  }

  // Render screen
  return (
    <>
      <PrivateLayoutProfile
        navigation={navigation}
        subHeaderIconName={'wallet'}
        subHeaderTitle={t('MenuDrawerContent.links.wallet')}
      >
        <View style={s.container}>

          {/* Intro */}
          {!isMobile &&
            <View style={s.headerContainerDesktop}>
              <Icon name={'wallet'} size={isTablet ? 24 : 40} color={Colors.mediumGrey100}/>
              <Label size={isTablet ? 22 : 38} style={s.headerTitleDesktop}>{t('ProfileWalletScreen.labels.titleDesktop')}</Label>
            </View>
          }
          <Label size={isMobile ? 10 : isTablet ? 14 : 22}>{t('ProfileWalletScreen.labels.intro')}</Label>

          {/* Amount */}
          <Label size={isMobile ? 12 : isTablet ? 16 : 28} style={s.amount}>
            {t('ProfileWalletScreen.labels.amount')}
            <Label size={isMobile ? 12 : isTablet ? 16 : 28} type={'bold'} skeleton={{width: 40, height: 16}}>
              {data?.user && formatPrice(data.user.creditsBalance)}
            </Label>
          </Label>

          {/* Fill wallet button */}
          <View style={[s.btnContainer, isDesktop && s.btnContainerDesktop]}>
            <Button size={isDesktop ? 'L' : 'M'} palette={'primary'} onPress={() => setIsModalOpen(true)}>{t('ProfileWalletScreen.labels.fillButton')}</Button>
          </View>

        </View>
      </PrivateLayoutProfile>

      {/* FillWalletModal */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <FillWalletModal user={data?.user} />
      </Modal>
    </>
  )
}

// PropsTypes
type PropsTypes = {
  navigation: StackNavigationProp<RoutesParamList, 'Wallet'>
}
