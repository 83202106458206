import React, { type Node, useState, useEffect, useRef, useCallback } from 'react'
import { View, FlatList, Animated, Easing } from 'react-native'
import { useQuery } from 'relay-hooks'
import useFullWidth from 'src/utils/useFullWidth'
import GoodDeal from 'src/components/common/moles/GoodDeal'
import s from './GoodDealList.style'

/**
 * GoodDealList Component
 */
const GoodDealList = ({
  style,
  onPress,
  isMobile
}: PropsTypes): Node => {
  /*
   * API Query
   */
  const {data} = useQuery(graphql`
    query GoodDealListQuery {
      goodDeals {
        name
        goodDealType
        startDateTime
        endDateTime
        affiliateLinks {
          token
          name
          logoURL
        }
        cashbackServices {
          service {
            catalogIcon {
              url
            }
          }
        }
        discountServices {
          service {
            catalogIcon {
              url
            }
          }
        }
      }
    }
  `)
  const goodDeals = data?.goodDeals

  /*
   * Init vars
   */
  const [currentIndex, setCurrentIndex] = useState(0)
  const currentIndexRef = useRef(currentIndex)
  currentIndexRef.current = currentIndex
  const listRef = useRef(null)
  const containerRef = useRef(null)
  const progressAnimRef = useRef(new Animated.Value(0)).current
  const timer = 5000
  const fullWidth = useFullWidth()
  const fullWidthRef = useRef(fullWidth)
  fullWidthRef.current = fullWidth

  /*
   * Helps FlatList to understand the overall layout for automatic scroll
   */
  const getItemLayout = (data, index) => {
    return {
      index,
      length: isMobile ? fullWidthRef.current : containerRef?.current?.clientWidth - 40,
      offset: fullWidthRef.current * index
    }
  }

  /*
   * Progress bar animation
   */
  const progressBarAnim = useCallback(() => {
    Animated.timing(progressAnimRef, {
      toValue: isMobile ? fullWidthRef.current : containerRef?.current?.clientWidth - 40,
      easing: Easing.linear,
      duration: timer,
      useNativeDriver: false
    }).start(() => {
      progressAnimRef.setValue(0)
    })
  })

  /*
   * Function to scroll to next index: used in setInterval
   */
  const scrollToNextIndex = () => {
    const nextIndex = currentIndexRef.current + 1 >= goodDeals.length ? 0 : currentIndexRef.current + 1

    listRef.current.scrollToIndex({
      index: nextIndex,
      viewPosition: 0
    })
    progressBarAnim()
  }

  /*
   * Function to get the current scroll status and update index if necessary
   */
  const onScroll = useCallback((event) => {
    const slideSize = event.nativeEvent.layoutMeasurement.width
    const index = event.nativeEvent.contentOffset.x / slideSize
    const roundIndex = Math.round(index)

    const distance = Math.abs(roundIndex - index)

    // Prevent one pixel triggering setIndex in the middle
    // of the transition. With this we have to scroll a bit
    // more to trigger the index change.
    const isNoMansLand = 0.4 < distance

    if (roundIndex !== currentIndexRef.current && !isNoMansLand) {
      setCurrentIndex(roundIndex)
    }
  })

  /*
   * Init carousel autoplay and progress bar animation
   */
  useEffect(() => {
    if (goodDeals && goodDeals.length > 0) {
      progressBarAnim()
      const interval = setInterval(
        scrollToNextIndex,
        timer
      )
      return () => clearInterval(interval)
    }
  }, [goodDeals])

  /*
   * Render mole, return null if empty
   */
  if (!goodDeals || (goodDeals && goodDeals.length === 0)) {
    return null
  }

  return (
    <View style={[s.container, !isMobile && s.containerDesktop, style]} ref={containerRef} >
      <FlatList
        ref={listRef}
        data={goodDeals && goodDeals}
        horizontal
        getItemLayout={getItemLayout}
        onScroll={onScroll}
        showsHorizontalScrollIndicator={false}
        pagingEnabled
        keyExtractor={(item, index) => item.name + index}
        renderItem={({ item: goodDeal }) => (
          <GoodDeal
            goodDeal={goodDeal}
            onPress={onPress}
            width={isMobile ? fullWidth : containerRef?.current?.clientWidth - 40}
          />
        )}
      />
      <Animated.View style={[s.progressBar, { width: progressAnimRef }]}/>
    </View>
  )
}

type PropsTypes = {
  style?: any,
  onPress?: function,
  isMobile?: boolean
}

export default React.memo(GoodDealList, () => true)
