// @flow
import React, { type Node } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { formatPrice } from 'src/utils/FormatDataUtils'
import useScreenSizes from 'src/utils/useScreenSizes'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import SwitchInputFormik from 'src/components/common/forms/SwitchInputFormik'
import s from './ProductConfigModal.style'

export default function SupplementsModalContent ({
  product,
  setOpenBis,
  setCurrentStep,
  steps
}: PropsTypes): Node {

  /*
   * Init
   */
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const initialValues = {}
  product.supplements.forEach(suppl => {
    initialValues[suppl.id] = false
  })

  /*
   * Render component
   */
  return (<>
    <Label style={s.title} size={!isMobile ? 28 : 14}>{t('ProductModal.supplements.title')}</Label>
    <Label style={s.description} size={isMobile ? 10 : 20}>{t('ProductModal.supplements.description')}</Label>
    <Formik
      initialValues={initialValues}
      onSubmit={values => console.log('Confirmation réservation')}
    >
      {(formikProps) => {
        return (
          product.supplements.map(supplement => (
            <View style={s.supplement} key={supplement.id}>
              <Label types={['default', 'smallMargin']}>{supplement.name} + {formatPrice(supplement.price)}</Label>
              <SwitchInputFormik size={isMobile ? 'S' : 'L'} {...formikProps} name={supplement.id}/>
            </View>
          ))
        )
      }}
    </Formik>

    <Button style={s.button} palette={'primary'} size={isMobile ? 'S' : 'L'} onPress={() => {
      setCurrentStep(steps.SUMMARY)
    }}>{t('ProductModal.supplements.confirm')}</Button>

    <Button style={[s.button, s.cancelButton]} size={isMobile ? 'S' : 'L'} onPress={() => {
      setOpenBis(false)
      setCurrentStep(steps.CONFIGS)
    }}>{t('ProductModal.cancel')}</Button>
  </>)
}

type PropsTypes = {
  product: any,
  setOpenBis: function,
  setCurrentStep: function,
  steps: any
}

