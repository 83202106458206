import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

// Styles
export default StyleSheet.create({
  loader: {
    marginTop: 72,
    marginBottom: 100
  },
  successIcon: {
    marginTop: 72,
    marginBottom: 80,
    textAlign: 'center',
    color: Colors.orange100
  },
  body: {
    marginTop: 20,
    marginBottom: 27
  },
  bodyDesktop: {
    marginTop: 60,
    marginBottom: 87
  },
  desktopError: {
    marginTop: 10,
    marginLeft: 40,
    marginBottom: -10
  },
  generalError: {
    marginBottom: 10,
    textAlign: 'center'
  },
  submit: {
    marginTop: 20,
    marginBottom: 19
  },
  submitDesktop: {
    marginTop: 50
  },
  btnSuccessClose: {
    marginBottom: 20
  },
  input: {
    paddingHorizontal: 14
  },
  desktopFormContainer: {
    marginBottom: 50
  },
  desktopInput: {
    marginHorizontal: 40,
    paddingHorizontal: 0
  },
  desktopContainer: {
    padding: 10
  }
})