import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

export default StyleSheet.create({
  container: {
    height: 50
  },
  containerDesktop: {
    height: 80
  },
  cardContainer: {
    flexDirection: 'row',
    height: '100%',
    backgroundColor: '#fff'
  },
  affiliationLeftContainer: {
    paddingLeft: 8,
    alignItems: 'flex-start',
    justifyContent: 'center',
    height: '100%',
    flex: 6
  },
  affiliationLeftContainerDesktop: {
    paddingLeft: 10
  },
  affiliationRightContainer: {
    justifyContent: 'center',
    height: '100%',
    flex: 6,
    backgroundColor: Colors.red100Less90
  },
  affiliationSeparationContainer: {
    flex: 1,
    height: '100%',
    overflow: 'hidden',
    alignItems: 'center',
  },
  affiliationSeparationBar: {
    width: '100%',
    height: '115%',
    backgroundColor: Colors.red100Less90,
    top: -3,
    right: '-50%',
    transform: [{rotate: '15deg'}]
  },
  affiliationImage: {
    flex: 1,
    width: '100%',
    maxHeight: 40,
    marginBottom: 10
  },
  affiliationButton: {
    flex: 1,
  },
})
