/**
 * @generated SignedSource<<9c48801e971051b8176d86d621888a14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Service",
    "kind": "LinkedField",
    "name": "service",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CatalogIcon",
        "kind": "LinkedField",
        "name": "catalogIcon",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "url",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "GoodDeal",
    "kind": "LinkedField",
    "name": "goodDeals",
    "plural": true,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "goodDealType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startDateTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "endDateTime",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AffiliateLink",
        "kind": "LinkedField",
        "name": "affiliateLinks",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "logoURL",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GoodDealCashbackService",
        "kind": "LinkedField",
        "name": "cashbackServices",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "GoodDealDiscountService",
        "kind": "LinkedField",
        "name": "discountServices",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GoodDealListQuery",
    "selections": (v2/*: any*/),
    "type": "ClientAppQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GoodDealListQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8b5ca21902d391aadc9d65d2133fddc7",
    "id": null,
    "metadata": {},
    "name": "GoodDealListQuery",
    "operationKind": "query",
    "text": "query GoodDealListQuery {\n  goodDeals {\n    name\n    goodDealType\n    startDateTime\n    endDateTime\n    affiliateLinks {\n      token\n      name\n      logoURL\n    }\n    cashbackServices {\n      service {\n        catalogIcon {\n          url\n        }\n      }\n    }\n    discountServices {\n      service {\n        catalogIcon {\n          url\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

node.hash = "9060fb6766b26fa2bf79e2120bb7f933";

module.exports = node;
