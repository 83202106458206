import 'react-native-gesture-handler'
import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { Translator } from 'src/utils/Translator'
import Navigation from 'src/components/Navigation'
import EQQITheme from 'src/components/common/layout/EQQITheme'
import 'moment/locale/fr'

/**
 * Entry Point of the App.
 */
export default function App () {
  return (
    <I18nextProvider i18n={Translator}>
      <EQQITheme>
        <Navigation />
      </EQQITheme>
    </I18nextProvider>
  )
}