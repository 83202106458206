// @flow
import React, { type Node } from 'react'
import { useTranslation } from 'react-i18next'
import useScreenSizes from 'src/utils/useScreenSizes'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import s from './ProductConfigModal.style'

export default function SummaryModalContent ({
  setOpenBis,
  setCurrentStep,
  steps
}: PropsTypes): Node {

  /*
   * Init
   */
  const { isMobile } = useScreenSizes()
  const { t } = useTranslation()

  /*
   * Render component
   */
  return (
    <>
      <Label style={s.title} size={!isMobile ? 28 : 14}>{t('ProductModal.summary.title')}</Label>
      <Label style={s.description} size={isMobile ? 10 : 20}>{t('ProductModal.summary.description')}</Label>

      <Button palette={'primary'} style={s.button} size={isMobile ? 'S' : 'L'} onPress={() => console.log('procéder au paiement')}>
        {t('ProductModal.summary.proceed')}
      </Button>

      <Button style={[s.button, s.cancelButton]} size={isMobile ? 'S' : 'L'} onPress={() => {
        setOpenBis(false)
        setCurrentStep(steps.CONFIGS)
      }}>{t('ProductModal.cancel')}</Button>
    </>
  )
}

type PropsTypes = {
  setOpenBis: any,
  setCurrentStep: any,
  steps: any
}