import React, { type Node, useEffect, useState } from 'react'
import DepositReadyLockerContent from './DepositReadyLockerContent'
import DepositReadyClosetContent from './DepositReadyClosetContent'
import DepositReadyReceptionContent from './DepositReadyReceptionContent'
import { useMutation } from 'relay-hooks'
import Loader from 'src/components/common/atoms/Loader'

/**
 * DepositBadgeModalContent component
 */
export default function DepositReadyModalContent ({
  nextStep,
  onClose,
  type,
  onCancel,
  handleDeposit,
  orderRowId,
  onError
}: PropsTypes): Node {
  /*
   * Init vars
   */
  const types = {
    KERN: 'KERN_SLAVE_LOCKER',
    LOCAL: 'LOCAL',
    SPARTIME: 'SPARTIME'
  }

  const [isLoading, setIsLoading] = useState(true)
  const [qrCode, setQrCode] = useState(null)
  const [secretCode, setSecretCode] = useState(null)

  /*
   * Mutation to generate codes and instantly self execute (should be a Query?)
   */
  const [generateCodeMutation] = useMutation(graphql`
      mutation DepositReadyModalContentGetCodeMutation($orderId: Int!) {
          GetOrderOpeningCode(orderId: $orderId) {
              code
              QRCode
          }
      }
  `, {
    onCompleted: ({ GetOrderOpeningCode }) => {
      setQrCode(GetOrderOpeningCode.QRCode)
      setSecretCode(GetOrderOpeningCode.code)
      setIsLoading(false)
    },
    onError: (errors) => {
      onError(errors[0].errorCode)
    }
  })

  useEffect(() => {
    if (!orderRowId || type === types.LOCAL) {
      setIsLoading(false)
      return
    }

    generateCodeMutation({ variables: { orderId: orderRowId } })
  }, [orderRowId])


  /*
   * Render component depending of locker type or loading state
   */
  if (isLoading) {
    return <Loader/>
  }

  if (type === types.LOCAL) {
    return <DepositReadyReceptionContent
      nextStep={nextStep}
      onClose={onClose}
      onCancel={onCancel}
      handleDeposit={handleDeposit}
    />
  } else if (type === types.KERN) {
    return <DepositReadyLockerContent
      orderRowId={orderRowId}
      nextStep={nextStep}
      onClose={onClose}
      onCancel={onCancel}
      openingCode={secretCode}
      qrCode={qrCode}
      handleDeposit={handleDeposit}
    />
  } else if (type === types.SPARTIME) {
    return <DepositReadyClosetContent
      nextStep={nextStep}
      onClose={onClose}
      onCancel={onCancel}
      handleDeposit={handleDeposit}
      openingCode={secretCode}
    />
  } else {
    return null
  }
}

type PropsTypes = {
  nextStep: function,
  onClose: function,
  type: string,
  onCancel: function,
  handleDeposit: function,
  orderRowId: number,
  onError: function
}