import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    minHeight: 180,
  },
  title: {
    marginBottom: 16
  },
  formContainer: {
    marginTop: 14
  },
  ratingRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  commentContainer: {
    marginBottom: 8
  },
  commentTextInput: {
    paddingTop: 10,
    paddingBottom: 10
  },
  btnContainer: {
    marginTop: 8,
    justifyContent: 'center'
  },
  btn: {
    width: '60%',
    marginBottom: 10
  },
  loaderContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  successContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  }
})
