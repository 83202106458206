// @flow
import React, { type Node, useEffect, useState } from 'react'
import { View } from 'react-native'
import { Field } from 'formik'
import Button from 'src/components/common/atoms/Button'
import Label from 'src/components/common/atoms/Label'
import s from './StepperFormik.style'
import Icon from 'src/components/common/atoms/Icon'

/**
 * StepperFormik atom.
 */
export default function StepperFormik ({
  size = 'L',
  palette,
  disabledMinus = false,
  disabledPlus = false,
  styles,
  style,
  values = {},
  setFieldValue,
  name = '',
  minimum,
  maximum,
  onMinimumReached,
  onMaximumReached,
  setQueryValue,

  // Non formik props
  onIncrementPress,
  onDecrementPress,
  val
}: PropsTypes): Node {

  // Init state
  const initialValue = values[name]
  const [value, setValue] = useState(initialValue)
  const buttonStyle = {
    ...s.button,
    ...s[`button${size}`],
    ...styles?.button
  }

  // Increment the counter value
  const handleIncrement = () => {
    if (maximum === undefined || value < maximum) {
      setFieldValue(name, value + 1)
      setValue(value + 1)
      setQueryValue(value + 1)
    }
  }

  // Decrement the counter value
  const handleDecrement = () => {
    if (minimum === undefined || value > minimum) {
      setFieldValue(name, value - 1)
      setValue(value - 1)
      setQueryValue(value - 1)
    }
  }

  // Check if max or min values are reached each time the counter value is changed
  useEffect(() => {
    if (value === maximum && onMaximumReached) {
      onMaximumReached()
    } else if (value === minimum && onMinimumReached) {
      onMinimumReached()
    }
  }, [value])

  // Render atom
  return (
    <Field
      name={name}
      component={() => (
        <View style={[s.container, s[`container${size}`], style, style?.container]}>
          <Button palette={palette}
                  style={buttonStyle}
                  disabled={disabledMinus || (minimum !== undefined && value === minimum)}
                  onPress={onDecrementPress || handleDecrement}>
            <Icon name={'minus'} size={size === 'S' ? 6 : 11} style={size === 'S' && { position: 'relative', top: -2 }}/>
          </Button>
          <Label style={[s.label, s[`label${size}`], style?.label]}>{val || value}</Label>
          <Button palette={palette}
                  style={buttonStyle}
                  disabled={disabledPlus || (maximum !== undefined && value === maximum)}
                  onPress={onIncrementPress || handleIncrement}>
            <Icon name={'plus'} size={size === 'S' ? 8 : 13} style={size === 'S' && { position: 'relative', top: -1 }}/>
          </Button>
        </View>
      )}
    />
  )

}

// PropsTypes
type PropsTypes = {
  size?: string,
  palette?: string,
  disabledMinus?: boolean,
  disabledPlus?: boolean,
  styles?: any,
  style?: any,
  values: any,
  setFieldValue: function,
  name: string,
  minimum?: number,
  maximum?: number,
  onMinimumReached?: function,
  onMaximumReached?: function,
  setQueryValue?: function,
  onIncrementPress?: function,
  onDecrementPress?: function,
  val?: number
}