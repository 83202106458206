/**
 * @generated SignedSource<<926201521bfeb789a4073381267a822c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "deviceId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "discoveredAppVersion"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "email"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstName"
},
v4 = {
  "defaultValue": false,
  "kind": "LocalArgument",
  "name": "isNotificationAllowed"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lang"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lastName"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password"
},
v8 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "registrationSource"
},
v9 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sponsorCode"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v12 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "deviceId",
        "variableName": "deviceId"
      },
      {
        "kind": "Variable",
        "name": "discoveredAppVersion",
        "variableName": "discoveredAppVersion"
      },
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "firstName",
        "variableName": "firstName"
      },
      {
        "kind": "Variable",
        "name": "isNotificationAllowed",
        "variableName": "isNotificationAllowed"
      },
      {
        "kind": "Variable",
        "name": "lang",
        "variableName": "lang"
      },
      {
        "kind": "Variable",
        "name": "lastName",
        "variableName": "lastName"
      },
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      },
      {
        "kind": "Variable",
        "name": "registrationSource",
        "variableName": "registrationSource"
      },
      {
        "kind": "Variable",
        "name": "sponsorCode",
        "variableName": "sponsorCode"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "UserRegistration",
    "plural": false,
    "selections": [
      (v10/*: any*/),
      (v11/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "jwtToken",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "refreshJwtToken",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lang",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "creditsBalance",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "UserCashback",
        "kind": "LinkedField",
        "name": "cashbackWallet",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activeAmount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Qorner",
        "kind": "LinkedField",
        "name": "companyQorner",
        "plural": false,
        "selections": [
          (v10/*: any*/),
          (v11/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "pictureURL",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "RegisterScreenMutation",
    "selections": (v12/*: any*/),
    "type": "ClientAppMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v7/*: any*/),
      (v8/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v9/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "RegisterScreenMutation",
    "selections": (v12/*: any*/)
  },
  "params": {
    "cacheID": "47b899ae7c7ff853072cd518c931c7a6",
    "id": null,
    "metadata": {},
    "name": "RegisterScreenMutation",
    "operationKind": "mutation",
    "text": "mutation RegisterScreenMutation(\n  $email: String!\n  $password: String!\n  $registrationSource: String!\n  $discoveredAppVersion: String!\n  $lang: UserLang!\n  $firstName: String!\n  $lastName: String!\n  $sponsorCode: String!\n  $deviceId: String!\n  $isNotificationAllowed: Boolean = false\n) {\n  UserRegistration(email: $email, password: $password, registrationSource: $registrationSource, discoveredAppVersion: $discoveredAppVersion, lang: $lang, firstName: $firstName, lastName: $lastName, sponsorCode: $sponsorCode, deviceId: $deviceId, isNotificationAllowed: $isNotificationAllowed) {\n    id\n    rowId\n    jwtToken\n    refreshJwtToken\n    email\n    firstName\n    lastName\n    lang\n    creditsBalance\n    cashbackWallet {\n      activeAmount\n    }\n    companyQorner {\n      id\n      rowId\n      pictureURL\n    }\n  }\n}\n"
  }
};
})();

node.hash = "784b036f3960713c05c88743d5ed001f";

module.exports = node;
