import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  shadowContainer: {
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: 'white'
  },
  container: {
    overflow: 'hidden',
    paddingLeft: 10,
    paddingRight: 15,
    width: '100%'
  },
  minimalContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 40
  },
  minimalContainerDesktop: {
    height: 80
  },
  textContainer: {
    flex:1
  },
  titleContainer: {
    maxWidth: '100%',
    overflow: 'hidden',
    flexDirection: 'row',
    alignItems: 'center'
  },
  logo: {
    width: 20,
    height: 20,
    borderRadius: 20,
    marginRight: 10
  },
  logoDesktop: {
    width: 40,
    height: 40,
    borderRadius: 40,
  },
  priceContainer: {
    marginLeft: 10,
    flexDirection: 'row',
    alignItems: 'center'
  },
  caret: {
    marginLeft: 15
  },
  detailsContainer: {
    marginBottom: 10
  },
  minAmount: {
    marginBottom: 10
  }
})

