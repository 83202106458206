import React, { type Node } from 'react'
import { Image, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import useScreenSizes from 'src/utils/useScreenSizes'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import s from '../CoverCollectModal.style'

/**
 * DepositReadyReceptionContent component
 */
export default function DepositReadyReceptionContent ({
  onCancel,
  handleDeposit
}: PropsTypes): Node {
  /*
   * Init vars
   */
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()

  /*
   * Render component
   */
  return (
    <View>
      <Label size={isMobile ? 10 : 12}>
        {t('CoverDepositModal.DepositReadyModalContent.DepositReadyReceptionContent.description')}
      </Label>
      <Image
        style={[s.contentImage, !isMobile && s.contentImageDesktop]}
        resizeMode={'contain'}
        source={require('assets/img/screens/private/shoppingTunnel/CoverDepositModal/animation_depot_accueil.gif')}
      />
      <View style={s.buttonsContainer}>
        <Button onPress={handleDeposit} palette={'primary'} size={isMobile ? 'S' : 'M'} fullWidth>
          {t('CoverDepositModal.DepositReadyModalContent.DepositReadyReceptionContent.doneBtn')}
        </Button>
        <Button onPress={onCancel} style={s.lastButton} size={isMobile ? 'S' : 'M'} fullWidth>
          {t('CoverDepositModal.cancelDeposit')}
        </Button>
      </View>
    </View>
  )
}

type PropsTypes = {
  onCancel: function,
  handleDeposit: function
}