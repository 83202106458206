/**
 * @generated SignedSource<<c0fa646ce8f9e7fc4e967e1cf9cb5323>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "orderId",
        "variableName": "orderId"
      }
    ],
    "concreteType": "LockerOpeningCode",
    "kind": "LinkedField",
    "name": "GetOrderOpeningCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "QRCode",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LockerContentMutation",
    "selections": (v1/*: any*/),
    "type": "ClientAppMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LockerContentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "401e8f44ddfcd811c9b876bae57849e8",
    "id": null,
    "metadata": {},
    "name": "LockerContentMutation",
    "operationKind": "mutation",
    "text": "mutation LockerContentMutation(\n  $orderId: Int!\n) {\n  GetOrderOpeningCode(orderId: $orderId) {\n    code\n    QRCode\n  }\n}\n"
  }
};
})();

node.hash = "0bfb18278efe84f6908a90c237be32ca";

module.exports = node;
