// @flow
import React, { type Node } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'relay-hooks'
import { View, Image, useWindowDimensions, ScrollView, Platform } from 'react-native'
import useScreenSizes from 'src/utils/useScreenSizes'
import HTML from 'react-native-render-html'
import { Link } from '@react-navigation/native'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import Loader from 'src/components/common/atoms/Loader'
import { Colors } from 'src/styles'
import s from './PracticalInformationsModal.style'

/**
 * PracticalInformationsModal.
 */
export default function PracticalInformationsModal ({
  informationId,
  onClose
}: PropsTypes): Node {

  // Init Values
  const { t } = useTranslation()
  const { isMobile, isTablet, isDesktop } = useScreenSizes()
  const contentWidth = useWindowDimensions().width
  const linkStyles = Platform.OS === 'android' ? { fontSize: 11, height: '100%' } : { fontSize: 11 }

  // Call API
  const { data } = useQuery(graphql`
    query PracticalInformationsModalQuery($informationId: Int!) {
      information(informationId: $informationId) {
        id
        title
        content
        buttonLabel
        buttonDestinationUrl
        mainPictureUrl
      }
    }
  `, {
    informationId: informationId
  })
  const information = data?.information

  // Render Modal
  return (
    <ScrollView>

      {!information ?
        <Loader style={s.loader}/>
        :
        <>
          <Label type={'bold'} size={isMobile ? 13 : isTablet ? 16 : 28}>{information.title}</Label>
          <Image
            source={{ uri: information.mainPictureUrl }}
            style={[s.image, !isMobile && s.imageDesktop]}
          />
          <HTML
            source={{ html: information.content }}
            contentWidth={contentWidth}
            // Doesn't work if style is externalized
            baseStyle={{
              color: Colors.darkGrey100,
              fontSize: isMobile ? 12 : isTablet ? 14 : 18
            }}
          />

          <View style={!isDesktop ? s.buttonsContainer : s.desktopButtonsContainer}>
            {
              information.buttonLabel && information.buttonDestinationUrl && (
                <Button
                  fullWidth
                  size={isMobile ? 'S' : isTablet ? 'M' : 'L'}
                  palette={'primary'}
                  onPress={() => console.log('pressed to ' + information.buttonDestinationUrl)}
                  style={[s.customButton, !isMobile && s.customButtonNotMobile]}
                  styles={{ label: linkStyles }}
                >
                  <Link to={information.buttonDestinationUrl}>{information.buttonLabel}</Link>
                </Button>
              )}
            <Button
              fullWidth
              size={isMobile ? 'S' : isTablet ? 'M' : 'L'}
              onPress={onClose}
              style={[s.customButton, !isMobile && s.customButtonNotMobile]}
            >
              {t('PracticalInformationsModal.goBack')}
            </Button>
          </View>
        </>
      }

    </ScrollView>
  )
}

// PropsTypes
type PropsTypes = {
  informationId?: number,
  onClose?: function
}
