import React, { type Node, useEffect, useRef, useState } from 'react'
import { Animated, Image, Pressable, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { formatPrice } from 'src/utils/FormatDataUtils'
import useScreenSizes from 'src/utils/useScreenSizes'
import Icon from 'src/components/common/atoms/Icon'
import Label from 'src/components/common/atoms/Label'
import ViewShadow from 'src/components/common/atoms/ViewShadow'
import BasketProductConfig from 'src/components/common/moles/BasketProductConfig'
import { Colors } from 'src/styles'
import s from './BasketServiceCard.style'

export default function BasketServiceCard ({
  service,
  products,
  basketId = null,
  hasMarginTop = false,
  setSelectedOrderProduct,
  setSelectedProviderTitle
}: PropsTypes): Node {

  // Check if service amount is enough
  const minAmountInsufficient = (
    service.lockerMinAmountPerOrder ? service.amount < service.lockerMinAmountPerOrder : service.amount < service.minAmountPerOrder
  )

  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const [isOpen, setIsOpen] = useState(minAmountInsufficient)

  const maxHeight = 1000
  const minimumHeight = isMobile ? 40 : 80
  const serviceHeightAnimation = useRef(new Animated.Value(isOpen ? maxHeight : minimumHeight)).current

  useEffect(() => {
    Animated.timing(serviceHeightAnimation, {
      toValue: isOpen ? maxHeight : minimumHeight,
      duration: 500,
      useNativeDriver: false  // <-- neccessary
    }).start()
  }, [isOpen])

  return (
    <ViewShadow
      shadowOffset={{ width: 0, height: 0 }}
      shadowRadius={8}
      style={[s.shadowContainer, hasMarginTop && { marginTop: 10 }]}
      key={service.name}
    >
      <Animated.View style={[
        s.container,
        { maxHeight: serviceHeightAnimation },
        minAmountInsufficient && {
          borderLeftColor: Colors.orange100,
          borderLeftWidth: 2
        }]}>
        <Pressable onPress={() => setIsOpen(v => !v)} style={[s.minimalContainer, !isMobile && s.minimalContainerDesktop]}>
          <View style={s.textContainer}>
            <View style={s.titleContainer}>
              <Image style={[s.logo, !isMobile && s.logoDesktop]} source={{ uri: service.logoURL }}/>
              <Label size={!isMobile ? 22 : 14} numberOfLines={1}>{service.name}</Label>
            </View>
          </View>

          <View style={s.priceContainer}>
            <Label size={!isMobile ? 22 : 14}>
              {formatPrice(products.reduce((accu, product) => accu + (product.productPrice ?? product.unitPrice) * product.quantity, 0))}
            </Label>
            <Icon name={'caret'} size={isMobile ? 5 : 10} style={[s.caret, isOpen && {transform: [{rotateX: "180deg"}]}]}/>
          </View>
        </Pressable>

        <View style={s.detailsContainer}>
          {minAmountInsufficient && (
            <Label size={isMobile ? 11 : 13} type={'bold'} style={s.minAmount}>
              {t('BasketServiceCard.minAmount', { amount: formatPrice(service.lockerMinAmountPerOrder ? service.lockerMinAmountPerOrder : service.minAmountPerOrder) })}
            </Label>
          )}
          {products.map(product => (
            <BasketProductConfig
              key={product.rowId}
              orderProductId={product.rowId}
              productId={product.productId}
              name={product.name}
              price={product.productPrice ?? product.unitPrice}
              quantity={product.quantity}
              setSelectedOrderProduct={setSelectedOrderProduct}
              setSelectedProviderTitle={setSelectedProviderTitle}
              selectedProviderTitle={service.providerTitle}
              basketId={basketId}
              disableQuantity={basketId === null}
              isCover={product.isCover}
            />
          ))}
        </View>

      </Animated.View>
    </ViewShadow>
  )
}

type PropsTypes = {
  service: any,
  products: any,
  hasMarginTop?: boolean,
  setSelectedOrderProduct: function,
  setSelectedProviderTitle: function,
  basketId?: number
}
