/**
 * @generated SignedSource<<786e1df5fc1378ce5bb6a8a98239e024>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "password"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "passwordConfirmation"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      },
      {
        "kind": "Variable",
        "name": "passwordConfirmation",
        "variableName": "passwordConfirmation"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "UpdatePassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "rowId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileSecurityScreenMutation",
    "selections": (v1/*: any*/),
    "type": "ClientAppMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProfileSecurityScreenMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "33d37ea5a94987c40668c6ffbbb48409",
    "id": null,
    "metadata": {},
    "name": "ProfileSecurityScreenMutation",
    "operationKind": "mutation",
    "text": "mutation ProfileSecurityScreenMutation(\n  $password: String!\n  $passwordConfirmation: String!\n) {\n  UpdatePassword(password: $password, passwordConfirmation: $passwordConfirmation) {\n    id\n    rowId\n  }\n}\n"
  }
};
})();

node.hash = "f13664e27459a098a3d5a6e5f75d45ca";

module.exports = node;
