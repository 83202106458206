import React, { type Node, useState } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import useScreenSizes from 'src/utils/useScreenSizes'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import DepositReadyLockerQRCode from './DepositReadyLockerQRCode'
import DepositReadyLockerSecretCode from './DepositReadyLockerSecretCode'
import s from '../CoverCollectModal.style'

/**
 * DepositReadyLockerContent component
 */
export default function DepositReadyLockerContent ({
  nextStep,
  onClose,
  onCancel,
  openingCode,
  qrCode,
  orderRowId,
  handleDeposit
}: PropsTypes): Node {
  /*
   * Init vars
   */
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const lockerChoices = {
    SECRET_CODE: 'SECRET_CODE',
    QR_CODE: 'QR_CODE'
  }
  const [lockerChoice, setLockerChoice] = useState(null)

  /*
  * Locker specific handlers
  */
  const handleSecretCode = () => {
    // retrieve secret code
    setLockerChoice(lockerChoices.SECRET_CODE)
  }

  const handleQRCode = () => {
    // retrieve QR code
    setLockerChoice(lockerChoices.QR_CODE)
  }

  /*
   * Render component
   */
  return (
    <View>
      {!lockerChoice &&
      <>
        <Label size={isMobile ? 10 : 12}>
          {t('CoverDepositModal.DepositReadyModalContent.DepositReadyLockerContent.description')}
        </Label>
        <View style={s.buttonsContainer}>
          <Button
            onPress={handleSecretCode}
            palette={'primary'}
            size={isMobile ? 'S' : 'M'}
            fullWidth>
            {t('CoverDepositModal.DepositReadyModalContent.DepositReadyLockerContent.secretCodeBtn')}
          </Button>
          <Button
            onPress={handleQRCode}
            style={s.middleButton}
            palette={'primary'}
            size={isMobile ? 'S' : 'M'}
            fullWidth>
            {t('CoverDepositModal.DepositReadyModalContent.DepositReadyLockerContent.qrCodeBtn')}
          </Button>
          <Button onPress={onCancel} style={s.lastButton} size={isMobile ? 'S' : 'M'} fullWidth>
            {t('CoverDepositModal.cancelDeposit')}
          </Button>
        </View>
      </>
      }
      {lockerChoice === lockerChoices.SECRET_CODE &&
      <DepositReadyLockerSecretCode onClose={onClose} orderRowId={orderRowId} switchChoice={handleQRCode} openingCode={openingCode} handleDeposit={handleDeposit}/>
      }
      {lockerChoice === lockerChoices.QR_CODE &&
      <DepositReadyLockerQRCode onClose={onClose} nextStep={nextStep} switchChoice={handleSecretCode} qrCode={qrCode}  handleDeposit={handleDeposit}/>
      }
    </View>
  )
}

type PropsTypes = {
  nextStep: function,
  onClose: function,
  onCancel: function,
  openingCode: string,
  qrCode: string,
  orderRowId: number,
  handleDeposit: function
}