import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

// Text size
export const textSize = {
  title: 14,
  address: 10
}

// Styles
export default StyleSheet.create({
  size: {
    flex: 1,
    height: 50,
    paddingHorizontal: 20
  },
  container: {
    flex: 1,
    width: '100%',
    backgroundColor: '#fff'
  },
  cardContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textContainer: {
    flex: 9,
    height:50,
    justifyContent:'center'
  },
  primaryTitle: {
    color: Colors.orange100,
  },
  iconContainer: {
    flex: 1,
    width: 30,
    height: 30,
    marginLeft: 10,
    borderRadius: 30,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    borderWidth: 0,
    backgroundColor: Colors.lightGrey90,
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    fontWeight: 'bold',
    color: Colors.darkGrey100,
    transform: [{ rotateY: '180deg' }]
  }
})