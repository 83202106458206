// @flow
import React, { type Node } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import Icon from 'src/components/common/atoms/Icon'
import Label from 'src/components/common/atoms/Label'
import ViewShadow from 'src/components/common/atoms/ViewShadow'
import s, { textSize } from './GoodDealHomeCard.style'
import { iconSize } from 'src/components/common/moles/ServiceCard/ServiceCard.style'

/**
 * GoodDealHomeCard mole.
 */
const GoodDealHomeCard = ({
  style,
  styles,
  size = 'M',
  width = 216,
  margin,
  onPress
}): Node => {
  const { t } = useTranslation()

  // Render mole
  return (
    <ViewShadow
      onPress={onPress}
      style={[s[size], s.container, style, styles?.container, {width: width}]}
      shadowOpacity={0.8}
      shadowRadius={4}
      shadowOffset={{width: 0, height: 2}}
      withColor={{color: '#716BAF', width: width, height: size === 'M' ? 50 : 80, radius: 0}}
      margin={margin}
      hasPadding={true}
    >
      <View style={[s.cardContainer, styles?.cardContainer]}>

        {/* Icon */}
        <Icon style={[s.icon, styles?.icon]} name={'sales'} color={'#fff'} size={iconSize[size]}/>

        {/* Title container */}
        <View style={[s.titleContainer, styles?.titleContainer]}>

          {/* Title */}
          <View>
            <Label style={s.title} type={'bold'} size={textSize[size]}>{t('GoodDealHomeCard.title')}</Label>
          </View>

        </View>


      </View>
    </ViewShadow>
  )
}

// Export
export default GoodDealHomeCard