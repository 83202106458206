/**
 * @generated SignedSource<<04405b02bf172bdb245e3a6cd6977eac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "CancelDeleteMyAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "toDeleteAt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ProfileDeleteMyAccountScreenCancelMutation",
    "selections": (v0/*: any*/),
    "type": "ClientAppMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ProfileDeleteMyAccountScreenCancelMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "163e89f2f5aea9538680c3fa6e403264",
    "id": null,
    "metadata": {},
    "name": "ProfileDeleteMyAccountScreenCancelMutation",
    "operationKind": "mutation",
    "text": "mutation ProfileDeleteMyAccountScreenCancelMutation {\n  CancelDeleteMyAccount {\n    id\n    toDeleteAt\n  }\n}\n"
  }
};
})();

node.hash = "0ed12620526d13b58f6f27eae59db000";

module.exports = node;
