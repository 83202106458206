import React, { type Node, useEffect, useRef, useState } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'relay-hooks'
import AdyenCheckout from '@adyen/adyen-web'
import { ADYEN_CLIENT_KEY, ADYEN_ENV } from 'src/environment'
import Label from 'src/components/common/atoms/Label'
import Loader from 'src/components/common/atoms/Loader'
import s from './AdyenPaymentForm.style'
import '@adyen/adyen-web/dist/adyen.css'

/**
 * AdyenPaymentForm component
 */
export default function AdyenPaymentForm ({
  amount,
  onCompleted,
  payableType,
  payableId = null,
  isWithCredits = false,
  setAllowClosing = () => {}
}: PropsTypes): Node {
  /*
   * Init vars
   */
  const { t } = useTranslation()
  const containerRef = useRef(null)
  const refetchRef = useRef(null)
  const [loading, setLoading] = useState(true)
  const [waitingWebhook, setWaitingWebhook] = useState(false)
  const [refetchPayableItemKey, setRefetchPayableItemKey] = useState(0)
  const [initError, setInitError] = useState(false)
  const [paymentError, setPaymentError] = useState(false)
  const [hidePaymentContainer, setHidePaymentContainer] = useState(false)
  const [paymentReference, setPaymentReference] = useState(null)

  const { data } = useQuery(graphql`
      query AdyenPaymentFormIsPaidQuery($payableType: PayableType!, $payableId: Int, $paymentReference: String) {
          isPaid(payableType: $payableType, payableId: $payableId, paymentReference: $paymentReference)
      }
  `,
    {
      payableType,
      payableId,
      paymentReference
    },
    {
      fetchPolicy: 'network-only',
      fetchKey: refetchPayableItemKey,
      networkCacheConfig: {
        force: true,
      }
    }
  )
  const isPaid = data?.isPaid

  /*
   * Create Payment Mutations
   */
  const [initPaymentSession] = useMutation(graphql`
    mutation AdyenPaymentFormInitSessionMutation(
        $type: PayableType!,
        $paymentMethod: PaymentMethod!,
        $payableId: Int,
        $amount: Float
    ) {
        InitPaymentSession(
            type: $type
            paymentMethod: $paymentMethod
            payableId: $payableId
            amount: $amount
        ) {
            id
            sessionData
            reference
        }
    }
  `)

  const [lockPaymentSession] = useMutation(graphql`
      mutation AdyenPaymentFormLockPaymentMutation(
          $type: PayableType!,
          $payableId: Int
      ) {
          LockForPayment(
              type: $type
              payableId: $payableId
          )
      }
  `)

  useEffect(() => {
    if (!waitingWebhook || refetchRef.current) return

    if (paymentError) {
      setWaitingWebhook(false)
      return
    }

    refetchRef.current = setInterval(() => {
      setRefetchPayableItemKey(v => v + 1)
    }, 1000)

    return () => {
      clearInterval(refetchRef.current)
    }
  }, [waitingWebhook])

  useEffect(() => {
    if (isPaid) {
      clearInterval(refetchRef.current)
      setTimeout(() => {
        onCompleted('web')
      }, 1000)
    }
  }, [isPaid, refetchPayableItemKey])

  useEffect(() => {
    if (paymentError) {
      setWaitingWebhook(false)
      setAllowClosing(true)
    }
  }, [paymentError])

  /*
   * Init DropIn Adyen on mount
   */
  useEffect(() => {
    if (!containerRef.current) return

    (async () => {
      let initData
      try {
        const { InitPaymentSession: data } = await initPaymentSession({
          variables: {
            type: payableType,
            payableId: payableId,
            paymentMethod: isWithCredits ? 'METHOD_NEW_CREDIT_CARD_AND_CREDITS' : 'METHOD_NEW_CREDIT_CARD',
            amount: amount
          }
        })
        setPaymentReference(data.reference)
        initData = data
      } catch (e) {
        setInitError(true)
        setLoading(false)
        return
      }

      const configuration = {
        locale: 'fr_FR',
        showPayButton: true,
        environment: ADYEN_ENV,
        clientKey: ADYEN_CLIENT_KEY,
        session: {
          id: initData.id,
          sessionData: initData.sessionData
        },
        paymentMethodsConfiguration: {
          showPaymentMethods: false,
          card: {
            enableStoreDetails: true
          }
        },
        beforeSubmit: (state, component, { resolve }) => {
          lockPaymentSession({variables: {
            payableId: payableId,
            type: payableType
          }})

          setTimeout(() => {
            setAllowClosing(false)
            setWaitingWebhook(true)
          }, 200)

          resolve(state)
        },
        onPaymentCompleted: async (result) => {
          setTimeout(() => {
            if (result?.resultCode !== 'Authorised') {
              setPaymentError(true)
            }
            setHidePaymentContainer(true)
          }, 400)
        },
        onError: (error) => {
          console.error(error)
          setPaymentError(true)
          setLoading(false)
        }
      }

      const checkout = await AdyenCheckout(configuration)
      checkout.create('dropin').mount(containerRef.current)
      setLoading(false)
    })()
  }, [containerRef])

  /*
   * Render component
   */
  return (
    <View style={s.container}>
      {initError && <Label type={'error'}>{t('AdyenPaymentForm.initError')}</Label>}
      {paymentError && <Label type={'error'}>{t('AdyenPaymentForm.paymentError')}</Label>}

      {loading && <View style={{ marginBottom: 8 }}>
        <Loader />
        <Label style={s.loadingLabel}>{loading}</Label>
      </View>
      }

      {waitingWebhook && <View style={{ marginBottom: 8 }}>
          <Loader />
          <Label style={s.loadingLabel}>{'En attente de la validation du paiement'}</Label>
        </View>
      }

      {!hidePaymentContainer && <View ref={containerRef} style={s.inputContainer} />}
    </View>
  )
}

type PropsTypes = {
  amount: number,
  onCompleted: function,
  payableType?: string,
  payableId?: number,
  isWithCredits?: boolean
}