/**
 * @generated SignedSource<<1c7c77406fcbf00a758d6c4a2579717e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "qornerId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "qornerId",
        "variableName": "qornerId"
      }
    ],
    "kind": "ScalarField",
    "name": "checkUserQornerCodePs",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NavigationUserQornerMutation",
    "selections": (v1/*: any*/),
    "type": "ClientAppMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NavigationUserQornerMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ddbe6df7e212ac97e4e54596a35b6058",
    "id": null,
    "metadata": {},
    "name": "NavigationUserQornerMutation",
    "operationKind": "mutation",
    "text": "mutation NavigationUserQornerMutation(\n  $qornerId: Int!\n) {\n  checkUserQornerCodePs(qornerId: $qornerId)\n}\n"
  }
};
})();

node.hash = "b24caad3e5713bd3b4b7f682f89b06c9";

module.exports = node;
