import React, { type Node } from 'react'
import { Image, View } from 'react-native'
import Label from 'src/components/common/atoms/Label'
import { formatPrice } from 'src/utils/FormatDataUtils'
import s from './OrderModal.style'
import useScreenSizes from 'src/utils/useScreenSizes'

/**
 * OrderProductsModalContent component
 */
export default function OrderProductsModalContent ({
  serviceName,
  serviceIcon,
  products
}: PropsTypes): Node {
  const { isMobile } = useScreenSizes()

  /*
   * Render component
   */
  return (
    <View style={s.block}>

      <View style={s.blockTitle}>
        <View style={s.productsTitle}>
          <Image source={{uri: serviceIcon}} style={s.serviceIcon}/>
          <Label size={isMobile ? 12 : 14} type={'bold'}>{serviceName}</Label>
        </View>
        <Label size={isMobile ? 12 : 14} type={'bold'}>
          {formatPrice(products.reduce((accu, product) => accu + product.price, 0))}
        </Label>
      </View>

      {products.map(product => (
        <View style={s.blockRow} key={product.id}>
          <Label size={isMobile ? 10 : 12}>{product.name}</Label>
          <Label size={isMobile ? 10 : 12}>{product.quantity} x {formatPrice(product.unitPrice)}</Label>
        </View>
      ))}

    </View>
  )
}

type PropsTypes = {
  serviceName: string,
  serviceIcon: string,
  products: any
}