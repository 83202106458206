// @flow
import React, { type Node } from 'react'
import { TouchableWithoutFeedback, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import useScreenSizes from 'src/utils/useScreenSizes'
import Icon from 'src/components/common/atoms/Icon'
import Label from 'src/components/common/atoms/Label'
import s from './MenuDrawerItem.style'

/**
 * MenuDrawerItem component.
 */
export default function MenuDrawerItem({
  iconName,
  title,
  subtitle,
  isSubmenuItem = false,
  // TODO: Handle isActive ?
  isActive = false,
  hasLabelSeeAll = false,
  onPress
}: PropsTypes): Node {

  const { t } = useTranslation()
  const { isMobile, isTablet, isDesktop, screenSize } = useScreenSizes()

  return (
    <TouchableWithoutFeedback onPress={onPress}>
      <View style={[s.menuItem, !isMobile && s[`menuItem${screenSize}`], isSubmenuItem && [s.subMenuItem, !isMobile && s[`subMenuItem${screenSize}`]]]}>

        {/* Submenu space */}
        {isSubmenuItem && <View style={s.subMenuSpace}/>}

        {/* Icon */}
        {iconName && (
          <Icon style={[s.icon, isTablet && s.iconTablet]} name={iconName} size={isDesktop ? 20 : 15}/>
        )}

        <View style={s.textContainer}>
          <View style={s.titleContainer}>
            {/* Title */}
            <Label
              style={[
                s.title,
                isSubmenuItem && s.titleSubMenu,
                !isMobile && isSubmenuItem && s.titleSubMenuDesktop
              ]}
              size={isSubmenuItem ? (isMobile ? 13 : isTablet ? 11 : 14) : (isMobile ? 16 : isTablet ? 14 : 20)}
              numberOfLines={1}
            >
              {title}
            </Label>

            {/* Subtitle */}
            <Label style={s.subtitle} size={isDesktop ? 18 : 11}>{subtitle}</Label>
          </View>

          {/* See all label */}
          {hasLabelSeeAll && (
            <Label style={[s.seeAllLabel, !isMobile && s.seeAllLabelDesktop]} size={isMobile ? 11 : isTablet ? 8 : 14}>
              {t('MenuDrawerContent.seeAll')}
            </Label>
          )}
        </View>

        {/* Arrow nav */}
        <Icon style={s.arrow} name={'back'} size={10} />

      </View>
    </TouchableWithoutFeedback>
  )
}

// PropsTypes
type PropsTypes = {
  iconName?: 'cashback' | 'wallet' | 'home' | 'search' | 'menu' | 'basket' | 'check' | 'edit' | 'calendar' | 'back' |
    'next' | 'plus' | 'minus' | 'sales' | 'favorite' | 'favorite-filled' | 'caret' | 'profile-rounded' | 'chat' |
    'locker' | 'news' | 'infos' | 'bank-cards' | 'email' | 'phone' | 'lock' | 'profile' | 'estimate' | 'order' |
    'notify' | 'close' | 'qorner-company' | 'qorner-public',
  title?: string,
  subtitle?: string,
  isSubmenuItem?: boolean,
  isActive?: boolean,
  hasLabelSeeAll?: boolean,
  onPress?: function
}
