import { Platform, StyleSheet } from 'react-native'
import { widthPercentageToDP as wp , heightPercentageToDP as hp } from 'react-native-responsive-screen'
import { Colors } from 'src/styles'

// Styles
export default StyleSheet.create({
  publicLayout: {
    width: '100%',
    height: '100%',
    flexDirection: 'row'
  },
  pageContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    flex: 1
  },
  header: {
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 20,
    paddingRight: 20,
    paddingLeft: 20,
  },
  languageSwitcherContainer: {
    width: '100%',
    alignItems: 'flex-end',
    paddingBottom: 17
  },
  languageSwitcherContainerRegisterCompanion: {
    position: 'absolute',
    top: 20,
    left: 20
  },
  languageSwitcherContainerLoginCompanion: {
    position: 'absolute',
    top: 20,
    right: 20
  },
  logo: {
    width: 230,
    height: 60,
    resizeMode: 'contain',
  },
  logoDesktop: {
    width: 455,
    height: 120,
    resizeMode: 'contain',
  },
  logoTablet: {
    width: 189,
    height: 50,
    resizeMode: 'contain',
  },
  mainContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  registerCompanionLayout: {
    flexDirection: 'row'
  },
  companion: {
    position: 'relative',
    paddingTop: 50,
    paddingRight: 50,
    paddingBottom: 50,
    paddingLeft: 50,
    width: '33%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.orange100
  },
  companionTitle: {
    marginBottom: 30,
    fontWeight: 'bold',
    color: Colors.white100,
    textAlign: 'center'
  },
  companionTitleTablet: {
    marginBottom: 20,
  },
  companionText: {
    width: '100%',
    marginBottom: 30,
    color: Colors.white100,
    textAlign: 'center'
  },
  companionTextTablet: {
    marginBottom: 10,
  },
  companionButton: {
    marginTop: 30,
    width: 280
  },
  companionButtonTablet: {
    width: 112
  }
})