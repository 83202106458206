import { StyleSheet, Platform } from 'react-native'
import { Colors } from 'src/styles'

// Styles
export default StyleSheet.create({
  mainContainer: {
    maxWidth: 500,
    alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    paddingLeft: 15,
    paddingRight: 15,
  },
  mainContainerDesktop: {
    maxWidth: '100%',
    width: '50%'
  },
  mainContainerTablet: {
    maxWidth: '100%',
    width: '80%'
  },
  formContainer: {
    marginTop: 30
  },
  formContainerDesktop: {
    marginTop: 60
  },
  helper: {
    color: Colors.darkGrey100,
    textAlign: 'center'
  },
  textInput: {
    marginTop: 15,
  },
  textInputDesktop: {
    marginTop: 30,
  },
  error: {
    marginTop: 5,
    color: Colors.red100,
    marginLeft: 30
  },
  errorDesktop: {
    marginTop: 10,
    marginLeft: 65,
    marginBottom: -10
  },
  generalError: {
    textAlign: 'center',
    marginTop: 20,
    marginBottom: -10
  },
  submitButton: {
    marginTop: 30,
    width: '60%'
  },
  submitButtonDesktop: {
    marginTop: 60,
    width: '50%'
  },
  cancelButton: {
    marginTop: 20,
    width: '60%'
  },
  cancelButtonDesktop: {
    marginTop: 30,
    width: '50%'
  },
  mainContainerSuccess: {
    // flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: 30
  },
  mainContainerSuccessDesktop: {
    paddingLeft: 30,
    paddingRight: 30
  },
  successIcon: {
    color: Colors.orange100
  },
  successIconDesktop: {
    color: Colors.orange100,
    marginTop: 60
  },
  successTitle: {
    marginTop: 15,
    marginBottom: 30,
  },
  successTextContainer: {
    marginBottom: 30
  },
  backToLoginButton: {
    marginTop: 30
  }
})