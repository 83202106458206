import { Platform, StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    ...Platform.select({
      web: {
        cursor: 'pointer',
      }
    }),
    marginRight: 10,
    marginBottom: 10
  },
  containerDesktop: {
    marginRight: 20,
    marginBottom: 20
  },

  // Container size
  S: { width: 150, height: 100 },
  M: { width: 216, height: 100 },
  L: { width: 360, height: 200 },

  // Image Background
  image: { alignItems: 'flex-end', justifyContent: 'flex-end' },
  imageS: { width: 150, height: 70 },
  imageM: { width: 216, height: 70 },
  imageL: { width: 360, height: 150 },
  imageStyle: { borderRadius: 5 },

  // Labels
  imageLegend: {
    zIndex: 2,
    margin: 5
  },
  title: {
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
  },
  titleS: {
    height: 30,
  },
  titleM: {
    height: 30,
  },
  titleL: {
    height: 50,
  },

  // Gradient
  gradient: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1,
    borderRadius: 5
  },
  gradientS: {
    height: 70,
  },
  gradientM: {
    height: 70,
  },
  gradientL: {
    height: 150,
  }
})

export const imageLegendSizes = {
  'S': 8,
  'M': 9,
  'L': 12,
}

export const titleSizes = {
  'S': 11,
  'M': 12,
  'L': 18,
}

export const gradientColors = ['rgba(48, 48, 48, 0)', 'rgba(48, 48, 48, 0.7)']