// @flow
import React, { type Node, useCallback, useEffect, useRef, useState } from 'react'
import { FlatList, ScrollView, View } from 'react-native'
import { type StackNavigationProp } from '@react-navigation/stack'
import { useQuery } from 'relay-hooks'
import { useTranslation } from 'react-i18next'
import useScreenSizes from 'src/utils/useScreenSizes'
import type { RoutesParamList } from 'src/components/Navigation'
import SkeletonContent from 'react-native-skeleton-content-nonexpo'
import useFullWidth from 'src/utils/useFullWidth'
import PrivateLayout from 'src/components/common/layout/PrivateLayout'
import CatalogTabs from 'src/components/common/moles/CatalogTabs'
import FamilyCard from 'src/components/common/moles/FamilyCard'
import ServiceCard from 'src/components/common/moles/ServiceCard'
import GoodDealList from './GoodDealList'
import s, { serviceItemsSkeleton } from './CatalogScreen.style'

/**
 * CatalogScreen screen.
 */
export default function CatalogScreen ({ navigation }: PropsTypes): Node {

  /*
   * API Query & format data for SectionList
   */
  const { data } = useQuery(graphql`
    query CatalogScreenQuery {
      families {
        id
        rowId
        name
        color
        isUserFavorite
        pictureURL
        services {
          id
          rowId
          name
          familyId
          logoURL
          isUserFavorite
          color
          catalogIcon {
            url
          }
        }
      }
    }
  `)
  const families = data?.families.map(family => ({
    ...family,
    title: family.name,
    data: family.services
  }))

  /*
   * Initialize vars
   */
  const { t } = useTranslation()
  const fullWidth = useFullWidth()
  const { isMobile, isTablet, width } = useScreenSizes()
  const refServicesList = useRef(null)
  const refCatalogTabs = useRef(null)
  const containerRef = useRef(null)
  const [currentTab, setCurrentTab] = useState(null)
  const [numColumns, setNumColumns] = useState(1)
  const [viewableItem, setViewableItem] = useState(null)

  const cardWidth = isMobile
    ? fullWidth - 48
    : isTablet
      ? ((containerRef?.current?.clientWidth - 60) / numColumns) - 20
      : ((containerRef?.current?.clientWidth - 60) / numColumns) - 20

  // updates number of columns on width change
  useEffect(() => {
    if (width >= 1600) {
      setNumColumns(5)
    } else if (width >= 1024) {
      setNumColumns(4)
    } else if (width >= 768) {
      setNumColumns(3)
    } else {
      setNumColumns(1)
    }
  }, [width])

  /*
   * When receive data from API, update currentTab
   */
  useEffect(() => {
    if (families && families.length > 0) {
      setCurrentTab(families[0].rowId)
    }
  }, [data])

  /*
   * Handle on Scroll with useEffect & middleItem
   */
  // useEffect(() => {
  //   if (!isMobile) { return }
  //
  //   // Quit early if no viewable item or families
  //   if (!viewableItem || !families) { return }
  //
  //   // Get current tab index base on viewable item
  //   const shouldBeActiveTab = viewableItem.item.rowId
  //   const activeIndex = families.findIndex(family => family.rowId === shouldBeActiveTab)
  //
  //   // Scroll to proper service tab
  //   if (currentTab !== shouldBeActiveTab.toString()) {
  //     setCurrentTab(shouldBeActiveTab)
  //     refCatalogTabs.current.scrollToIndex({
  //       index: activeIndex,
  //       viewPosition: 0.5
  //     })
  //   }
  //
  // }, [viewableItem])

  /*
   * Handle scroll and update currentTab
   */
  const handleScrollServicesList = useCallback((items) => {
    const middleItem = items.viewableItems.slice(Math.trunc(items.viewableItems.length / 4))[0]
    setViewableItem(middleItem)
  }, [])

  /*
   * Handle press one tab
   */
  const handlePressedTab = (tab, index) => {
    refServicesList.current.scrollToIndex({
      index: index,
      viewPosition: 0
    })
  }

  /*
   * Handle press one service
   */
  const handlePressedService = service => {
    navigation.navigate({
      name: 'Service',
      params: {
        familyId: service.familyId,
        serviceId: service.rowId
      }
    })
  }

  /*
   * Handle press good deal: redirect to GoodDealScreen
   */
  const handlePressedGoodDeal = () => navigation.navigate('GoodDeal')

  const _getItemLayout = (data, index) => {
    const FamilyCardHeight = isMobile ? 40 : 80
    const serviceCardHeight = isMobile ? 50 : 80

    let itemOffset = 0

    for (let i = 0; i < index; i++) {
      const nbServices = data[i].services.length
      const tmpOffset = (((serviceCardHeight * nbServices) / numColumns) + FamilyCardHeight)
      itemOffset += tmpOffset
    }

    // Return info to FlatList in order to help him positionate the window for automatic scrolling
    return {
      index,
      length: serviceCardHeight + FamilyCardHeight,
      offset: itemOffset
    }
  }

  const renderServicesList = ({ item: family }) => {
    if (family.services.length === 0) { return null }

    return <FlatList
      data={family.services}
      numColumns={numColumns}
      key={numColumns}
      keyExtractor={service => service.id}
      maxToRenderPerBatch={20}
      initialNumToRender={100}
      ListHeaderComponent={<FamilyCard
        style={isMobile ? s.familyCard : s.familyCardDesktop}
        title={family.title}
        color={family.color}
        pictureURL={family.pictureURL}
      />}
      renderItem={renderService}
    />
  }

  const renderService = ({ item: service }) => (
    <ServiceCard
      key={service.id}
      width={cardWidth}
      title={service.name}
      color={service.color}
      iconURL={service.logoURL}
      isFav={service.isUserFavorite}
      margin={isMobile ? { bottom: 14 } : { bottom: 20, right: 20 }}
      onPress={() => handlePressedService(service)}
      size={isMobile ? 'M' : 'L'}
      displayNonFavoriteIcon={false}
    />
  )

  /*
   * Render component
   */
  return (
    <PrivateLayout
      headerTitle={t('CatalogScreen.title')}
      headerBackOnPress={() => navigation.navigate({ name: 'Home' })}
      headerBackTitle={t('Navigation.naming.Home')}
    >


      <ScrollView>
        <View style={[s.container, !isMobile && s.containerDesktop]} ref={containerRef}>
          {/* Good deal list (soldes, cashback) */}

          <View style={!isMobile && s.goodDealListContainer}>
            <GoodDealList onPress={handlePressedGoodDeal} isMobile={isMobile}/>
          </View>

          {/* Services list with products */}
          <SkeletonContent
            containerStyle={!families ? s.skeletonContainer : s.subContainer}
            isLoading={!families}
            layout={serviceItemsSkeleton}
          >
            {families && <FlatList
              ref={refServicesList}
              contentContainerStyle={[s.listContainer, !isMobile && s.listContainerDesktop]}
              style={s.list}
              onScrollToIndexFailed={error => console.error(error)}
              onViewableItemsChanged={handleScrollServicesList}
              getItemLayout={_getItemLayout}
              removeClippedSubviews={true}
              updatedCellsBatchingPeriod={100}
              initalNumToRender={20}
              data={families}
              keyExtractor={item => item.id}
              renderItem={renderServicesList}
            />}
          </SkeletonContent>

        </View>
      </ScrollView>
    </PrivateLayout>
  )
}

// PropsTypes
type PropsTypes = {
  navigation: StackNavigationProp<RoutesParamList, 'Catalog'>
}
