// @flow
import React, {useCallback, useState} from 'react'
import Modal from 'src/components/common/atoms/Modal'
import OrderModal from 'src/components/screens/private/profile/ProfileOrdersScreen/OrderModal'
import CoverDeliveryModal from 'src/components/screens/private/CoverDeliveryModal'
import CoverCollectModal from 'src/components/screens/private/CoverCollectModal'
import CancelOrderModal from 'src/components/screens/private/profile/ProfileOrdersScreen/CancelOrderModal'
import CancelCollectModal from 'src/components/screens/private/profile/ProfileOrdersScreen/CancelCollectModal'
import SendOrderInvoiceModal from 'src/components/screens/private/profile/ProfileOrdersScreen/InvoiceModal'
import RateOrderModal from 'src/components/screens/private/profile/ProfileOrdersScreen/RateOrderModal'
import { actions } from 'src/components/screens/private/profile/ProfileOrdersScreen/constants/OrderActionsConstant'
import useIntercom from 'src/utils/useIntercom'
import { useQuery } from 'relay-hooks'
import { useNavigation } from '@react-navigation/native'

/**
 * Hook returning everything needed to manage order modals in ProfileAgendaScreen and ProfileOrdersScreen as they are the same
 * (should most likely be done differently)
 */
export default function useOrderModals () {

  const navigation = useNavigation()

  // Get user data for Intercom
  const { data } = useQuery(graphql`
    query useOrderModalsQuery {
      user {
        id
        rowId
        firstName
        lastName
        email
        intercomUserIdentity
      }
    }
  `)
  const user = data?.user

  const initialModalState = {
    detail: false,
    collect: false,
    delivery: false,
    cancel: false,
    cancelCollect: false,
    sendInvoice: false,
    rate: false
  }

  const [modalState, setModalState] = useState(initialModalState)
  const [selectedOrderId, setSelectedOrderId] = useState(null)
  const intercom = useIntercom(user)

  // Handle primeCustomerAction : delivery, pay, collect, rate
  const handleAction = (action) => {
    switch (action) {
      case actions.PAY:
        navigation.navigate({ name: 'PaymentOrder', params: { orderId: selectedOrderId } })
        break
      case actions.DELIVERY:
        setModalState(v => ({ ...v, detail: false, delivery: true }))
        break
      case actions.COLLECT:
        setModalState(v => ({ ...v, detail: false, collect: true }))
        break
      case actions.CANCEL_ORDER:
        setModalState(v => ({ ...v, cancel: true }))
        break
      case actions.CANCEL_COLLECT:
        setModalState(v => ({ ...v, cancelCollect: true }))
        break
      case actions.INVOICE:
        setModalState(v => ({ ...v, sendInvoice: true }))
        break
      case actions.CONTACT:
        intercom.start()
        break
      case actions.RATE:
        setModalState(v => ({ ...v, detail: false, rate: true }))

    }
  }

  // Handle Order Modal
  const handlePressOrder = (type, id) => {
    setSelectedOrderId(id)
    setModalState(v => ({ ...v, detail: true }))
  }

  // Handle delivered order: fadeOut + add in finishedOrders list
  const handleCloseDeliverModal = (isDone = false) => {
    setModalState(v => ({ ...v, delivery: false }))
  }

  // Handle collected order: set status to "processing"
  const handleCloseCollectModal = (isDone = false) => {
    setModalState(v => ({ ...v, collect: false }))
  }
  const handleCloseCancelModal = (isDone = false) => {
    setModalState(v => (isDone?{...v, detail: false, cancel: false}:{...v, cancel: false}))
  }

  const OrderModals = useCallback( () => (<>
          <Modal open={modalState.detail} onClose={() => setModalState(v => ({...v, detail: false}))}>
            <OrderModal orderId={selectedOrderId} handleAction={handleAction}/>
          </Modal>

          <Modal open={modalState.delivery} onClose={handleCloseDeliverModal}>
            <CoverDeliveryModal orderId={selectedOrderId}/>
          </Modal>

          <Modal open={modalState.collect} onClose={handleCloseCollectModal}>
            <CoverCollectModal orderId={selectedOrderId}/>
          </Modal>

          <Modal open={modalState.cancel} onClose={handleCloseCancelModal}>
            <CancelOrderModal orderId={selectedOrderId}/>
          </Modal>

          <Modal open={modalState.cancelCollect} onClose={() => setModalState(v => ({...v, cancelCollect: false}))}>
            <CancelCollectModal orderId={selectedOrderId}/>
          </Modal>

          <Modal open={modalState.sendInvoice} onClose={() => setModalState(v => ({...v, sendInvoice: false}))}>
            <SendOrderInvoiceModal orderId={selectedOrderId}/>
          </Modal>

          <Modal open={modalState.rate} onClose={() => setModalState(v => ({...v, rate: false}))}>
            <RateOrderModal orderId={selectedOrderId}/>
          </Modal>
        </>), [modalState, selectedOrderId])

  return {
    OrderModals,
    handleAction,
    handlePressOrder,
    setModalState,
    setSelectedOrderId
  }
}