import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

// Styles
export default StyleSheet.create({
  containerNotMobile: {
    width: '100%',
    paddingLeft: 60,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch'
  },
  modalTitle: {
    marginBottom: 72
  },
  checkMarkIcon: {
    color: Colors.orange100,
    alignSelf: 'center'
  },
  modalContent: {
    justifyContent: 'space-around',
    marginBottom: 30
  },
  modalText: {
    flex:1,
    flexWrap: 'wrap',
  },
  modalCertify: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30
  },
  qornerTitle: {
    marginBottom: 10,
  },
  qornerTitleNotMobile: {
    marginBottom: 30,
  },
  modalSwitchDesktop: {
    marginLeft: 60
  },
  actionButton: {
    width: '80%',
    marginTop: 20
  },
  actionButtonDesktop: {
    width: 320,
    marginTop: 20
  },
  actionButtonTablet: {
    width: 320,
    marginTop: 20
  },
  cancelButtonDesktop: {
    width: 320,
    marginTop: 20
  },
  cancelButtonTablet: {
    width: 320,
    marginTop: 20
  },
  iconStateContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 40,
    paddingBottom: 40
  },
  successIcon: {
    color: Colors.orange100
  },
  errorIcon: {
    color: Colors.red100
  },
  inputCodePS: {
    width: '80%',
    marginTop: 20,
    marginBottom: 20,
    textAlign: 'center'
  },
  inputCodePSDesktop: {
    width: 320,
    marginTop: 20
  },
  inputCodePSTablet: {
    width: 320,
    marginTop: 20
  },
  divInputCodePS: {
    display:'flex',
    flexDirection:'column',
    alignItems:'center'
  },
  textError: {
    color: 'red'
  }
})
