// @flow
import React, { type Node, useState } from 'react'
import Modal from 'src/components/common/atoms/Modal'
import ConfigModalContent from './ConfigModalContent'
import SupplementsModalContent from './SupplementsModalContent'
import SummaryModalContent from './SummaryModalContent'

export default function ProductConfigModal ({
  openBis,
  setOpenBis,
  product
}: PropsTypes): Node {

  /*
   * Init
   */
  const steps = {
    CONFIGS: 'configs',
    SUPPLEMENTS: 'supplements',
    SUMMARY: 'summary'
  }
  const [currentStep, setCurrentStep] = useState(steps.CONFIGS)

  /*
   * Render component
   */
  return (
    <Modal open={openBis} onClose={() => setOpenBis(false)}>
      {currentStep === steps.CONFIGS ? (
        <ConfigModalContent product={product} setOpenBis={setOpenBis} steps={steps} setCurrentStep={setCurrentStep}/>
      ) : currentStep === steps.SUPPLEMENTS ? (
        <SupplementsModalContent product={product} setOpenBis={setOpenBis} steps={steps} setCurrentStep={setCurrentStep}/>
      ) : currentStep === steps.SUMMARY && (
        <SummaryModalContent setOpenBis={setOpenBis} steps={steps} setCurrentStep={setCurrentStep}/>
      )}
    </Modal>
  )
}

type PropsTypes = {
  openBis: boolean,
  setOpenBis: function,
  product: any
}



