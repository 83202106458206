/**
 * @generated SignedSource<<bad14e4e006ce1ec08790c30ae0c6029>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "payableId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "type"
},
v2 = [
  {
    "kind": "Variable",
    "name": "payableId",
    "variableName": "payableId"
  },
  {
    "kind": "Variable",
    "name": "type",
    "variableName": "type"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "useCashback",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "type": "Order",
  "abstractKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/),
    (v4/*: any*/),
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amountAfterReduction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "discountByCashback",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cashbackEarnedAmount",
      "storageKey": null
    }
  ],
  "type": "Basket",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PaymentCashbackSectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "ToggleUseCashback",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "ClientAppMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PaymentCashbackSectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "ToggleUseCashback",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fdc249828cccb2b2eff46f7bcd972d8a",
    "id": null,
    "metadata": {},
    "name": "PaymentCashbackSectionMutation",
    "operationKind": "mutation",
    "text": "mutation PaymentCashbackSectionMutation(\n  $type: PayableType!\n  $payableId: Int\n) {\n  ToggleUseCashback(type: $type, payableId: $payableId) {\n    __typename\n    ... on Order {\n      id\n      useCashback\n      amount\n    }\n    ... on Basket {\n      id\n      useCashback\n      amount\n      amountAfterReduction\n      discountByCashback\n      cashbackEarnedAmount\n    }\n  }\n}\n"
  }
};
})();

node.hash = "13c5fa79a1aadee2f6abf329ffc003d1";

module.exports = node;
