/**
 * @generated SignedSource<<48dc75630bd77db0b9b97abb0ce139a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "channel"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "channel",
        "variableName": "channel"
      },
      {
        "kind": "Variable",
        "name": "orderId",
        "variableName": "orderId"
      }
    ],
    "kind": "ScalarField",
    "name": "SendOrderInvoice",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SendOrderInvoiceModalMutation",
    "selections": (v1/*: any*/),
    "type": "ClientAppMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SendOrderInvoiceModalMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e9f4bab64f06e00c741d88ab1a3685df",
    "id": null,
    "metadata": {},
    "name": "SendOrderInvoiceModalMutation",
    "operationKind": "mutation",
    "text": "mutation SendOrderInvoiceModalMutation(\n  $channel: CommunicationChannel!\n  $orderId: Int!\n) {\n  SendOrderInvoice(channel: $channel, orderId: $orderId)\n}\n"
  }
};
})();

node.hash = "8719b487c52a285a51027721e310878a";

module.exports = node;
