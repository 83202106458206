// @flow
import React, { type Node, type ComponentType } from 'react'
import { createIconSetFromIcoMoon } from '@expo/vector-icons'
import iconsConfig from 'assets/fonts/Icons.config'
import { Colors } from 'src/styles'

// Create EQQ IconSet from a ttf file
const IconSet: ComponentType<any> = createIconSetFromIcoMoon(iconsConfig, 'Icons', 'Icons.ttf')

/**
 * Icon atom.
 */
export default function Icon ({
  style,
  name,
  size = 14,
  color = Colors.darkGrey100
}: PropsTypes): Node {
  return <IconSet
    style={style}
    name={name}
    size={size}
    color={color}
  />
}

// PropsTypes
type PropsTypes = {
  style?: any,
  name: 'cashback' | 'wallet' | 'home' | 'search' | 'menu' | 'basket' | 'check' | 'edit' | 'calendar' | 'back' |
    'next' | 'plus' | 'minus' | 'sales' | 'favorite' | 'favorite-filled' | 'caret' | 'profile-rounded' | 'chat' |
    'locker' | 'news' | 'infos' | 'bank-cards' | 'email' | 'phone' | 'lock' | 'profile' | 'estimate' | 'order' |
    'notify' | 'close' | 'qorner-company' | 'qorner-public',
  size?: number,
  color?: string,
}


