import React, { type Node } from 'react'
import { Image, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import s from '../CoverDeliveryModal.style'
import useScreenSizes from 'src/utils/useScreenSizes'

/**
 * LockerReadySecretCodeContent component
 */
export default function LockerSecretCodeContent ({
  onRetrievedOrder,
  onClose,
  secretCode,
  handleQRCode
}: PropsTypes): Node {
  /*
   * Init vars
   */
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const codes = secretCode.split(' ')

  /*
   * Render component
   */
  return (
    <View>
      <Label size={isMobile ? 10 : 12}>{t('CoverDeliveryModal.LockerContent.SecretCode.description')}</Label>
      <View style={s.secretCodeContainer}>
        <View>
          <Label types={['bold', 'smallMargin']} size={isMobile ? 12 : 14}>{t('CoverDeliveryModal.LockerContent.SecretCode.orderNumber')}</Label>
          <Label size={isMobile ? 12 : 14}>N°{codes[0]}</Label>
        </View>
        <View style={s.secretCodeSeparator} />
        <View>
          <Label types={['bold', 'smallMargin']} size={isMobile ? 12 : 14}>{t('CoverDeliveryModal.LockerContent.SecretCode.secretCode')}</Label>
          <Label size={isMobile ? 12 : 14}>{codes[1]}</Label>
        </View>
      </View>
      <Image
        style={[s.contentImage, !isMobile && s.contentImageDesktop]}
        resizeMode={'contain'}
        source={require('assets/img/screens/private/shoppingTunnel/CoverDepositModal/animation_code_consigne_connectee.gif')}
      />
      <View style={s.buttonsContainer}>
        <Button onPress={onRetrievedOrder} palette={'primary'} size={isMobile ? 'S' : 'M'}
                fullWidth>{t('CoverDeliveryModal.LockerContent.doneBtn')}</Button>
        <Button onPress={handleQRCode} style={s.middleButton} size={isMobile ? 'S' : 'M'}
                fullWidth>{t('CoverDeliveryModal.LockerContent.retrieveByQRCode')}</Button>
        <Button onPress={onClose} style={s.lastButton} size={isMobile ? 'S' : 'M'}
                fullWidth>{t('CoverDeliveryModal.cancelRecovery')}</Button>
      </View>
    </View>
  )
}

type PropsTypes = {
  onRetrievedOrder: function,
  onClose: function,
  secretCode: string,
  handleQRCode: function
}