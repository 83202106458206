import { Platform, StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

// Styles
export default StyleSheet.create({
  menuBarButton: {
    position: 'relative',
    flex: 1,
    borderWidth: 0,
    backgroundColor: 'transparent',
    borderRadius: 0,
  },
  menuBarIcon: {
    fontSize: 25,
    color: Colors.mediumGrey100
  },
  menuBarIconNotMobile: {
    position: 'relative',
    top: 5
  },
  menuBarButtonRight: {
    borderTopLeftRadius: 40,
    borderBottomLeftRadius: 40,
    backgroundColor: '#fff',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 4
  },
  buttonNotMobile: {
    marginLeft: 10,
    ...Platform.select({
      native: {
        borderWidth: 0
      },
      web: {
        border: 'none'
      }
    }),
    backgroundColor: 'transparent',
  },
  labelNotMobile: {
    lineHeight: 50,
    marginLeft: 5
  }
})