// @flow
import React, { type Node } from 'react'
import { Image, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { type StackNavigationProp } from '@react-navigation/stack'
import type { RoutesParamList } from 'src/components/Navigation'
import { useStoreState } from 'pullstate'
import useScreenSizes from 'src/utils/useScreenSizes'
import UserStore from 'src/store/UserStore'
import Button from 'src/components/common/atoms/Button'
import Label from 'src/components/common/atoms/Label'
import s from './NotFoundScreen.style'

/**
 * NotFoundScreen screen.
 */
export default function NotFoundScreen ({
  navigation
}: PropsTypes): Node {

  // Init state
  const { t } = useTranslation()
  const isAuthenticated = useStoreState(UserStore, s => s.jwtToken !== null)
  const initialRouteName = !isAuthenticated ? 'Public' : 'Private'
  const { isMobile } = useScreenSizes()

  // Render screen for desktop screen
  return (
    <View style={[s.mainContainer, isMobile && s.mainContainerMobile]}>

      {isMobile && (
        <Image
          source={require('assets/img/common/layout/PublicLayout/logo_horizontal_orange.png')}
          style={[s.logoImage, isMobile && s.logoImageMobile]}
        />
      )}

      <View style={[s.imgContainer, isMobile && s.imgContainerMobile]}>
        <Image
          source={require('assets/img/screens/public/NotFoundScreen/404.png')}
          style={[s.errorImage, isMobile && s.errorImageMobile]}
        />
      </View>
      <View style={[s.textContainer, isMobile && s.textContainerMobile]}>

        {!isMobile && (
          <Image
            source={require('assets/img/common/layout/PublicLayout/logo_horizontal_orange.png')}
            style={s.logoImage}
          />
        )}

        {/* Title */}
        <Label type={'bold'} size={isMobile ? 25 : 60} style={!isMobile && s.title}>
          {t('NotFoundScreen.title')}
        </Label>

        {/* Subtitle */}
        <Label size={isMobile ? 12 : 30} style={[s.subTitle, isMobile && s.subTitleMobile]}>
          {t('NotFoundScreen.subtitle')}
        </Label>

        {/* Button to go back to home or login */}
        <Button
          palette={'primary'}
          size={isMobile ? 'S' : 'L'}
          onPress={() => navigation.navigate({ name: initialRouteName })}
        >
          {t('NotFoundScreen.button')}
        </Button>
      </View>
    </View>
  )
}

// PropsTypes
type PropsTypes = {
  navigation: StackNavigationProp<RoutesParamList, 'NotFound'>
}