// @flow
import React, { type Node } from 'react'
import { TouchableWithoutFeedback, Linking, Text, Platform } from 'react-native'

/**
 * ExternalLink atom.
 */
export default function ExternalLink ({
  style,
  styles,
  url,
  title,
}: PropsTypes): Node {

  // Open link in new tab
  const handleLink = () => {
    if(Platform.OS === 'web'){
      window.open(url, '_blank')
    } else {
      Linking
        .openURL(url)
        .catch(err => {
          console.error('Failed opening page because: ', err)
        })
    }
  }

  // Render link
  return (
    <TouchableWithoutFeedback onPress={handleLink} style={[style, styles?.container]}>
      <Text style={styles?.title}>{title ? title : url}</Text>
    </TouchableWithoutFeedback>
  )

}

// PropsTypes
type PropsTypes = {
  style?: any,
  styles?: any,
  url: string,
  title?: string
}