import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

export default StyleSheet.create({
  container: {
    flex: 1
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  headerTitleContainer: {
    flexDirection: 'column'
  },
  headerActionBtn: {
    alignSelf: 'flex-start',
    marginTop: 2
  },
  primeLabel: {
    color: Colors.orange100
  },
  block: {
    marginTop: 16
  },
  lastBlock: {
    marginTop: 22
  },
  blockTitle: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 6,
    paddingBottom: 4,
    borderBottomWidth: 1,
    borderBottomColor: Colors.darkGrey100Less50
  },
  productsTitle: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  serviceIcon: {
    width: 20,
    height: 20,
    marginRight: 6,
    borderRadius: 20
  },
  blockRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  blockButtonsRow: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  alignStart: {
    alignSelf: 'flex-start'
  },
  paymentStatusText: {
    marginBottom: 6
  },
  btn: {
    width: '49%',
    paddingLeft: 2,
    paddingRight: 2
  }
})

export const skeletonLayout = [
  {width: '100%', height: 40, marginBottom: 20, marginTop: 6},
  {width: '100%', height: 70, marginBottom: 20},
  {width: '100%', height: 90, marginBottom: 20},
  {width: '100%', height: 40, marginBottom: 20},
  {width: '100%', height: 70, marginBottom: 6}
]
