// @flow
import React, { type Node } from 'react'
import { View } from 'react-native'
import Label from 'src/components/common/atoms/Label'
import s from 'src/components/common/atoms/Tag/Tag.style'

/**
 * Tag atom.
 */
export default function Tag ({
  palette = 'default',
  size = 'M',
  rounded = false,
  Icon,
  styles,
  style,
  children
}: PropsTypes): Node {
  return (
    <View
      style={[
        s.container,
        palette === 'primary' && s.containerPrimary,
        rounded && s.rounded,
        style,
        styles?.container
      ]}>
      <>
        {Icon && Icon}
        <Label
          style={[
            s.label,
            palette === 'primary' && s.labelPrimary,
            s[`label${size}`],
            Icon && s.hasIcon,
            styles?.label
          ]}>
          {children}
        </Label>
      </>
    </View>
  )
}

// PropsTypes
type PropsTypes = {
  palette?: 'default' | 'primary',
  size?: string,
  rounded?: boolean,
  Icon?: any,
  children: any,
  styles?: any,
  style?: any,
}