// @flow
import React, { type Node, useEffect, useState } from 'react'
import { View, SectionList, FlatList } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'relay-hooks'
import { type StackNavigationProp } from '@react-navigation/stack'
import type { RoutesParamList } from 'src/components/Navigation'
import moment from 'moment'
import useScreenSizes from 'src/utils/useScreenSizes'
import SkeletonContent from 'react-native-skeleton-content-nonexpo'
import PrivateLayoutProfile from 'src/components/common/layout/PrivateLayoutProfile'
import Button from 'src/components/common/atoms/Button'
import Label from 'src/components/common/atoms/Label'
import Icon from 'src/components/common/atoms/Icon'
import Modal from 'src/components/common/atoms/Modal'
import ProfileEstimateCard from 'src/components/common/moles/ProfileEstimateCard/ProfileEstimateCard'
import ProfileOrderCard from 'src/components/common/moles/ProfileOrderCard'
import ProfileEntertainementCard from 'src/components/common/moles/ProfileEntertainmentCard/ProfileEntertainementCard'
import ProfileActivityCard from 'src/components/common/moles/ProfileActivityCard/ProfileActivityCard'
import CalendarModal from './CalendarModal'
import useOrderModals from 'src/utils/useOrderModals'
import s from './ProfileAgendaScreen.style'
import { listSkeletonLayout } from './ProfileAgendaScreen.style'

/**
 * ProfileAgendaScreen screen.
 */
export default function ProfileAgendaScreen ({
  navigation
}: PropsTypes): Node {

  // Init state
  const { t } = useTranslation()
  const [isModalView, setIsModalView] = useState(false)
  const [filterDate, setFilterDate] = useState(null)
  const [diariesByDate, setDiariesByDate] = useState([])
  const { isMobile } = useScreenSizes()
  const { OrderModals, handleAction, handlePressOrder }  = useOrderModals()

  // API Call
  const { data, isLoading } = useQuery(graphql`
    query ProfileAgendaScreenQuery($date: Date) {
      diary(date: $date) {
        date
        isLate
        event {
          ... on Entertainment {
            __typename
            id
            rowId
            startDateTime
            title
            endDateTime
          },
          ... on Estimate {
            __typename
            id
            rowId
            estimateSubmitDateTime
            allowedCustomerActions
          },
          ... on Order {
            __typename
            id
            rowId
            collectDate
            deliveryDate
            status
            allowedCustomerActions
            primeCustomerAction
            amount
            service {
              name
              pictureURL
            }
          }
        }
      }
    }
  `, {
    date: filterDate ? filterDate.year + '-' + filterDate.month + '-' + filterDate.day : null
  })
  const diary = data?.diary

  useEffect(() => {
    if (diary) {
      setDiariesByDate(groupEventsByDate(diary))
    }
  }, [diary])

  // Group events by date section to display them in a SectionList component
  const groupEventsByDate = events => {
    let eventsByDate = []
    events.forEach(event => {

      // Init date section if there is not yet a section for this date
      if (!eventsByDate[event.date]) {
        eventsByDate[event.date] = { title: event.date, data: [] }
      }

      // Add event to proper date section
      eventsByDate[event.date].data.push(event.event)

    })

    // Return array of data group by date for SectionList component
    // we need to convert this object as real array (with numerical keys)
    let eventsByDays = []
    for (let day in eventsByDate) {
      eventsByDays.push(eventsByDate[day])
    }

    return eventsByDays
  }

  // Fake handler for the moment
  // TODO : real handler
  const handleAgendaItem = (type, id) => {
    console.log(type, id)
  }

  // Fake handler for the moment
  // TODO : real handler
  const handleAgendaItemAction = (type, id, action) => {
    console.log(type, id, action)
  }

  // Show or hide calendar modal
  const toggleModalView = () => {
    setIsModalView(!isModalView)
  }

  // Return correct card for item
  const switchItem = item => {
    switch (item.__typename) {
      case 'Activity':
        return <ProfileActivityCard
          id={item.rowId}
          actionType={null}
          handlePress={handleAgendaItem}
          handleAction={handleAgendaItemAction}
        />
      case 'Entertainement':
        return <ProfileEntertainementCard
          id={item.rowId}
          actionType={null}
          handlePress={handleAgendaItem}
          handleAction={handleAgendaItemAction}
        />
      case 'Estimate':
        return <ProfileEstimateCard
          id={item.rowId}
          handlePress={handleAgendaItem}
          handleAction={handleAgendaItemAction}
        />
      case 'Order':
        return <ProfileOrderCard
          style={[{ marginBottom: 10 }, !isMobile && { flex: 0.5, marginHorizontal: 10 }]}
          id={item.rowId}
          status={item.status}
          handlePress={handlePressOrder}
          handleAction={handleAction}
          primeAction={item.primeCustomerAction}
          amount={item.amount}
          serviceName={item.service.name}
          collectDate={item.collectDate}
          pictureURL={item.service.pictureURL}

        />
    }
  }

  // Render screen
  return (
    <>
      <PrivateLayoutProfile
        navigation={navigation}
        subHeaderIconName={'calendar'}
        subHeaderTitle={t('MenuDrawerContent.links.agenda')}
      >

        {/* Actions buttons */}
        <View style={s.buttonContainer}>

          {/* Date criteria filters with remove button */}
          {filterDate && (
            <View style={s.filterDateContainer}>
              <Button fontStyle={'bold'} size={'M'} onPress={toggleModalView}>
                {filterDate.day + '/' + filterDate.month + '/' + filterDate.year}
              </Button>
              <Button size={'M'} onPress={() => setFilterDate(null)} style={s.filterDateDelete}
                      Icon={<Icon name={'plus'} style={s.filterDateDeleteIcon}/>}/>
            </View>
          )}

          {/* See overview (modal calendar) which allow to filter on a particular day */}
          {!filterDate && (
            <Button fontStyle={'bold'} size={'M'} onPress={toggleModalView}>
              {t('ProfileAgendaScreen.seeOverview')}
            </Button>
          )}

        </View>

        <SkeletonContent
          isLoading={isLoading}
          containerStyle={[s.skeletonContainer, isLoading && s.skeletonContainerActive]}
          layout={listSkeletonLayout}
        >
          {diariesByDate && (
            <View style={s.listContainer}>
              {isMobile ? (
                <SectionList
                  keyExtractor={item => item.id}
                  style={s.list}
                  contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 20, paddingBottom: 20 }}
                  sections={diariesByDate}
                  renderItem={({ item }) => switchItem(item)}
                  renderSectionHeader={({ section: { title } }) => (
                    <Label type={('bold')} size={15} style={s.listSectionHeader}>{title}</Label>
                  )}
                  ListEmptyComponent={(
                    <View style={s.emptyListContainer}>
                      <Label style={s.emptyListMessage}>{t('ProfileAgendaScreen.emptyList')}</Label>
                    </View>
                  )}
                />
              ) : (
                // Desktop view
                <FlatList
                  style={s.list}
                  data={diariesByDate}
                  keyExtractor={(item) => item.title}
                  ListEmptyComponent={(
                    <View style={s.emptyListContainer}>
                      <Label style={s.emptyListMessage}>{t('ProfileAgendaScreen.emptyList')}</Label>
                    </View>
                  )}
                  renderItem={({ item: { title, data } }) => (
                    <>
                      <Label
                        type={'bold'}
                        size={20}
                        style={s.desktopListTitle}>
                        {moment(title).format('LL')}
                      </Label>

                      <FlatList
                        numColumns={2}
                        data={data}
                        keyExtractor={item => item.id}
                        renderItem={({ item }) => switchItem(item)}
                      />
                    </>
                  )}
                />
              )}
            </View>
          )}
        </SkeletonContent>
      </PrivateLayoutProfile>

      {/* Modal to filter on a specific date */}
      <Modal open={isModalView} onClose={toggleModalView}>
        <CalendarModal filterDate={filterDate} setFilterDate={setFilterDate}/>
      </Modal>

      <OrderModals />
    </>
  )
}

// PropsTypes
type PropsTypes = {
  navigation: StackNavigationProp<RoutesParamList, 'Agenda'>
}
