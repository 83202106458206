import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

// Styles
export default StyleSheet.create({

  modalTitle: {
    marginBottom: 20
  },
  modalTitleDesktop: {
    marginBottom: 30
  },

  // ChooseAmountModalContent

  chooseAmountMaxAmountReached: {
    marginTop: 30,
    marginBottom: 30
  },
  chooseAmountCloseButton: {
    marginBottom: 20,
    width: '60%'
  },
  chooseAmountFormContainer: {
    alignItems: 'center'
  },
  chooseAmountForm: {
    width: '70%'
  },
  chooseAmountSelect: {
    margin: 20
  },
  chooseAmountSelectDesktop: {
    margin: 60
  },
  chooseAmountSelectInput: {
    marginTop: 30,
    marginBottom: 30
  },
  chooseAmountSubmitButton: {
    marginBottom: 30,
    width: 180
  },

  // ChoosePaymentModalContent

  choosePaymentMethodLoaderContainer: {
    height: 227,
    justifyContent: 'center',
    alignItems: 'center'
  },
  choosePaymentMethodButtons: {
    alignItems: 'center',
  },
  choosePaymentMethodButtonsDesktop: {
    marginTop: 60
  },
  choosePaymentMethodCardButton: {
    marginTop: 20,
  },
  choosePaymentMethodCancelButton: {
    marginTop: 20,
    marginBottom: 10,
  },
  choosePaymentMethodButtonDesktop: {
    width: '60%'
  },

  // NewCreditCardModalContent

  newPaymentMethodContainer: {
    padding: 8
  },
  newPaymentMethodForm: {
    marginTop: 20
  },
  newPaymentMethodFormInput: {
    marginBottom: 14,
    flexDirection: 'column'
  },
  newPaymentMethodFormRow: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  newPaymentMethodFormName: {
    flex: 1
  },
  newPaymentMethodFormCardNumber: {
    flex: 3,
    marginRight: 10
  },
  newPaymentMethodFormCardExpiresAt: {
    flex: 2
  },
  newPaymentMethodFormCardCode: {
    flex: 3,
    marginRight: 10
  },
  newPaymentMethodFormRegisterCard: {
    flex: 2,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  newPaymentMethodFormRegisterCardLabel: {
    marginRight: 10,
    lineHeight: 25
  },
  newPaymentMethodSubmitButton: {
    marginTop: 10,
    marginBottom: 20,
    width: '80%'
  },
  newPaymentMethodChangeButton: {
    marginBottom: 20
  },
  newPaymentMethodCardsContainer: {
    alignItems: 'center',
    marginTop: 10
  },
  newPaymentMethodCards: {
    width: '60%',
    height: 30,
    resizeMode: 'contain'
  },
  newPaymentMethodAdyen: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginTop: 6
  },
  newPaymentMethodAdyenLogo: {
    width: 40,
    height: 18,
    marginLeft: 2,
    marginBottom: -3,
    resizeMode: 'contain'
  },

  // ProcessingModalContent

  processingContainer: {
    alignItems: 'center'
  },
  processingLoader: {
    marginTop: 50
  },
  processingLabel: {
    marginTop: 20,
    marginBottom: 40
  },

  // SuccessModalContent

  successContainer: {
    alignItems: 'center'
  },
  successIcon: {
    marginTop: 50,
    color: Colors.orange100
  },
  successLabel: {
    marginTop: 20,
    marginBottom: 30
  },
  successCloseButton: {
    marginBottom: 20
  }

})