import React, { type Node } from 'react'
import { Image, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import useScreenSizes from 'src/utils/useScreenSizes'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import s from './CoverCollectModal.style'

/**
 * DepositQuestionModalContent component
 */
export default function DepositQuestionModalContent ({
  style,
  styles,
  onClose,
  nextStep,
  type,
  onCancel
}: PropsTypes): Node {
  /*
   * Init vars
   */
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const types = {
    KERN: 'KERN_SLAVE_LOCKER',
    LOCAL: 'LOCAL',
    SPARTIME: 'SPARTIME'
  }

  /*
   * Load image depending on type
   */
  let imageURL = null
  switch (type) {
    case types.LOCAL:
      imageURL = require('assets/img/screens/private/shoppingTunnel/CoverDepositModal/eqqi_cover_deposit_reception.png')
      break
    case types.KERN:
      imageURL = require('assets/img/screens/private/shoppingTunnel/CoverDepositModal/eqqi_cover_deposit_locker.png')
      break
    case types.SPARTIME:
      imageURL = require('assets/img/screens/private/shoppingTunnel/CoverDepositModal/eqqi_cover_deposit_closet.png')
      break
  }

  /*
   * Render component
   */
  return (
    <View style={[style, styles?.container]}>
      <Label size={isMobile ? 10 : 12}>
        {t('CoverDepositModal.DepositQuestionModalContent.description.' + type.toLowerCase())}
      </Label>
      <Image
        style={s.contentImage}
        resizeMode={'contain'}
        source={imageURL}
      />
      <View style={s.buttonsContainer}>
        <Button onPress={nextStep} palette={'primary'} size={isMobile ? 'S' : 'M'} fullWidth>
          {t('CoverDepositModal.DepositQuestionModalContent.depositNowBtn')}
        </Button>
        <Button onPress={onClose} style={s.middleButton} size={isMobile ? 'S' : 'M'} fullWidth>
          {t('CoverDepositModal.DepositQuestionModalContent.depositLaterBtn')}
        </Button>
        <Button onPress={onCancel} style={s.lastButton} size={isMobile ? 'S' : 'M'} fullWidth>
          {t('CoverDepositModal.DepositQuestionModalContent.cancelDeposit')}
        </Button>
      </View>
    </View>
  )
}

type PropsTypes = {
  style?: any,
  styles?: any,
  nextStep: function,
  onClose: function,
  type: string,
  onCancel: function
}