import { StoreLocalStorage } from './StoreLocalStorage'
import { createPullstateCore } from 'pullstate'
import UserStore from './UserStore'
import GlobalRefetchStore from './GlobalRefetchStore'

// Create Pullstate Store using all differents Store.
export const PullstateCore = createPullstateCore({
  UserStore,
  GlobalRefetchStore
})

// Hydrate and save store using AsyncStorage
export const StoreLocalStorageInstance = new StoreLocalStorage()
StoreLocalStorageInstance.addStore({ key: 'user', store: UserStore })
StoreLocalStorageInstance.addStore({ key: 'global_refetch', store: GlobalRefetchStore })
export const AsyncStorageLoader = StoreLocalStorageInstance.initiateLocalStorageValues()
