// @flow
import React, { type Node, useCallback } from 'react'
import { View } from 'react-native'
import useIntercom from 'src/utils/useIntercom'
import { useQuery } from 'relay-hooks'
import { useTranslation } from 'react-i18next'
import useScreenSizes from 'src/utils/useScreenSizes'
import ViewShadow from 'src/components/common/atoms/ViewShadow'
import Icon from 'src/components/common/atoms/Icon'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import { Sizes } from 'src/styles'
import s from './IntercomButton.style'

/**
 * IntercomButton screen.
 */
export default function IntercomButton ({
  fullWidth,
  children,
  style
}: PropsTypes): Node {

  // Init
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()

  // API call to get connected user information
  const { data } = useQuery(graphql`
    query IntercomButtonQuery {
      user {
        id
        rowId
        intercomUserIdentity
        email
        firstName
        lastName
      }
    }
  `)
  const user = data?.user

  // Send to intercom the user identity
  const intercom = useIntercom(user)

  // Display Intercom interface
  const displayIntercomInterface = useCallback(() => {
    intercom.start()
  })

  // Render mobile.
  return isMobile ? (
      <ViewShadow
        style={style ? style : s.menuBarButtonRight}
        shadowOffset={{ width: -2, height: 0 }}
        shadowOpacity={0.15}
        withColor={{ color: '#000', width: fullWidth / 5 - 8, height: 40, radius: 16 }}
        onPress={displayIntercomInterface}
      >
        {children ? children :
          <Icon name={'chat'} size={Sizes.XM} style={s.menuBarIcon}/>
        }
      </ViewShadow>
    ) :
    // Render desktop
    (
      <Button style={style ? style : s.buttonNotMobile} onPress={displayIntercomInterface}>
        {children ? children :
          <>
            <Icon name={'chat'} size={Sizes.XM} style={[s.menuBarIcon, s.menuBarIconNotMobile]}/>
            <Label style={s.labelNotMobile}>{t('IntercomButton.label')}</Label>
          </>
        }
      </Button>
    )

}

// PropsTypes
type PropsTypes = {
  fullWidth?: number,
  children?: Node
}

