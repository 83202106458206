// @ flow
import React, { type Node, useState } from 'react'
import { View } from 'react-native'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'relay-hooks'
import { formatPrice } from 'src/utils/FormatDataUtils'
import useScreenSizes from 'src/utils/useScreenSizes'
import Icon from 'src/components/common/atoms/Icon'
import Label from 'src/components/common/atoms/Label'
import Loader from 'src/components/common/atoms/Loader'
import Button from 'src/components/common/atoms/Button'
import Modal from 'src/components/common/atoms/Modal'
import s, {modalLockerConfigStyle} from './SelectLockerModal.style'
import { Colors } from 'src/styles'

/**
 * SelectLockerModal
 */
export default function SelectLockerModal ({
  onClose
}): Node {

  // Init state
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [servicesWithConfig, setServicesWithConfig] = useState([])
  const [selectedLocker, setSelectedLocker] = useState()

  // Retrieve available lockers
  const { data } = useQuery(graphql`
    query SelectLockerModalQuery {
      basketAvailableLockers {
        id
        rowId
        name
      }
    }
  `)
  const availableLockers = data?.basketAvailableLockers

  // Select locker for basket
  const [setLockerForBasket, { loading }] = useMutation(
    graphql`
      mutation SelectLockerModalMutation($lockerId: Int!) {
        SetLockerOfBasket(lockerId: $lockerId) {
          id
          rowId
          amount
          deliverySettings {
            collectDate
            deliveryDate
            price
            collectLocker {
              name
            }
            deliveryLocker {
              name
            }
          }
          services {
            rowId
            name
            minAmountPerOrder
            lockerMinAmountPerOrder
          }
        }
      }
    `,
    {
      onCompleted: ({ SetLockerOfBasket : basket }) => {

        // Set the selected locker name
        setSelectedLocker(basket.deliverySettings.collectLocker.name)

        // Update services config list with updated basket
        basket.services.forEach(service => {
          if (service.lockerMinAmountPerOrder && service.lockerMinAmountPerOrder > basket.amount) {
            setServicesWithConfig([...servicesWithConfig, service])
          }
        })

        // If there is no services with config, this meen we have all the required data to submit the basket
        if (servicesWithConfig.length === 0) {
          setSuccess(true)
        }

      },
      onError: () => setError(true)
    })

  // Reset state on close
  const handleClose = () => {
    onClose()
    setSuccess()
    setError()
    setServicesWithConfig([])
    setSelectedLocker()
  }

  // Affichage du message des configurations de minimum de commande
  if (servicesWithConfig.length > 0) {
    return (
      <Modal onClose={handleClose} open={open}>

        {/* Minimum amount needed by services */}
        <Label style={modalLockerConfigStyle.title} size={!isMobile ? 16 : 14}>{t('BasketScreen.delivery.chooseLockerLabel')}</Label>
        {servicesWithConfig.map(service => (
          <Label key={service.rowId} size={isMobile ? 10 : 12} style={modalLockerConfigStyle.configInfo}>
            <Trans
              i18nKey={'SelectLockerModal.lockerConfig'}
              components={{ b: <Label type={'bold'} size={isMobile ? 10 : 12}/> }}
              values={{ amount: formatPrice(service.lockerMinAmountPerOrder), serviceName: service.name }}
            />
          </Label>
        ))}

        {/* Minimum amount needed for locker */}
        <Label size={isMobile ? 10 : 12} style={modalLockerConfigStyle.configDetail}>
          <Trans
            i18nKey={'SelectLockerModal.addProduct'}
            components={{ b: <Label type={'bold'} size={isMobile ? 10 : 12}/> }}
            values={{ lockerName: selectedLocker }}
          />
        </Label>

        {/* Confirm */}
        <View style={modalLockerConfigStyle.buttonContainer}>
          <Button
            size={'M'}
            fullWidth
            palette={'primary'}
            onPress={() => setServicesWithConfig([])}
          >
            {t('SelectLockerModal.understood')}
          </Button>
        </View>

      </Modal>
    )
  }

  return (
    <View>
      <Label style={s.title} size={!isMobile ? 16 : 14}>{t('BasketScreen.delivery.chooseLockerLabel')}</Label>

      {!availableLockers || loading ? (
        <Loader style={s.loader}/>
      ) : success ? (
        <Icon color={Colors.orange100} name={'check'} style={s.icon}/>
      ) : error ? (
        <Icon color={Colors.orange100} name={'close'} style={s.icon}/>
      ) : (
        <View style={s.buttonContainer}>
          {availableLockers.map((locker, index) => (
            <Button
              fullWidth
              size={'M'}
              key={locker.id}
              onPress={() => setLockerForBasket({ variables: { lockerId: locker.rowId } })}
              style={{ marginBottom: index === (availableLockers.length - 1) ? 36 : 10 }}
            >
              {locker.name}
            </Button>
          ))}
        </View>
      )}

    </View>
  )
}