import { useCallback, useEffect, useRef, useState } from 'react'
import { useFocusEffect as useFocusEffectReactNavigation } from '@react-navigation/native'

/**
 * Hook to keep previous state or props in order to compare later.
 *
 * @url https://stackoverflow.com/questions/53446020/how-to-compare-oldvalues-and-newvalues-on-react-hooks-useeffect
 * @param value any type, data to keep trace
 * @returns {*}
 */
export function usePrevious(value) {
    const ref = useRef()
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}

/**
 * Hook to trace update inside a component in order to debug activity.
 *
 * @url https://usehooks.com/useWhyDidYouUpdate/
 * @param name
 * @param props
 */
export function useWhyDidYouUpdate(name, props) {
    // Get a mutable ref object where we can store props ...
    // ... for comparison next time this hook runs.
    const previousProps = useRef()

    useEffect(() => {
        if (previousProps.current) {
            // Get all keys from previous and current props
            const allKeys = Object.keys({ ...previousProps.current, ...props })
            // Use this object to keep track of changed props
            const changesObj = {}
            // Iterate through keys
            allKeys.forEach(key => {
                // If previous is different from current
                if (previousProps.current[key] !== props[key]) {
                    // Add to changesObj
                    changesObj[key] = {
                        from: previousProps.current[key],
                        to: props[key]
                    }
                }
            })

            // If changesObj not empty then output to console
            if (Object.keys(changesObj).length) {
                console.log('[why-did-you-update]', name, changesObj)
            }
        }

        // Finally update previousProps with current props for next hook call
        previousProps.current = props
    })
}

/**
 * Is component still mounted or not ?
 *
 * @url https://wattenberger.com/blog/react-hooks
 * @returns Boolean
 */
export function useIsMounted() {
    const isMounted = useRef(false)
    useEffect(() => {
        isMounted.current = true
        return () => isMounted.current = false
    }, [])
    return isMounted
}

/**
 * Is component in view of the user or not ?
 *
 * @url https://wattenberger.com/blog/react-hooks
 * @param margin
 * @returns {[React.MutableRefObject<undefined>, *]}
 */
export function useIsInView(margin="0px") {
    const [isIntersecting, setIntersecting] = useState(false)
    const ref = useRef()
    useEffect(() => {
        const observer = new IntersectionObserver(([ entry ]) => {
            setIntersecting(entry.isIntersecting)
        }, { rootMargin: margin })
        if (ref.current) observer.observe(ref.current)
        const refCurrent = ref.current
        return () => {
            observer.unobserve(refCurrent)
        }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [])
    return [ref, isIntersecting]
}

/**
 * Is this the initial render for this component ?
 *
 * @return boolean
 */
export function useInitialRender(): boolean {
    const [isInitialRender, setIsInitialRender] = useState(false)
    if (!isInitialRender) {
        setTimeout(() => setIsInitialRender(true), 1)
        return true
    }
    return false
}

/**
 * Better useFocusEffect from react-navigation.
 */
export function useFocusScreenEffect(callback) {

    // Keep focus state to know if screen has been focus or not
    const [focus, setFocus] = useState(false)

    // Memoized callback
    const callbackMemoized = useCallback(() => {

        // Update focus to true because we enter screen
        setFocus(true)

        // Only call callback on focus screen (and not on every render)
        focus && callback()

        // Reset focus to false when leaving screen
        return () => setFocus(false)

    }, [focus])

    // Call useFocusEffect from react-navigation
    useFocusEffectReactNavigation(callbackMemoized)

}
