import { Store } from 'pullstate'

// UserStore
export default new Store({

  // Authentication token
  jwtToken: null,

  // Authentication refresh token
  refreshJwtToken: null,

  // His current user have a qorner set ?
  currentUserHaveQorner: false,

  // Current user email
  currentUserEmail: null,

  // Current user fullname
  currentUserFullname: null,

  // Current qorner id
  currentQornerID: null,
});
