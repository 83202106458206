import React, {type Node, useEffect, useState} from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'relay-hooks'
import Label from 'src/components/common/atoms/Label'
import Loader from 'src/components/common/atoms/Loader'
import Button from 'src/components/common/atoms/Button'
import Icon from 'src/components/common/atoms/Icon'
import { Colors } from 'src/styles'
import s from './CancelOrderModal.style'
import useScreenSizes from 'src/utils/useScreenSizes'
import GlobalRefetchStore from 'src/store/GlobalRefetchStore'

/**
 * CancelOrderModal component
 */
export default function CancelOrderModal ({
  onClose,
  setOnCloseParam,
  orderId
}: PropsTypes): Node {
  /*
   * Init vars
   */
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const steps = {
    CONFIRM_CANCEL: 10,
    LOADING: 20,
    SUCCESS: 30
  }
  const [step, setStep] = useState(steps.CONFIRM_CANCEL)
  const [error, setError] = useState(false)

  /*
   * API Mutation
   */
  const [cancelOrderMutation] = useMutation(graphql`
    mutation CancelOrderModalMutation ($orderId: Int!) {
      CancelOrder(orderId: $orderId) {
        id
        status
        primeCustomerAction
      }
    }
  `, {
    onCompleted: () => {
      setStep(steps.SUCCESS)
      setOnCloseParam(true)
      GlobalRefetchStore.update(s => {
        s.bestServicesKey = new Date()
      })
    },
    onError: () => {
      setStep(steps.CONFIRM_CANCEL)
      setError(true)
    }
  })

  /*
   * Handle Cancel Order
   */
  const handleCancelOrder = () => {
    setStep(steps.LOADING)
    setError(false)
    cancelOrderMutation({variables: { orderId }})
  }

  /*
   * Render component
   */
  return (
    <View style={s.container}>

      {/* Modal title */}
      <Label style={s.title}>{t('CancelOrderModal.title', { orderId })}</Label>

      {error && <Label type={'error'}>{t('Yup.global.errorTryAgain')}</Label>}

      {/* Modal content */}
      <View style={s.contentContainer}>
        {{
          [steps.CONFIRM_CANCEL]: (
            <>
              <Button
                size={isMobile ? 'S' : 'M'}
                palette={'primary'}
                style={s.btn}
                onPress={handleCancelOrder}
              >
                {t('CancelOrderModal.cancel')}
              </Button>
              <Button size={isMobile ? 'S' : 'M'} style={s.btn} onPress={onClose}>{t('App.cancel')}</Button>
            </>
          ),
          [steps.LOADING]: (
            <Loader />
          ),
          [steps.SUCCESS]: (
            <Icon name={'check'} color={Colors.orange100} />
          )
        }[step]}
      </View>

    </View>
  )
}

type PropsTypes = {
  orderId: number
}