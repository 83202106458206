import { StyleSheet } from 'react-native'
import { Colors, Palette } from 'src/styles'

// Styles
export default StyleSheet.create({
  // Main Modal
  modalTitle: {
    color: Colors.darkGrey100,
    marginBottom: 30
  },
  modalTitleDesktop: {
    marginBottom: 40
  },
  generalError: {
    textAlign: 'center',
    marginBottom: 10
  },
  dataContainer: {
    flexDirection: 'row'
  },
  avatar: {
    marginLeft: 10,
    marginRight: 10,
  },
  updateAvatar: {
    width: 20,
    height: 20,
    borderRadius: 20,
    paddingLeft: 0,
    paddingRight: 0,
    position: 'absolute',
    bottom: 0,
    borderColor: Colors.orange100,
    backgroundColor: Colors.orange100
  },
  modalUpdateAvatar: {
    bottom: -7
  },
  modalUpdateAvatarDesktop: {
    paddingTop: 0,
    width: 42,
    height: 42,
    borderRadius: 42,
    bottom: -20
  },
  textContainer: {
    flex: 1
  },
  textContainerDesktop: {
    flex: 1,
    marginLeft: 30
  },
  personalInfoRow: {
    flexDirection: 'row',
    marginBottom: 10,
    flexWrap: 'wrap'
  },
  personalInfoData: {
    paddingLeft: 6,
    color: Palette.dpActiveInputText,
  },
  textInput: {
    width: '100%'
  },
  inputContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  inputContainerDesktop: {
    marginLeft: 14
  },
  selectInputContainer: {
    width: '100%',
    height: 20,
    borderWidth: 1,
    borderColor: Palette.dpDefaultInputBorder,
    marginBottom: 10,
  },
  selectInputContainerDesktop: {
    height: 30,
    marginBottom: 20
  },
  selectInputPicker: {
    width: '100%',
    paddingLeft: 8,
    fontSize: 10
  },
  selectInputIcon: {
    top: 2
  },
  submitButton: {
    marginTop: 10,
    width: 145
  },
  submitButtonDesktop: {
    marginTop: 20,
    width: '40%'
  },
  cancelButton: {
    marginTop: 10,
    marginBottom: 4,
    width: 145
  },
  cancelButtonDesktop: {
    marginTop: 20,
    width: '40%'
  },
  formLabel: {
    minWidth: 60
  },
  formLabelDesktop: {
    minWidth: 82
  },
  formRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  formRowDesktop: {
    marginBottom: 20,
  },

  // Success Modal styles
  successContainer: {
    alignItems: 'center'
  },
  successIcon: {
    marginTop: 20,
    color: Colors.orange100
  },
  successLabel: {
    marginTop: 20,
    marginBottom: 30
  },
  successCloseButton: {
    marginBottom: 20
  }
})