/**
 * @generated SignedSource<<660738a98cc61ba076274f38ad3951e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "firstName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lang"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "lastName"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "phoneNumber"
},
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "firstName",
        "variableName": "firstName"
      },
      {
        "kind": "Variable",
        "name": "lang",
        "variableName": "lang"
      },
      {
        "kind": "Variable",
        "name": "lastName",
        "variableName": "lastName"
      },
      {
        "kind": "Variable",
        "name": "phoneNumber",
        "variableName": "phoneNumber"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "UpdateUserInfos",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lastName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phoneNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lang",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "NeedCoverModalMutation",
    "selections": (v4/*: any*/),
    "type": "ClientAppMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "NeedCoverModalMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "e401d2b7ef0afab04ea285e14f06ff32",
    "id": null,
    "metadata": {},
    "name": "NeedCoverModalMutation",
    "operationKind": "mutation",
    "text": "mutation NeedCoverModalMutation(\n  $firstName: String!\n  $lastName: String!\n  $phoneNumber: String!\n  $lang: UserLang!\n) {\n  UpdateUserInfos(firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber, lang: $lang) {\n    id\n    firstName\n    lastName\n    phoneNumber\n    lang\n  }\n}\n"
  }
};
})();

node.hash = "51cbd125e755b67dabfe25f51e3eff35";

module.exports = node;
