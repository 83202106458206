import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

export default StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center'
  },
  skeletonContainer: {
    alignItems: 'center',
    width: '100%',
    flex: 1
  },
  mainList: {
    width: '100%',
    paddingBottom: 100
  },
  mainListDesktop: {
    marginTop: 20
  },
  nestedListHeaderContainer: {
    height: 60,
    backgroundColor: Colors.red100Less80,
    marginTop: 10,
    marginBottom: 20,
    paddingHorizontal: 14,
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative'
  },
  nestedListHeaderContainerDesktop: {
    height: 120,
    paddingHorizontal: 30,
    marginBottom: 40,
    marginTop: 0
  },
  nestedListHeaderIcon: {
    color: Colors.red100,
    marginRight: 14
  },
  nestedListHeaderIconDesktop: {
    marginRight: 30
  },
  nestedListHeaderIconBackground: {
    position: 'absolute',
    color: Colors.white100Less50,
    right: -4,
    transform: [{rotate: '-19deg'}]
  },
  listRowContainer: {
    flex: 1,
    flexDirection: 'row',
    marginLeft: 20,
    marginRight: 40
  }
})
