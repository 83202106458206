// @flow
import React, { type Node, useEffect, useState } from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import useScreenSizes from 'src/utils/useScreenSizes'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import Icon from 'src/components/common/atoms/Icon'
import ViewShadow from 'src/components/common/atoms/ViewShadow'
import BasketButton from 'src/components/common/moles/BasketButton'
import MenuBar from 'src/components/common/layout/MenuBar'
import { Sizes } from 'src/styles'
import s from './PrivateLayout.style'
import { Formik } from 'formik'
import TextInputFormik from 'src/components/common/forms/TextInputFormik'
import IntercomButton from 'src/components/common/atoms/IntercomButton/IntercomButton'
import { useQuery } from 'relay-hooks'
import Avatar from 'src/components/common/atoms/Avatar'

/**
 * PrivateLayout layout.
 */
export default function PrivateLayout ({
  children = null,
  header = true,
  headerTitleSize = 18,
  headerBackOnPress,
  headerBackTitle,
  subHeader = false,
  subHeaderBackOnPress,
  subHeaderIconName = null,
  subHeaderTitle,
  headerCenter = null,
  headerTitle = '',
  headerRight = null,
  safeAreaView = true,
  avatar = null,
  subHeaderContent = null,
  searchValue = '',
  searchInputRef,
}: PropsTypes): Node {

  // Navigation info
  const navigation = useNavigation()
  const { t } = useTranslation()
  const { width: fullWidth, isMobile } = useScreenSizes()
  const [search, setSearch] = useState(searchValue)
  const navigationState = navigation.getState()
  const previousScreenName = navigationState.history && navigationState.history[navigationState.history.length -2] ? navigationState.history[navigationState.history.length -2].key.split('-')[0] : 'Home'
  const defaultHeaderBackTitle = t('Navigation.naming.' + previousScreenName)

  // API Call
  const { data } = useQuery(graphql`
    query PrivateLayoutQuery {
      user {
        id
        firstName
        pictureURL
      }
    }
  `)
  const user = data?.user
  
  // Update search form when searchValue prop change
  useEffect(() => {
    setSearch(searchValue)
  }, [searchValue])

  // Do we need to wrap this screen content with an SafeAreaView ? (default = true)
  const RootView = safeAreaView ? SafeAreaView : View

  // Header center component
  const HeaderCenter = headerCenter ? headerCenter : (
    <View style={[s.headerCenter, !(navigation.canGoBack() || headerBackOnPress) && { paddingLeft: 14 }]}>

      {/* Back button */}
      {(navigation.canGoBack() || headerBackOnPress) &&
      <View style={s.backButtonContainer}>
        <Button
          Icon={<Icon name={'back'} size={Sizes.XM} style={s.backButtonIcon}/>}
          style={s.backButton}
          onPress={headerBackOnPress ? headerBackOnPress : () => navigation.goBack()}
        />
      </View>}

      {/* Title content */}
      <View style={s.headerTitleContainer}>
        {typeof headerTitle === 'string' ?
          <Label style={s.headerTitle} size={headerTitleSize}>{headerTitle}</Label>
          : headerTitle
        }
      </View>

    </View>
  )

  // Header center component
  const HeaderRight = headerRight ? headerRight : (
    <View style={[s.headerRight, { alignItems: 'center' }]}>
      <BasketButton navigation={navigation} />
    </View>
  )

  // Render layout
  return (
    <RootView style={s.privateLayout}>

      {/* Header mobile */}
      {header && isMobile && (
        <View style={s.header}>

          {/* Header center component */}
          {HeaderCenter}

          {/* Header right component */}
          {HeaderRight}

        </View>
      )}

      {/* Header desktop */}
      {header && !isMobile && (
        <View style={s.headerNotMobile}>

          {/* Home button */}
          <Button
            style={s.homeButton}
            onPress={() => navigation.navigate({ name: 'Private', params: { screen: 'Home' }})}
          >
            <Icon name={'home'} style={s.homeIcon} />
          </Button>

          {/* Back button */}
          <View style={[s.backButtonContainer, s.backButtonContainerNotMobile]}>
            <Button
              Icon={<Icon name={'back'} size={Sizes.M} style={s.backButtonIconNotMobile}/>}
              style={s.backButtonNotMobile}
              onPress={() => {

                // Custom header back on press
                if(headerBackOnPress) {
                  headerBackOnPress()
                  return
                }

                // Go back possible
                if(navigation.canGoBack()) {
                  navigation.goBack()
                  return
                }

                // Default return to home
                navigation.navigate({ name: 'Private', params: { screen: 'Home' }})

              }}
            />
          </View>

          {/* Previous screen title */}
          <Label style={s.headerBackTitle}>
            {headerBackTitle ? headerBackTitle : defaultHeaderBackTitle}
          </Label>

          {/* Search form */}
          <Formik
            initialValues={{ search: search }}
            enableReinitialize={true}
            onSubmit={() => {}}
          >
            {(formikProps) => (
              <View
                style={s.searchForm}
              >
                <TextInputFormik
                  {...formikProps}
                  innerRef={searchInputRef}
                  name={'search'}
                  icon={'search'}
                  size={'L'}
                  placeholder={t('HomeScreen.searchPlaceholder')}
                  onChangeText={value => {
                    setSearch(value)
                    if (value.length >= 3) {
                      setSearch('')
                      navigation.navigate('Search', { search: value })
                    }
                  }}
                />
              </View>
            )}
          </Formik>

          {/* Intercom button */}
          <IntercomButton />

          {/* Profil button */}
          <Button
            style={s.profileButton}
            onPress={() => navigation.navigate({ name: 'Private', params: { screen: 'Profile' } })}
          >
            {user?.pictureURL && (
              <Avatar source={{ uri : user.pictureURL }} style={s.profileAvatar} size={Sizes.L} isActive={true} />
            )}
            <Label style={s.profileLabel}>{user?.firstName ? user.firstName : t('MenuDrawerContent.title.profile')}</Label>
          </Button>

          {/* Basket button */}
          <View style={{marginLeft: 15, marginRight: 30}}>
            <BasketButton navigation={navigation} small={false} />
          </View>

        </View>
      )}

      {/* Subheader */}
      {subHeader && isMobile && (
        <ViewShadow
          style={s.subHeader}
          shadowOffset={{ width: 0, height: 6 }}
          shadowOpacity={0.2}
          withColor={{ color: '#000', width: fullWidth, height: 50, radius: 0 }}
        >

          {/* Back button */}
          <Button
            Icon={<Icon name={'back'} size={Sizes.XM} style={s.subHeaderBackButtonIcon}/>}
            style={s.subHeaderBackButton}
            onPress={subHeaderBackOnPress}
          />

          {/* Sub header title */}
          {subHeaderContent ? subHeaderContent : (
            <View style={s.subHeaderTitleContainer}>
              {avatar ? avatar : subHeaderIconName && <Icon style={s.subHeaderIcon} name={subHeaderIconName} size={Sizes.XM}/>}
              <Label style={s.subHeaderTitle} skeleton={{width: 140, height: 16}}>{subHeaderTitle}</Label>
            </View>
          )}

        </ViewShadow>
      )}

      {/* Main content */}
      <View style={s.mainContent}>
        {children}
      </View>

      {/* Menu bar */}
      <MenuBar/>

    </RootView>
  )
}

// PropsTypes
type PropsTypes = {
  children?: any,
  header?: boolean,
  headerTitleSize?: number,
  headerBackOnPress?: function,
  headerBackTitle?: string,
  subHeader?: boolean,
  subHeaderBackOnPress?: function,
  subHeaderIconName?: 'cashback' | 'wallet' | 'home' | 'search' | 'menu' | 'basket' | 'check' | 'edit' | 'calendar' | 'back' |
    'next' | 'plus' | 'minus' | 'sales' | 'favorite' | 'favorite-filled' | 'caret' | 'profile-rounded' | 'chat' |
    'locker' | 'news' | 'infos' | 'bank-cards' | 'email' | 'phone' | 'lock' | 'profile' | 'estimate' | 'order' |
    'notify' | 'close' | 'qorner-company' | 'qorner-public',
  subHeaderTitle?: string,
  headerCenter?: any,
  headerTitle?: any,
  headerRight?: any,
  safeAreaView?: boolean,
  avatar?: any,
  subHeaderContent?: any,
  searchValue?: string,
  searchInputRef?: any
}