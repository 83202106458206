import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  modalTitle: {
    marginBottom: 10,
    alignItems: 'flex-start'
  },
  processingContainer: {
    height: 120,
    alignItems: 'center',
    justifyContent: 'center'
  },
  contentImage: {
    width: '100%',
    height: 200,
    marginTop: 14
  },
  contentImageDesktop: {
    height: 330
  },
  subLabel: {
    marginTop: 10
  },
  buttonsContainer: {
    alignItems: 'center',
    marginHorizontal: '10%',
    marginTop: 14
  },
  middleButton: {
    marginTop: 10
  },
  lastButton: {
    marginVertical: 10
  },
  validationButton: {
    marginBottom: 10
  },
  successCloseButton: {
    marginBottom: 6
  },
  secretCodeContainer: {
    flexDirection: 'row',
    marginTop: 10
  },
  secretCodeSeparator: {
    width: 16
  }
})

export const modalSkeletonLayout = [
  {width: '100%', height: 20, marginBottom: 10, marginTop: 10},
  {width: '100%', height: 40, marginBottom: 20},
  {width: '60%', height: 150, marginBottom: 20},
  {width: '80%', height: 20, marginBottom: 10},
  {width: '80%', height: 20, marginBottom: 10},
  {width: '80%', height: 20, marginBottom: 10}
]

