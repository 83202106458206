import { StyleSheet, Platform } from 'react-native'
import { Colors, Sizes } from 'src/styles'

// Styles
export default StyleSheet.create({
  drawerContent: {
    paddingTop: Platform.OS === 'web' ? 20 : 50,
    paddingRight: 10,
    paddingBottom: 20,
    paddingLeft: 10,
    alignItems: 'center'
  },
  companyLogo: {
    height: 40,
    width: '100%',
    marginTop: 0,
    marginBottom: 20,
    resizeMode: 'contain'
  },
  companyLogoDesktop: {
    height: 60,
    marginBottom: 40,
  },
  companyName: {
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 20,
    color: Colors.white100
  },
  profileContainer: {
    maxWidth: 200,
    margin: 'auto',
    marginBottom: 10,
    flexDirection: 'row',
  },
  profileContainerDesktop: {
    marginBottom: 30
  },
  avatar: {
    borderWidth: 2,
    borderColor: Colors.white100,
    marginRight: 5
  },
  avatarDesktop: {
    marginRight: 17
  },
  moneyContainer: {
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  moneyRow: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  moneyIcon: {
    paddingRight: 5,
    color: Colors.white100
  },
  moneyLabel: {
    fontWeight: 'bold',
    color: Colors.white100
  },
  navigationLinkContainer: {
    width: 250,
    margin: 'auto'
  },
  navigationLinkContainerTablet: {
    width: 180, // drawer width - padding
  },
  buttonContainerMobile: {
    marginTop: 20
  },
  buttonContainerTablet: {
    marginTop: 40,
    paddingLeft: 20,
    paddingRight: 20
  },
  buttonContainerDesktop: {
    marginTop: 40,
    paddingLeft: 40,
    paddingRight: 40
  },
  changeQornerIconMobile: {
    width: Sizes.S,
    height: Sizes.S
  },
  changeQornerIconDesktop: {
    width: Sizes.M,
    height: Sizes.M
  },
  changeQornerIconTablet: {
    width: 14,
    height: 14
  },
  logoutButton: {
    marginBottom: 20,
    marginTop: 20
  },
  brand: {
    textAlign: 'center',
    color: Colors.white100
  },
})