/**
 * @generated SignedSource<<a01eb560008e6267eadd1a66f900cb14>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      }
    ],
    "kind": "ScalarField",
    "name": "RequestResetToken",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ForgotPasswordScreenMutation",
    "selections": (v1/*: any*/),
    "type": "ClientAppMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ForgotPasswordScreenMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "fc6af10b78883d71d94b649f7c7d7fff",
    "id": null,
    "metadata": {},
    "name": "ForgotPasswordScreenMutation",
    "operationKind": "mutation",
    "text": "mutation ForgotPasswordScreenMutation(\n  $email: String!\n) {\n  RequestResetToken(email: $email)\n}\n"
  }
};
})();

node.hash = "3d9aad59a7a65091b5cb4d0c8987e882";

module.exports = node;
