/**
 * @generated SignedSource<<236626e1c80425b43aea8329a51c6223>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "informationId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "informationId",
        "variableName": "informationId"
      }
    ],
    "concreteType": "Information",
    "kind": "LinkedField",
    "name": "information",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "content",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "buttonLabel",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "buttonDestinationUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mainPictureUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PracticalInformationsModalQuery",
    "selections": (v1/*: any*/),
    "type": "ClientAppQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PracticalInformationsModalQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e390618a502517250f03656c708324b8",
    "id": null,
    "metadata": {},
    "name": "PracticalInformationsModalQuery",
    "operationKind": "query",
    "text": "query PracticalInformationsModalQuery(\n  $informationId: Int!\n) {\n  information(informationId: $informationId) {\n    id\n    title\n    content\n    buttonLabel\n    buttonDestinationUrl\n    mainPictureUrl\n  }\n}\n"
  }
};
})();

node.hash = "306e61c63df5e79ac207956e0d293de7";

module.exports = node;
