// @flow
import React, { type Node } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'relay-hooks'
import { formatPrice } from 'src/utils/FormatDataUtils'
import useScreenSizes from 'src/utils/useScreenSizes'
import Button from 'src/components/common/atoms/Button'
import Label from 'src/components/common/atoms/Label'
import Loader from 'src/components/common/atoms/Loader'
import ViewShadow from 'src/components/common/atoms/ViewShadow'
import s from './PaymentCashbackSection.style'

export default function PaymentCashbackSection ({
  cashbackAmount,
  useCashback,
  payableType,
  payableId = null,
  usableCashback
}: PropsTypes): Node {

  // Init values
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()

  // Toggle cashback mutation
  const [toggleUseCashbackMutation, { loading }] = useMutation(graphql`
      mutation PaymentCashbackSectionMutation(
        $type: PayableType!,
        $payableId: Int
      ) {
        ToggleUseCashback(
          type: $type,
          payableId: $payableId
        ) {
          ... on Order {
            id
            useCashback
            amount
          }
          ... on Basket {
            id
            useCashback
            amount
            amountAfterReduction
            discountByCashback
            cashbackEarnedAmount
          }
        }
      }
  `)

  const handleToggleUseCashback = () => {
    toggleUseCashbackMutation({
      variables: {
        type: payableType,
        payableId: payableId
      }
    })
  }

  // Render component
  return (
    <ViewShadow
      shadowOffset={{ width: 0, height: 0 }}
      shadowRadius={8}
      style={[s.cashbackContainer, !isMobile && s.cashbackContainerDesktop]}
    >
      <View style={s.cashbackHeader}>
        <Label size={isMobile ? 14 : 20}>{t('BasketScreen.cashback.title')}</Label>
        <Label size={isMobile ? 10 : 14}>
          {t('BasketScreen.cashback.available', { amount: formatPrice(cashbackAmount) })}
        </Label>
      </View>

      {loading ? <Loader /> :
        <View style={[s.cashbackAction, !isMobile && s.cashbackActionDesktop]}>
          <Label size={isMobile ? 10 : 16}>
            {useCashback ?
              t('BasketScreen.cashback.deducing', { amount: formatPrice(usableCashback) })
              :
              t('BasketScreen.cashback.deduce', { amount: formatPrice(cashbackAmount) })
            }
          </Label>
          <Button size={isMobile ? 'XS' : 'S'} onPress={handleToggleUseCashback}>
            {t(useCashback ? 'BasketScreen.cashback.dontUse' : 'BasketScreen.cashback.yes')}
          </Button>
        </View>
      }
    </ViewShadow>
  )
}

type PropsTypes = {
  cashbackAmount: number,
  useCashback: boolean,
  payableType: string,
  payableId?: number,
  usableCashback: number
}