import { Platform, StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

export default StyleSheet.create({
  container: {
    marginLeft: 15,
    marginRight: 15,
    height: '100%',
    flex: 1,
    flexDirection: 'column',
    ...Platform.select({ web: { overflow: 'auto' }}),
    marginTop: 10
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  dateLabel: {
    marginVertical: 20
  },
  dateLabelDesktop: {
    marginTop: 20,
    marginBottom: 40
  },

  desktopHeaderContainer: {
    height: 120,
    paddingLeft: 30,
    backgroundColor: Colors.orange100Less80,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 40
  },
  desktopBackgroundIcon: {
    position: 'absolute',
    color: Colors.white100Less50,
    right: 15,
    transform: [{ rotate: '-29deg' }]
  },
  desktopTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  desktopTitleIcon: {
    color: Colors.orange100,
    marginRight: 30
  },
  desktopListContainer: {
    marginLeft: 40,
    marginRight: 30,
    flex: 1,
    ...Platform.select({ web: { overflow: 'auto' }}),
  }
})