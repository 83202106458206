/**
 * @generated SignedSource<<73dffc6830699c9f239000145328f0f9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "RecurringCreditCard",
    "kind": "LinkedField",
    "name": "recurringCreditCards",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cardNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "token",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ChoosePaymentMethodModalContentQuery",
    "selections": (v0/*: any*/),
    "type": "ClientAppQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ChoosePaymentMethodModalContentQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "0fe2bf826ff5fc4b49d0eb53a8e3ce35",
    "id": null,
    "metadata": {},
    "name": "ChoosePaymentMethodModalContentQuery",
    "operationKind": "query",
    "text": "query ChoosePaymentMethodModalContentQuery {\n  recurringCreditCards {\n    cardNumber\n    token\n  }\n}\n"
  }
};
})();

node.hash = "4abc2d00e0efb2fbf068f898aa4a84b7";

module.exports = node;
