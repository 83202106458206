// @flow
import React, { type Node, useEffect, useState } from 'react'
import { View } from 'react-native'
import { useMutation } from 'relay-hooks'
import PayWithWalletModalContent from './PayWithWalletModalContent'
import PayWithNewCardModalContent from './PayWithNewCardModalContent'
import FillWalletModal from 'src/components/common/moles/FillWalletModal'
import PayWithRecurringCardModalContent from './PayWithRecurringCardModalContent'
import PayWithWalletAndCardModalContent from './PayWithWalletAndCardModalContent'
import FreeBasketModalContent from 'src/components/common/moles/PayModal/FreeBasketModalContent'
import GlobalRefetchStore from 'src/store/GlobalRefetchStore'

export default function PayModal ({
  onClose,
  amount,
  user,
  modalStep,
  setModalStep,
  modalSteps,
  selectedCard,
  setPaidBasket,
  payableType,
  payableId = null,
  setAllowClosing
}: PropsTypes): Node {

  // Init mutations
  const [payMutation] = useMutation(
    graphql`
      mutation PayModalBasketMutation(
        $type: PayableType!,
        $paymentMethod: PaymentMethod!,
        $payableId: Int,
        $browserInfo: BrowserInfoInput,
        $creditCard: PaymentCreditCardInput,
        $creditCardEnableRecurring: Boolean,
        $recurringCreditCard: PaymentRecurringCreditCardInput,
        $payment3DS2Action: Payment3DS2ActionInput,
      ) {
        Pay(
          type: $type
          paymentMethod: $paymentMethod
          payableId: $payableId
          browserInfo: $browserInfo
          creditCard: $creditCard
          creditCardEnableRecurring: $creditCardEnableRecurring
          recurringCreditCard: $recurringCreditCard
          payment3DS2Action: $payment3DS2Action
        ) {
          payableItem {
            ... on Basket {
              __typename
              id
              amount
              services {
                rowId
                amount
                name
                logoURL
                serviceType
                providerTitle
                products {
                  id
                  rowId
                  productId
                  productPrice
                  productType
                  isCover
                  name
                  price
                  quantity
                  customerComment
                }
              }
            },
            ... on Order {
              id
              amount
              status
              paymentStatus
              allowedCustomerActions
              primeCustomerAction
              impossibleCancelReason
            }
          }
          resultCode
          user {
            id
            creditsBalance
            cashbackWallet {
              activeAmount
            }
          }
        }
      }`
      , {
        onCompleted: () => {
          GlobalRefetchStore.update(s => {
            s.bestServicesKey = new Date()
          })
        }
    }
  )

  // Define handlePay with given payableType
  const handlePay = ({ variables }) => {
    let finalVars = { ...variables }

    switch (payableType) {
      case 'BASKET':
        finalVars.type = 'BASKET'
        break
      case 'ORDER':
        finalVars.type = 'ORDER'
        finalVars.payableId = payableId
    }

    return payMutation({
      variables: finalVars
    })
  }

  // Render component
  return (
    <View>
      {{
        // pay with a new card
        [modalSteps.NEW_CARD]: (
          <PayWithNewCardModalContent
            amount={amount}
            onClose={onClose}
            setPaidBasket={setPaidBasket}
            setModalStep={setModalStep}
            payableType={payableType}
            payableId={payableId}
            setAllowClosing={setAllowClosing}
          />
        ),

        // pay with wallet
        [modalSteps.WALLET]: (
          <PayWithWalletModalContent
            amount={amount}
            onClose={onClose}
            creditsBalance={user.creditsBalance}
            setModalStep={setModalStep}
            modalSteps={modalSteps}
            setPaidBasket={setPaidBasket}
            handlePay={handlePay}
          />
        ),

        // pay with recurring card
        [modalSteps.RECURRING_CARD]: (
          <PayWithRecurringCardModalContent
            amount={amount}
            onClose={onClose}
            selectedCard={selectedCard}
            setModalStep={setModalStep}
            setPaidBasket={setPaidBasket}
            handlePay={handlePay}
          />
        ),

        // pay with wallet and card
        [modalSteps.WALLET_AND_CARD]: (
          <PayWithWalletAndCardModalContent
            amount={amount}
            onClose={onClose}
            creditsBalance={user.creditsBalance}
            setModalStep={setModalStep}
            modalSteps={modalSteps}
            setPaidBasket={setPaidBasket}
            handlePay={handlePay}
            payableType={payableType}
            payableId={payableId}
          />
        ),

        // fill wallet
        [modalSteps.FILL_WALLET]: (
          <FillWalletModal
            user={user}
            setAllowClosing={setAllowClosing}
            fromBasket={true}
            fromBasketClose={onClose}
          />
        ),

        // free basket
        [modalSteps.FREE_BASKET]: (
          <FreeBasketModalContent
            payableType={payableType}
            onClose={onClose}
            handlePay={handlePay}
            setModalStep={setModalStep}
            setPaidBasket={setPaidBasket}
          />
        )
      }[modalStep]}
    </View>
  )
}

type PropsTypes = {
  amount: number,
  user: any,
  modalStep: number,
  setModalStep: function,
  modalSteps: any,
  selectedCard?: any,
  setPaidBasket: function,
  payableType: string,
  payableId?: number
}