// @flow
import React, { type Node } from 'react'
import { View, Image } from 'react-native'
import Label from 'src/components/common/atoms/Label'
import ViewShadow from 'src/components/common/atoms/ViewShadow'
import Button from 'src/components/common/atoms/Button'
import s from './AffiliationCard.style'
import { Colors } from 'src/styles'
import ExternalLink from 'src/components/common/atoms/ExternalLink'
import { useTranslation } from 'react-i18next'

/**
 * AffiliationCard mole.
 */
const AffiliationCard = ({
  style,
  styles,
  title,
  logoURL,
  width = 216,
  margin,
  onPress = () => {},
  isMobile = true,
  targetLink
}: PropsTypes): Node => {

  const { t } = useTranslation()

  // Render mole
  return (
    <ViewShadow
      onPress={onPress}
      style={[s.container, !isMobile && s.containerDesktop, style, styles?.container, { width: width }]}
      shadowOpacity={0.8}
      shadowRadius={4}
      shadowOffset={{width: 0, height: 2}}
      withColor={{color: Colors.red100, width: width, height: 50, radius: 0}}
      margin={margin}
      hasPadding={true}
    >
      <View style={[s.cardContainer, styles?.cardContainer]}>

        <View style={[s.affiliationLeftContainer, !isMobile && s.affiliationLeftContainerDesktop]}>
          <Label type={'bold'} size={isMobile ? 13 : 20}>{title}</Label>
        </View>
        <View style={s.affiliationSeparationContainer}>
          <View style={s.affiliationSeparationBar}/>
        </View>
        <View style={s.affiliationRightContainer}>
          <Image
            style={s.affiliationImage}
            source={{ uri: logoURL }}
            resizeMode={'contain'}
          />
          <Button size={isMobile ? 'XS' : 'S'} palette={'primary'} style={{ paddingHorizontal: 0 }}>
            <ExternalLink
              url={targetLink}
              title={t('AffiliationCard.goTo')}
              styles={{ title: { fontSize: isMobile ? 8 : 11 } }}
            />
          </Button>
        </View>

      </View>
    </ViewShadow>
  )
}

// Memo
export default React.memo(AffiliationCard, (prev, next) => {
  return prev.title === next.title &&
    prev.logoURL === next.logoURL &&
    prev.width === next.width &&
    prev.margin?.top === next.margin?.top &&
    prev.margin?.right === next.margin?.right &&
    prev.margin?.bottom === next.margin?.bottom &&
    prev.margin?.left === next.margin?.left &&
    prev.targetLink === next.targetLink
})

// PropsTypes
type PropsTypes = {
  style?: any,
  styles?: any,
  title: string,
  logoURL: string,
  width?: number,
  margin?: any,
  onPress?: function,
  isMobile?: boolean,
  targetLink?: string
}