import React, { type Node } from 'react'
import { Image, View } from 'react-native'
import { Trans, useTranslation } from 'react-i18next'
import useScreenSizes from 'src/utils/useScreenSizes'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import s from '../CoverCollectModal.style'

/**
 * DepositReadyLockerQRCode component
 */
export default function DepositReadyLockerQRCode ({
  onClose,
  switchChoice,
  handleDeposit,
  qrCode
}: PropsTypes): Node {
  /*
   * Init vars
   */
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()

  /*
   * Render component
   */
  return (
    <View>
      <Label size={isMobile ? 10 : 12}>{t('CoverDepositModal.DepositReadyModalContent.DepositReadyLockerContent.QRCode.description')}</Label>
      <Label size={isMobile ? 10 : 12} style={s.subText}>
        <Trans
          i18nKey={'CoverDepositModal.DepositReadyModalContent.mandatoryDeposit'}
          components={{ b: <Label types={['bold', 'smallMargin']} size={isMobile ? 10 : 12} />}}
        />
      </Label>
      <Image
        style={s.contentImage}
        resizeMode={'contain'}
        source={{uri: qrCode}}
      />
      <View style={s.buttonsContainer}>
        <Button onPress={handleDeposit} palette={'primary'} size={isMobile ? 'S' :'M'} fullWidth>
          {t('CoverDepositModal.DepositReadyModalContent.DepositReadyLockerContent.doneBtn')}
        </Button>
        <Button onPress={switchChoice} style={s.middleButton} size={isMobile ? 'S' :'M'}
                fullWidth>{t('CoverDepositModal.DepositReadyModalContent.DepositReadyLockerContent.QRCode.switchToSecretCode')}</Button>
        <Button onPress={onClose} style={s.lastButton} size={isMobile ? 'S' :'M'}
                fullWidth>{t('CoverDepositModal.cancelDeposit')}</Button>
      </View>
    </View>
  )
}

type PropsTypes = {
  onClose: function,
  switchChoice: function,
  handleDeposit: function,
  qrCode: string
}