import React, { type Node } from 'react'
import { View } from 'react-native'
import moment from 'moment'
import { formatPrice } from 'src/utils/FormatDataUtils'
import { useTranslation } from 'react-i18next'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import Icon from 'src/components/common/atoms/Icon'
import { actions } from '../constants/OrderActionsConstant'
import s from './OrderModal.style'
import useScreenSizes from 'src/utils/useScreenSizes'

/**
 * OrderDeliveryModalContent component
 */
export default function OrderDeliveryModalContent ({
  price,
  lockerName,
  collectDate,
  deliveryDate,
  hasAllowedAction,
  handleAction
}: PropsTypes): Node {
  /*
   * Init vars
   */
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()

  /*
   * Render component
   */
  return (
    <View style={s.block}>

      {/* Display informations */}
      <View style={s.blockTitle}>
        <Label size={isMobile ? 12 : 14} type={'bold'}>{t('OrderModal.delivery')}</Label>
        <Label size={isMobile ? 10 : 12}>{t('OrderModal.deliveryPrice', {price: formatPrice(price)})}</Label>
      </View>
      <View style={s.blockRow}>
        <Label size={isMobile ? 10 : 12}>{t('OrderModal.collectPlace')}</Label>
        <Label size={isMobile ? 10 : 12}>{lockerName}</Label>
      </View>
      {collectDate && (
        <View style={s.blockRow}>
          <Label size={isMobile ? 10 : 12}>{t('OrderModal.collectDate')}</Label>
          <Label size={isMobile ? 10 : 12}>{moment(collectDate).format('DD MMMM')}</Label>
        </View>
      )}
      <View style={s.blockRow}>
        <Label size={isMobile ? 10 : 12}>{t('OrderModal.deliveryDate')}</Label>
        <Label size={isMobile ? 10 : 12}>{moment(deliveryDate).format('DD MMMM')}</Label>
      </View>

      {/* Cancel delivery if possible */}
      {hasAllowedAction(actions.CANCEL_COLLECT) &&
        <Button
          size={isMobile ? 'S' : 'M'}
          style={[s.btn, s.alignStart]}
          onPress={() => handleAction(actions.CANCEL_COLLECT)}
        >
          {t('OrderModal.cancelCollect')}
        </Button>
      }

      {/* Collect Action */}
      {hasAllowedAction(actions.COLLECT) &&
        <Button
          palette={'primary'}
          size={isMobile ? 'S' : 'M'}
          style={[s.btn, s.alignStart]}
          Icon={<Icon name={'edit'} color={'#fff'}/>}
          onPress={() => handleAction(actions.COLLECT)}
        >
          {t('ProfileOrderCard.' + actions.COLLECT)}
        </Button>
      }

      {/* Delivery Action */}
      {hasAllowedAction(actions.DELIVERY) &&
        <Button
          palette={'primary'}
          size={isMobile ? 'S' : 'M'}
          style={[s.btn, s.alignStart]}
          Icon={<Icon name={'edit'} color={'#fff'}/>}
          onPress={() => handleAction(actions.DELIVERY)}
        >
          {t('ProfileOrderCard.' + actions.DELIVERY)}
        </Button>
      }
    </View>
  )
}

type PropsTypes = {
  price: number,
  lockerName: string,
  collectDate: string,
  deliveryDate: string,
  hasAllowedAction: function,
  handleAction: function
}