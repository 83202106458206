// @flow
import React, { type Node } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { type StackNavigationProp } from '@react-navigation/stack'
import moment from 'moment'
import { useQuery } from 'relay-hooks'
import type { RoutesParamList } from 'src/components/Navigation'
import useScreenSizes from 'src/utils/useScreenSizes'
import PrivateLayoutProfile from 'src/components/common/layout/PrivateLayoutProfile'
import Label from 'src/components/common/atoms/Label'
import Loader from 'src/components/common/atoms/Loader'
import Icon from 'src/components/common/atoms/Icon'
import s from './ProfileCashbackScreen.style'
import { Colors } from 'src/styles'
import { formatPrice } from 'src/utils/FormatDataUtils'

/**
 * ProfileCashbackScreen screen.
 */
export default function ProfileCashbackScreen({
  navigation
}: PropsTypes) : Node {

  // Init state
  const { t } = useTranslation()
  const { isMobile, isDesktop, isTablet } = useScreenSizes()

  // API Call
  const { data } = useQuery(graphql`
    query ProfileCashbackScreenQuery {
      user {
        id
        cashbackWallet {
          activeAmount
          currentMonthExpirableAmount
          currentMonthExpirableAmountExpirationDate
          inAcquisitionAmount
          inAcquisitionActivationDate
          activeDetails {
            acquiredCashback
            acquisitionDate
            expirationDate
          }
        }
      }
    }
  `)

  // Loading state handle
  if (!data?.user) {
    return (
      <PrivateLayoutProfile
        navigation={navigation}
        subHeaderIconName={'cashback'}
        subHeaderTitle={t('MenuDrawerContent.links.cashback')}
      >
        <View style={s.loadingContainer}>
          <Loader />
        </View>
      </PrivateLayoutProfile>
    )
  }

  // Render screen
  return (
    <PrivateLayoutProfile
      navigation={navigation}
      subHeaderIconName={'cashback'}
      subHeaderTitle={t('MenuDrawerContent.links.cashback')}
    >
      <View style={s.container}>

        {/* Intro */}
        {!isMobile &&
          <View style={s.headerContainerDesktop}>
            <Icon name={'cashback'} size={isTablet ? 24 : 40} color={Colors.mediumGrey100}/>
            <Label size={isTablet ? 22 : 38} style={s.headerTitleDesktop}>{t('ProfileCashbackScreen.labels.titleDesktop')}</Label>
          </View>
        }
        <Label size={isMobile ? 10 : isTablet ? 14 : 22}>{t('ProfileCashbackScreen.labels.intro')}</Label>

        {/* Cashback amounts container */}
        <View style={[s.currentCashbackContainer, isDesktop && s.currentCashbackContainerDesktop]}>

          {/* Amount available */}
          <View style={s.currentCashbackSubContainer}>
            <Label size={isMobile ? 12 : isTablet ? 14 : 22} style={s.currentCashbackTitle}>{t('ProfileCashbackScreen.labels.available')}</Label>
            <Label size={isMobile ? 12 : isTablet ? 14 : 22} type={'bold'}>{formatPrice(data.user.cashbackWallet.activeAmount)}</Label>
            <Label size={isMobile ? 10 : isTablet ? 12 : 14}>
              {data.user.cashbackWallet.currentMonthExpirableAmount ?
                t('ProfileCashbackScreen.labels.amountExpiresAt', {
                  amount: data.user.cashbackWallet.currentMonthExpirableAmount,
                  date: moment(data.user.cashbackWallet.currentMonthExpirableAmountExpirationDate, 'YYYY-MM-DD').format('DD/MM')
                }) : ' '
              }
            </Label>
          </View>

          {/* Amount currently gathering */}
          <View style={s.currentCashbackSubContainer}>
            <Label size={isMobile ? 12 : isTablet ? 14 : 22} style={s.currentCashbackTitle}>{t('ProfileCashbackScreen.labels.gathering')}</Label>
            <Label size={isMobile ? 12 : isTablet ? 14 : 22} type={'bold'}>{formatPrice(data.user.cashbackWallet.inAcquisitionAmount)}</Label>
            <Label size={isMobile ? 10 : isTablet ? 12 : 14}>
              {t('ProfileCashbackScreen.labels.availableAt', {
                date: moment(data.user.cashbackWallet.inAcquisitionActivationDate, 'YYYY-MM-DD').format('DD/MM')
              })}
            </Label>
          </View>

        </View>

        {/* Table title */}
        <Label type={'bold'} size={isMobile ? 12 : isTablet ? 16 : 25} style={[s.tableTitle, !isMobile && s.tableTitleDesktop]}>
          {t('ProfileCashbackScreen.labels.tableTitle')}
        </Label>

        {/* Cashback amount month after month */}
        <View style={[s.table, isDesktop && s.tableDesktop]}>

          {/* Table header */}
          <View style={s.tableHeader}>
            <View style={[s.tableCell, isDesktop && s.tableCellDesktop]}>
              <Label size={isMobile ? 9 : isTablet ? 11 : 18}>{t('ProfileCashbackScreen.labels.acquisitionDate')}</Label>
            </View>
            <View style={[s.tableCell, isDesktop && s.tableCellDesktop]}>
              <Label size={isMobile ? 9 : isTablet ? 11 : 18}>{t('ProfileCashbackScreen.labels.amount')}</Label>
            </View>
            <View style={[s.tableLastCell, isDesktop && s.tableCellDesktop]}>
              <Label size={isMobile ? 9 : isTablet ? 11 : 18}>{t('ProfileCashbackScreen.labels.expirationDate')}</Label>
            </View>
          </View>

          {/* Rows */}
          {data.user.cashbackWallet.activeDetails.map(activeDetail => (
            <View key={activeDetail.acquisitionDate} style={s.tableRow}>
              <View style={[s.tableCell, isDesktop && s.tableCellDesktop]}>
                <Label size={isMobile ? 9 : isTablet ? 11 : 18}>{activeDetail.acquisitionDate}</Label>
              </View>
              <View style={[s.tableCell, isDesktop && s.tableCellDesktop]}>
                <Label size={isMobile ? 9 : isTablet ? 11 : 18}>{activeDetail.acquiredCashback} €</Label>
              </View>
              <View style={[s.tableLastCell, isDesktop && s.tableCellDesktop]}>
                <Label size={isMobile ? 9 : isTablet ? 11 : 18}>{activeDetail.expirationDate}</Label>
              </View>
            </View>
          ))}

          {/* Empty table case */}
          {data.user.cashbackWallet.activeDetails.length === 0 && <View style={s.tableRowEmpty}>
            <Label size={isMobile ? 9 : isTablet ? 11 : 18} style={[s.tableRowEmptyLabel, isDesktop && s.tableCellDesktop]}>
              {t('ProfileCashbackScreen.labels.emptyTable')}
            </Label>
          </View>}

        </View>
      </View>
    </PrivateLayoutProfile>
  )
}

// PropsTypes
type PropsTypes = {
  navigation: StackNavigationProp<RoutesParamList, 'Cashback'>
}
