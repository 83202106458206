import React, { type Node } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'relay-hooks'
import type { StackNavigationProp } from '@react-navigation/stack'
import type { RoutesParamList } from 'src/components/Navigation'
import useScreenSizes from 'src/utils/useScreenSizes'
import MenuScreenItem from 'src/components/common/moles/MenuScreenItem'
import Avatar from 'src/components/common/atoms/Avatar'
import { Sizes } from 'src/styles'
import s from './ProfileMenu.style'
import { useRoute } from '@react-navigation/native'

/**
 * ProfileMenu component
 */
export default function ProfileMenu ({
  navigation
}: PropsTypes): Node {
  // Init state
  const { t } = useTranslation()
  const { isDesktop } = useScreenSizes()
  const route = useRoute()

  // API Call
  const { data } = useQuery(graphql`
    query ProfileMenuQuery {
      user {
        id
        firstName
        lastName
        pictureURL
      }
    }
  `)
  const user = data?.user

  /*
   * Render component
   */
  return (
    <>
      <MenuScreenItem
        active={route.name === 'PersonalInformations'}
        iconName={user?.pictureURL ? null : 'profile'}
        icon={user?.pictureURL ? <Avatar source={{ uri : user?.pictureURL }} style={!isDesktop && s.avatar} size={Sizes.L} isActive={true} skeleton={true} /> : null}
        title={user?.firstName ? user.firstName + ' ' + user.lastName : t('MenuDrawerContent.title.profile')}
        onPress={() => navigation.navigate({'name': 'PersonalInformations'})}
        isDesktop={isDesktop}
        iconSize={isDesktop ? 16 : 14}
      />
      <MenuScreenItem
        active={route.name === 'Agenda'}
        iconName={'calendar'}
        title={t('MenuDrawerContent.links.agenda')}
        onPress={() => navigation.navigate({ name : 'Agenda', params: { orderId: '' } })}
        isDesktop={isDesktop}
        iconSize={isDesktop ? 16 : 14}
      />
      <MenuScreenItem
        active={route.name === 'Orders'}
        iconName={'order'}
        title={t('MenuDrawerContent.links.orders')}
        onPress={() => navigation.navigate({ name : 'Orders', params: { orderId: '' } })}
        isDesktop={isDesktop}
        iconSize={isDesktop ? 16 : 14}
      />
      {/*<MenuScreenItem*/}
      {/*  active={route.name === 'Estimates'}*/}
      {/*  iconName={'estimate'}*/}
      {/*  title={t('MenuDrawerContent.links.estimates')}*/}
      {/*  onPress={() => navigation.navigate({ name : 'Estimates' })}*/}
      {/*  isDesktop={isDesktop}*/}
      {/*  iconSize={isDesktop ? 16 : 14}*/}
      {/*/>*/}
      <MenuScreenItem
        active={route.name === 'Cashback'}
        iconName={'cashback'}
        title={t('MenuDrawerContent.links.cashback')}
        onPress={() => navigation.navigate({ name : 'Cashback' })}
        isDesktop={isDesktop}
        iconSize={isDesktop ? 16 : 14}
      />
      <MenuScreenItem
        active={route.name === 'Wallet'}
        iconName={'wallet'}
        title={t('MenuDrawerContent.links.wallet')}
        onPress={() => navigation.navigate({ name : 'Wallet' })}
        isDesktop={isDesktop}
        iconSize={isDesktop ? 16 : 14}
      />
      {/*<MenuScreenItem*/}
      {/*  active={route.name === 'PaymentCards'}*/}
      {/*  iconName={'bank-cards'}*/}
      {/*  title={t('MenuDrawerContent.links.paymentCards')}*/}
      {/*  onPress={() => navigation.navigate({ name : 'PaymentCards' })}*/}
      {/*  isDesktop={isDesktop}*/}
      {/*  iconSize={isDesktop ? 16 : 14}*/}
      {/*/>*/}
      <MenuScreenItem
        active={route.name === 'SponsorCode'}
        iconName={'sales'}
        title={t('MenuDrawerContent.links.sponsorCode')}
        onPress={() => navigation.navigate({ name : 'SponsorCode' })}
        isDesktop={isDesktop}
        iconSize={isDesktop ? 14 : 12}
      />
      <MenuScreenItem
        active={route.name === 'Security'}
        iconName={'lock'}
        title={t('MenuDrawerContent.links.security')}
        onPress={() => navigation.navigate({ name : 'Security' })}
        isDesktop={isDesktop}
        iconSize={isDesktop ? 16 : 14}
      />
      <MenuScreenItem
        active={route.name === 'DeleteMyAccount'}
        iconName={'close'}
        title={t('MenuDrawerContent.links.deleteMyAccount')}
        onPress={() => navigation.navigate({ name : 'DeleteMyAccount' })}
        isDesktop={isDesktop}
        iconSize={isDesktop ? 16 : 14}
      />
    </>
  )
}

// PropsTypes
type PropsTypes = {
  navigation: StackNavigationProp<RoutesParamList, 'ProfileMenu'>
}