import React, { type Node, useState } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'relay-hooks'
import Label from 'src/components/common/atoms/Label'
import Loader from 'src/components/common/atoms/Loader'
import Button from 'src/components/common/atoms/Button'
import Icon from 'src/components/common/atoms/Icon'
import { Colors } from 'src/styles'
import s from './SendOrderInvoiceModal.style'
import useScreenSizes from 'src/utils/useScreenSizes'

/**
 * InvoiceModal component
 */
export default function SendOrderInvoiceModal ({
  onClose,
  orderId
}: PropsTypes): Node {
  /*
   * Init vars
   */
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const steps = {
    CONFIRM_CANCEL: 10,
    LOADING: 20,
    SUCCESS: 30
  }
  const channels = {
    SMS: 'sms',
    MAIL: 'mail',
    PUSH: 'pushNotification'
  }
  const [step, setStep] = useState(steps.CONFIRM_CANCEL)
  const [error, setError] = useState(false)

  /*
   * API Mutation
   */
  const [sendOrderInvoiceMutation] = useMutation(graphql`
    mutation SendOrderInvoiceModalMutation ($channel: CommunicationChannel!, $orderId: Int!) {
      SendOrderInvoice(channel: $channel, orderId: $orderId)
    }
  `, {
    onCompleted: () => setStep(steps.SUCCESS),
    onError: () => {
      setStep(steps.CONFIRM_CANCEL)
      setError(true)
    }
  })

  /*
   * Handle Cancel Order
   */
  const handleSendOrderInvoice = (channel) => {
    setStep(steps.LOADING)
    setError(false)
    sendOrderInvoiceMutation({variables: { channel, orderId }})
  }

  /*
   * Render component
   */
  return (
    <View style={s.container}>

      {/* Modal title */}
      <Label style={s.title}>{t('SendOrderInvoiceModal.title', { orderId })}</Label>

      {error && <Label type={'error'}>{t('Yup.global.errorTryAgain')}</Label>}

      {/* Modal content */}
      <View style={s.contentContainer}>
        {{
          [steps.CONFIRM_CANCEL]: (
            <>
              <Button
                size={isMobile ? 'S' : 'M'}
                palette={'primary'}
                style={s.btn}
                onPress={() => handleSendOrderInvoice(channels.MAIL)}
              >
                {t('SendOrderInvoiceModal.sendByMail')}
              </Button>
              <Button size={isMobile ? 'S' : 'M'} style={s.btn} onPress={onClose}>{t('App.cancel')}</Button>
            </>
          ),
          [steps.LOADING]: (
            <Loader />
          ),
          [steps.SUCCESS]: (
            <Icon name={'check'} color={Colors.orange100} />
          )
        }[step]}
      </View>

    </View>
  )
}

type PropsTypes = {
  orderId: number
}