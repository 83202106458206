import { StyleSheet } from 'react-native'

export default StyleSheet.create({
  cashbackContainer: {
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
    padding: 10,
    backgroundColor: 'white'
  },
  cashbackContainerDesktop: {
    marginTop: 40,
    paddingVertical: 20
  },
  cashbackHeader: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  cashbackAction: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15
  },
  cashbackActionDesktop: {
    marginTop: 30
  }
})