// @flow
import React, { type Node } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'relay-hooks'
import useScreenSizes from 'src/utils/useScreenSizes'
import { formatPrice } from 'src/utils/FormatDataUtils'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import Loader from 'src/components/common/atoms/Loader'
import s from './FillWalletModal.style'

/**
 * ChoosePaymentMethodModalContent.
 */
export default function ChoosePaymentMethodModalContent({
  handlePayment,
  closeAndReset,
  error,
  amount
}: PropsTypes): Node {

  // Init state
  const { t } = useTranslation()
  const { isDesktop } = useScreenSizes()

  // API call to retrieve payments cards already saved
  const { data } = useQuery(graphql`
    query ChoosePaymentMethodModalContentQuery {
      recurringCreditCards {
        cardNumber
        token
      }
    }
  `)

  // Handle loading case
  if (!data?.recurringCreditCards) {
    return <View style={s.choosePaymentMethodLoaderContainer}>
      <Loader />
    </View>
  }

  // Render modal content
  return (
    <View>

      {/* Intro recap chosen amount */}
      <Label size={isDesktop ? 20 : 14}>
        {t('ProfileWalletScreen.ChoosePaymentMethod.amountToPay')}
        <Label type={'bold'} size={isDesktop ? 20 : 14}>
          {amount && formatPrice(amount)}
        </Label>
      </Label>

      {/* Actions buttons container */}
      <View style={[s.choosePaymentMethodButtons, isDesktop && s.choosePaymentMethodButtonsDesktop]}>

        {/* Display previously saved credits cards */}
        {data.recurringCreditCards.map(card => (
          <Button
            key={card.token}
            palette={'primary'}
            size={!isDesktop ? 'M' : 'L'}
            onPress={() => handlePayment(card)}
            fontStyle={'bold'}
            style={[s.choosePaymentMethodCardButton, isDesktop && s.choosePaymentMethodButtonDesktop]}
            fullWidth={true}
          >
            {t('ProfileWalletScreen.ChoosePaymentMethod.payWithCard', { card: card.cardNumber })}
          </Button>
        ))}

        {/* Pay with new card button */}
        <Button
          palette={'primary'}
          size={!isDesktop ? 'M' : 'L'}
          fontStyle={'bold'}
          onPress={() => handlePayment(null)}
          style={[s.choosePaymentMethodCardButton, isDesktop && s.choosePaymentMethodButtonDesktop]}
          fullWidth={true}
        >
          {t('ProfileWalletScreen.ChoosePaymentMethod.payWithNewCard')}
        </Button>

        {/* Cancel button */}
        <Button
          size={!isDesktop ? 'M' : 'L'}
          onPress={closeAndReset}
          fontStyle={'bold'}
          style={[s.choosePaymentMethodCancelButton, isDesktop && s.choosePaymentMethodButtonDesktop]}
          fullWidth={true}
        >
          {t('ProfileWalletScreen.ChoosePaymentMethod.cancel')}
        </Button>

      </View>
    </View>
  )
}

// PropsTypes
type PropsTypes = {
  handlePayment: function,
  closeAndReset: function,
  error: boolean,
  amount?: ?number
}