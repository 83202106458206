// @flow
import React, { type Node } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import useScreenSizes from 'src/utils/useScreenSizes'
import PrivateLayout from 'src/components/common/layout/PrivateLayout'
import ProfileMenu from 'src/components/common/moles/ProfileMenu/ProfileMenu'
import Label from 'src/components/common/atoms/Label'
import Icon from 'src/components/common/atoms/Icon'
import s from './PrivateLayoutProfile.style'

/**
 * PrivateLayoutProfile layout.
 */
export default function PrivateLayoutProfile({
  navigation,
  children,
  subHeaderIconName = null,
  subHeaderTitle,
  subHeaderContent = null,
  avatar = null
}: PropsTypes) : Node {
  // Init state
  const { t } = useTranslation()
  const { isMobile, isDesktop } = useScreenSizes()

  // Render mobile layout
  if (isMobile) {
    return (
      <PrivateLayout
        headerTitle={t('MenuDrawerContent.title.profile')}
        subHeader={true}
        subHeaderIconName={subHeaderIconName}
        subHeaderTitle={subHeaderTitle}
        subHeaderContent={subHeaderContent}
        subHeaderBackOnPress={() => navigation.navigate({ name: 'ProfileMenu' })}
        avatar={avatar}
      >
        {children}
      </PrivateLayout>
    )
  }
  // Render desktop profile layout
  else {
    return (
      <PrivateLayout
        header={true}
        headerTitle={t('MenuDrawerContent.title.profile')}
        headerBackOnPress={() => {
          navigation.navigate({ name: 'Home'})
        }}
        headerBackTitle={t('MenuDrawerContent.links.home')}
        avatar={avatar}
      >

        {/* Page title */}
        {/* Header banner in desktop version */}
        {!isMobile ? (
          <View style={s.desktopHeaderContainer}>
            <Icon name={'profile'} style={s.desktopBackgroundIcon} size={120}/>

            <View style={s.desktopTitleContainer}>
              <Icon name={'profile'} style={s.desktopTitleIcon} size={60}/>
              <Label type={'bold'} size={28}>{t('MenuDrawerContent.title.profile')}</Label>
            </View>
          </View>
        ) : (
          <View style={s.header}>
            <Label type={'bold'} size={24}>{t('MenuDrawerContent.title.profile')}</Label>
          </View>
        )}

        <View style={s.container}>

          {/* Profile menu */}
          <View style={[s.profileMenuContainer, isDesktop && s.profileMenuContainerDesktop]}>
            <View style={s.profileMenu}>
              <ProfileMenu navigation={navigation} />
            </View>
          </View>

          {/* Page content */}
          <View style={s.profileContentContainer}>
            {children}
          </View>

        </View>

      </PrivateLayout>
    )
  }
}

// PropsTypes
type PropsTypes = {
  navigation: any,
  children: any,
  subHeaderIconName?: 'cashback' | 'wallet' | 'home' | 'search' | 'menu' | 'basket' | 'check' | 'edit' | 'calendar' | 'back' |
    'next' | 'plus' | 'minus' | 'sales' | 'favorite' | 'favorite-filled' | 'caret' | 'profile-rounded' | 'chat' |
    'locker' | 'news' | 'infos' | 'bank-cards' | 'email' | 'phone' | 'lock' | 'profile' | 'estimate' | 'order' |
    'notify' | 'close' | 'qorner-company' | 'qorner-public',
  subHeaderTitle?: any,
  subHeaderContent?: any,
  avatar?: any
}
