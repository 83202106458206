import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'
import { mediumGrey100 } from 'src/styles/colors'

// Styles
export const sAsObject = {
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.lightGrey90,
    borderWidth: 1,
    borderColor: Colors.darkGrey100Less50
  },
  containerDisabled: {
    backgroundColor: Colors.darkGrey100Less50
  },
  defaultOn: {
    backgroundColor: Colors.lightGrey80,
    borderWidth: 1,
    borderColor: Colors.orange100
  },
  defaultOff: {
    backgroundColor: Colors.lightGrey80,
    borderWidth: 1,
    borderColor: Colors.orange100,
  },
  primaryOn: {
    backgroundColor: Colors.orange100
  },
  primaryOff: {
    backgroundColor: Colors.orange100,
  },
  disabled: {
    borderWidth: 0,
    backgroundColor: Colors.lightGrey100
  },
  text: {
    position: 'absolute',
    fontWeight: 'bold',
    color: Colors.mediumGrey100,
  },
  textDisabled: {
    color: Colors.lightGrey100
  },
  XL: {
    width: 125,
    height: 50,
    borderRadius: 50
  },
  L: {
    width: 100,
    height: 40,
    borderRadius: 40
  },
  M: {
    width: 65,
    height: 30,
    borderRadius: 30
  },
  S: {
    width: 45,
    height: 20,
    borderRadius: 20
  },
  circleXLOn: {
    left: 78
  },
  circleLOn: {
    left: 62
  },
  circleMOn: {
    left: 36.5
  },
  circleSOn: {
    left: 26
  },
  circleXLOff: {
    left: 5
  },
  circleLOff: {
    left: 4
  },
  circleMOff: {
    left: 3
  },
  circleSOff: {
    left: 2
  },
  circleXL: {
    width: 37,
    height: 37,
    borderRadius: 37
  },
  circleL: {
    width: 30,
    height: 30,
    borderRadius: 30
  },
  circleM: {
    width: 23,
    height: 23,
    borderRadius: 23
  },
  circleS: {
    width: 15,
    height: 15,
    borderRadius: 15
  },
  textXL: {
    fontSize: 22,
    lineHeight: 28
  },
  textL: {
    fontSize: 20,
    lineHeight: 24
  },
  textM: {
    fontSize: 14,
    lineHeight: 18
  },
  textS: {
    fontSize: 10,
    lineHeight: 12,
  },
  textXLOn: {
    left: 12
  },
  textLOn: {
    left: 8
  },
  textMOn: {
    left: 6
  },
  textSOn: {
    left: 4
  },
  textXLOff: {
    right: 12
  },
  textLOff: {
    right: 8
  },
  textMOff: {
    right: 6
  },
  textSOff: {
    right: 4
  },
}

export default StyleSheet.create(sAsObject)