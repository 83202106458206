// @flow
import React, { type Node, useState } from 'react'
import { Image, ScrollView, View } from 'react-native'
import { type StackNavigationProp } from '@react-navigation/stack'
import type { RoutesParamList } from 'src/components/Navigation'
import { Trans, useTranslation } from 'react-i18next'
import { useQuery } from 'relay-hooks'
import { formatPrice } from 'src/utils/FormatDataUtils'
import SelectLockerModal from 'src/components/common/moles/SelectLockerModal'
import Label from 'src/components/common/atoms/Label'
import Loader from 'src/components/common/atoms/Loader'
import SelectCollectDateModal from 'src/components/common/moles/SelectCollectDateModal'
import SelectDeliveryDateModal from 'src/components/common/moles/SelectDeliveryDateModal'
import PrivateLayout from 'src/components/common/layout/PrivateLayout'
import BasketServiceCard from 'src/components/common/moles/BasketServiceCard/BasketServiceCard'
import CustomerCommentModal from 'src/components/common/moles/BasketProductConfig/CustomerCommentModal'
import PayModal from 'src/components/common/moles/PayModal'
import PaymentDeliverySection from 'src/components/common/moles/PaymentDeliverySection/PaymentDeliverySection'
import PaymentCashbackSection from 'src/components/common/moles/PaymentCashbackSection/PaymentCashbackSection'
import PaymentSection from 'src/components/common/moles/PaymentSection/PaymentSection'
import Modal from 'src/components/common/atoms/Modal'
import s from './PaymentOrderScreen.style'
import useScreenSizes from 'src/utils/useScreenSizes'
import Icon from 'src/components/common/atoms/Icon'

/**
 * BasketScreen screen.
 */
export default function BasketScreen ({ navigation, route }: PropsTypes): Node {
  // Init vars
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const orderId = route.params?.orderId
  const [selectedOrderProduct, setSelectedOrderProduct] = useState()
  const [selectedProviderTitle, setSelectedProviderTitle] = useState()
  const [modalStep, setModalStep] = useState()
  const [selectedCard, setSelectedCard] = useState()
  const [paidBasket, setPaidBasket] = useState(false)
  const [isOpenLockerSelect, setIsOpenLockerSelect] = useState(false)
  const [isOpenCollectDateSelect, setIsOpenCollectDateSelect] = useState(false)
  const [isOpenDeliveryDateSelect, setIsOpenDeliveryDateSelect] = useState(false)

  // Steps for payment modal
  const modalSteps = {
    NEW_CARD: 10,
    WALLET: 20,
    RECURRING_CARD: 30,
    FILL_WALLET: 40,
    WALLET_AND_CARD: 50,
    FREE_BASKET: 60
  }

  // Api call to retrieve user's basket content
  const { data } = useQuery(graphql`
    query PaymentOrderScreenQuery($id: Int!) {
      order(id: $id) {
        id
        rowId
        amount
        paymentStatus
        cashbackEarnedAmount
        discountByCashback
        useCashback
        service {
          id
          rowId
          name
          serviceType
          logoURL
        }
        orderProducts {
          rowId
          productId
          name
          quantity
          price
          unitPrice
        }
        collectDate
        deliveryDate
        deliveryPrice
        deliveryLocker {
          id
          name
        }
        requiredCollectAndDelivery
      }
      user {
        id
        creditsBalance
        cashbackWallet {
          activeAmount
        }
      }
      recurringCreditCards {
        cardNumber
        token
      }
    }`,
    { id: orderId }
  )
  const order = data?.order
  const user = data?.user
  const recurringCreditCards = data?.recurringCreditCards

  const HeaderRight = () => (
    <View style={s.headerRight}>
      <Label type={!isMobile && 'bold'} size={!isMobile ? 20 : 14}>{t('BasketScreen.headerToPay', { amount: formatPrice(order.amount) })}</Label>
      <Label size={isMobile ? 9 : 13} style={s.headerRightCashback}>
        <Trans
          i18nKey={'BasketScreen.cashback.earnable'}
          components={{ b: <Label type={'bold'} size={9}/> }}
          values={{ amount: formatPrice(order.cashbackEarnedAmount) }}
        />
      </Label>
    </View>
  )

  if (paidBasket) {
    // nav to OrdersScreen
    navigation.navigate('Private', { screen: 'Profile', params: { screen : 'Orders'} })
  }

  // Loading State
  if (!data) {
    return (
      <PrivateLayout
        headerTitle={t('PaymentOrderScreen.headerTitle')}
        headerTitleSize={14}
        headerRight={<></>}
      >
        <View style={s.loadingContainer}>
          <Loader/>
        </View>
      </PrivateLayout>
    )
  }

  // Bad order
  if (data && !order) {
    return (
      <PrivateLayout headerTitle={t('BasketScreen.headerTitle')} headerRight={<></>}>
        <View style={s.loadingContainer}>
          <Label>{t('PaymentOrderScreen.errors.orderNotFound')}</Label>
        </View>
      </PrivateLayout>
    )
  }

  // Order already paid
  if (data?.order?.paymentStatus === 1) {
    return (
      <PrivateLayout headerTitle={t('BasketScreen.headerTitle')} headerRight={<></>}>
        <View style={s.loadingContainer}>
          <Label>{t('PaymentOrderScreen.errors.orderAlreadyPaid')}</Label>
        </View>
      </PrivateLayout>
    )
  }

  // Render component
  return (
    <>
      <PrivateLayout
        headerTitle={t('PaymentOrderScreen.headerTitle')}
        headerTitleSize={14}
        headerRight={isMobile && <HeaderRight />}
        headerBackOnPress={() => navigation.navigate('Private', { screen: 'Profile', params: { screen : 'Orders'} })}
      >

        {/* Header banner in desktop version */}
        {!isMobile && (
          <View style={s.desktopHeaderContainer}>
            <Icon name={'basket'} style={s.desktopBackgroundIcon} size={56}/>

            <View style={s.desktopTitleContainer}>
              <Icon name={'basket'} style={s.desktopTitleIcon} size={36}/>
              <Label type={'bold'} size={24}>{t('PaymentOrderScreen.desktopHeaderTitle')}</Label>
            </View>

            <HeaderRight />
          </View>
        )}

        <ScrollView style={s.container}>

          <View style={!isMobile && s.twoColumnsLayout}>

            <View style={!isMobile && s.leftColumn}>

              {/* ServiceBasketCard */}
              <BasketServiceCard
                service={order.service}
                products={order.orderProducts}
                setSelectedOrderProduct={setSelectedOrderProduct}
                setSelectedProviderTitle={setSelectedProviderTitle}
                hasMarginTop
              />

              {/* Delivery */}
              <PaymentDeliverySection
                collectDate={order?.collectDate}
                deliveryDate={order?.deliveryDate}
                deliveryLockerName={order?.deliveryLocker?.name}
                setIsOpenLockerSelect={setIsOpenLockerSelect}
                setIsOpenCollectDateSelect={setIsOpenCollectDateSelect}
                setIsOpenDeliveryDateSelect={setIsOpenDeliveryDateSelect}
                requiredCollectAndDelivery={order?.requiredCollectAndDelivery}
                deliveryPrice={order?.deliveryPrice}
                disabled
              />

              {/* Cashback */}
              {user?.cashbackWallet?.activeAmount > 0 && (
                <PaymentCashbackSection
                  cashbackAmount={user?.cashbackWallet?.activeAmount}
                  useCashback={order?.useCashback}
                  payableType={'ORDER'}
                  payableId={order?.rowId}
                  usableCashback={order?.discountByCashback}
                />
              )}

            </View>

            <View style={!isMobile && s.rightColumn}>

              {/* Payment */}
              <PaymentSection
                payableType={'ORDER'}
                amount={order.amount}
                creditsBalance={user?.creditsBalance}
                recurringCreditCards={recurringCreditCards}
                setSelectedCard={setSelectedCard}
                setModalStep={setModalStep}
                modalSteps={modalSteps}
              />

              {!isMobile && (
                <>
                  <View style={s.cardsImageContainer}>
                    <Image
                      source={require('assets/img/screens/private/profile/ProfileWalletScreen/FillWalletModal/NewCreditCardModalContent/logo_cb.png')}
                      style={s.cardsImage}
                    />
                  </View>
                  <View style={s.byAdyenContainer}>
                    <Label size={12}>{t('ProfileWalletScreen.NewCreditCard.securePaymentText')}</Label>
                    <Image
                      source={require('assets/img/screens/private/profile/ProfileWalletScreen/FillWalletModal/NewCreditCardModalContent/adyen_logo.png')}
                      style={s.byAdyenLogo}
                    />
                  </View>
                </>
              )}

            </View>

          </View>
      </ScrollView>
    </PrivateLayout>

    {/* Various modals used in the basket sreen */}

    <Modal open={!!modalStep} onClose={() => setModalStep()}>
      <PayModal
        amount={order.amount}
        user={user}
        open={!!modalStep}
        onClose={() => setModalStep()}
        modalStep={modalStep}
        setModalStep={setModalStep}
        modalSteps={modalSteps}
        selectedCard={selectedCard}
        setPaidBasket={setPaidBasket}
        payableType={'ORDER'}
        payableId={order?.rowId}
      />
    </Modal>

    {selectedOrderProduct && selectedProviderTitle &&
    <Modal
      open={!!selectedOrderProduct}
      onClose={() => {
        setSelectedOrderProduct(null)
        setSelectedProviderTitle(null)
      }}>
      <CustomerCommentModal
        orderProductId={selectedOrderProduct.rowId}
        open={!!selectedOrderProduct}
        onClose={() => {
          setSelectedOrderProduct(null)
          setSelectedProviderTitle(null)
        }}
        comment={selectedOrderProduct.customerComment}
        selectedProviderTitle={selectedProviderTitle}
      />
    </Modal>}

    <Modal open={isOpenLockerSelect} onClose={() => setIsOpenLockerSelect(false)}>
      <SelectLockerModal/>
    </Modal>
    <Modal open={isOpenCollectDateSelect} onClose={() => setIsOpenCollectDateSelect(false)}>
      <SelectCollectDateModal/>
    </Modal>
    <Modal open={isOpenDeliveryDateSelect} onClose={() => setIsOpenDeliveryDateSelect(false)}>
      <SelectDeliveryDateModal/>
    </Modal>
  </>
  )
}

// PropsTypes
type PropsTypes = {
  navigation: StackNavigationProp<RoutesParamList, 'Basket'>
}
