import { StyleSheet, Platform } from "react-native"
import { Colors, Fonts } from 'src/styles'

// Styles
export default StyleSheet.create({
  menuItem: {
    width: '100%',
    height: 50,
    paddingLeft: 20,
    paddingRight: 20,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: Colors.lightGrey100,
    ...Platform.select({ web: { cursor: 'pointer' }})
  },
  menuItemDesktop: {
    height: 60,
    width: 360,
    paddingLeft: 0,
    paddingRight: 0,
  },
  iconContainer: {
    width: 30,
    justifyContent: 'flex-start'
  },
  iconContainerDesktop: {
    width:60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  icon: {
    marginRight: 15,
    color: Colors.darkGrey100
  },
  iconDesktop: {
    marginRight: 0
  },
  title: {
    flex: 1,
    color: Colors.darkGrey100,
    fontFamily: Fonts.primary.regular
  },
  active: {
    fontFamily: Fonts.primary.bold
  },
  titleDesktop: {
    marginLeft: 10
  },
  arrow: {
    color: Colors.darkGrey100Less50,
    transform: [{ rotateY: '180deg' }],
    position: 'absolute',
    right: 20
  }
})