// @flow
import React, { type Node, useEffect, useRef, useState } from 'react'
import { View, Image, ScrollView } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'relay-hooks'
import * as Yup from 'yup'
import { useFocusScreenEffect } from 'src/utils/ComponentUtils'
import GlobalRefetchStore from 'src/store/GlobalRefetchStore'
import useScreenSizes from 'src/utils/useScreenSizes'
import useIntercom from 'src/utils/useIntercom'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import Icon from 'src/components/common/atoms/Icon'
import StepperFormik from 'src/components/common/forms/StepperFormik'
import Loader from 'src/components/common/atoms/Loader'
import ProductConfigModal from 'src/components/common/moles/ProductModal/ProductConfigModal'
import { Colors } from 'src/styles'
import { Formik } from 'formik'
import s from './ProductModal.style'
import { formatPrice } from 'src/utils/FormatDataUtils'

export default function ProductModal ({
  setAllowClosing,
  onClose,
  productId,
  navigation
}: PropsTypes): Node {

  // Init state
  const { t } = useTranslation()
  const { isMobile, isDesktop } = useScreenSizes()
  const formRef: any = useRef()
  const picturesContainerRef = useRef()
  const [error, setError] = useState()
  const [openBis, setOpenBis] = useState(false)
  const [refetchKey, setRefetchKey] = useState(new Date())
  const [basketCount, setBasketCount] = useState(0)

  // Refetch product on focus screen
  useFocusScreenEffect(() => {
    setRefetchKey(new Date())
  })

  const imgWidth = isMobile
    ? ((picturesContainerRef?.current?.clientWidth - 10) / 2)
    : ((picturesContainerRef?.current?.clientWidth - 31) / 2)

  // API Call to retrieve product
  const { data } = useQuery(
    graphql`
      query ProductModalQuery($productId: Int!) {
        product(productId: $productId) {
          service {
            serviceType
          }
          id
          productType
          name
          description
          price
          discountPerProduct {
            discountPercentage
            discountPrice
          }
          isCashbackActive
          cashbackPercentage
          quantityInBasket
          pictures {
            id
            title
            url
          }
        }
        user {
          id
          rowId
          intercomUserIdentity
          email
          firstName
          lastName
        }
        basket {
          id
        }
      }
    `,
    // Variable
    { productId },
    {
      fetchPolicy: 'network-only',
      fetchKey: refetchKey,
      networkCacheConfig: {
        force: true,
      }
    }
  )
  const product = data?.product
  const user = data?.user
  const basket = data?.basket

  // Mutation to add product to basket
  const [addProductToBasketMutation, { loading }] = useMutation(
    graphql`
      mutation ProductModalMutation($productId: Int!) {
        AddProductToBasket(productId: $productId) {
          basket {
            id
            productCount
            amount
            amountAfterReduction
            services {
              rowId
              amount
              name
              logoURL
              serviceType
              providerTitle
              products {
                id
                rowId
                productId
                productPrice
                productType
                isCover
                name
                price
                quantity
                customerComment
              }
            } 
          }
          product {
            id
            quantityInBasket
          }
        }
      }
    `,
    {
      onCompleted: () => {
        setError()
        GlobalRefetchStore.update(s => {
          s.basketCountFetchKey = new Date()
        })
      },
      onError: () => setError(t('Yup.global.errorTryAgain'))
    },
  )

  // Mutation to remove product from basket
  const [removeProductToBasketMutation, { loading: loadingRemove }] = useMutation(
    graphql`
      mutation ProductModalRemoveProductMutation($productId: Int!) {
        RemoveProductFromBasket(productId: $productId) {
          basket {
            id
            productCount
            amount
            amountAfterReduction
            services {
              rowId
              amount
              name
              logoURL
              serviceType
              providerTitle
              products {
                id
                rowId
                productId
                productPrice
                productType
                isCover
                name
                price
                quantity
                customerComment
              }
            }          
          }
          product {
            id
            quantityInBasket
          }
        }
      }
    `,
    {
      onCompleted: ({ RemoveProductFromBasket }) => {
        setError()
        if (!RemoveProductFromBasket.basket) setRefetchKey(new Date())
      },
      onError: () => setError(t('Yup.global.errorTryAgain'))
    },
  )

  // Create or refresh intercom instance with fresh user data
  const intercom = useIntercom(user)

  // Update basket count
  useEffect(() => {
    setBasketCount(product?.quantityInBasket)
  }, [product?.quantityInBasket])

  // Allow closing modal state
  useEffect(() => {
    setAllowClosing(!loading)
  }, [loading])

  const displayButtons = () => {
    if (product.productType === 'standard' || product.productType === 'retail') {
      return (
        basketCount > 0 ? (
          <View style={[s.buttonContainer, !isMobile && s.buttonContainerDesktop]}>
              <Formik
                innerRef={formRef}
                initialValues={{ count: basketCount }}
                validationSchema={Yup.object().shape({
                  count: Yup.number().min(0)
                })}
                onSubmit={(values) => console.log('submit ' + values)}
              >
                {(formikProps) => (
                  <StepperFormik
                    {...formikProps}
                    name={'count'}
                    size={isMobile ? 'S' : 'M'}
                    minimum={0}
                    setQueryValue={setBasketCount}
                    disabledMinus={loading || loadingRemove}
                    disabledPlus={loading || loadingRemove}
                    onIncrementPress={() => addProductToBasketMutation({ variables: { productId } })}
                    onDecrementPress={() => removeProductToBasketMutation({ variables: { productId } })}
                    val={basketCount}
                    style={s.stepper}
                  />
                )}
              </Formik>
              <Button size={isMobile ? 'S' : 'L'} style={[s.basketButton, !isMobile && s.basketButtonDesktop]} onPress={onClose}>
                {t('ProductModal.continue')}
              </Button>
              <Button
                size={isMobile ? 'S' : 'L'}
                palette={'primary'}
                style={{ marginTop: 10 }}
                onPress={() => {
                  onClose()
                  navigation.navigate('Basket')
                }}
              >
                {t('ProductModal.goToBasket')}
              </Button>
            </View>
        ) : (
          <View style={s.buttonToCartContainer}>
            <Button size={isMobile ? 'S' : 'L'} palette={'primary'}
                    style={[s.button, { marginRight: 10 }]}
                    disabled={loading}
                    onPress={() => addProductToBasketMutation({ variables: { productId } })}>
              {t('ProductModal.addToBasket')}
            </Button>
            <Button size={isMobile ? 'S' : 'L'} style={s.button} onPress={onClose}>{t('ProductModal.backToCatalog')}</Button>
          </View>
        )
      )
    }
    // else {
    //   return (
    //     <>
    //       <Button size={isMobile ? 'S' : 'L'} palette={'primary'}
    //               style={[s.button, { marginRight: 10 }]}
    //               onPress={() => {setOpenBis(true)}}
    //       >
    //         {t('ProductModal.book')}
    //       </Button>
    //       <Button size={isMobile ? 'S' : 'L'} style={s.button} onPress={onClose}>{t('ProductModal.backToCatalog')}</Button>
    //     </>
    //   )
    // }
  }

  const pictureList = () => (
    <View ref={picturesContainerRef} style={s.picturesContainer}>
      {product.pictures.map((picture, index) => {
        let styles

        // First pic bigger
        if (index === 0) {
          styles = [s.mainImage, isDesktop && s.mainImageDesktop]
        } else {
          // Pic on the left has margin
          if ((product.pictures.length % 2 !== 0 && index % 2 === 0) || (product.pictures.length % 2 === 0 && index % 2 !== 0)) {
            styles = [s.secondaryImage, isDesktop && s.secondaryImageDesktop, { width: imgWidth, marginRight: isDesktop ? 30 : 10 }]
          } else {
            styles = [s.secondaryImage, isDesktop && s.secondaryImageDesktop, { width: imgWidth }]
          }
        }
        return (<Image source={picture.url} style={styles}/>)
      })}
    </View>
  )

  return !product ? <Loader /> : (
    <View style={s.modal}>

      {/* Title */}
      <View style={s.titleContainer}>
        <Label size={!isMobile ? 28 : 14}>{product.name}</Label>
        <View>
          {product.discountPerProduct?.discountPercentage && (
            <Label size={isMobile ? 9 : 12} type={'bold'} color={Colors.red100} style={s.saleLabel}>
              {t('ProductModal.sale', {
                sale: product.discountPerProduct?.discountPercentage
              })}
            </Label>
          )}
          { product?.productType !== "content" ?
              <View style={s.priceContainer}>
                {product.discountPerProduct?.discountPercentage ? (
                  <>
                    <Label size={isMobile ? 12 : 22} style={s.dashedPrice}>
                      {formatPrice(product.price)}
                    </Label>
                    <Label style={s.price} type={'bold'} size={!isMobile ? 28 : 14} color={Colors.red100} >
                      {formatPrice(product.discountPerProduct?.discountPrice)}
                    </Label>
                  </>
                ) : (
                  <Label style={{ margin: 0 }} size={!isMobile ? 28 : 14}>{formatPrice(product.price)}</Label>
                )}
              </View>
              : null
          }
        </View>
      </View>

      {/* Error */}
      {error && <Label types={['alert', 'error']} style={s.error}>{error}</Label>}

      {/* Buttons */}
      {displayButtons()}

      {/* Cashback */}
      {product.isCashbackActive && (
        <View style={s.cashbackContainer}>
          <Icon name={'cashback'} size={isMobile ? 12 : 18} color={Colors.orange100}/>
          <Label size={isMobile ? 9 : 18} style={s.cashbackLabel}>
            {t('ProductModal.cashbackToWin')} {product.cashbackPercentage}%
          </Label>
        </View>
      )}

      {/* Product description */}
      <ScrollView>

        {/* Content */}
        <Label size={isMobile ? 12 : 24} style={s.content}>
          {product.description}
        </Label>

        {/* Images */}

        {product?.pictures && pictureList()}

      </ScrollView>

      {/* Contact button */}
      <Button
        size={isMobile ? 'XS' : 'M'}
        style={s.bottomButton}
        onPress={() => intercom.start()}
      >
        {t('ProductModal.askSomething')}
      </Button>

      {/* Product config modal to ask required data to add to basket */}
      <ProductConfigModal openBis={openBis} setOpenBis={setOpenBis} product={product} />

    </View>
  )
}

type PropsTypes = {
  productId: string,
  navigation: any
}
