// @flow
import React, { type Node, useRef, useState } from 'react'
import { View } from 'react-native'
import { useMutation } from 'relay-hooks'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { type StackNavigationProp } from '@react-navigation/stack'
import type { RoutesParamList } from 'src/components/Navigation'
import useScreenSizes from 'src/utils/useScreenSizes'
import PublicLayout from 'src/components/common/layout/PublicLayout'
import Button from 'src/components/common/atoms/Button'
import TextInputFormik from 'src/components/common/forms/TextInputFormik'
import Label from 'src/components/common/atoms/Label'
import Icon from 'src/components/common/atoms/Icon'
import s from './UpdatePasswordScreen.styles'

/**
 * UpdatePasswordScreen screen.
 */
export default function UpdatePasswordScreen ({
  navigation, route
}: PropsTypes): Node {

  // Init state
  const { t } = useTranslation()
  const { isMobile, isTablet, screenSize, isDesktop } = useScreenSizes()
  const formRef: any = useRef()
  const [passwordUpdated, setPasswordUpdated] = useState(false)

  // Extract token from query param
  const resetToken = route?.params?.token

  // Form validation schema
  const updatePassordSchema = Yup.object().shape({
    password: Yup.string()
      .min(8, t('Yup.string.min', { length: 8 }))
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{4,}$/, t('UpdatePasswordScreen.labels.updatePasswordDetail')),
    passwordConfirmation: Yup.string()
      .min(8, t('Yup.string.min', { length: 8 }))
      .oneOf([Yup.ref('password'), null], t('UpdatePasswordScreen.errors.passwordMismatch')),
  })

  // ResetPassword mutation API call
  const [resetPasswordMutation, { loading }] = useMutation(
    graphql`
      mutation UpdatePasswordScreenMutation($password: String!, $passwordConfirmation: String!, $resetToken: String!) {
        ResetPassword(password: $password, passwordConfirmation: $passwordConfirmation, resetToken: $resetToken)
      }
    `,
    {
      onCompleted: () => {
        // Update state to show success content
        setPasswordUpdated(true)
      },
      onError: (errors) => {
        // Handle errors
        for (let error of errors) {
          switch (error.errorCode) {
            case '135014':
              formRef.current.setFieldError('passwordConfirmation', t('UpdatePasswordScreen.errors.passwordMismatch'))
              break
            case '135012':
              formRef.current.setFieldError('passwordConfirmation', t('Yup.global.errorTryAgain'))
              break
            default :
              formRef.current.setFieldError('general', t('Yup.global.errorTryAgain'))
          }
        }
      }
    },
  )

  // Password updated case
  if(passwordUpdated === true) {
    return (
      <PublicLayout navigation={navigation} registerCompanion={true}>
        <View style={[s.mainContainerSuccess, !isMobile && s.mainContainerSuccessDesktop]}>

          {/* Icon success */}
          <Icon style={!isDesktop ? s.successIcon : s.successIconDesktop} name={'check'} size={!isDesktop ? 20 : 58} />

          {/* Title success */}
          <Label style={s.successTitle} size={!isDesktop ? 16 : 25}>
            {t('UpdatePasswordScreen.labels.success')}
          </Label>

          {/* Back to login */}
          <Button style={isDesktop && s.backToLoginButton}
                  palette={'primary'}
                  onPress={() => navigation.navigate({ name: 'Login' })}
                  size={!isDesktop ? 'M' : 'L'}
          >
            {t('UpdatePasswordScreen.labels.loginReturn')}
          </Button>

        </View>
      </PublicLayout>
    )
  }

  // Render page
  return (
    <PublicLayout navigation={navigation} registerCompanion={true}>
      <View style={[s.mainContainer, !isMobile && s[`mainContainer${screenSize}`]]}>

        {/* Update password form */}
        <Formik
          innerRef={formRef}
          initialValues={{ password: '', passwordConfirmation: '' }}
          validationSchema={updatePassordSchema}
          onSubmit={(values) => {
            resetPasswordMutation({ variables: { ...values, resetToken: resetToken } })
          }}
        >
          {(formikProps) => (
            <View style={[s.formContainer, !isMobile && s.formContainerDesktop]}>

              {/* Instructions */}
              <Label style={s.updatePassword} size={!isDesktop ? 15 : 25}>
                {t('UpdatePasswordScreen.labels.updatePassword')}
              </Label>
              <Label style={[s.updatePasswordDetail, !isMobile && s.updatePasswordDetailDesktop]} size={isMobile ? 8 : isTablet ? 12 : 16}>
                {t('UpdatePasswordScreen.labels.updatePasswordDetail')}
              </Label>

              {/* New password */}
              <TextInputFormik style={[s.textInput, isDesktop && s.textInputDesktop]}
                               name={'password'}
                               placeholder={t('UpdatePasswordScreen.placeholders.password')}
                               autoCompleteType={'password'}
                               textContentType={'password'}
                               secureTextEntry={true}
                               hasError={formikProps.errors.password && formikProps.touched.password}
                               icon={'lock'}
                               onSubmitEditing={formikProps.handleSubmit}
                               outsideIcon={true}
                               size={isDesktop ? 'XL' : 'M'}
                               iconSize={isDesktop ? 46 : 19}
                               {...formikProps}
              />
              {formikProps.errors.password && formikProps.touched.password && (
                <Label style={[s.error, isDesktop && s.errorDesktop]} size={isDesktop ? 16 : 8}>
                  {formikProps.errors.password}
                </Label>
              )}

              {/* Confirm new password */}
              <TextInputFormik style={[s.textInput, isDesktop && s.textInputDesktop]}
                               name={'passwordConfirmation'}
                               placeholder={t('UpdatePasswordScreen.placeholders.confirmPassword')}
                               secureTextEntry={true}
                               hasError={formikProps.errors.passwordConfirmation && formikProps.touched.passwordConfirmation}
                               icon={'lock'}
                               onSubmitEditing={formikProps.handleSubmit}
                               outsideIcon={true}
                               size={isDesktop ? 'XL' : 'M'}
                               iconSize={isDesktop ? 46 : 19}
                               {...formikProps}
              />
              {formikProps.errors.passwordConfirmation && formikProps.touched.passwordConfirmation && (
                <Label style={[s.error, isDesktop && s.errorDesktop]} size={isDesktop ? 16 : 8}>
                  {formikProps.errors.passwordConfirmation}
                </Label>
              )}

              {/* General error */}
              {formikProps.errors.general && (
                <Label style={[s.error, s.generalError]} size={isMobile ? 8 : isTablet ? 12 : 16}>
                  {formikProps.errors.general}
                </Label>
              )}

              {/* Actions buttons */}
              <Button style={isMobile ? s.submitButton : s.submitButtonDesktop}
                      onPress={formikProps.handleSubmit}
                      palette={'primary'}
                      size={isDesktop ? 'L' : 'M'}
                      isLoading={loading}
                      disabled={!(formikProps.isValid && formikProps.dirty)}>
                {t('UpdatePasswordScreen.labels.resetPassword')}
              </Button>
              <Button style={isMobile ? s.cancelButton : s.cancelButtonDesktop}
                      size={isDesktop ? 'L' : 'M'}
                      onPress={() => navigation.navigate({ name: 'Login' })}
              >
                {t('UpdatePasswordScreen.labels.cancel')}
              </Button>

            </View>
          )}
        </Formik>
      </View>
    </PublicLayout>
  )
}

// PropsTypes
type PropsTypes = {
  navigation: StackNavigationProp<RoutesParamList, 'UpdatePassword'>,
  route: any
}