/**
 * @generated SignedSource<<8913d0561d7ab55940840efdbf59efde>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "channel"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "channel",
        "variableName": "channel"
      }
    ],
    "kind": "ScalarField",
    "name": "SendUserBadge",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DepositBadgeModalContentMutation",
    "selections": (v1/*: any*/),
    "type": "ClientAppMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DepositBadgeModalContentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d2016718ee797e0d6945bf50323dee7d",
    "id": null,
    "metadata": {},
    "name": "DepositBadgeModalContentMutation",
    "operationKind": "mutation",
    "text": "mutation DepositBadgeModalContentMutation(\n  $channel: CommunicationChannel!\n) {\n  SendUserBadge(channel: $channel)\n}\n"
  }
};
})();

node.hash = "09a1fd150af5f4f3a35982bb1142445a";

module.exports = node;
