import { StyleSheet } from 'react-native'

export default StyleSheet.create({

  title: {
    marginBottom:10
  },
  button: {
    marginTop: 10
  },
  cancelButton: {
    marginBottom:16
  },
  description: {
    marginBottom: 14
  },
  supplement: {
    marginBottom: 14,
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
})