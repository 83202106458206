// @flow
import React, { type Node, useState, useEffect } from 'react'
import { Dimensions, Platform, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'relay-hooks'
import useScreenSizes from 'src/utils/useScreenSizes'
import Label from 'src/components/common/atoms/Label'
import Loader from 'src/components/common/atoms/Loader'
import Icon from 'src/components/common/atoms/Icon'
import Button from 'src/components/common/atoms/Button'
import ChooseAmountModalContent from './ChooseAmountModalContent'
import ChoosePaymentMethodModalContent from './ChoosePaymentMethodModalContent'
import NewCreditCardModalContent from './NewCreditCardModalContent'
import s from './FillWalletModal.style'

/**
 * FillWalletModal.
 */
export default function FillWalletModal ({
  onClose,
  setAllowClosing,
  user,
  fromBasket = false,
  fromBasketClose
}: PropsTypes ): Node {

  // Init state
  const { t } = useTranslation()
  const { isMobile, isDesktop } = useScreenSizes()
  const modalSteps = {
    CHOOSE_AMOUNT: 10,
    CHOOSE_PAYMENT_METHOD: 20,
    NEW_CARD: 30,
    PROCESSING: 40,
    SUCCESS: 50
  }
  const [modalStep, setModalStep] = useState(modalSteps.CHOOSE_AMOUNT)
  const [amount, setAmount] = useState(null)
  const [error, setError] = useState(false)

  // Prevent modal closing if processing
  useEffect(() => {
    modalStep === modalSteps.PROCESSING ? setAllowClosing(false) : setAllowClosing(true)
  }, [modalStep])

  // Pay with recurring credit card mutation
  const [fillWalletRecurringMutation] = useMutation(graphql`
    mutation FillWalletModalRecurringCardMutation($amount: FillWalletAmount!, $paymentMethod: PaymentMethod, $browserInfo: BrowserInfoInput, $creditCard: PaymentCreditCardInput, $creditCardEnableRecurring: Boolean, $recurringCreditCard: PaymentRecurringCreditCardInput, $payment3DS2Action: Payment3DS2ActionInput) {
      FillWallet(amount: $amount, paymentMethod: $paymentMethod, browserInfo: $browserInfo, creditCard: $creditCard, creditCardEnableRecurring: $creditCardEnableRecurring, recurringCreditCard: $recurringCreditCard, payment3DS2Action: $payment3DS2Action) {
        user {
          id
          creditsBalance
        }
        resultCode
        creditCard3DS2Action {
          paymentData
          paymentMethodType
          token
          type
        }
      }
    }`, {
    onCompleted: () => {
      setModalStep(modalSteps.SUCCESS)
    },
    onError: () => {
      setError(true)
      setModalStep(modalSteps.CHOOSE_PAYMENT_METHOD)
    }
  })

  // Handle payment method
  const handlePayment = (card) => {
    if (card === null) {
      setModalStep(modalSteps.NEW_CARD)
    } else {
      // handle payment with registered card
      setModalStep(modalSteps.PROCESSING)
      fillWalletRecurringMutation({
        variables: {
          amount: 'AMOUNT_' + amount,
          paymentMethod: 'METHOD_REGISTERED_CREDIT_CARD',
          browserInfo: {
            browser: Platform.OS === 'web' ? {
              acceptHeader: 'application/json',
              colorDepth: window.screen.colorDepth,
              javaEnabled: navigator.javaEnabled(),
              language: navigator.language,
              screenHeight: Dimensions.get('window').height,
              screenWidth: Dimensions.get('window').width,
              timeZoneOffset: new Date().getTimezoneOffset(),
              userAgent: navigator.userAgent
            } : {},
            isFromAndroid: Platform.OS === 'android',
            isFromIos: Platform.OS === 'ios'
          },
          recurringCreditCard: {
            contractId: card.token
          }
        }
      })
    }
  }

  // Render modal
  return (
    <View>

      {/* Modal title */}
      <Label type={'h3'} style={[s.modalTitle, !isMobile && s.modalTitleDesktop]} size={isDesktop ? 28 : 14}>
        {t('ProfileWalletScreen.Modal.title')}
      </Label>

      {/* Modal content */}
      {{

        // Choose amount step
        [modalSteps.CHOOSE_AMOUNT]: (
          <ChooseAmountModalContent
            modalSteps={modalSteps}
            setAmount={setAmount}
            setModalStep={setModalStep}
            closeAndReset={onClose}
            currentBalance={user.creditsBalance}
          />
        ),

        // Choose payment method step
        [modalSteps.CHOOSE_PAYMENT_METHOD]: (
          <ChoosePaymentMethodModalContent
            handlePayment={handlePayment}
            closeAndReset={onClose}
            error={error}
            amount={amount}
          />
        ),

        // New card step
        [modalSteps.NEW_CARD]: (
          <NewCreditCardModalContent
            modalSteps={modalSteps}
            setModalStep={setModalStep}
            amount={amount}
            userId={user.id}
          />
        ),

        // Processing step
        [modalSteps.PROCESSING]: (
          <View style={s.processingContainer}>
            <Loader style={s.processingLoader} />
            <Label style={s.processingLabel}>{t('ProfileWalletScreen.Processing.text')}</Label>
          </View>
        ),

        // Success step
        [modalSteps.SUCCESS]: (
          <View style={s.successContainer}>
            <Icon name={'check'} size={24} style={s.successIcon}/>
            <Label style={s.successLabel}>{t('ProfileWalletScreen.Success.text')}</Label>
            {fromBasket ? (
              <Button
                style={s.successCloseButton}
                size={'M'}
                palette={'primary'}
                onPress={fromBasketClose}
              >
                {t('PayBasketModal.backToWalletModal')}
              </Button>
            ) : (
            <Button
              style={s.successCloseButton}
              size={'M'}
              palette={'primary'}
              onPress={onClose}
            >
              {t('App.close')}
            </Button>
            )}
          </View>
        )

      }[modalStep]}

    </View>
  )
}

// PropsTypes
type PropsTypes = {
  user: any,
  fromBasket?: boolean,
  fromBasketClose?: function,
}