import React, { type Node } from 'react'
import { Image, View } from 'react-native'
import { Trans, useTranslation } from 'react-i18next'
import useScreenSizes from 'src/utils/useScreenSizes'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import s from '../CoverCollectModal.style'

/**
 * DepositReadyLockerSecretCode component
 */
export default function DepositReadyLockerSecretCode ({
  onClose,
  switchChoice,
  openingCode,
  orderRowId,
  handleDeposit
}: PropsTypes): Node {
  /*
   * Init vars
   */
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()

  /*
   * Render component
   */
  return (
    <View>
      <Label size={isMobile ? 10 : 12}>
        {t('CoverDepositModal.DepositReadyModalContent.DepositReadyLockerContent.SecretCode.description')}
      </Label>
      <Label size={isMobile ? 10 : 12} style={s.subText}>
        <Trans
          i18nKey={'CoverDepositModal.DepositReadyModalContent.mandatoryDeposit'}
          components={{ b: <Label types={['bold', 'smallMargin']} size={isMobile ? 10 : 12} />}}
        />
      </Label>
      <View style={s.secretCodeContainer}>
        <View>
          <Label types={['bold', 'smallMargin']} size={isMobile && 12}>
            {t('CoverDepositModal.DepositReadyModalContent.DepositReadyLockerContent.SecretCode.orderNumber')}
          </Label>
          <Label size={isMobile && 12}>N°{orderRowId}</Label>
        </View>
        <View style={{width: 16}}/>
        <View>
          <Label types={['bold', 'smallMargin']} size={isMobile && 12}>
            {t('CoverDepositModal.DepositReadyModalContent.DepositReadyLockerContent.SecretCode.secretCode')}
          </Label>
          <Label size={isMobile && 12}>{openingCode}</Label>
        </View>
      </View>
      <Image
        style={[s.contentImage, !isMobile && s.contentImageDesktop]}
        resizeMode={'contain'}
        source={require('assets/img/screens/private/shoppingTunnel/CoverDepositModal/animation_code_consigne_connectee.gif')}
      />
      <View style={s.buttonsContainer}>
        <Button onPress={handleDeposit} palette={'primary'} size={isMobile ? 'S' : 'M'} fullWidth>
          {t('CoverDepositModal.DepositReadyModalContent.DepositReadyLockerContent.doneBtn')}
        </Button>
        <Button onPress={switchChoice} style={s.middleButton} size={isMobile ? 'S' : 'M'} fullWidth>
          {t('CoverDepositModal.DepositReadyModalContent.DepositReadyLockerContent.SecretCode.switchToQRCode')}
        </Button>
        <Button onPress={onClose} style={s.lastButton} size={isMobile ? 'S' : 'M'} fullWidth>
          {t('CoverDepositModal.cancelDeposit')}
        </Button>
      </View>
    </View>
  )
}

type PropsTypes = {
  onClose: function,
  switchChoice: function,
  orderRowId: number,
  handleDeposit: function,
  openingCode: string
}