import { Platform, StyleSheet } from 'react-native'

export default StyleSheet.create({
  scrollView: {
    paddingTop: 20,
    paddingBottom: 20
  },
  separator: {
    marginTop: 20,
    marginBottom: 20
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },

  // Animation Container
  animationContainer: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
  },
  animation: {
    width: '100%',
    height: Platform.OS === 'web' ? 400 : 200,
  },

  // Header Container
  shadow: {
    backgroundColor: 'white',
    zIndex: 10,
    display: 'flex',
    justifyContent: 'center'
  },
  shadowDesktop: {
    marginTop: 40
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 40,
    marginRight: 25,
    marginTop: 20,
    marginBottom: 20
  },
  headerContainerDesktop: {
    marginHorizontal: 60,
    marginVertical: 0,
  },
  orderButtonDesktop: {
    width:245
  },
  orderButtonTablet: {
    width:144
  },


  // Cashback container
  cashbackContainer: {
    width: '100%',
    textAlign: 'center',
    alignItems: 'center'
  },
  cashbackAmountContainer: {
    flexDirection: 'row',
  },
  cashbackImage: {
    width: 50,
    height: 50
  },
  cashbackImageDesktop: {
    width: 142,
    height: 142
  },
  cashbackDetails: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 10,
    marginTop: 5
  },
  cashbackDetailsDesktop: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 40,
  },
  cashbackLabel: {
    marginTop: 5,
    marginBottom: 0,
    textAlign: 'center'
  },
  cashbackButton: {
    marginTop: 10
  },

  // Questions
  questionContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  questionImage: {
    width: 48,
    height: 49,
    marginRight: 18
  },
  questionImageTablet: {
    width: 68,
    height: 69,
    marginRight: 25
  },
  questionImageDesktop: {
    width: 107,
    height: 110,
    marginRight: 40
  },
  questionLabel: {
    marginBottom: 10
  },

  // Lists
  flatList: {
    height: 98
  },
  listLoader: {
    height: 62,
    justifyContent: 'center'
  },
  listContainer: {
    marginLeft: 10,
    marginRight: 0
  },
  listButton: {
    alignSelf: 'flex-start'
  },
  listTitle: {
    marginBottom: 5
  },
  listTitleDesktop: {
    marginBottom: 30
  },
  listLinearGradient: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    zIndex: 1,
    width: 20
  },

  desktopScrollView: {
    paddingTop: 60,
    paddingBottom: 40,
    paddingLeft:30,
    paddingRight: 20
  },
  cashbackDesktopContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  desktopSeparator: {
    marginTop: 60,
    marginBottom: 60
  },
  questionDesktopContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  }})

export const newsLayoutDesktop = { width: 360, height: 120, marginRight: 30, marginBottom: 20 }