// @flow
import React, { type Node } from 'react'
import { View, Image } from 'react-native'
import { useTranslation } from 'react-i18next'
import useScreenSizes from 'src/utils/useScreenSizes'
import Icon from 'src/components/common/atoms/Icon'
import Label from 'src/components/common/atoms/Label'
import ViewShadow from 'src/components/common/atoms/ViewShadow'
import s, { iconSize, textSize } from './ServiceCard.style'

/**
 * ServiceCard mole.
 */
const ServiceCard = ({
  style,
  styles,
  title,
  iconURL,
  color = '#8e44ad',
  size = 'M',
  width = 216,
  margin,
  isFav = false,
  discount = null,
  cashback = null,
  onPress = () => {},
  displayNonFavoriteIcon = true
}: PropsTypes): Node => {
  // Init
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()

  // Render mole
  return (
    <ViewShadow
      onPress={onPress}
      style={[s[size], s.container, style, styles?.container, {width: width}]}
      shadowOpacity={0.8}
      shadowRadius={4}
      shadowOffset={{width: 0, height: 2}}
      withColor={{color: color, width: width, height: size === 'M' ? 50 : 80, radius: 0}}
      margin={margin}
      hasPadding={true}
    >
      <View style={[s.cardContainer, styles?.cardContainer]}>

        {/* Favorite ? */}
        {isFav ?
          <Icon style={[s.fav, styles?.fav]} name={'favorite-filled'} color={color} size={iconSize[size]}/>
          : displayNonFavoriteIcon && <Icon style={[s.fav, styles?.fav]} name={'favorite'} color={color} size={iconSize[size]}/>
        }


        {/* Title container */}
        <View style={[s.titleContainer, styles?.titleContainer]}>

          {/* Title */}
          <View style={[styles?.titleSubContainer, { width: '100%' }]}>

            {/* Service name */}
            <Label size={textSize[size]} style={s.serviceName} numberOfLines={1}>{title}</Label>

            {/* Discount ? */}
            {discount && <View style={[{flexDirection: 'row'}, !isMobile && {marginTop: 10}]}>
              <Icon name={'sales'} size={isMobile ? 8 : 10} color={'red'} style={{marginRight: 4, lineHeight: 14}}/>
              <Label size={isMobile ? 9 : 11} color={'red'} type={'bold'}>{t('ServiceCard.discount', {discount})}</Label>
            </View>}

            {/* Cashback ? */}
            {cashback && <View style={{flexDirection: 'row'}}>
              <Icon name={'cashback'} size={8} color={'red'} style={{marginRight: 4, lineHeight: 14}}/>
              <Label size={9} color={'red'} types={['bold', 'smallMargin']}>{t('ServiceCard.cashback', {cashback})}</Label>
            </View>}

          </View>

        </View>

        {/* Icon */}
        <View style={s.iconContainer}>
          <Image
            style={{width: iconSize[size] * 2, height: iconSize[size] * 2, borderRadius: iconSize[size] * 2}}
            source={iconURL && { uri: iconURL }}
          />
        </View>

      </View>
    </ViewShadow>
  )
}

// Memo
export default React.memo(ServiceCard, (prev, next) => {
 return prev.title === next.title &&
   prev.iconURL === next.iconURL &&
   prev.width === next.width &&
   prev.isFav === next.isFav &&
   prev.margin?.top === next.margin?.top &&
   prev.margin?.right === next.margin?.right &&
   prev.margin?.bottom === next.margin?.bottom &&
   prev.margin?.left === next.margin?.left
})

// PropsTypes
type PropsTypes = {
  style?: any,
  styles?: any,
  title: string,
  iconURL: string,
  color: string,
  size?: 'M' | 'L' | 'XL',
  width?: number,
  margin?: any,
  isFav?: boolean,
  discount?: number,
  cashback?: number,
  onPress?: function,
  displayNonFavoriteIcon?: boolean
}