// @flow
import React, { type Node } from 'react'
import { View } from 'react-native'
import { Trans, useTranslation } from 'react-i18next'
import { formatPrice } from 'src/utils/FormatDataUtils'
import useScreenSizes from 'src/utils/useScreenSizes'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import ViewShadow from 'src/components/common/atoms/ViewShadow'
import s from './PaymentSection.style'

export default function PaymentSection ({
  payableType = 'BASKET',
  amount,
  creditsBalance,
  recurringCreditCards,
  setSelectedCard,
  setModalStep,
  modalSteps,
  isBasketPayable = true
}: PropsTypes): Node {

  const { t } = useTranslation()
  const { isMobile, isTablet, isDesktop, width } = useScreenSizes()
  const isCustomTablet = 768 <= width && width < 1024

  return (
    <ViewShadow
      shadowOffset={{ width: 0, height: 0 }}
      shadowRadius={8}
      style={[s.paymentContainer, !isMobile && s.paymentContainerDesktop, isCustomTablet && s.paymentContainerTablet]}
    >
      <View style={[s.paymentHeader, !isMobile && s.paymentHeaderDesktop]}>
        <Label size={!isMobile ? 20 : 14}>{t('BasketScreen.payment.title')}</Label>

        <Label style={s.toPay}>
          <Trans
            i18nKey={'BasketScreen.payment.toPay'}
            components={{ b: <Label type={'bold'}/> }}
            values={{ amount: formatPrice(amount) }}
          />
        </Label>
      </View>

      <View>
        {amount === 0 && (
          <Button
            fullWidth
            palette={'primary'}
            size={'M'}
            style={[s.paymentButton, !isMobile && s.paymentButtonDesktop]}
            styles={isTablet && { touchableOpacity: { paddingLeft: 0, paddingRight: 0 }, label: { fontSize: 11 } }}
            onPress={() => setModalStep(modalSteps.FREE_BASKET)}
            disabled={!isBasketPayable}
          >
            {payableType === 'BASKET' ? t('BasketScreen.payment.free') : t('BasketScreen.payment.freeOrder')}
          </Button>
        )}

        {amount !== 0 && creditsBalance > 0 && (
          <Button
            fullWidth
            palette={'primary'}
            size={'M'}
            style={[s.paymentButton, !isMobile && s.paymentButtonDesktop]}
            styles={isTablet && { touchableOpacity: { paddingLeft: 0, paddingRight: 0 }, label: { fontSize: 11 } }}
            onPress={() => setModalStep(modalSteps.WALLET)}
            disabled={!isBasketPayable}
          >
            {t('BasketScreen.payment.wallet')}
          </Button>
        )}
        {amount !== 0 && recurringCreditCards.map(card => (
          <Button
            key={card.cardNumber}
            fullWidth
            palette={'primary'}
            size={'M'}
            style={[s.paymentButton, !isMobile && s.paymentButtonDesktop]}
            styles={isTablet && { touchableOpacity: { paddingLeft: 0, paddingRight: 0 }, label: { fontSize: 11 } }}
            onPress={() => {
              setSelectedCard(card)
              setModalStep(modalSteps.RECURRING_CARD)
            }}
            disabled={!isBasketPayable}
          >
            {t('BasketScreen.payment.savedCard', { number: card?.cardNumber })}
          </Button>
        ))}
        {amount !== 0 && (
          <Button
            fullWidth
            size={'M'}
            style={[s.paymentButton, !isMobile && s.paymentButtonDesktop]}
            styles={isTablet && { touchableOpacity: { paddingLeft: 0, paddingRight: 0 }, label: { fontSize: 11 } }}
            onPress={() => setModalStep(modalSteps.NEW_CARD)}
            disabled={!isBasketPayable}
          >
            {t('BasketScreen.payment.card')}
          </Button>
        )}
      </View>
    </ViewShadow>
  )
}

type PropsTypes = {
  amount: number,
  creditsBalance: number,
  recurringCreditCards: any,
  setSelectedCard: function,
  setModalStep: function,
  modalSteps: any,
  isBasketPayable?: boolean
}