// @flow
import React, { type Node, useState } from 'react'
import { FlatList, View } from 'react-native'
import { useQuery } from 'relay-hooks'
import { useTranslation } from 'react-i18next'
import SkeletonContent from 'react-native-skeleton-content-nonexpo'
import useScreenSizes from 'src/utils/useScreenSizes'
import { useFocusScreenEffect } from 'src/utils/ComponentUtils'
import ProfileOrderCard from 'src/components/common/moles/ProfileOrderCard'
import Label from 'src/components/common/atoms/Label'
import s, { listSkeletonLayout } from './ProfileOrdersScreen.style'

/**
 * FinishedOrdersList component.
 */
export default function FinishedOrdersList ({
  handleOrderDetails
}: PropsTypes): Node {

  // Init vars
  const [refetchKey, setRefetchKey] = useState(new Date())
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()

  // API call to retrieve finished orders
  const { data, isLoading } = useQuery(graphql`
    query FinishedOrdersListQuery {
      finishedOrders {
        id
        rowId
        amount
        serviceType
        status
        statusName
        paymentStatus
        paymentDateTime
        requiredCollectAndDelivery
        collectDate
        deliveryDate
        allowedCustomerActions
        primeCustomerAction
        service {
          id
          name
          pictureURL
        }
      }
    }
  `, {}, {
    fetchPolicy: 'store-and-network',
    fetchKey: refetchKey,
    networkCacheConfig: {
      force: true,
    },
  })
  const finishedOrders = data?.finishedOrders

  // Refresh on focus screen
  useFocusScreenEffect(() => {
    setRefetchKey(new Date())
  })

  // Render list
  return (
    <SkeletonContent
      isLoading={!finishedOrders}
      containerStyle={[s.listSkeletonContainer, !finishedOrders && s.listSkeletonContainerActive]}
      layout={listSkeletonLayout}
    >
      <FlatList
        style={[s.list]}
        contentContainerStyle={{ flexGrow: 1, paddingHorizontal: 20, paddingBottom: 20 }}
        keyExtractor={item => item.id}
        numColumns={isMobile ? 1 : 2}
        key={isMobile ? '1' : '2'}
        columnWrapperStyle={!isMobile && { flex: 1 }}
        data={finishedOrders}
        onRefresh={() => setRefetchKey(new Date())}
        refreshing={isLoading}
        renderItem={({item: order, index}) => (
          <>
            <ProfileOrderCard
              id={order.rowId}
              status={order.status}
              collectDate={order.collectDate}
              serviceName={order.service.name}
              primeAction={order.primeCustomerAction}
              amount={order.amount}
              handlePress={handleOrderDetails}
              handleAction={handleOrderDetails}
              style={[{ marginBottom: 10 }, !isMobile && { flex: 0.5, marginHorizontal: 10 }]}
            />

            {!isMobile
              && index === finishedOrders.length - 1
              && finishedOrders.length % 2 !== 0
              && <View style={{ width: 20 }}/>
            }
          </>
        )}
        ListEmptyComponent={(
          <View style={s.emptyListContainer}>
            <Label style={s.emptyListMessage}>{t('FinishedOrdersList.emptyList')}</Label>
          </View>
        )}
      />
    </SkeletonContent>
  )
}

// PropsTypes
type PropsTypes = {
  handleOrderDetails: function
}