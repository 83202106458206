import { StyleSheet, Platform } from 'react-native'
import { widthPercentageToDP as wp } from 'react-native-responsive-screen'
import { Colors } from 'src/styles'

// Styles
export default StyleSheet.create({
  mainContainer: {
    width: wp('100%'),
    maxWidth: 500,
    alignItems: Platform.OS === 'web' ? 'center' : 'stretch',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20,
  },
  mainContainerDesktop: {
    maxWidth: '100%',
    width: '60%'
  },
  mainContainerTablet: {
    maxWidth: '100%',
    width: '80%'
  },
  formContainer: {
    width: '100%',
    justifyContent: 'center',
    marginTop: 15
  },
  formContainerDesktop: {
    marginTop: 30
  },
  switchContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20
  },
  buttonContainer: {
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  textInput: {
    marginTop: 15,
  },
  textInputDesktop: {
    marginTop: 10,
  },
  error: {
    marginTop: 5,
    marginLeft: 30,
    marginBottom: -9,
    color: Colors.red100
  },
  errorDesktop: {
    marginTop: 10,
    marginLeft: 65,
    marginBottom: -10
  },
  generalError: {
    textAlign: 'center',
    marginTop: 20,
    marginBottom: -10,
    marginLeft: 0
  },
  switchLabel: {
    paddingLeft: 15,
    flex: 1
  },
  switchLink: {
    fontSize: 10,
    textDecorationLine: 'underline',
    ...Platform.select({
      web: {
        cursor: 'pointer',
      }
    })
  },
  switchLinkDesktop: {
    fontSize: 18
  },
  actionButton: {
    width: '50%',
    marginTop: 20
  },
  loginButton: {
    position: 'absolute',
    bottom: 15
  }
})
