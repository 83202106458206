import * as ImagePicker from 'expo-image-picker'

/**
 * Open image picker.
 */
export async function openImagePickerAsync() {

  // Permission request for mobile
  let permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync()
  if (permissionResult.granted === false) {
    // TODO: better handle this case ?
    alert('Permission to access camera roll is required!')
    return
  }

  // Launch image picker and return promise
  return await ImagePicker.launchImageLibraryAsync({
    allowsEditing: true,
    base64: true
  })

}
