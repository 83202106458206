// @flow
import React, { type Node } from 'react'
import { View } from 'react-native'
import useScreenSizes from 'src/utils/useScreenSizes'
import Icon from 'src/components/common/atoms/Icon'
import Label from 'src/components/common/atoms/Label'
import ViewShadow from 'src/components/common/atoms/ViewShadow'
import s, { textSize } from './QornerCard.style.js'

/**
 * QornerCard mole.
 */
export default function QornerCard ({
  style,
  styles,
  qorner,
  onPress,
  type = 'default'
}: PropsTypes): Node {

  // Init
  const { isMobile } = useScreenSizes()

  return (
    <ViewShadow
      onPress={() => onPress(qorner)}
      style={[
        s.size,
        s.container,
        style,
        styles?.container
      ]}
    >
      <View style={[s.cardContainer, styles?.cardContainer]}>
        <View style={s.textContainer}>

          {/* Qorner Title */}
          <Label
            numberOfLines={1}
            type={type === 'primary' ? 'bold' : 'default'}
            size={textSize.title}
            style={[
              type === 'primary' && s.primaryTitle,
              s.title
            ]}>
              {qorner.name}
          </Label>

          {/* Qorner Address */}
          <Label size={textSize.address}>{qorner.address}</Label>

        </View>

        {/* Qorner Icon */}
        {isMobile && (
          <View style={s.iconContainer}>
            <Icon style={[s.icon, styles?.icon]} name={'back'} size={10}/>
          </View>
        )}

      </View>
    </ViewShadow>
  )
}

// PropsTypes
type PropsTypes = {
  style?: any,
  styles?: any,
  qorner: any,
  type?: 'primary' | 'default'
}