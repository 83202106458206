// @flow
import React, { type Node } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import SkeletonContent from 'react-native-skeleton-content-nonexpo'
import ProfileCard from 'src/components/common/moles/ProfileCard/ProfileCard'
import Button from 'src/components/common/atoms/Button'
import Icon from 'src/components/common/atoms/Icon'
import Label from 'src/components/common/atoms/Label'
import {Colors, Sizes} from 'src/styles'
import s from './ProfileEstimateCard.style'
import { ESTIMATE_SUBMIT_STATUS } from 'src/components/common/moles/EstimateModal/constants'
import moment from 'moment'

/**
 * ProfileEstimateCard mole.
 */
export default function ProfileEstimateCard ({
  style,
  styles,
  handlePress,
  isLoading = false,
  estimate
}: PropsTypes): Node {

  // Init state
  const { t } = useTranslation()

  // Actions buttons
  const action = estimate.primeCustomerAction === 'accept' ?
    (<Button
      size={'S'}
      palette={'primary'}
      style={[styles?.validateButton]}
      fontStyle={'bold'}
      Icon={<Icon name={'edit'} color={'#fff'}/>}
      onPress={() => handlePress(estimate)}
    >
      {t('ProfileEstimateCard.' + estimate.primeCustomerAction)}
    </Button>)
    : (<Label
      size={11}
      fontStyle={'bold'}
      color={Colors.red100 }
    >
      {t('ProfileEstimateCard.processing')}
    </Label>)

  const getEstimateCardContent = () => {
    if (estimate.estimateSubmitStatus === ESTIMATE_SUBMIT_STATUS.STATUS_WAITING_FOR_PROVIDER_SUBMISSION) {
      return t('EstimateModal.status.processing', { serviceName: estimate.serviceName.toLowerCase() })
    } else if (estimate.estimateSubmitStatus === ESTIMATE_SUBMIT_STATUS.STATUS_SUBMITTED) {
      return t('EstimateModal.status.submitted', {
        serviceName: estimate.serviceName.toLowerCase(),
        date: moment(estimate.estimateSubmitDateTime).format('dddd DD MMMM')
      })
    }
  }

  const actionSkeleton = (<SkeletonContent
    containerStyle={{}}
    isLoading={true}
    layout={[s.actionSkeleton]}
  />)

  // Render mole
  return (
    <ProfileCard
      style={style}
      styles={styles}
      type={'estimate'}
      id={estimate.rowId}
      title={t('ProfileEstimateCard.title', { id: estimate.rowId })}
      action={isLoading ? actionSkeleton : action}
      primaryContent={getEstimateCardContent()}
      handlePress={() => handlePress(estimate)}
      isLoading={isLoading}
    />
  )
}

// PropsTypes
type PropsTypes = {
  style?: any,
  styles?: any,
  handlePress: function,
  isLoading?: boolean,
  estimate: any
}