import { StyleSheet } from 'react-native'

// Styles
export default StyleSheet.create({

  // Header
  shadowContainer: {
    width: '100%',
    borderRadius: 15,
    backgroundColor: '#fff'
  },
  imageBackground: {
    height: '100%',
    width: '100%',
    paddingRight: 15,
    paddingLeft: 15,
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flexDirection: 'row',
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15
  },
  image: {
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15
  },
  imageLegend: { zIndex: 2, marginBottom: 5 },
  linearGradient: {
    width:'100%',
    height: '100%'
  },
  gradientContainer: {
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom:0,
    overflow:'hidden'
  },

  // Main Content
  cardButtonContainer: {
    flexDirection: 'row',
    paddingLeft: 15,
    paddingRight: 10,
    justifyContent: 'flex-end'
  },
  serviceIcon: {
    width: 30,
    height: 30,
    borderRadius: 30,
    marginBottom: -15
  },
  detailsContainer: {
    borderBottomLeftRadius: 15, borderBottomRightRadius: 15,
    overflow: 'hidden'
  },
  detailsButton: {
    height: 20,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonIcon: { marginLeft: 5 },
  details: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 15,
    paddingBottom: 15
  },

  // Favorite
  favoriteContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 15
  },
  favoriteLabel: { marginLeft: 10 }

})

// Gradient colors
export const gradientColors = ['rgba(51, 51, 51, 0)', 'rgba(51, 51, 51, 0.5)']