// @flow
import React, { type Node, useRef, useEffect } from 'react'
import { Animated } from 'react-native'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { formatPrice } from 'src/utils/FormatDataUtils'
import ProfileCard from 'src/components/common/moles/ProfileCard'
import Button from 'src/components/common/atoms/Button'
import Icon from 'src/components/common/atoms/Icon'
import Label from 'src/components/common/atoms/Label'
import {Colors, Sizes} from 'src/styles'
import s from './ProfileOrderCard.style'

/**
 * ProfileOrderCard mole.
 */
export default function ProfileOrderCard({
  style,
  styles,
  id,
  status,
  serviceName,
  collectDate,
  pictureURL = null,
  primeAction = null,
  amount = null,
  handleAction,
  handlePress,
  shouldFadeOut = false,
  fadeOutDone = () => {}
}: PropsTypes): Node {
  // Init state
  const { t } = useTranslation()
  const fadeOutAnim = useRef(new Animated.Value(1)).current
  const reduceHeightAnim = useRef(new Animated.Value(160)).current

  // useEffect for fade out animation
  useEffect(() => {
    if (shouldFadeOut) {
      Animated.timing(fadeOutAnim, {
        toValue: 0,
        duration: 800,
        useNativeDriver: false
      }).start()
      Animated.timing(reduceHeightAnim, {
        toValue: 0,
        duration: 800,
        useNativeDriver: false
      }).start(({ finished }) => { finished && fadeOutDone() })
    }
  }, [shouldFadeOut])

  // Action button : pay, rate, collect, delivery
  let action = null
  if (primeAction) {
    action = <Button
      size={'S'}
      palette={'primary'}
      style={[s.actionBtn, styles?.actionBtn]}
      fontStyle={'bold'}
      Icon={['delivery', 'collect'].includes(primeAction) && <Icon name={'edit'} color={'#fff'}/>}
      onPress={() => handleAction && handleAction('order', id, primeAction)}
    >
      {t('ProfileOrderCard.' + primeAction, {amount: formatPrice(amount)})}
    </Button>
  } else {
    action = <Label
      size={11}
      types={['bold', 'smallMargin']}
      style={{color: Colors.orange100}}
    >
      {status < 29
        ? t('ProfileOrderCard.processing')
        : t('ProfileOrderCard.ended')}
    </Label>
  }

  // Render mole
  return (
        <Animated.View style={[{height: reduceHeightAnim, opacity: fadeOutAnim, flex: 1}, style]}>
          <ProfileCard
            styles={{container: {height: 150, paddingTop: 5}}}
            type={'order'}
            id={id}
            title={t('ProfileOrderCard.title', {id: id})}
            action={action}
            pictureURL={pictureURL}
            primaryContent={
              t('ProfileOrderCard.primaryContent', {
                serviceName,
                primaryContentAction: status < 29
                  ? t([
                    'ProfileOrderCard.primaryContentAction.' + primeAction,
                    'ProfileOrderCard.primaryContentAction.processing'
                  ])
                  : t('ProfileOrderCard.primaryContentAction.ended')
              })
            }
            secondaryContent={
              status < 29
              ? t([
                'ProfileOrderCard.secondaryContent.' + primeAction,
                'ProfileOrderCard.secondaryContent.processing'
              ], { collectDate: moment(collectDate).format('DD MMMM [avant] H[h]') })
              : t('ProfileOrderCard.secondaryContent.ended')
            }
            handlePress={handlePress}
          />
        </Animated.View>
  )
}

// PropsTypes
type PropsTypes = {
  style?: any,
  styles?: any,
  id: number,
  status: number,
  serviceName: string,
  collectDate: string,
  pictureURL?: string,
  primeAction?: string,
  amount?: number,
  handleAction?: function,
  handlePress: function,
  shouldFadeOut?: boolean,
  fadeOutDone?: function
}