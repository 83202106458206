/**
 * @generated SignedSource<<2eb329a7e0f2fe5361f3a87755eafbb1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "payableId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "payableType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "paymentReference"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "payableId",
        "variableName": "payableId"
      },
      {
        "kind": "Variable",
        "name": "payableType",
        "variableName": "payableType"
      },
      {
        "kind": "Variable",
        "name": "paymentReference",
        "variableName": "paymentReference"
      }
    ],
    "kind": "ScalarField",
    "name": "isPaid",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AdyenPaymentFormIsPaidQuery",
    "selections": (v3/*: any*/),
    "type": "ClientAppQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "AdyenPaymentFormIsPaidQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "78077632a7474c013cf772ee2de51842",
    "id": null,
    "metadata": {},
    "name": "AdyenPaymentFormIsPaidQuery",
    "operationKind": "query",
    "text": "query AdyenPaymentFormIsPaidQuery(\n  $payableType: PayableType!\n  $payableId: Int\n  $paymentReference: String\n) {\n  isPaid(payableType: $payableType, payableId: $payableId, paymentReference: $paymentReference)\n}\n"
  }
};
})();

node.hash = "491cdd944c3400a978f28f2c8dc64738";

module.exports = node;
