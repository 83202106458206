import { Platform, StyleSheet } from 'react-native'

export default StyleSheet.create({
  container: {
    paddingTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
    zIndex: Platform.OS === 'web' ? undefined : 1
  },
  codeContainer: {
    flexDirection: 'row',
    marginTop: 30,
    marginBottom: 30
  },
  codeContainerDesktop: {
    marginTop: 60,
    marginBottom: 60
  },

  desktopContainer: {
    maxWidth: 596,
    padding: 20
  },
  desktopTitle: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 30
  }
})