// @flow
import React, {type Node, useEffect, useState} from 'react'
import {FlatList, Image, TouchableWithoutFeedback, View} from 'react-native'
import {useTranslation} from 'react-i18next'
import {Formik} from 'formik'
import {useQuery} from 'relay-hooks'
import {type StackNavigationProp} from '@react-navigation/stack'
import {SafeAreaView} from 'react-native-safe-area-context'
import type {RoutesParamList} from 'src/components/Navigation'
import useScreenSizes from 'src/utils/useScreenSizes'
import Modal from 'src/components/common/atoms/Modal'
import Loader from 'src/components/common/atoms/Loader'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import TextInputFormik from 'src/components/common/forms/TextInputFormik'
import QornerCard from 'src/components/common/moles/QornerCard'
import ValidateQornerSelectionModal
    from 'src/components/screens/private/QornerChoiceScreen/ValidateQornerSelectionModal'
import Icon from 'src/components/common/atoms/Icon'
import {Sizes} from 'src/styles'
import s from './QornerChoiceScreen.style'

/**
 * QornerChoiceScreen screen.
 */
export default function QornerChoiceScreen({
                                               navigation,
                                               route
                                           }: PropsTypes): Node {

    // Init state
    const userTypes = {
        PUBLIC_USER: 'PUBLIC_USER',
        COMPANY_USER: 'COMPANY_USER'
    }
    const [userType, setUserType] = useState(userTypes.PUBLIC_USER)
    const [search, setSearch] = useState('')
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [selectedQorner, setSelectedQorner] = useState(null)
    const {t} = useTranslation()
    const {isMobile, isTablet, isDesktop, screenSize} = useScreenSizes()
    const isChangeQorner = route.params?.change === 'true';
    const isQornerPsExpired = route.params?.expired;
    const [isShowExpiredQornerAlert, setShowExpiredQornerAlert] = useState(false)

    // API Call
    const {data} = useQuery(graphql`
    query QornerChoiceScreenQuery {
      qorners {
        publicQorners {
          id
          rowId
          name
          logoURL
          address
          zipCode
          city
          qornerType
        }
        privateQorners {
          id
          rowId
          name
          logoURL
          address
          zipCode
          city
          qornerType
        }
        matchesDomainOfPrivateQorners
      }
    }
  `)
    const publicQorners = data?.qorners?.publicQorners
    const privateQorners = data?.qorners?.privateQorners
    const matchesDomainOfPrivateQorners = data?.qorners?.matchesDomainOfPrivateQorners

    // Set user type to company if API told us
    useEffect(() => {
        if (matchesDomainOfPrivateQorners === true) {
            setUserType(userTypes.COMPANY_USER)
        }
    }, [matchesDomainOfPrivateQorners])

    // Reset screen
    function resetScreen() {
        setSearch('')
        setModalIsOpen(false)
        setSelectedQorner(null)
    }

    // Loading state
    if (publicQorners === undefined) {
        return (
            <View style={s.loadingContainer}>
                <Loader/>
            </View>
        )
    }

    // Qorners list to display base on user type and search value
    let qornersListToDisplay = userType === userTypes.COMPANY_USER ? privateQorners : publicQorners
    qornersListToDisplay = qornersListToDisplay.filter(qorner => {
        return (
            qorner.name.toLowerCase().indexOf(search.toLowerCase()) >= 0 ||
            qorner.address.toLowerCase().indexOf(search.toLowerCase()) >= 0
        )
    })

    // Show modal with selected qorner
    const handleQornerSelection = qorner => {
        setSelectedQorner(qorner)
        setModalIsOpen(true)
    }

    // Select qorner by isQornerPsExpired param id
    var qorner = qornersListToDisplay.find(q => {
        return q.rowId === parseInt(isQornerPsExpired)
    })
    if (isShowExpiredQornerAlert === false && qorner) {
        handleQornerSelection(qorner)
        setShowExpiredQornerAlert(true)
    }

    // Render screen
    return (
        <SafeAreaView style={[s.container, isDesktop && s.containerDesktop]}>

            {/* Screen title and intro */}
            <View style={[s.sideMargin, isDesktop && s.sideMarginDesktop]}>
                <View style={s.backAndTitleContainer}>
                    {isChangeQorner && (
                        <View style={[s.backButtonContainer, !isMobile && s.backButtonContainerNotMobile]}>
                            <Button
                                Icon={<Icon name={'back'} size={Sizes.XM} style={s.backButtonIcon}/>}
                                style={s.backButton}
                                onPress={() => navigation.canGoBack() ? navigation.goBack() : navigation.navigate({
                                    name: 'Private',
                                    params: {screen: 'Home'}
                                })}
                            />
                        </View>
                    )}
                    <Label types={['bold', 'smallMargin']} size={isMobile ? 18 : isTablet ? 24 : 34}
                           style={s.mainTitle}>
                        {t(isChangeQorner ? 'QornerChoiceScreen.changeQornerTitle' : (isQornerPsExpired ? 'QornerChoiceScreen.expiredQornerTitle' : 'QornerChoiceScreen.chooseQornerTitle'))}
                    </Label>
                </View>
                <Label style={s.descriptionText} size={isMobile ? 12 : isTablet ? 18 : 25}>
                    {t(isChangeQorner ? 'QornerChoiceScreen.changeQornerIntro' : (isQornerPsExpired ? 'QornerChoiceScreen.expiredQornerIntro' : 'QornerChoiceScreen.chooseQornerIntro'))}
                </Label>
            </View>

            {/* Profile type choices */}
            {!userType ?
                <View
                    style={[s.sideMargin, isDesktop && s.sideMarginDesktop, !isMobile && s.profileTypeChoiceContainerNotMobile]}>
                    <Label type={'bold'} size={isMobile ? 15 : 25}
                           style={[s.chooseType, !isMobile && s.chooseTypeNotMobile]}>
                        {t('QornerChoiceScreen.iAm')}
                    </Label>
                    {isMobile && (
                        <View>
                            <Button
                                palette={'primary'}
                                onPress={() => {
                                    setUserType(userTypes.PUBLIC_USER)
                                }}
                                style={s.chooseTypeButton}
                                fontStyle={'bold'}
                            >
                                {t('QornerChoiceScreen.publicUser')}
                            </Button>
                            <Button
                                palette={'primary'}
                                onPress={() => {
                                    setUserType(userTypes.COMPANY_USER)
                                }}
                                style={s.chooseTypeButton}
                                fontStyle={'bold'}
                            >
                                {t('QornerChoiceScreen.companyUser')}
                            </Button>
                        </View>
                    )}
                    {!isMobile && (
                        <View style={[s.chooseTypeButtonContainer, isTablet && s.chooseTypeButtonContainerTablet]}>
                            <TouchableWithoutFeedback
                                onPress={() => {
                                    setUserType(userTypes.PUBLIC_USER)
                                }}
                            >
                                <View style={s.chooseTypeButtonNotMobile}>
                                    <Label style={s.chooseTypeButtonLabel}>{t('QornerChoiceScreen.publicUser')}</Label>
                                    <Image source={require('assets/img/common/public_icon.png')} resizeMode={'contain'}
                                           style={s.publicUserIcon}/>
                                </View>
                            </TouchableWithoutFeedback>
                            <TouchableWithoutFeedback
                                onPress={() => {
                                    setUserType(userTypes.COMPANY_USER)
                                }}
                            >
                                <View style={s.chooseTypeButtonNotMobile}>
                                    <Label style={s.chooseTypeButtonLabel}>{t('QornerChoiceScreen.companyUser')}</Label>
                                    <Image source={require('assets/img/common/building_icon.png')}
                                           resizeMode={'contain'} style={s.companyUserIcon}/>
                                </View>
                            </TouchableWithoutFeedback>
                        </View>
                    )}
                </View>
                :
                <>
                    {/* Profile type choice */}
                    <View style={[s.sideMargin, isDesktop && s.sideMarginDesktop]}>

                        {/* Change profil type */}
                        {matchesDomainOfPrivateQorners && (
                            <View style={[s.changeType, !isMobile && s.changeTypeNotMobile]}>
                                <Label size={isMobile ? 12 : isTablet ? 18 : 25} type={'bold'}>
                                    {userType === userTypes.COMPANY_USER ?
                                        t('QornerChoiceScreen.iAmCompany') :
                                        t('QornerChoiceScreen.iAmPublic')}
                                </Label>
                                <Button
                                    size={isMobile ? 'S' : 'M'}
                                    onPress={() => {
                                        setUserType(null)
                                        resetScreen()
                                    }}
                                    fontStyle={'bold'}
                                    style={!isMobile && s.changeTypeButtonNotMobile}
                                >
                                    {t('QornerChoiceScreen.change')}
                                </Button>
                            </View>
                        )}

                        {/* Search Qorners form */}
                        <Formik initialValues={{search: search}}
                                onSubmit={() => {
                                }}
                        >
                            {(formikProps) => (
                                <TextInputFormik {...formikProps} name={'search'} icon={'search'} size={'L'}
                                                 placeholder={t('QornerChoiceScreen.search')}
                                                 style={[s.searchInput, !isMobile && s.searchInputNotMobile]}
                                                 onChangeText={value => setSearch(value)}
                                />
                            )}
                        </Formik>

                        {/* Known email info */}
                        {userType === userTypes.COMPANY_USER && (
                            <Label size={12} style={[s.knownMailQorners, isDesktop && s.knownMailQornersDesktop]}>
                                <Label
                                    size={isMobile ? 12 : 15}>{t('QornerChoiceScreen.knownMailQornersPart1', {'count': privateQorners.length})}</Label>
                                <Label size={isMobile ? 12 : 15}
                                       types={['bold', 'smallMargin']}> {privateQorners.length} </Label>
                                <Label
                                    size={isMobile ? 12 : 15}>{t('QornerChoiceScreen.knownMailQornersPart2', {'count': privateQorners.length})}</Label>
                            </Label>
                        )}

                    </View>

                    <View style={[s.resultsContainer, !isMobile && s.resultsContainerNotMobile]}>

                        {/* List of Qorners */}
                        <FlatList
                            style={!isMobile && {width: 0}}
                            contentContainerStyle={[s.flatListContainer, !isMobile && s[`flatListContainer${screenSize}`]]}
                            data={qornersListToDisplay}
                            extraData={search}
                            keyExtractor={item => item.id}
                            numColumns={!isDesktop ? 1 : 2}
                            key={!isDesktop ? '1' : '2'}
                            ItemSeparatorComponent={() => (<View style={isMobile ? s.rowSpace : s.rowSpaceDesktop}/>)}
                            renderItem={({item}) => {
                                return (
                                    <View style={isDesktop && s.qornerCardContainerNotMobile}>
                                        <QornerCard
                                            onPress={handleQornerSelection}
                                            qorner={item}
                                            type={userType === userTypes.COMPANY_USER ? 'primary' : 'default'}
                                        />
                                    </View>
                                )
                            }}
                        />

                        {/* Selected qorner for Desktop view */}
                        {!isMobile && (
                            <View style={s.validateQornerSelectionDesktop}>
                                {modalIsOpen && (
                                    <ValidateQornerSelectionModal
                                        selectedQorner={selectedQorner}
                                        mustCertify={userType === userTypes.COMPANY_USER}
                                        isQornerPsExpired={isQornerPsExpired}
                                        navigation={navigation}
                                        resetScreen={resetScreen}
                                        onClose={() => setModalIsOpen(false)}
                                    />
                                )}
                            </View>
                        )}

                    </View>

                </>
            }

            {/* Modal to validate qorner selection */}
            <Modal open={modalIsOpen && isMobile} onClose={() => setModalIsOpen(false)}>
                <ValidateQornerSelectionModal
                    selectedQorner={selectedQorner}
                    mustCertify={userType === userTypes.COMPANY_USER}
                    isQornerPsExpired={isQornerPsExpired}
                    navigation={navigation}
                    resetScreen={resetScreen}
                />
            </Modal>

        </SafeAreaView>
    )
}

// PropsTypes
type PropsTypes = {
    navigation: StackNavigationProp<RoutesParamList, 'QornerChoice'>,
    route: any
}
