// @flow
import React, { type Node, useRef, useState } from 'react'
import { Image, Platform, View } from 'react-native'
import { Trans, useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useMutation, useQuery } from 'relay-hooks'
import useScreenSizes from 'src/utils/useScreenSizes'
import Icon from 'src/components/common/atoms/Icon'
import Label from 'src/components/common/atoms/Label'
import Loader from 'src/components/common/atoms/Loader'
import Button from 'src/components/common/atoms/Button'
import TextInputFormik from 'src/components/common/forms/TextInputFormik'
import { Colors } from 'src/styles'
import s from './NeedCoverModal.style'

export default function NeedCoverModal ({
  navigation
}: PropsTypes): Node {

  // Init values
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()
  const formRef: any = useRef()
  const [success, setSuccess] = useState(false)

  // Form validation schema
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().min(1, t('Yup.string.min', { length: 1 })).required(t('Yup.mixed.required')),
    lastName: Yup.string().min(1, t('Yup.string.min', { length: 1 })).required(t('Yup.mixed.required')),
    phoneNumber: Yup.string().matches(/^(0)[1-9]( \d{2}){4}$/, t('ProfilePersonalInfoScreen.errors.wrongPhoneFormat')).required(t('Yup.mixed.required')),
  })

  const { data } = useQuery(
    graphql`
      query NeedCoverModalQuery {
        user {
          id
          firstName
          lastName
          phoneNumber
          lang
        }
      }
    `
  )
  const user = data?.user

  // API Call to update personal infos
  const [updateUserMutation, { loading }] = useMutation(
    graphql`
      mutation NeedCoverModalMutation($firstName: String!, $lastName: String!, $phoneNumber: String!, $lang: UserLang!) {
        UpdateUserInfos(firstName: $firstName, lastName: $lastName, phoneNumber: $phoneNumber, lang: $lang) {
          id
          firstName
          lastName
          phoneNumber
          lang
        }
      }
    `,
    {
      onCompleted: () => setSuccess(true),
      onError: () => {
        // Handle all error as global error because it's not supposed to happen ?
        formRef.current.setFieldError('general', t('Yup.global.error'))
      }
    },
  )

  if (!user || loading) {
    return (
      <View>
        <Label size={!isMobile ? 28 : 14}>{t('NeedCoverModal.title')}</Label>
        <Loader size={20} style={s.loaderStep}/>
      </View>
    )
  }

  if (success) {
    return (
      <View>
        <Label size={!isMobile ? 28 : 14}>{t('NeedCoverModal.title')}</Label>
        <Icon name={'check'} color={Colors.orange100} style={s.successStep}/>
      </View>
    )
  }

  return (
    <View>
      <Label style={[s.title, !isMobile && s.titleDesktop]} size={!isMobile ? 28 : 14}>{t('NeedCoverModal.title')}</Label>
      <Label size={isMobile ? 10 : 20} style={s.content}>
        <Trans
          i18nKey={'NeedCoverModal.content'}
          components={{ b: <Label type={'bold'} size={isMobile ? 10 : 20}/> }}
        />
      </Label>

      {/* Personal infos form */}
      <Formik
        innerRef={formRef}
        initialValues={{
          firstName: user?.firstName,
          lastName: user?.lastName,
          phoneNumber: user?.phoneNumber,
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => updateUserMutation({ variables: { ...values, lang: user.lang.toUpperCase() } })}
      >
        {(formikProps) => (
          <>

            {/* Fields */}
            <View style={s.formContainer}>

              {/* Firstname */}
              <View style={s.formRow}>
                <View style={s.textInputContainer}>
                  <TextInputFormik
                    size={isMobile ? 'S' : 'L'}
                    style={!isMobile && s.textInputDesktop}
                    name={'firstName'}
                    placeholder={t('NeedCoverModal.firstName')}
                    hasError={formikProps.errors.firstName && formikProps.touched.firstName}
                    autoCompleteType={Platform.OS === 'web' ? 'given-name' : undefined}
                    textContentType={'givenName'}
                    icon={'profile'}
                    onSubmitEditing={formikProps.handleSubmit}
                    outsideIcon={true}
                    iconSize={isMobile ? 18 : 36}
                    {...formikProps}
                  />
                  {formikProps.errors.firstName && formikProps.touched.firstName && (
                    <Label types={['alert', 'error']} size={isMobile ? 10 : 12}>{formikProps.errors.firstName}</Label>
                  )}
                </View>
              </View>

              {/* Lastname */}
              <View style={s.formRow}>
                <View style={s.textInputContainer}>
                  <TextInputFormik
                    size={isMobile ? 'S' : 'L'}
                    style={!isMobile && s.textInputDesktop}
                    name={'lastName'}
                    placeholder={t('NeedCoverModal.lastName')}
                    hasError={formikProps.errors.lastName && formikProps.touched.lastName}
                    autoCompleteType={Platform.OS === 'web' ? 'family-name' : undefined}
                    textContentType={'familyName'}
                    icon={'profile'}
                    onSubmitEditing={formikProps.handleSubmit}
                    outsideIcon={true}
                    iconSize={isMobile ? 18 : 36}
                    {...formikProps}
                  />
                </View>
                {formikProps.errors.lastName && formikProps.touched.lastName && (
                  <Label types={['alert', 'error']} size={isMobile ? 10 : 12}>{formikProps.errors.lastName}</Label>
                )}
              </View>

              {/* Phone */}
              <View style={s.formRow}>
                <View style={s.textInputContainer}>
                  <TextInputFormik
                    size={isMobile ? 'S' : 'L'}
                    style={!isMobile && s.textInputDesktop}
                    name={'phoneNumber'}
                    placeholder={t('NeedCoverModal.phone')}
                    hasError={formikProps.errors.phoneNumber && formikProps.touched.phoneNumber}
                    mask={'00 00 00 00 00'}
                    autoCompleteType={'tel'}
                    textContentType={'telephoneNumber'}
                    icon={'phone'}
                    onSubmitEditing={formikProps.handleSubmit}
                    outsideIcon={true}
                    iconSize={isMobile ? 18 : 36}
                    {...formikProps}
                  />
                  {formikProps.errors.phoneNumber && formikProps.touched.phoneNumber && (
                    <Label types={['alert', 'error']} size={isMobile ? 10 : 12}>{formikProps.errors.phoneNumber}</Label>
                  )}
                </View>
              </View>
            </View>

            {/* General error */}
            {formikProps.errors.general && (
              <Label types={['alert', 'error']} size={isMobile ? 10 : 12}>{formikProps.errors.general}</Label>
            )}

            {/* Submit & Cancel buttons */}
            <View style={[s.buttonContainer, !isMobile && s.buttonContainerDesktop]}>
              <Button
                fullWidth
                size={isMobile ? 'M' : 'L'}
                style={[s.button, !isMobile && s.buttonDesktop]}
                onPress={formikProps.handleSubmit}
                palette={'primary'}
                // isLoading={loading}
                disabled={!(formikProps.isValid && formikProps.dirty)}
              >
                {t('App.validate')}
              </Button>
              <Button
                fullWidth
                size={isMobile ? 'M' : 'L'}
                onPress={()=>navigation.navigate('Home')}
              >
                {t('App.goBack')}
              </Button>
            </View>
          </>
        )}
      </Formik>

      <View style={s.protectedBy}>
        <Label size={9}>{t('NeedCoverModal.protectedBy')}</Label>
        <Image
          style={s.protectedByLogo}
          resizeMode={'contain'}
          source={require('assets/img/screens/private/shoppingTunnel/CoverDepositModal/generali_logo.png')}
        />
      </View>
    </View>
  )
}

type PropsTypes = {
  navigation: any
}