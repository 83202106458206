import { StyleSheet, Platform } from 'react-native'
import { Colors } from 'src/styles'

// Styles
export default StyleSheet.create({
  privateLayout: {
    flexDirection: 'column',
    backgroundColor: 'white',
    width: '100%',
    height: '100%'
  },
  header: {
    width: '100%',
    height: 50,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  headerNotMobile: {
    width: '100%',
    height: 50,
    flexDirection: 'row',
    alignItems: 'center',
  },
  headerCenter: {
    flex: 8,
    flexDirection: 'row',
    alignItems: 'center'
  },
  headerRight: {
    marginRight: 10
  },
  headerTitleContainer: {
    marginLeft: 10,
    marginRight: 10,
    flex: 8
  },
  headerTitle: {
    lineHeight: 21,
    color: Colors.darkGrey100,
  },
  backButtonContainer: {
    width: 30,
    marginLeft: 10,
    height: 50,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    justifyContent: 'center'
  },
  backButtonContainerNotMobile: {
    marginLeft: 0
  },
  backButtonNotMobile: {
    ...Platform.select({
      native: {
        borderWidth: 0
      },
      web: {
        border: 'none'
      }
    }),
    backgroundColor: 'transparent'
  },
  backButtonIconNotMobile: {
    color: Colors.darkGrey100
  },
  backButton: {
    width: 30,
    height: 30,
    lineHeight: 30,
    fontSize: 30,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: 60,
    borderWidth: 0,
    backgroundColor: Colors.lightGrey90
  },
  backButtonIcon: {
    color: Colors.mediumGrey100
  },
  headerBackTitle: {
    marginLeft: 10,
    marginRight: 20
  },
  searchForm: {
    flex: 1
  },
  headerBasket: {
    width: 30,
    height: 30,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    borderRadius: 30,
    borderWidth: 0,
    backgroundColor: Colors.lightGrey90,
  },
  basketButtonIcon: {
    color: Colors.mediumGrey100
  },
  logo: {
    width: 230,
    height: 60,
    resizeMode: 'contain',
  },
  mainContent: {
    flex: 1,
    zIndex: Platform.OS === 'web' ? 'auto' : undefined
  },
  subHeader: {
    position: 'relative',
    width: '100%',
    height: 50,
    paddingLeft: 13,
    paddingRight: 13,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: Colors.lightGrey80,
    zIndex: 1,
    marginTop: 5
  },
  subHeaderBackButtonIcon: {
    fontWeight: 'bold',
    color: Colors.darkGrey100
  },
  subHeaderBackButton: {
    width: 20,
    height: 20,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    borderWidth: 0,
    backgroundColor: 'transparent'
  },
  subHeaderTitleContainer: {
    marginLeft: 14,
    flexDirection: 'row',
    alignItems: 'center'
  },
  subHeaderIcon: {
    marginRight: 8,
    color: Colors.mediumGrey100
  },
  subHeaderTitle: {
    fontSize: 14,
    color: Colors.darkGrey100
  },
  homeButton: {
    width: 30,
    marginLeft: 20,
    marginRight: 10,
    ...Platform.select({
      native: {
        borderWidth: 0
      },
      web: {
        border: 'none'
      }
    }),
    backgroundColor: 'transparent'
  },
  homeIcon: {
    fontSize: 25,
    color: Colors.darkGrey100Less25
  },
  profileButton: {
    flexDirection: 'row',
    ...Platform.select({
      native: {
        borderWidth: 0
      },
      web: {
        border: 'none',
        cursor: 'pointer'
      }
    }),
    backgroundColor: 'transparent'
  },
  profileAvatar: {
    position: 'relative',
    top: 10
  },
  profileLabel: {
    lineHeight: 50,
    marginLeft: 5
  }
})