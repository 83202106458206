import { StyleSheet } from 'react-native'

// Styles
export default StyleSheet.create({
  loader: {
    marginTop: 50,
    marginBottom: 50
  },
  image: {
    height: 130,
    borderRadius: 5,
    marginTop: 10,
    marginBottom: 10
  },
  imageDesktop: {
    height: 260,
    marginTop: 20,
    marginBottom: 40
  },
  date: {
    marginTop: 10,
    alignSelf: 'flex-end'
  },
  buttonsContainer: {
    marginLeft: 35,
    marginRight: 35,
    marginTop: 20,
    marginBottom: 5
  },
  customButton: {
    marginBottom: 10
  },
  customButtonNotMobile: {
    width: 'auto',
    minWidth: 200,
    marginBottom: 20
  },
  desktopButtonsContainer: {
    marginTop: 40
  },
})