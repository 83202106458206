// @flow
import React, { type Node, useEffect, useState } from 'react'
import { FlatList, View } from 'react-native'
import { useRelayEnvironment } from 'relay-hooks'
import { fetchQuery, graphql } from 'relay-runtime'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import type { RoutesParamList } from 'src/components/Navigation'
import useScreenSizes from 'src/utils/useScreenSizes'
import PrivateLayout from 'src/components/common/layout/PrivateLayout'
import Loader from 'src/components/common/atoms/Loader'
import NewsPreview from 'src/components/common/moles/NewsPreview'
import Modal from 'src/components/common/atoms/Modal'
import Icon from 'src/components/common/atoms/Icon'
import Label from 'src/components/common/atoms/Label'
import PracticalInformationsModal from './PracticalInformationsModal'
import s from './PracticalInformationsScreen.style'

/**
 * PracticalInformationsScreen.
 */
export default function PracticalInformationsScreen ({ navigation, route }: PropsTypes): Node {

  // Init values
  const { t } = useTranslation()
  const { isMobile, width: fullWidth, isTablet } = useScreenSizes()
  const [selectedInfoId, setSelectedInfoId] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [lastPageNumber, setLastPageNumber] = useState(1)
  const [informations, setInformations] = useState([])
  const relayEnvironment = useRelayEnvironment()
  const itemHeight = 100
  const cardWidth = isMobile ? fullWidth - 30 : isTablet ? (fullWidth - 260) / 3 - 20 : (fullWidth - 390) / 3 - 20

  // Opens the modal if the user come from the click on an info in HomeScreen
  useEffect(() => {
    setSelectedInfoId(route.params?.infoId)
  }, [route.params?.infoId])

  // Retrieve informations query
  const query = graphql`
    query PracticalInformationsScreenQuery($page: Int) {
      user {
        id
        lang
      }
      informations (page: $page) {
        items {
          id
          rowId
          title
          mainPictureUrl
          statusUpdatedAt
        }
        totalCount
      }
    }
  `

  // Retrieve information at init and when current page change
  useEffect(() => {
    fetchQuery(relayEnvironment, query, { page: currentPage })
      .toPromise()
      .then(data => {
        setInformations([...informations, ...data.informations.items])
        setLastPageNumber(data?.informations.totalCount ? Math.ceil(data.informations.totalCount / 5) : 1)
        moment.locale(data.user?.lang)
      })
  }, [currentPage])

  // Render Loading Screen if no data is fetched yet
  if (!informations) {
    return (
      <PrivateLayout headerTitle={t('PracticalInformationsScreen.headerTitle')}>
        <View style={s.loadingContainer}>
          <Loader/>
        </View>
      </PrivateLayout>
    )
  }

  // Render Screen
  return (
    <>
      <PrivateLayout
        headerTitle={t('PracticalInformationsScreen.headerTitle')}
        headerBackOnPress={() => navigation.navigate({ name: 'Home' })}
        headerBackTitle={t('Navigation.naming.Home')}
      >

        {/* Header banner in desktop version */}
        {!isMobile && (
          <View style={s.desktopHeaderContainer}>
            <Icon name={'infos'} style={s.desktopBackgroundIcon} size={153}/>
            <View style={s.desktopTitleContainer}>
              <Icon name={'infos'} style={s.desktopTitleIcon} size={60}/>
              <Label type={'bold'} size={28}>{t('PracticalInformationsScreen.headerTitle')}</Label>
            </View>
          </View>
        )}

        <View style={[s.container, !isMobile && s.containerDesktop]}>

          {/* List */}
          <FlatList
            numColumns={isMobile ? 1 : 3}
            key={isMobile ? '1' : '3'}
            style={{ flex: 1 }}
            data={informations}
            keyExtractor={item => item.id}
            onEndReached={() => {
              if (currentPage < lastPageNumber) {
                setCurrentPage(currentPage + 1)
              }
            }}
            onEndReachedThreshold={1}
            getItemLayout={(data, index) => ({ length: itemHeight, offset: itemHeight * index, index: index })}
            ListEmptyComponent={<Label size={isMobile ? 12 : 18}>{t('PracticalInformationsScreen.empty')}</Label>}
            renderItem={({ item: info }) => (
              <NewsPreview
                size={isMobile ? 'M' : 'L'}
                image={info.mainPictureUrl}
                title={info.title}
                styles={{ container: { width: cardWidth }, image: { width: cardWidth } }}
                onPress={() => setSelectedInfoId(info.rowId)}
              />
            )
            }
          />
        </View>
      </PrivateLayout>

      {/* Modal */}
      <Modal open={!!selectedInfoId} onClose={() => setSelectedInfoId()} style={{ maxHeight: '80%' }}>
        <PracticalInformationsModal informationId={selectedInfoId}/>
      </Modal>
    </>
  )
}

// PropsTypes
type PropsTypes = {
  route: any,
  navigation: StackNavigationProp<RoutesParamList, 'PracticalInformations'>
}
