// @flow
import React, { useState, useEffect, type Node, useCallback } from 'react'
import { Platform } from 'react-native'
import { useFonts } from 'expo-font'
import AppLoading from 'expo-app-loading'
import { RelayEnvironmentProvider } from 'relay-hooks'
import RelayEnvironment from 'src/utils/RelayEnvironment'
import { PullstateProvider, useStoreState } from 'pullstate'
import { PullstateCore } from 'src/store/store'
import UserStore from 'src/store/UserStore'
import Sentry from 'src/utils/Sentry'
import { Translator } from 'src/utils/Translator'
import { SENTRY_DEBUG, SENTRY_DSN, SENTRY_ENVIRONMENT, VERSION } from 'src/environment'

/**
 * ResetCSS style for web
 */
const ResetCSS = `
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  textarea, select, input, button {
    -webkit-appearance: none;
    outline: none!important;
  }
  textarea:focus, select:focus, input:focus, button:focus {
    -webkit-appearance: none;
    outline: none!important;
  }
`

/**
 * EQQITheme.
 */
export default function EQQITheme({
  children
}: any): Node {

  //  Inject reset css style on web
  if (Platform.OS === 'web') {
    const style = document.createElement('style')
    style.textContent = ResetCSS
    document?.head?.append(style)
  }

  // Instantiate Store
  const store = PullstateCore.instantiate()

  // Load EQQI fonts
  const [fontsLoaded] = useFonts({
    'Icons': require('assets/fonts/Icons.ttf'),
    'Raleway': require('assets/fonts/Raleway-Regular.ttf'),
    'Raleway-Bold': require('assets/fonts/Raleway-Bold.ttf'),
    'Raleway-Light': require('assets/fonts/Raleway-Light.ttf'),
    'Raleway-Italic': require('assets/fonts/Raleway-Italic.ttf'),
    'Raleway-Medium': require('assets/fonts/Raleway-Medium.ttf'),
    'Raleway-SemiBold': require('assets/fonts/Raleway-SemiBold.ttf'),
    'Quicksand': require('assets/fonts/Quicksand-Regular.ttf'),
    'Quicksand-Bold': require('assets/fonts/Quicksand-Bold.ttf'),
    'Quicksand-Light': require('assets/fonts/Quicksand-Light.ttf'),
  })

  // Relay environment which refresh when auth tokens change to adapt endpoint and headers
  const { jwtToken, refreshJwtToken, currentUserEmail, currentUserFullname } = useStoreState(UserStore, s => ({
    jwtToken: s.jwtToken,
    refreshJwtToken: s.refreshJwtToken,
    currentUserEmail: s.currentUserEmail,
    currentUserFullname: s.currentUserFullname
  }))
  const [relayEnvironment, setRelayEnvironment] = useState(RelayEnvironment(jwtToken, refreshJwtToken))
  useEffect(() => {
    setRelayEnvironment(RelayEnvironment(jwtToken, refreshJwtToken))
  }, [jwtToken, refreshJwtToken])

  // Sentry popin before send call
  const beforeSendSentry = useCallback((event, hint) => {
    if (event.exception) {
      Sentry.showReportDialog({
        eventId: event.event_id,
        user: {
          email: currentUserEmail,
          name: currentUserFullname
        },
        title: Translator.t('Sentry.title'),
        subtitle: Translator.t('Sentry.subtitle'),
        subtitle2: Translator.t('Sentry.subtitle2'),
        labelName: Translator.t('Sentry.labelName'),
        labelEmail: Translator.t('Sentry.labelEmail'),
        labelComments: Translator.t('Sentry.labelComments'),
        labelClose: Translator.t('Sentry.labelClose'),
        labelSubmit: Translator.t('Sentry.labelSubmit'),
        errorGeneric: Translator.t('Sentry.errorGeneric'),
        errorFormEntry: Translator.t('Sentry.errorFormEntry'),
        successMessage: Translator.t('Sentry.successMessage')
      })
    }
    return event
  }, [currentUserEmail, currentUserFullname])

  // Init Sentry
  useEffect(() => {
    if(SENTRY_ENVIRONMENT !== 'dev') {
      Sentry.init({
        enableNative: false,
        release: VERSION,
        environment: SENTRY_ENVIRONMENT,
        dsn: SENTRY_DSN,
        enableInExpoDevelopment: false,
        debug: SENTRY_DEBUG === 'true',
        enableAutoSessionTracking: true,
        beforeSend: beforeSendSentry,
      })
    }
  }, [currentUserEmail, currentUserFullname])

  // Fonts are not ready yet
  if (!fontsLoaded) {
    return (
      <AppLoading />
    )
  }

  // Return App with providers
  return (
    <PullstateProvider instance={store}>
      <RelayEnvironmentProvider environment={relayEnvironment}>
        {children}
      </RelayEnvironmentProvider>
    </PullstateProvider>
  )

}

