import { StyleSheet } from 'react-native'

// Styles
export default StyleSheet.create({
  container: {
    width: '100%',
    height: 40,
    flexDirection: 'row',
    backgroundColor: 'pink',
    alignItems: 'center',
    paddingHorizontal: 10
  },
  containerDesktop: {
    height: 80,
  },
  title: {
    marginLeft: 10,
    zIndex:1
  },
  linearGradient: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: 40
  },
  linearGradientDesktop: {
    height: 80
  }
})
