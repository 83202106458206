// @flow
import React, { type Node, useState, useEffect } from 'react'
import { Dimensions } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import IntercomButton from 'src/components/common/atoms/IntercomButton/IntercomButton'
import Button from 'src/components/common/atoms/Button'
import Icon from 'src/components/common/atoms/Icon'
import ViewShadow from 'src/components/common/atoms/ViewShadow'
import { Sizes } from 'src/styles'
import s from './MenuBar.style'
import useScreenSizes from 'src/utils/useScreenSizes'

/**
 * MenuBar component.
 */
export default function MenuBar(): Node {

  // Init state
  const navigation = useNavigation()
  const { width: fullWidth, isMobile } = useScreenSizes()

  // Only render on mobile
  if(!isMobile) {
    return null
  }

  // Render MenuBar
  return (
    <ViewShadow
      style={s.menuBar}
      shadowOffset={{width: 0, height: -2}}
      shadowRadius={8}
      shadowOpacity={0.2}
      withColor={{color: '#000', width: fullWidth, height: 40, radius: 0}}
    >

      {/* Menu drawer */}
      <ViewShadow
        style={s.menuBarButtonLeft}
        shadowOffset={{ width: 2, height: 0 }}
        shadowOpacity={0.15}
        withColor={{color: '#000', width: fullWidth / 5 - 8, height: 40, radius: 16}}
        onPress={() => navigation.openDrawer()}
      >
        <Icon name={'menu'} size={Sizes.XM} style={s.menuBarIcon} />
      </ViewShadow>

      {/* Search */}
      <Button
        Icon={<Icon name={'search'} size={Sizes.XM} style={s.menuBarIcon} />}
        style={s.menuBarButton}
        onPress={() => navigation.navigate({ name: 'Search'})}
      />

      {/* Home */}
      <Button
        Icon={<Icon name={'home'} size={Sizes.XM} style={s.menuBarIcon} />}
        style={s.menuBarButton}
        onPress={() => navigation.navigate({ name: 'Home'})}
      />

      {/* Profile */}
      <Button
        Icon={<Icon name={'profile-rounded'} size={Sizes.XM} style={s.menuBarIcon} />}
        style={s.menuBarButton}
        onPress={() => navigation.navigate({ name: 'Profile'})}
      />

      {/* Chat */}
      <IntercomButton fullWidth={fullWidth} />

    </ViewShadow>
  )
}
