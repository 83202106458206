import React, { type Node } from 'react'
import { Image, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import Button from 'src/components/common/atoms/Button'
import Label from 'src/components/common/atoms/Label'
import s from '../CoverDeliveryModal.style'
import useScreenSizes from 'src/utils/useScreenSizes'

/**
 * LockerReadyQRCodeContent component
 */
export default function LockerQRCodeContent ({
  onRetrievedOrder,
  onClose,
  qrCode,
  handleSecretCode
}: PropsTypes): Node {
  /*
   * Init vars
   */
  const { t } = useTranslation()
  const { isMobile } = useScreenSizes()

  /*
   * Render component
   */
  return (
    <View>
      <Label size={isMobile ? 10 : 12}>{t('CoverDeliveryModal.LockerContent.QRCode.description')}</Label>
      <Image
        style={s.contentImage}
        resizeMode={'contain'}
        source={{ uri: qrCode }}
      />
      <View style={s.buttonsContainer}>
        <Button onPress={onRetrievedOrder} palette={'primary'} size={isMobile ? 'S' : 'M'}
                fullWidth>{t('CoverDeliveryModal.LockerContent.doneBtn')}</Button>
        <Button onPress={handleSecretCode} style={s.middleButton} size={isMobile ? 'S' : 'M'}
                fullWidth>{t('CoverDeliveryModal.LockerContent.retrieveBySecretCode')}</Button>
        <Button onPress={onClose} style={s.lastButton} size={isMobile ? 'S' : 'M'}
                fullWidth>{t('CoverDeliveryModal.cancelRecovery')}</Button>
      </View>
    </View>
  )
}

type PropsTypes = {
  onRetrievedOrder: function,
  onClose: function,
  qrCode: string,
  handleSecretCode: function
}