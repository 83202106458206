/**
 * @generated SignedSource<<b12ab00d3ad6fb3065e797489317abca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "lockerId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "lockerId",
        "variableName": "lockerId"
      }
    ],
    "concreteType": "Basket",
    "kind": "LinkedField",
    "name": "SetLockerOfBasket",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "amount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BasketDeliverySettings",
        "kind": "LinkedField",
        "name": "deliverySettings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "collectDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deliveryDate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "price",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Locker",
            "kind": "LinkedField",
            "name": "collectLocker",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Locker",
            "kind": "LinkedField",
            "name": "deliveryLocker",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BasketService",
        "kind": "LinkedField",
        "name": "services",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "minAmountPerOrder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lockerMinAmountPerOrder",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SelectLockerModalMutation",
    "selections": (v4/*: any*/),
    "type": "ClientAppMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SelectLockerModalMutation",
    "selections": (v4/*: any*/)
  },
  "params": {
    "cacheID": "9a5362cd63f23de37a413dd9e007b97a",
    "id": null,
    "metadata": {},
    "name": "SelectLockerModalMutation",
    "operationKind": "mutation",
    "text": "mutation SelectLockerModalMutation(\n  $lockerId: Int!\n) {\n  SetLockerOfBasket(lockerId: $lockerId) {\n    id\n    rowId\n    amount\n    deliverySettings {\n      collectDate\n      deliveryDate\n      price\n      collectLocker {\n        name\n      }\n      deliveryLocker {\n        name\n      }\n    }\n    services {\n      rowId\n      name\n      minAmountPerOrder\n      lockerMinAmountPerOrder\n    }\n  }\n}\n"
  }
};
})();

node.hash = "9abfd250a295c91d98cde1e75e802f70";

module.exports = node;
