import { StyleSheet } from 'react-native'

// Styles
export default StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    flex: 1,
    backgroundColor: '#FFF'
  },
  mainContainerMobile: {
    flexDirection: 'column'
  },
  imgContainer: {
    width: '67%',
    height: '100%',
  },
  imgContainerMobile: {
    width: '100%',
    height: 'unset'
  },
  textContainer: {
    paddingRight: 60,
    width: '33%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center'
  },
  textContainerMobile: {
    width: '100%',
    height: 'unset',
    paddingLeft: 50,
    paddingRight: 50
  },
  errorImage: {
    resizeMode: 'contain',
    height: '100%',
    width: '100%'
  },
  errorImageMobile: {
    height: 250
  },
  logoImage: {
    resizeMode: 'contain',
    height: 120,
    width: '100%'
  },
  logoImageMobile: {
    height: 40,
    marginTop:30,
    marginBottom: 40
  },
  title: {
    marginTop: 160
  },
  subTitle: {
    marginTop: 30,
    marginBottom: 60
  },
  subTitleMobile: {
    marginTop: 10,
    marginBottom: 30
  }
})