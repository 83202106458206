import { useRef } from 'react'

/**
 * Init inputs ref and a function to focus the next input
 * @param values
 * @returns {[{}, focusNextInputWithName]}
 */
export default function useInputRefs (values) {
  const inputRefs = {}
  Object.keys(values).forEach(val => {
    inputRefs[val] = useRef()
  })

  // take the key of the next input to focus
  const focusNextInputWithName = (nextInput) => {
    inputRefs[nextInput].current.focus()
  }

  return [
    inputRefs,
    focusNextInputWithName
  ]
}