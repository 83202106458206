// @flow
import React, { type Node } from 'react'
import { Text, Platform } from 'react-native'
import SkeletonContent from 'react-native-skeleton-content-nonexpo'
import { Fonts, Sizes } from 'src/styles'
import s from './Label.style'

/**
 * Label atom.
 */
export default function Label({
  style,
  type = 'default',
  types = [],
  font,
  size,
  color,
  children = null,
  skeleton = false,
  numberOfLines
}: PropsTypes): Node {

  // Init vars
  const fontStyle = font ? font.split('.') : null

  // Skeleton state
  if (skeleton && children === null) {
    return (<SkeletonContent
      containerStyle={[{marginLeft: 1, marginBottom: -6}, skeleton.width === '100%' && {flex: 1}, Platform.OS === 'web' && {display: 'inline'}]}
      isLoading={true}
      layout={[{...skeleton, marginBottom: -3}]}
    />)
  }

  // Render atom
  return (
    <Text
      style={[
        type && s[type],
        ...types.map(type => s[type]),
        color && { color: color },
        font && fontStyle && { fontFamily: Fonts[fontStyle[0]][fontStyle[1]] },
        size && { fontSize: size },
        style
      ]}
      numberOfLines={numberOfLines}
    >
      {children}
    </Text>
  )
}

// PropsTypes
type PropsTypes = {
  style?: any,
  type?: 'default' | 'bold' | 'smallMargin' | 'small' | 'h1' | 'h2' | 'h3' | 'alert'| 'error' | 'warning' | 'success',
  types?: Array<'default' | 'bold' | 'smallMargin' | 'small' | 'h1' | 'h2' | 'h3' | 'alert'| 'error' | 'warning' | 'success'>,
  font?: string,
  size?: number,
  weight?: string,
  color?: string,
  numberOfLines?: number,
  skeleton?: any,
  children?: any
}