import React, { type Node } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import Label from 'src/components/common/atoms/Label'
import Button from 'src/components/common/atoms/Button'
import Icon from 'src/components/common/atoms/Icon'
import EqqiCalendar from 'src/components/common/atoms/EqqiCalendar'
import s from '../ProfileAgendaScreen.style'

/**
 * CalendarModal component
 */
export default function CalendarModal ({
  onClose,
  filterDate,
  setFilterDate
}: PropsTypes): Node {

  const { t } = useTranslation()

  /*
   * Render component
   */
  return (
    <View>
      <View style={s.modalHeader}>
        <Label size={15}>{t('ProfileAgendaScreen.overview')}</Label>
        <Button
          onPress={onClose}
          style={s.closeModalButton}
          Icon={<Icon name={'plus'} style={s.closeModalButtonIcon}/>}
        />
      </View>

      {/* Calendar to see events and select a day */}
      <View>
        {/* TODO : real data on calendar*/}
        <EqqiCalendar
          handleDayPress={(date) => {
            setFilterDate(date)
            onClose()
          }}
          defaultSelectedDay={filterDate}
        />
      </View>
    </View>
  )
}

type PropsTypes = {
  filterDate: string,
  setFilterDate: function
}