/**
 * @generated SignedSource<<517b4ea23605fa5f318278e1f82be48d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "amount"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "browserInfo"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "creditCard"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "creditCardEnableRecurring"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "payment3DS2Action"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "paymentMethod"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "recurringCreditCard"
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "amount",
        "variableName": "amount"
      },
      {
        "kind": "Variable",
        "name": "browserInfo",
        "variableName": "browserInfo"
      },
      {
        "kind": "Variable",
        "name": "creditCard",
        "variableName": "creditCard"
      },
      {
        "kind": "Variable",
        "name": "creditCardEnableRecurring",
        "variableName": "creditCardEnableRecurring"
      },
      {
        "kind": "Variable",
        "name": "payment3DS2Action",
        "variableName": "payment3DS2Action"
      },
      {
        "kind": "Variable",
        "name": "paymentMethod",
        "variableName": "paymentMethod"
      },
      {
        "kind": "Variable",
        "name": "recurringCreditCard",
        "variableName": "recurringCreditCard"
      }
    ],
    "concreteType": "FillWalletOutput",
    "kind": "LinkedField",
    "name": "FillWallet",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "creditsBalance",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "resultCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CreditCard3DS2Action",
        "kind": "LinkedField",
        "name": "creditCard3DS2Action",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paymentData",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paymentMethodType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "token",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "FillWalletModalRecurringCardMutation",
    "selections": (v7/*: any*/),
    "type": "ClientAppMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Operation",
    "name": "FillWalletModalRecurringCardMutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "3e2605b706caa3f4e195d00d26e81c36",
    "id": null,
    "metadata": {},
    "name": "FillWalletModalRecurringCardMutation",
    "operationKind": "mutation",
    "text": "mutation FillWalletModalRecurringCardMutation(\n  $amount: FillWalletAmount!\n  $paymentMethod: PaymentMethod\n  $browserInfo: BrowserInfoInput\n  $creditCard: PaymentCreditCardInput\n  $creditCardEnableRecurring: Boolean\n  $recurringCreditCard: PaymentRecurringCreditCardInput\n  $payment3DS2Action: Payment3DS2ActionInput\n) {\n  FillWallet(amount: $amount, paymentMethod: $paymentMethod, browserInfo: $browserInfo, creditCard: $creditCard, creditCardEnableRecurring: $creditCardEnableRecurring, recurringCreditCard: $recurringCreditCard, payment3DS2Action: $payment3DS2Action) {\n    user {\n      id\n      creditsBalance\n    }\n    resultCode\n    creditCard3DS2Action {\n      paymentData\n      paymentMethodType\n      token\n      type\n    }\n  }\n}\n"
  }
};
})();

node.hash = "3e7a2659ba9b412bf7b80db4f017dc19";

module.exports = node;
