/**
 * @generated SignedSource<<0d6e04a8d8226e383a07afbe069ff584>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "orderId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "orderId",
        "variableName": "orderId"
      }
    ],
    "concreteType": "LockerOpeningCode",
    "kind": "LinkedField",
    "name": "GetOrderOpeningCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClosetContentMutation",
    "selections": (v1/*: any*/),
    "type": "ClientAppMutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClosetContentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f5cd82473b17ae327a4a50e335734052",
    "id": null,
    "metadata": {},
    "name": "ClosetContentMutation",
    "operationKind": "mutation",
    "text": "mutation ClosetContentMutation(\n  $orderId: Int!\n) {\n  GetOrderOpeningCode(orderId: $orderId) {\n    code\n  }\n}\n"
  }
};
})();

node.hash = "6661d4224e04017344729a2c5ae70d81";

module.exports = node;
