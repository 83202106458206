import { StyleSheet } from 'react-native'
import { Colors } from 'src/styles'

export default StyleSheet.create({
  container: {
    marginLeft: 10,
    marginRight: 10,
    paddingBottom: 10
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerRight: {
    flex: 8,
    alignItems: 'flex-end',
    marginRight: 20
  },
  headerRightCashback: {
    marginTop: 5
  },
  headerPlaceholder: {
    flex: 8
  },

  desktopHeaderContainer: {
    paddingVertical: 20,
    paddingLeft: 20,
    backgroundColor: Colors.orange100Less80,
    marginBottom: 30,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  desktopBackgroundIcon: {
    position: 'absolute',
    color: Colors.white100Less50,
    right: -4,
    transform: [{ rotate: '-14deg' }]
  },
  desktopTitleContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  desktopTitleIcon: {
    color: Colors.orange100,
    marginRight: 14
  },
  twoColumnsLayout: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 40
  },
  leftColumn: {
    marginHorizontal: 20,
    flex:2
  },
  rightColumn: {
    marginLeft: 10,
    marginRight: 20,
    flex:1
  }
})

export const modalStyle = StyleSheet.create({
  title: {
    marginBottom:36
  },
  loader: {
    marginBottom:36
  },
  icon: {
    margin: 'auto',
    marginBottom: 36
  },
  buttonContainer: {
    marginLeft: 15,
    marginRight: 15
  },
  selectInput: {
    marginTop:30,
    marginBottom:30
  },
  selectSubmit: {
    marginBottom: 25,
    minWidth: 123
  }
})
