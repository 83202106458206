import { StyleSheet } from 'react-native'
import { Colors, Fonts } from 'src/styles'
import { white100 } from 'src/styles/colors'

export default StyleSheet.create({
  // Header
  headerImage: {
    width: '100%',
    height: 100,
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center'
  },
  headerImageDesktop: {
    height: 280
  },
  headerImageTablet: {
    height: 130
  },
  headerContainer: {
    height: 100,
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10,
    paddingRight: 10,
    zIndex: 2,
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  headerContainerNotMobile: {
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: 0,
    justifyContent: 'center'
  },
  headerContainerDesktop: {
    height: 280,
  },
  headerContainerTablet: {
    height: 130,
  },
  headerContentContainer: {
    justifyContent: 'space-between',
    height: '100%'
  },
  headerContentContainerDesktop: {
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerLogo: {
    height: 40,
    width: 40
  },
  helloHeader: {
    marginBottom: 40,
    textAlign: 'center',
    color: Colors.white100,
  },
  helloHeaderTablet: {
    marginBottom: 20
  },
  searchInput: {
    width: 440
  },
  searchIcon: {
    color: '#CCC'
  },
  walletContainer: {
    flexDirection: 'row'
  },
  walletIcon: {
    marginRight: 5
  },
  walletLabel: {
    marginRight: 10
  },
  headerButtonContainer: {
    position: 'absolute',
    top: 15,
    right: 15
  },
  headerButton: {
    width: 30,
    height: 30,
    borderRadius: 30,
    borderWidth: 0,
    padding: 0,
    backgroundColor: Colors.lightGrey90
  },
  headerButtonIcon: {
    width: 30,
    height: 30,
    borderRadius: 30,
    padding: 6
  },
  headerButtonCounter: {
    position: 'absolute',
    top: -5,
    right: -5,
    width: 16,
    height: 16,
    borderRadius: 30,
    backgroundColor: Colors.orange100,
    textAlign: 'center',
    fontSize: 10,
    lineHeight: 18,
    fontFamily: Fonts.primary.bold,
    color: Colors.white100,
  },
  headerGradient: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: 100,
    zIndex: 1
  },
  headerGradientDesktop: {
    height: 280,
  },
  headerGradientTablet: {
    height: 130,
  },

  // Alert
  alert: {
    height: 34,
    backgroundColor: 'rgba(219, 84, 26, 0.1)',
    flexDirection: 'row',
    padding: 10,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  alertDesktop: {
    height: 50,
    justifyContent: 'flex-start',
    paddingLeft: 40
  },
  alertTablet: {
    height: 39,
    justifyContent: 'flex-start',
    paddingLeft: 20
  },
  alertButtonDesktop: {
    marginLeft: 20
  },
  alertButtonTablet: {
    marginLeft: 10
  },

  // Lists
  flatList: {
/* TODO: vérifier le design 0 height + margin bottom avec Pauline */
// height: 62
  },
  flatListNotMobile: {
    height: 180,
    marginRight: -10
  },
  listLoader: {
    height: 62,
    justifyContent: 'center'
  },
  listContainerMobile: {
    marginLeft: 10,
    marginVertical: 10
  },
  listContainerLeft: {
    flex: 1,
    marginRight: 0
  },
  listContainerRightDesktop: {
    flex: 1,
    marginLeft: 40
  },
  listContainerRightTablet: {
    flex: 1,
    marginLeft: 20
  },
  listTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  listTitleContainerDesktop: {
    marginBottom: 20
  },
  listButton: {
    alignSelf: 'flex-start'
  },
  listButtonCatalog: {
    marginTop: 8,
    alignSelf: 'flex-start'
  },
  listButtonDesktop: {
    alignSelf: 'center'
  },
  listTitle: {
    marginBottom: 5
  },
  listLinearGradient: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    zIndex: 1,
    width: 20
  },
  articlesList: {
    height: 110
  },
  articlesListNotMobile: {
    height: 200
  },

  twoColumnsLayout: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 40
  },
  mainScrollViewDesktop: {
    paddingTop: 40,
    paddingRight: 40,
    paddingBottom: 0,
    paddingLeft: 40
  },
  mainScrollViewTablet: {
    paddingTop: 20,
    paddingRight: 20,
    paddingBottom: 0,
    paddingLeft: 20
  },

})

export const cardLayout = { width: 216, height: 52, marginRight: 10, marginBottom: 10 }
export const newsLayout = { width: 150, height: 100, marginRight: 10, marginBottom: 10 }